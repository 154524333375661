import axios, { Axios } from "axios";
import { success } from "daisyui/src/colors";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../BaseUrl";
import Heading from "../../Shared/Heading";

const AddFlatInformation = () => {
  const [buildingInfo, setBuildingInfo] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(baseUrl + `/projects/building/data/${id}`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        setBuildingInfo(res.data.data);
      });
  }, [id]);

  const { register, handleSubmit } = useForm();
  const onsubmit = (data) => {
    let newData = { ...data, project_id: id, type: 1 };

    axios
      .post(baseUrl + `/flat_or_plot/store`, newData, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success(res?.data?.message);
          navigate(
            `/dashboard/buildingandflatdetails/viewflatsoldunsolddetails/${id}`
          );
        } else {
          res?.data?.message?.map((data) => toast.error(data));
        }
      });
  };

  return (
    <div>
      <Heading>
        <Link to="/dashboard/buildingandflatdetails">
          <i className="fa-solid fa-arrow-left"></i> Add Flat Information
        </Link>
      </Heading>
      <div className="p-5 bg-white shadow-2xl w-[64%] rounded-lg mx-auto my-8">
        <div className="flex">
          <div className="form-control w-full max-w-sm m-2">
            <label className="label">
              <span className="label-text text-[#717A82] text-md font-bold">
                Building Name
              </span>
            </label>
            <input
              disabled
              type="text"
              value={buildingInfo?.name}
              className="input input-bordered input-sm w-full max-w-sm"
            />
          </div>
        </div>
        <div className="grid lg:grid-cols-4 gap-2">
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text text-[#717A82] text-md font-bold">
                Road No.
              </span>
            </label>
            <input
              disabled
              type="text"
              value={buildingInfo?.road_no}
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text text-[#717A82] text-md font-bold">
                Building Number
              </span>
            </label>
            <input
              disabled
              type="text"
              value={buildingInfo?.project_no}
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text text-[#717A82] text-md font-bold">
                Building Face Direction
              </span>
            </label>
            <input
              disabled
              type="text"
              value={buildingInfo?.face_direction}
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text text-[#717A82] text-md font-bold">
                Building Location
              </span>
            </label>
            <input
              disabled
              type="text"
              value={buildingInfo?.location}
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
        </div>

        <h1 className="text-start text-2xl font-bold underline underline-offset-4 text-[#717A82] mx-2 mt-8">
          Add Flat Details
        </h1>

        <form onSubmit={handleSubmit(onsubmit)}>
          <div className="grid lg:grid-cols-4 gap-2">
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text text-[#717A82] text-md font-bold">
                  File No.
                </span>
              </label>
              <input
                {...register("file_no")}
                name="file_no"
                type="text"
                placeholder="File_no"
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text text-[#717A82] text-md font-bold">
                  Flat Number
                </span>
              </label>
              <input
                {...register("flat_number")}
                name="flat_number"
                placeholder="number"
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>

            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text text-[#717A82] text-md font-bold">
                  Flat Floor No.
                </span>
              </label>
              <input
                {...register("floor_no")}
                name="floor_no"
                placeholder="floor_no"
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text text-[#717A82] text-md font-bold">
                  Flat Face Direction
                </span>
              </label>
              <select
                {...register("face_direction")}
                className="select select-bordered select-sm"
              >
                <option value={""}>Select</option>
                <option value={"East"}>East</option>
                <option value={"West"}>West</option>
                <option value={"North"}>North</option>
                <option value={"South"}>South</option>
                <option value={"North-East"}>North-East</option>
                <option value={"South-East"}>South-East</option>
                <option value={"North-West"}>North-West</option>
                <option value={"South-West"}>South-West</option>
              </select>
            </div>
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text text-[#717A82] text-md font-bold">
                  Flat Size
                </span>
              </label>
              <input
                {...register("size")}
                name="size"
                placeholder="size"
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
          </div>

          <div className="flex flex-wrap justify-start mt-8">
            <Link to="/dashboard/buildingandflatdetails">
              <button
                type="submit"
                className="uppercase  text-white text-[14px] font-semibold bg-[#E30B0B] rounded-md py-2.5 px-16 m-2"
              >
                Cancel
              </button>
            </Link>
            {/* <Link to="/dashboard/buildingandflatdetails"> */}
            <button
              type="submit"
              className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 mb-10 m-2"
            >
              Add Flat
            </button>
            {/* </Link> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddFlatInformation;
