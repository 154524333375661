import React from "react";
import Heading from "../../Shared/Heading";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

let allDatas = [];
const RegistrationCompleteButPaymentDueList = () => {
  const [
    registrationCompleteButPaymentDueList,
    setRegistrationCompleteButPaymentDueList,
  ] = useState([]);
  useEffect(() => {
    axios
      .get(baseUrl + `/reports/registrationCompleteButPaymentDueList`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        const registrationCompleteButPaymentDueListCustomers =
          res.data.data.filter((item) => item.customers.length > 0);
        // console.log(registrationCompleteButPaymentDueListCustomers);
        allDatas = registrationCompleteButPaymentDueListCustomers;
        setRegistrationCompleteButPaymentDueList(
          registrationCompleteButPaymentDueListCustomers
        );
      });
  }, []);

  //----------filter functionality start ---------------
  const handleFilterData = (e) => {
    console.log(e);
    if (e === "all") {
      setRegistrationCompleteButPaymentDueList(allDatas);
    } else if (e === "Total Flat/Plot Amount") {
      setRegistrationCompleteButPaymentDueList(
        allDatas?.filter((list) => list?.price_information?.total_money > 0)
      );
    } else if (e === "Total Payment Complete Amount") {
      setRegistrationCompleteButPaymentDueList(
        allDatas?.filter(
          (list) =>
            parseInt(
              list?.price_information?.per_month_installment_amount_paid
            ) +
              parseInt(list?.price_information?.total_additional_amount_paid) +
              parseInt(list?.price_information?.total_booking_money_paid) +
              parseInt(list?.price_information?.total_car_parking_paid) +
              parseInt(list?.price_information?.total_downpayment_amount_paid) +
              parseInt(list?.price_information?.total_installment_amount_paid) +
              parseInt(list?.price_information?.total_utility_charge_paid) >
            0
        )
      );
    } else if (e === "Total Payment Due Amount") {
      setRegistrationCompleteButPaymentDueList(
        allDatas?.filter(
          (list) =>
            parseInt(list?.price_information?.total_money) -
              (parseInt(
                list?.price_information?.per_month_installment_amount_paid
              ) +
                parseInt(
                  list?.price_information?.total_additional_amount_paid
                ) +
                parseInt(list?.price_information?.total_booking_money_paid) +
                parseInt(list?.price_information?.total_car_parking_paid) +
                parseInt(
                  list?.price_information?.total_downpayment_amount_paid
                ) +
                parseInt(
                  list?.price_information?.total_installment_amount_paid
                ) +
                parseInt(list?.price_information?.total_utility_charge_paid)) >
            0
        )
      );
    } else if (e === "Registration & Mutation Due Amount") {
      setRegistrationCompleteButPaymentDueList(
        allDatas?.filter(
          (list) =>
            parseInt(list?.registration_amount?.mutation_cost_amount) +
              parseInt(list?.registration_amount?.registry_amount) -
              (parseInt(list?.registration_amount?.mutation_cost_payment) +
                parseInt(list?.registration_amount?.registry_payment)) >
              0 ||
            parseInt(list?.registration_amount?.mutation_cost_amount) +
              parseInt(list?.registration_amount?.registry_amount) -
              (parseInt(list?.registration_amount?.mutation_cost_payment) +
                parseInt(list?.registration_amount?.registry_payment)) <
              0
        )
      );
    } else if (e === "Registration Deed complete Date") {
      setRegistrationCompleteButPaymentDueList(
        allDatas?.filter(
          (list) => list?.plot_or_flat_details?.registration_date
        )
      );
    } else if (e === "Registration Deed number") {
      setRegistrationCompleteButPaymentDueList(
        allDatas?.filter((list) => list?.plot_or_flat_details?.sub_deed_no)
      );
    } else if (e === "Mutation Submission Date") {
      setRegistrationCompleteButPaymentDueList(
        allDatas?.filter((list) => list?.mutation_details?.registration_date)
      );
    } else if (e === "Mutation Submission Number") {
      setRegistrationCompleteButPaymentDueList(
        allDatas?.filter((list) => list?.mutation_details?.misscase_no)
      );
    }
  };
  //----------filter functionality End ---------------

  //----------Print a div or section functionality start-----------
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //----------Print a div or section functionality end-------------

      // ---------------search table data start------------------
      const handleSearch = (e) => {
        // console.log(e.target.value);
        // Declare variables
        let status = 0;
        let filterBy = e.target.value.toUpperCase();
        let table = document.getElementById("dataTable");
        let tr = table.querySelectorAll(".searchData");
        let i = 0;
        let j = 0;
        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
          let tempTd = tr[i].getElementsByTagName("td");
          for (j = 0; j < tempTd.length; j++) {
            let td = tempTd[j];
            let txtValue;
            if (td) {
              txtValue = td.textContent || td.innerText;
              if (txtValue.toUpperCase().indexOf(filterBy) > -1) {
                status = 1;
              }
              if (!status) {
                status = 0;
              }
            }
          }
          if (status) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      };
      // ------------------search table data end-----------------
  return (
    <div>
      <div>
        <Heading>Registration complete but payment due list</Heading>
        <div className="p-5 whiteBackground">
          <div className="flex flex-wrap justify-between items-center my-5">
            <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
            <div className="my-2">
              <div className="form-control">
                <div className="flex flex-wrap items-center">
                  <h1 className="m-3 text-[#717A82] font-bold">
                    Search by date
                  </h1>
                  <input
                    name="complete_date"
                    type="date"
                    className="input input-bordered input-sm max-w-xs"
                  />
                  <h1 className="m-3 text-[#717A82] font-bold">Search for</h1>
                  <select
                    onChange={(e) => handleFilterData(e.target.value)}
                    className="select select-sm select-bordered  my-2 rounded-[5px] bg-[#ECECEC]"
                  >
                    <option value={"all"}>All</option>
                    <option value="Total Flat/Plot Amount">
                      Total Flat/Plot Amount
                    </option>
                    <option value="Total Payment Complete Amount">
                      Total Payment Complete Amount
                    </option>
                    <option value="Total Payment Due Amount">
                      Total Payment Due Amount
                    </option>
                    <option value="Registration & Mutation Due Amount">
                      Registration & Mutation Due Amount
                    </option>
                    <option value="Registration Deed complete Date">
                      Registration Deed complete Date
                    </option>
                    <option value="Registration Deed number">
                      Registration Deed number
                    </option>
                    <option value="Mutation Submission Date">
                      Mutation Submission Date
                    </option>
                    <option value="Mutation Submission Number">
                      Mutation Submission Number
                    </option>
                    <option value="Mutation Complete Date">
                      Mutation Complete Date
                    </option>
                  </select>
                  <input
                     onChange={handleSearch}
                    id="searchInput"
                    type="text"
                    placeholder="Search..."
                    className="input input-bordered input-sm max-w-xs ml-1"
                  />
                </div>
              </div>
            </div>
          </div>
          {registrationCompleteButPaymentDueList?.length > 0 ? (
            <div ref={componentRef} className="overflow-x-auto max-h-[60vh]">
              <table id="dataTable" className="table table-zebra w-full max-h-[60vh]">
                <thead className="text-[#00CA08]">
                  <tr className="sticky top-0">
                    <th>Customer's Id</th>
                    <th>Customer's Name</th>
                    <th>File No.</th>
                    <th>
                      Building/Land <br /> No.
                    </th>
                    <th>
                      Flat/Plot <br /> Size
                    </th>
                    <th>
                      Total <br />
                      Flat/Plot <br />
                      Amount
                    </th>
                    <th>
                      Total Payment <br />
                      Complete <br />
                      Amount
                    </th>
                    <th>
                      Total Payment <br />
                      Due <br />
                      Amount
                    </th>
                    <th>
                      Registration & <br />
                      Mutation Due <br />
                      Amount
                    </th>
                    <th>
                      Registration Deed <br />
                      complete Date
                    </th>
                    <th>
                      Registration <br />
                      Deed <br />
                      number
                    </th>
                    <th>
                      Mutation <br />
                      Submission <br />
                      Date
                    </th>
                    <th>
                      Mutation <br />
                      Submission <br />
                      Number
                    </th>
                    <th>
                      Mutation <br />
                      Complete <br />
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {registrationCompleteButPaymentDueList.map((list) => {
                    return (
                      <tr className="searchData" key={list.id}>
                        <td>{list.id}</td>
                        <td>
                          {list?.customers?.map((customer, index) => (
                            <p key={index}>
                              {customer?.name ? customer?.name : "--"}
                            </p>
                          ))}
                        </td>
                        <td>{list?.flat_or_plot_details?.file_no}</td>
                        <td>
                          {list?.project?.project_no
                            ? list?.project?.project_no
                            : "--"}
                        </td>
                        <td>{list?.flat_or_plot_details?.size}</td>
                        <td>{list?.price_information?.total_money} TK</td>
                        <td>
                          {parseInt(
                            list?.price_information
                              ?.per_month_installment_amount_paid
                          ) +
                            parseInt(
                              list?.price_information
                                ?.total_additional_amount_paid
                            ) +
                            parseInt(
                              list?.price_information?.total_booking_money_paid
                            ) +
                            parseInt(
                              list?.price_information?.total_car_parking_paid
                            ) +
                            parseInt(
                              list?.price_information
                                ?.total_downpayment_amount_paid
                            ) +
                            parseInt(
                              list?.price_information
                                ?.total_installment_amount_paid
                            ) +
                            parseInt(
                              list?.price_information?.total_utility_charge_paid
                            )}{" "}
                          TK
                        </td>
                        <td>
                          {parseInt(list?.price_information?.total_money) -
                            (parseInt(
                              list?.price_information
                                ?.per_month_installment_amount_paid
                            ) +
                              parseInt(
                                list?.price_information
                                  ?.total_additional_amount_paid
                              ) +
                              parseInt(
                                list?.price_information
                                  ?.total_booking_money_paid
                              ) +
                              parseInt(
                                list?.price_information?.total_car_parking_paid
                              ) +
                              parseInt(
                                list?.price_information
                                  ?.total_downpayment_amount_paid
                              ) +
                              parseInt(
                                list?.price_information
                                  ?.total_installment_amount_paid
                              ) +
                              parseInt(
                                list?.price_information
                                  ?.total_utility_charge_paid
                              ))}{" "}
                          TK
                        </td>
                        <td>
                          {parseInt(
                            list?.registration_amount?.mutation_cost_amount
                          ) +
                            parseInt(
                              list?.registration_amount?.registry_amount
                            ) -
                            (parseInt(
                              list?.registration_amount?.mutation_cost_payment
                            ) +
                              parseInt(
                                list?.registration_amount?.registry_payment
                              ))}{" "}
                          TK
                        </td>
                        <td>
                          {list?.plot_or_flat_details?.registration_date
                            ? list?.plot_or_flat_details?.registration_date
                            : "dd-mm-yy"}
                        </td>
                        <td>
                          {list?.plot_or_flat_details?.sub_deed_no
                            ? list?.plot_or_flat_details?.sub_deed_no
                            : "N/A"}
                        </td>
                        <td>
                          {list?.mutation_details?.registration_date
                            ? list?.mutation_details?.registration_date
                            : "dd-mm-yy"}
                        </td>
                        <td>
                          {list?.mutation_details?.misscase_no
                            ? list?.mutation_details?.misscase_no
                            : "N/A"}
                        </td>
                        <td>dd-mm-yy</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <h1 className="text-center font-bold my-5 py-5">No Data Found</h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegistrationCompleteButPaymentDueList;
