import React, { useState } from "react";
import { useForm } from "react-hook-form";

const AddCustomer = () => {
  const [indexes, setIndexes] = useState([]);
  const [counter, setCounter] = useState(0);
  const { register, handleSubmit } = useForm();

  const addFriend = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
  };

  const removeFriend = (index) => () => {
    setIndexes((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index),
    ]);
    setCounter((prevCounter) => prevCounter - 1);
  };

  const clearFriends = () => {
    setIndexes([]);
  };

  const onSubmit = (data) => {};

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {indexes.map((index) => {
        const fieldName = `customer[${index}]`;
        return (
          <fieldset name={fieldName} key={fieldName}>
            <label>
              First Name {index}:
              <input
                {...register(`${fieldName}.customer_name`)}
                type="text"
                name={`${fieldName}.customer_name`}
              />
            </label>

            <label>
              Last Name {index}:
              <input
                {...register(`${fieldName}.nominee_name`)}
                type="text"
                name={`${fieldName}.nominee_name`}
              />
            </label>
            <button type="button" onClick={removeFriend(index)}>
              Remove
            </button>
          </fieldset>
        );
      })}

      <button type="button" onClick={addFriend}>
        Add Friend
      </button>
      <button type="button" onClick={clearFriends}>
        Clear Friends
      </button>
      <input type="submit" />
    </form>
  );
};

export default AddCustomer;
