import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../BaseUrl";
import Heading from "../../Shared/Heading";

const AddPriceAmount = () => {
  const { state } = useLocation();
  const [singleData, setSingleData] = useState({});
  const navigate = useNavigate();
  const formikProps = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: state?.id,
      registry_amount: singleData?.registry_amount,
      registry_amount_schedule_date: singleData?.registry_amount_schedule_date,
      mutation_cost_amount: singleData?.mutation_cost_amount,
      mutation_cost_schedule_date: singleData?.mutation_cost_schedule_date,
      power_of_attorney_cost_amount: singleData?.power_of_attorney_cost_amount,
      power_of_attorney_cost_schedule_date:
        singleData?.power_of_attorney_cost_schedule_date,
    },
    onSubmit: (values) => {
      // console.log(values);
      axios
        .post(baseUrl + `/registration/amount/add_price`, values, {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        })
        .then((res) => {
          if (res?.status == 200) {
            toast.success("Price amount added successfully..");
            navigate("/dashboard/registrationamount");
          }
        })
        .catch((res) => {
          if (res?.response?.status == 422) {
            toast.error(res?.response?.data?.message);
          }
        });
    },
  });

  useEffect(() => {
    axios
      .get(
        baseUrl + `/registration/amount/due_details/${state?.id}`,
       
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        if (res?.status == 200) {
          setSingleData(res?.data?.data);
        }
      });
  }, [state?.id]);
  return (
    <div>
      <Heading>
        <Link to="/dashboard/registrationamount">
          <i className="fa-solid fa-arrow-left"></i> Add Price Amount
        </Link>
      </Heading>
      <form onSubmit={formikProps.handleSubmit}>
        <div className="p-5 bg-white shadow-2xl lg:w-[43%] rounded-lg mx-auto my-8">
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text">File No.</span>
            </label>
            <input
              disabled
              type="text"
              value={singleData?.file_no}
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
          <div className="flex flex-wrap ">
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text">Customer's Name</span>
              </label>
              <input
                disabled
                type="text"
                value={singleData?.customers?.map(
                  (item) =>
                    `${item?.name} ${
                      singleData?.customers?.length > 1 ? "/" : ""
                    }`
                )}
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text">Customer's Id</span>
              </label>
              <input
                disabled
                type="text"
                value={singleData?.customers?.map(
                  (item) =>
                    `${item?.nid_number}${
                      singleData?.customers?.length > 1 ? "/" : ""
                    }`
                )}
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
          </div>
          <div className="flex flex-wrap ">
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text font-bold text-[#717A82]">
                  Land Registry Amount
                </span>
              </label>
              <span className="flex">
                <input
                  type="text"
                  name="registry_amount"
                  value={formikProps?.values?.registry_amount}
                  className="input input-bordered input-sm w-full max-w-xs"
                  onChange={formikProps.handleChange}
                />

                <input
                  type="date"
                  name="registry_amount_schedule_date"
                  className="input input-bordered input-sm   max-w-xs id-custom-input-date ml-2"
                  value={formikProps.values.registry_amount_schedule_date}
                  onChange={formikProps.handleChange}
                />
              </span>
            </div>
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text font-bold text-[#717A82]">
                  Mutation Cost
                </span>
              </label>
              <span className="flex">
                <input
                  type="text"
                  name="mutation_cost_amount"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.mutation_cost_amount}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
                <input
                  type="date"
                  name="mutation_cost_schedule_date"
                  value={formikProps.values.mutation_cost_schedule_date}
                  className="input input-bordered input-sm  max-w-xs ml-2"
                  onChange={formikProps.handleChange}
                />
              </span>
            </div>
          </div>
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text font-bold text-[#717A82]">
                Power Of Attorney Cost
              </span>
            </label>
            <span className="flex">
              <input
                type="text"
                name="power_of_attorney_cost_amount"
                value={formikProps.values.power_of_attorney_cost_amount}
                onChange={formikProps.handleChange}
                className="input input-bordered input-sm w-full max-w-xs"
              />

              <input
                type="date"
                name="power_of_attorney_cost_schedule_date"
                value={formikProps.values.power_of_attorney_cost_schedule_date}
                className="input input-bordered input-sm  max-w-xs ml-2"
                onChange={formikProps.handleChange}
              />
            </span>
          </div>

          <div className="flex flex-wrap justify-start mt-8">
            <Link to="/dashboard/registrationamount">
              <button
                type="submit"
                className="uppercase  text-white text-[14px] font-semibold bg-[#E30B0B] rounded-md py-2.5 px-16 m-2"
              >
                Cancel
              </button>
            </Link>
            {/* <Link to="/dashboard/registrationamount"> */}
            <button
              type="submit"
              className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 mb-10 m-2"
            >
              Submit
            </button>
            {/* </Link> */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddPriceAmount;
