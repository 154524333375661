import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../BaseUrl";
import AddButton from "../../Shared/AddButton";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import * as html2pdf from "html2pdf.js";
import { useReactToPrint } from "react-to-print";
import Loader from "../../Shared/Loader";



const MutationDetails = () => {
  const { register, handleSubmit, reset } = useForm();
  const [mutationData, setMutationData] = useState(null);
  const [fileList, setFileList] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [customerName, setCustomerName] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [customeAddInfo, setCustomerAddInfo] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const [reFetch, setReFetch] = useState(false);


  useEffect(() => {
    axios
      .get(
        baseUrl + "/registration/mutationDetails/manage",
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setMutationData(res?.data?.data?.mutationDetails);
      });
    axios
      .get(baseUrl + "/registration/plotOrFlat/addCustomerToPlotOrFlat", {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        setFileList(res?.data?.file_info);
      });
  }, [reFetch]);

  useEffect(() => {
    if (fileId != "null") {
      axios
        .get(baseUrl + `/registration/plotOrFlat/getCustomerInfo/${fileId}`, {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        })
        .then((res) => {
          // console.log(res?.data?.customerInfo[0]);
          setFileData(res?.data?.customerInfo[0]);
          let cusName = "";
          let cusId = "";
          for (
            let i = 0;
            i < res?.data?.customerInfo[0]?.customers?.length;
            i++
          ) {
            if (i == 0) {
              cusName = res?.data?.customerInfo[0]?.customers[i]?.name;
              cusId = res?.data?.customerInfo[0]?.customers[i]?.nid_number;
              // console.log(cusName);
              // console.log(cusId);
            } else {
              cusName =
                cusName +
                " / " +
                res?.data?.customerInfo[0]?.customers[i]?.name;
              cusId =
                cusId +
                " / " +
                res?.data?.customerInfo[0]?.customers[i]?.nid_number;
              // console.log(cusName);
              // console.log(cusId);
            }
          }
          setCustomerName(cusName);
          setCustomerId(cusId);
        });
    }
    setFileData(null);
  }, [fileId]);
  
//-----------add mutation details functionality start-----------
  const addMutationDetails = (data) => {
    axios
      .post(baseUrl + "/registration/mutationDetails/store", data, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        if (res?.data?.status) {
          toast.success("Add successfully!");
          document.getElementById("addMutationModal").checked = false;
          reset();
          setReFetch(!reFetch);
        }
      })
      .catch((error) => {
        const errorMessage = Object.values(error?.response?.data?.errors);
        return errorMessage.map((item) => toast.error(item[0]));
      });
  };
//-----------add mutation details functionality end-----------

//-----------update mutation details functionality start-----------
  const handleEditData = (event) => {
    event.preventDefault();
    const form = event.target;
    const registration_date = form.registration_date.value;
    const misscase_no = form.misscase_no.value;
    const land_size = form.land_size.value;
    const cs_daag_no = form.cs_daag_no.value;
    const rs_daag_no = form.rs_daag_no.value;
    const sa_daag_no = form.sa_daag_no.value;
    const bs_daag_no = form.bs_daag_no.value;
    const cs_khatian_no = form.cs_khatian_no.value;
    const rs_khatian_no = form.rs_khatian_no.value;
    const sa_khatian_no = form.sa_khatian_no.value;
    const bs_khatian_no = form.bs_khatian_no.value;
    const data = {
      registration_date,
      misscase_no,
      land_size,
      cs_daag_no,
      rs_daag_no,
      sa_daag_no,
      bs_daag_no,
      cs_khatian_no,
      rs_khatian_no,
      sa_khatian_no,
      bs_khatian_no,
      mouza_name: null,
    };

    axios
      .post(
        baseUrl + `/registration/mutationDetails/update/${updateData?.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.status);
        }
        setReFetch(!reFetch);
        setUpdateData(null);
        document.getElementById("updateMutationModal").checked = false;
      })
      .catch((e) => {
        for (const variable in e?.response?.data.errors) {
          toast.error(e?.response?.data.errors[variable][0]);
        }
      });
  };
//-----------update mutation details functionality end-----------

//-----------add customer for mutation details functionality start-----------
  const handleAddCustomer = (fileID) => {
    if (fileID == undefined) {
      toast.error("Please fill all field");
      return;
    }
    axios
      .post(
        baseUrl + `/registration/mutationDetails/assignCustomer/${fileID}`,
        {
          mutation_detailes_id: customeAddInfo?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success("Add customer successfully!");
          setCustomerName("");
          setCustomerId("");
          setCustomerAddInfo(null);
          setFileData(null);
          document.getElementById("addCustomerMutationModal").checked = false;
        } else {
          toast.error("Add customer unsuccessfull!");
        }
      });
  };
//-----------add customer for mutation details functionality end-----------

//-----------pdf download functionality start-----------
  const pdfDownload = () => {
    var element = document.getElementById("pdf-section");
    var opt = {
      margin: 0.2,
      filename: "myfile.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().from(element).set(opt).save();
    html2pdf(element, opt);
  };
//-----------pdf download functionality end-----------

//-----------print functionality start-----------
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
//-----------print functionality end-----------

  return (
    <div>
      <div className="flex flex-wrap justify-between my-5">
        <CopyPrintPdfBtn
          handlePrint={handlePrint}
          handleDownloadPdf={pdfDownload}
        ></CopyPrintPdfBtn>
        <div className="my-2">
          <AddButton>
            <label htmlFor="addMutationModal" className="cursor-pointer">
              Add Mutation Details
            </label>
          </AddButton>
        </div>
      </div>
      {mutationData == null ? (
        <Loader></Loader>
      ) : (
        <div className="overflow-x-auto max-h-[60vh]">
          <table
            ref={componentRef}
            id="pdf-section"
            className="table table-zebra w-full"
          >
            <thead className="text-[#00CA08]">
              <tr className="sticky top-0">
                <th>
                  Mutation <br />
                  Date
                </th>
                <th>
                  Mutation <br />
                  Misscase <br />
                  Number
                </th>
                <th>
                  Total Land Size <br />
                  (Decimal)
                </th>
                <th>
                  Mutation <br />
                  CS Daag No
                </th>
                <th>
                  Mutation <br />
                  SA Daag No
                </th>
                <th>
                  Mutation <br />
                  RS Daag No
                </th>
                <th>
                  Mutation <br />
                  BS Daag No
                </th>
                <th>
                  Mutation <br />
                  CS Khatian No
                </th>
                <th>
                  Mutation <br />
                  SA Khatian No
                </th>
                <th>
                  Mutation <br />
                  RS Khatian No
                </th>
                <th>
                  Mutation <br />
                  BS Khatian No
                </th>
                <th className="hide-when-print">
                  Edit <br />
                  Mutation <br />
                  Details
                </th>
                <th className="hide-when-print">
                  Customer <br />
                  Add/View
                </th>
              </tr>
            </thead>
            <tbody>
              {mutationData && mutationData?.length > 0 ? (
                mutationData?.map((mutation, index) => {
                  const {
                    registration_date,
                    misscase_no,
                    land_size,
                    mouza_name,
                    cs_daag_no,
                    sa_daag_no,
                    rs_daag_no,
                    bs_daag_no,
                    cs_khatian_no,
                    sa_khatian_no,
                    rs_khatian_no,
                    bs_khatian_no,
                  } = mutation;
                  return (
                    <tr key={index + mutation?.id + Math.random().toFixed(5)}>
                      <td>{registration_date}</td>
                      <td>{misscase_no}</td>
                      <td>{land_size}</td>
                      <td>{cs_daag_no}</td>
                      <td>{sa_daag_no}</td>
                      <td>{rs_daag_no}</td>
                      <td>{bs_daag_no}</td>
                      <td>{cs_khatian_no}</td>
                      <td>{sa_khatian_no}</td>
                      <td>{rs_khatian_no}</td>
                      <td>{bs_khatian_no}</td>
                      <td className="text-center hide-when-print">
                        <label
                          className="cursor-pointer"
                          onClick={() => {
                            setUpdateData(mutation);
                            document.getElementById(
                              "updateMutationModal"
                            ).checked = true;
                          }}
                        >
                          <i className="fa-solid fa-pen-to-square m-2 text-xl"></i>
                        </label>
                      </td>
                      <td className="text-center hide-when-print">
                        <label
                          className="cursor-pointer"
                          onClick={() => {
                            document.getElementById(
                              "addCustomerMutationModal"
                            ).checked = true;
                            const { id, land_size } = mutation;
                            setCustomerAddInfo({ id, land_size });
                          }}
                        >
                          <i className="fa-solid fa-square-plus m-2 text-xl"></i>
                        </label>
                        <Link
                          to={`/dashboard/registrationnumberdetails/mutation/viewmutationcustomer/${mutation?.id}`}
                        >
                          <button>
                            <i className="fa-solid fa-eye m-2 text-xl"></i>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>
                    <h2
                      className="text-center py-5 col-span-10 font-bold"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      No Mutation Details Found
                    </h2>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}


      {/* -----------------Add Mutation Modal------------- */}
      <input type="checkbox" id="addMutationModal" className="modal-toggle" />
      <div className="modal mt-5">
        <form
          onSubmit={handleSubmit(addMutationDetails)}
          className="modal-box max-w-2xl"
        >
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text font-bold text-[#717A82]">
                Mutation Date
              </span>
            </label>
            <input
              type="date"
              {...register("registration_date")}
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
          <div className="flex flex-wrap ">
            <div className="form-control max-w-sm m-2">
              <label className="label">
                <span className="label-text font-bold text-[#717A82]">
                  Mutation Misscase No.
                </span>
              </label>
              <input
                type="text"
                {...register("misscase_no")}
                className="input input-bordered input-sm w-full max-w-sm"
              />
            </div>
            <div className="form-control max-w-sm m-2">
              <label className="label">
                <span className="label-text font-bold text-[#717A82]">
                  Total Land Size ( Decimal)
                </span>
              </label>
              <input
                type="text"
                {...register("land_size")}
                className="input input-bordered input-sm w-full max-w-sm"
              />
            </div>
          </div>
          <div>
            <div className="grid lg:grid-cols-4 gap-2">
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text">CS Daag No.</span>
                </label>
                <input
                  type="text"
                  {...register("cs_daag_no")}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text">SA Daag No.</span>
                </label>
                <input
                  type="text"
                  {...register("sa_daag_no")}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text">RS Daag No.</span>
                </label>
                <input
                  type="text"
                  {...register("rs_daag_no")}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text">BS Daag No.</span>
                </label>
                <input
                  type="text"
                  {...register("bs_daag_no")}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text">CS Khatian No.</span>
                </label>
                <input
                  type="text"
                  {...register("cs_khatian_no")}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text">SA Khatian No.</span>
                </label>
                <input
                  type="text"
                  {...register("sa_khatian_no")}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text">RS Khatian No.</span>
                </label>
                <input
                  type="text"
                  {...register("rs_khatian_no")}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text">BS Khatian No.</span>
                </label>
                <input
                  type="text"
                  {...register("bs_khatian_no")}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
            </div>
          </div>
          <div className="flex  justify-center mt-5">
            <label
              htmlFor="addMutationModal"
              className="cursor-pointer uppercase  text-white text-[14px] font-semibold bg-[#E30B0B] rounded-md py-2.5 px-16 m-2 "
            >
              Cancel
            </label>
            <button
              htmlFor="addMutationModal"
              type="submit"
              className="cursor-pointer uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 m-2"
            >
              Submit
            </button>
          </div>
        </form>
      </div>

      {/* -----------------Update Mutation Modal---------------- */}
      <input
        type="checkbox"
        id="updateMutationModal"
        className="modal-toggle"
      />
      {updateData !== null && (
        <div className="modal mt-5">
          <form onSubmit={handleEditData} className="modal-box max-w-2xl">
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text font-bold text-[#717A82]">
                  Mutation Date
                </span>
              </label>
              <input
                name={"registration_date"}
                type="date"
                defaultValue={updateData?.registration_date}
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
            <div className="flex flex-wrap ">
              <div className="form-control max-w-sm m-2">
                <label className="label">
                  <span className="label-text font-bold text-[#717A82]">
                    Mutation Misscase No.
                  </span>
                </label>
                <input
                  type="text"
                  name="misscase_no"
                  defaultValue={updateData?.misscase_no}
                  className="input input-bordered input-sm w-full max-w-sm"
                />
              </div>
              <div className="form-control max-w-sm m-2">
                <label className="label">
                  <span className="label-text font-bold text-[#717A82]">
                    Total Land Size ( Decimal)
                  </span>
                </label>
                <input
                  type="text"
                  name="land_size"
                  defaultValue={updateData?.land_size}
                  className="input input-bordered input-sm w-full max-w-sm"
                />
              </div>
            </div>
            <div>
              <div className="grid lg:grid-cols-4 gap-2">
                <div className="form-control w-full max-w-xs m-2">
                  <label className="label">
                    <span className="label-text">CS Daag No.</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={updateData?.cs_daag_no}
                    name="cs_daag_no"
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-2">
                  <label className="label">
                    <span className="label-text">SA Daag No.</span>
                  </label>
                  <input
                    type="text"
                    name="sa_daag_no"
                    defaultValue={updateData?.sa_daag_no}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-2">
                  <label className="label">
                    <span className="label-text">RS Daag No.</span>
                  </label>
                  <input
                    type="text"
                    name="rs_daag_no"
                    defaultValue={updateData?.rs_daag_no}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-2">
                  <label className="label">
                    <span className="label-text">BS Daag No.</span>
                  </label>
                  <input
                    type="text"
                    name="bs_daag_no"
                    defaultValue={updateData?.bs_daag_no}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-2">
                  <label className="label">
                    <span className="label-text">CS Khatian No.</span>
                  </label>
                  <input
                    type="text"
                    name="cs_khatian_no"
                    defaultValue={updateData?.cs_khatian_no}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-2">
                  <label className="label">
                    <span className="label-text">SA Khatian No.</span>
                  </label>
                  <input
                    type="text"
                    name="sa_khatian_no"
                    defaultValue={updateData?.sa_khatian_no}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-2">
                  <label className="label">
                    <span className="label-text">RS Khatian No.</span>
                  </label>
                  <input
                    type="text"
                    name="rs_khatian_no"
                    defaultValue={updateData?.rs_khatian_no}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-2">
                  <label className="label">
                    <span className="label-text">BS Khatian No.</span>
                  </label>
                  <input
                    type="text"
                    name="bs_khatian_no"
                    defaultValue={updateData?.bs_khatian_no}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
              </div>
            </div>
            <div className="flex  justify-center mt-5">
              <label
                htmlFor="updateMutationModal"
                onClick={() => setUpdateData(null)}
                className="cursor-pointer uppercase  text-white text-[14px] font-semibold bg-[#E30B0B] rounded-md py-2.5 px-16 m-2 "
              >
                Cancel
              </label>
              <button
                htmlFor="updateMutationModal"
                type="submit"
                className="cursor-pointer uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 m-2"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      )}

      {/*----------------- Add Customer For Mutation Modal-------------------- */}
      {
        <div>
          <input
            id="addCustomerMutationModal"
            type="checkbox"
            className="modal-toggle"
          />
          <div className="modal mt-5">
            <div className="modal-box max-w-2xl">
              <h1 className="font-bold text-[#717A82] mx-2 my-5">
                Add Customer Under Mutation
              </h1>
              <div className="flex flex-wrap ">
                <div className="form-control max-w-xs m-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      File No.
                    </span>
                  </label>
                  <select
                    className="select select-bordered select-sm max-w-xs"
                    onChange={(e) => setFileId(e.target.value)}
                  >
                    <option disabled selected value={"null"}>
                      Select File No
                    </option>
                    {fileList &&
                      fileList?.map((list, index) => {
                        return (
                          <option value={list?.id} key={index}>
                            {list?.file_no}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="form-control w-full max-w-xs m-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Customer's Name
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={customerName}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
              </div>
              <div className="flex flex-wrap ">
                <div className="form-control max-w-xs m-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Building/Land No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={fileData?.project?.project_no}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control max-w-xs m-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Customer's ID
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={customerId}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control max-w-xs m-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Land/Flat Size(decimal)
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={customeAddInfo?.land_size}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
              </div>
              <div className="flex  justify-center mt-5">
                <label
                  onClick={() => {
                    setCustomerName("");
                    setCustomerId("");
                    setCustomerAddInfo(null);
                    setFileData(null);
                  }}
                  htmlFor="addCustomerMutationModal"
                  className="cursor-pointer uppercase  text-white text-[14px] font-semibold bg-[#E30B0B] rounded-md py-2.5 px-16 m-2 "
                >
                  Cancel
                </label>
                <label
                  onClick={() => handleAddCustomer(fileData?.id)}
                  // htmlFor="addCustomerMutationModal"
                  type="submit"
                  className="cursor-pointer uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 m-2"
                >
                  Submit
                </label>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default MutationDetails;
