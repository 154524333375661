import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="h-[90vh] flex flex-col gap-8 justify-center">
      <h1 className="text-center text-2xl text-red-500">
        Page Not Found ..! error 404
      </h1>
      <p className="text-center">
        <Link
          to="/"
          className="p-2 px-6 bg-green-600 font-bold text-lg rounded-[4px] text-white"
        >
          Home
        </Link>
      </p>
    </div>
  );
};

export default NotFoundPage;
