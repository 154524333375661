import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { baseUrl } from '../../../BaseUrl';
import { toast } from 'react-toastify';
import Heading from '../../Shared/Heading';

const UpdateCanceledApplicationAfterRegi = () => {
    const { register, handleSubmit } = useForm();
    const navigate=useNavigate();
    const location = useLocation();
    const singleFileCustomersInfoAfterRegistration = location.state.data;
    
    const onSubmit = (data) => {
        const flat_or_plot_id = singleFileCustomersInfoAfterRegistration?.flat_or_plot_id;
        const newData = {
          ...data,
          flat_or_plot_id,
        };
        axios
          .post(
            baseUrl + `/reports/afterRegCancelCustomerList/editCanceledCustomer`,
            newData,
            {
              headers: {
                Authorization: `Bearer ${JSON.parse(
                  localStorage.getItem("crm-tdata")
                )}`,
                "content-type": "text/json",
              },
            }
          )
          .then((res) => {
            if (res?.data?.status === "failed") {
              res?.data?.message.forEach((message) => {
                toast.error(message);
              });
            } else {
              toast.success(res?.data?.message);
              navigate("/dashboard/afterregistrationcanceledcustomerlist");
            }
          });
      };
    return (
        <div className="m-5">
        <Heading>
          <Link to="/dashboard/afterregistrationcanceledcustomerlist">
            <i className="fa-solid fa-arrow-left"></i>Update After Registration Canceled
            Form
          </Link>
        </Heading>
        <div className="m-5">
          <form onSubmit={handleSubmit(onSubmit)} className="w-3/5">
            <fieldset className="border-2 rounded-md border-[#1F487C]">
              <legend className="mx-4">
                <p className="bg-[#1F497D] text-[white] py-1 px-4 rounded-md">
                Update After Registration Canceled Application Form
                </p>
              </legend>
              <div className="grid lg:grid-cols-3 m-4">
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                      File No.
                      </span>
                    </label>
                    <input
                      required
                      value={
                        singleFileCustomersInfoAfterRegistration?.flat_or_plot?.file_no
                      }
                      type="text"
                      className="input input-bordered input-sm w-full max-w-lg"
                    />
                  </div>
                </div>
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                        Building/Land No.
                      </span>
                    </label>
                    <input
                      required
                      value={
                        singleFileCustomersInfoAfterRegistration?.flat_or_plot?.project_info
                          ?.project_no
                      }
                      type="text"
                      className="input input-bordered input-sm w-full max-w-lg"
                    />
                  </div>
                </div>
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                        Flat/Plot size
                      </span>
                    </label>
                    <input
                      required
                      value={
                        singleFileCustomersInfoAfterRegistration?.flat_or_plot?.size
                      }
                      type="text"
                      className="input input-bordered input-sm w-full max-w-lg"
                    />
                  </div>
                </div>
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                        Customer's Name
                      </span>
                    </label>
                    <input
                      required
                      value={singleFileCustomersInfoAfterRegistration?.flat_or_plot?.customers?.map(
                        (customer) => customer.name
                      )}
                      type="text"
                      className="input input-bordered input-sm w-full max-w-lg"
                    />
                  </div>
                </div>
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                        Customer ID*
                      </span>
                    </label>
                    <input
                      required
                      value={singleFileCustomersInfoAfterRegistration?.flat_or_plot?.customers?.map(
                        (customer) => customer.id
                      )}
                      type="text"
                      className="input input-bordered input-sm w-full max-w-lg"
                    />
                  </div>
                </div>
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                        Mobile Number*
                      </span>
                    </label>
                    <input
                      required
                      value={singleFileCustomersInfoAfterRegistration?.flat_or_plot?.customers?.map(
                        (customer) => customer.phone_number
                      )}
                      type="text"
                      className="input input-bordered input-sm w-full max-w-lg"
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex flex-wrap m-4">
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                        Canceled Application Date <span>*</span>
                      </span>
                    </label>
                    <input
                      {...register("canceled_application_date")}
                      name="canceled_application_date"
                      defaultValue={
                        singleFileCustomersInfoAfterRegistration?.canceled_application_date
                      }
                      type="date"
                      className="input input-bordered input-sm w-full max-w-lg"
                    />
                  </div>
                </div>
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                        Total Amount of Canceled File
                      </span>
                    </label>
                    <input
                      {...register("total_amount")}
                      name="total_amount"
                      defaultValue={
                        singleFileCustomersInfoAfterRegistration?.total_amount
                      }
                      type="text"
                      className="input input-bordered input-sm w-full max-w-lg"
                    />
                  </div>
                </div>
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                        Orginal Amount of Canceled File
                      </span>
                    </label>
                    <input
                      {...register("original_amount")}
                      name="original_amount"
                      defaultValue={
                        singleFileCustomersInfoAfterRegistration?.original_amount
                      }
                      type="text"
                      className="input input-bordered input-sm w-full max-w-lg"
                    />
                  </div>
                </div>
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                        Extra Amount of Canceled File
                      </span>
                    </label>
                    <input
                      {...register("extra_amount")}
                      name="extra_amount"
                      defaultValue={
                        singleFileCustomersInfoAfterRegistration?.extra_amount
                      }
                      type="text"
                      className="input input-bordered input-sm w-full max-w-lg"
                    />
                  </div>
                </div>
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                        Canceled File Registration Date
                      </span>
                    </label>
                    <input
                      {...register("canceled_file_reg_date")}
                      name="canceled_file_reg_date"
                      defaultValue={
                        singleFileCustomersInfoAfterRegistration?.canceled_file_reg_date
                      }
                      
                      type="date"
                      className="input input-bordered input-sm w-full max-w-lg"
                    />
                  </div>
                </div>
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                      Canceled File Registration Deed No
                      </span>
                    </label>
                    <input
                      {...register("canceled_file_deed_no")}
                      name="canceled_file_deed_no"
                      defaultValue={
                        singleFileCustomersInfoAfterRegistration?.canceled_file_reg_deed_no
                      }
                      
                      type="text"
                      className="input input-bordered input-sm w-full max-w-lg"
                    />
                  </div>
                </div>
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                      Canceled File Registration Land Size
                      </span>
                    </label>
                    <input
                      {...register("canceled_file_land_size")}
                      name="canceled_file_land_size"
                      defaultValue={
                        singleFileCustomersInfoAfterRegistration?.canceled_file_reg_land_size
                      }
                      
                      type="text"
                      className="input input-bordered input-sm w-full max-w-lg"
                    />
                  </div>
                </div>
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                        Canceled Payment Start Date
                      </span>
                    </label>
                    <input
                      {...register("canceled_payment_start_date")}
                      name="canceled_payment_start_date"
                      defaultValue={
                        singleFileCustomersInfoAfterRegistration?.canceled_payment_start_date
                      }
                      
                      type="date"
                      className="input input-bordered input-sm w-full max-w-lg"
                    />
                  </div>
                </div>
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                        Authorized person name
                      </span>
                    </label>
                    <input
                      {...register("authorized_person_name")}
                      name="authorized_person_name"
                      defaultValue={
                        singleFileCustomersInfoAfterRegistration?.authorized_person_name
                      }
                     
                      type="text"
                      className="input input-bordered input-sm w-full max-w-lg"
                    />
                  </div>
                </div>
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                        Authorized mobile no
                      </span>
                    </label>
                    <input
                      {...register("authorized_phone_number")}
                      name="authorized_phone_number"
                      defaultValue={
                        singleFileCustomersInfoAfterRegistration?.authorized_phone_number
                      }
                      
                      type="text"
                      className="input input-bordered input-sm w-full max-w-lg"
                    />
                  </div>
                </div>
              </div>
              <div className="grid lg:grid-cols-2 m-4">
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                        Write new address here
                      </span>
                    </label>
                    <textarea
                      {...register("new_address")}
                      name="new_address"
                      defaultValue={
                        singleFileCustomersInfoAfterRegistration?.new_address
                      }
                      className="textarea textarea-bordered"
                    ></textarea>
                  </div>
                </div>
                <div className="p-2">
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text font-bold text-[#887d7d]">
                        Description
                      </span>
                    </label>
                    <textarea
                      {...register("description")}
                      name="description"
                      defaultValue={
                        singleFileCustomersInfoAfterRegistration?.description
                      }
                      
                      className="textarea textarea-bordered"
                    ></textarea>
                  </div>
                </div>
              </div>
            </fieldset>
  
            <div className="flex justify-end">
              <button
                type="submit"
                className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 mb-10 m-2"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    );
};

export default UpdateCanceledApplicationAfterRegi;