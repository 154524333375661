import React, { useEffect, useState } from "react";
import Heading from "../../Shared/Heading";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import { baseUrl } from "../../../BaseUrl";
import axios from "axios";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

let allDatas = [];
const InstallmentDueList = () => {
  const [installmentDueLists, setInstallmentDueLists] = useState([]);
  // console.log(installmentDueLists);
  useEffect(() => {
    axios
      .get(baseUrl + `/reports/installmentDueList`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        allDatas = res?.data?.data;
        setInstallmentDueLists(res?.data?.data);
      });
  }, []);

  //----------filter functionality start ---------------
  const handleFilterData = (e) => {
    if (e === "all") {
      setInstallmentDueLists(allDatas);
    } else if (e === "number_of_installments") {
      setInstallmentDueLists(
        allDatas?.filter(
          (list) => list?.price_information?.number_of_installment > 0
        )
      );
    } else if (e === "per_installment_amount") {
      setInstallmentDueLists(
        allDatas?.filter(
          (list) => list?.price_information?.per_month_installment_amount > 0
        )
      );
    } else if (e === "total_installment_amount") {
      setInstallmentDueLists(
        allDatas?.filter(
          (list) => list?.price_information?.total_installment_amount > 0
        )
      );
    } else if (e === "number_of_installment_completed") {
      setInstallmentDueLists(
        allDatas?.filter(
          (list) =>
            list?.price_information?.installment?.filter(
              (item) => item?.amount === item?.paid
            ).length > 0
        )
      );
    } else if (e === "total_installment_complete_amount") {
      setInstallmentDueLists(
        allDatas?.filter(
          (list) =>
            list?.price_information?.installment?.filter(
              (item) => item?.amount === item?.paid
            ).length > 0
        )
      );
    } else if (e === "as_of_today_number_of_due") {
      setInstallmentDueLists(
        allDatas?.filter(
          (list) =>
            list?.price_information?.number_of_installment -
              list?.price_information?.installment?.filter(
                (item) => item?.amount === item?.paid
              ).length >
            0
        )
      );
    } else if (e === "as_of_today_installment_due_amount") {
      setInstallmentDueLists(
        allDatas?.filter(
          (list) =>
            list?.price_information?.total_installment_amount -
              list?.price_information?.total_installment_amount_paid >
              0 ||
            list?.price_information?.total_installment_amount -
              list?.price_information?.total_installment_amount_paid <
              0
        )
      );
    } else if (e === "total_number_of_installment_due") {
      setInstallmentDueLists(
        allDatas?.filter(
          (list) =>
            list?.price_information?.number_of_installment -
              list?.price_information?.installment?.filter(
                (item) => item?.amount === item?.paid
              ).length >
            0
        )
      );
    } else if (e === "total_installment_due_amount") {
      setInstallmentDueLists(
        allDatas?.filter(
          (list) =>
            list?.price_information?.total_installment_amount -
              list?.price_information?.total_installment_amount_paid >
              0 ||
            list?.price_information?.total_installment_amount -
              list?.price_information?.total_installment_amount_paid <
              0
        )
      );
    }
  };
  //----------filter functionality End ---------------

  //----------Print a div or section functionality start-----------
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //----------Print a div or section functionality end-------------

    // ---------------search table data start------------------
    const handleSearch = (e) => {
      // console.log(e.target.value);
      // Declare variables
      let status = 0;
      let filterBy = e.target.value.toUpperCase();
      let table = document.getElementById("dataTable");
      let tr = table.querySelectorAll(".searchData");
      let i = 0;
      let j = 0;
      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        let tempTd = tr[i].getElementsByTagName("td");
        for (j = 0; j < tempTd.length; j++) {
          let td = tempTd[j];
          let txtValue;
          if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filterBy) > -1) {
              status = 1;
            }
            if (!status) {
              status = 0;
            }
          }
        }
        if (status) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    };
    // ------------------search table data end-----------------
  return (
    <div>
      <div>
        <Heading>Installment Due List</Heading>
        <div className="p-5 whiteBackground">
          <div className="flex flex-wrap justify-between items-center my-5">
            <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
            <div className="my-2">
              <div className="form-control">
                <div className="flex flex-wrap items-center">
                  <h1 className="m-3 text-[#717A82] font-bold">
                    Search Installment Due List
                  </h1>
                  <select
                    onChange={(e) => handleFilterData(e.target.value)}
                    className="select select-sm select-bordered  my-2 rounded-[5px] bg-[#ECECEC]"
                  >
                    <option value={"all"}>ALl</option>
                    <option value={"number_of_installments"}>
                      Number Of Installments
                    </option>
                    <option value={"per_installment_amount"}>
                      Per Installment Amount
                    </option>
                    <option value={"total_installment_amount"}>
                      Total Installment Amount
                    </option>
                    <option value={"number_of_installment_completed"}>
                      Number Of Installment Completed
                    </option>
                    <option value={"total_installment_complete_amount"}>
                      Total Installment Complete Amount
                    </option>
                    <option value={"as_of_today_number_of_due"}>
                      As Of Today Number Of Due
                    </option>
                    <option value={"as_of_today_installment_due_amount"}>
                      As Of Today Installment Due Amount
                    </option>
                    <option value={"total_number_of_installment_due"}>
                      Total Number Of Installment Due
                    </option>
                    <option value={"total_installment_due_amount"}>
                      Total Installment Due Amount
                    </option>
                  </select>
                  <input
                  onChange={handleSearch}
                    id="searchInput"
                    type="text"
                    placeholder="Search..."
                    className="input input-bordered input-sm max-w-xs ml-1"
                  />
                </div>
              </div>
            </div>
          </div>
          {installmentDueLists?.length > 0 ? (
            <div ref={componentRef} className="overflow-x-auto max-h-[60vh]">
              <table  id="dataTable" className="table table-zebra w-full max-h-[60vh]">
                <thead className="text-[#00CA08]">
                  <tr className="sticky top-0">
                    <th>Customer's Id</th>
                    <th>Customer's Name</th>
                    <th>File No.</th>
                    <th>
                      Building/Land <br /> No.
                    </th>
                    <th>
                      Flat/Plot <br /> Size
                    </th>
                    <th>
                      Number of <br /> Installments
                    </th>
                    <th>
                      Per Installment <br /> Amount
                    </th>
                    <th>
                      Total Installment <br /> Amount
                    </th>
                    <th>
                      Number of <br /> Installments <br /> Completed
                    </th>
                    <th>
                      Total Installment <br />
                      Complete <br /> Amount
                    </th>
                    <th>
                      As of <br /> Today Number <br /> Of Installment <br /> Due
                    </th>
                    <th>
                      As of Today <br /> Installment Due <br /> Amount
                    </th>
                    <th>
                      Total Number <br /> of Installment <br /> Due
                    </th>
                    <th>
                      Total <br /> Installment <br /> Due Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {installmentDueLists?.map((list) => {
                    const installments = list?.price_information?.installment;
                    const completedInstallment = installments?.filter(
                      (item) => item?.amount === item?.paid
                    );
                    let totalInstallmentCompleteAmount = 0;
                    completedInstallment?.forEach((item) => {
                      totalInstallmentCompleteAmount += parseInt(item.paid);
                    });

                    return (
                      <tr  className="searchData" key={list.id}>
                        <td>
                          {list?.customers?.map((singleCustomer) => (
                            <h1 key={singleCustomer.id}>{singleCustomer.id}</h1>
                          ))}
                        </td>
                        <td>
                          {list.customers.map((singleCustomer) => (
                            <h1 key={singleCustomer.id}>
                              {singleCustomer.name}
                            </h1>
                          ))}
                        </td>
                        <td>{list.file_no}</td>
                        <td>{list?.project?.project_no}</td>
                        <td>{list.size}</td>
                        <td>
                          {list?.price_information?.number_of_installment > 0
                            ? list?.price_information?.number_of_installment
                            : 0}
                        </td>
                        <td>
                          {list?.price_information?.per_month_installment_amount
                            ? list?.price_information
                                ?.per_month_installment_amount
                            : 0}
                        </td>
                        <td>
                          {list?.price_information?.total_installment_amount
                            ? list?.price_information?.total_installment_amount
                            : 0}
                        </td>
                        <td>
                          {completedInstallment?.length > 0
                            ? completedInstallment.length
                            : 0}
                        </td>
                        <td>{totalInstallmentCompleteAmount}</td>
                        <td>
                          {list?.price_information?.number_of_installment > 0
                            ? list?.price_information?.number_of_installment -
                              completedInstallment.length
                            : 0}
                        </td>
                        <td>
                          {list?.price_information
                            ? list?.price_information
                                ?.total_installment_amount -
                              list?.price_information
                                ?.total_installment_amount_paid
                            : 0}
                        </td>
                        <td>
                          {list?.price_information?.number_of_installment > 0
                            ? list?.price_information?.number_of_installment -
                              completedInstallment.length
                            : 0}
                        </td>
                        <td>
                          {list?.price_information
                            ? list?.price_information
                                ?.total_installment_amount -
                              list?.price_information
                                ?.total_installment_amount_paid
                            : 0}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <h1 className="text-center font-bold my-5 py-5">No Data Found</h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default InstallmentDueList;
