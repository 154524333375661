import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { baseUrl } from "../../../BaseUrl";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import Heading from "../../Shared/Heading";
import Loader from "../../Shared/Loader";

const paymentAgainstList = [
  {
    name: "booking_money",
    value: 1,
  },
  {
    name: "car_parking",
    value: 2,
  },
  {
    name: "utility_charge",
    value: 3,
  },
  {
    name: "additional_amount",
    value: 4,
  },
  {
    name: "downpayment_amount",
    value: 5,
  },
  {
    name: "installment_amount",
    value: 6,
  },
];

const ClientPaymentListHome = () => {
  const [clientpaymentlist, setClientpaymentlist] = useState(null);
  const [addDownpaymentInstallment, setAddDownpaymentInstallment] =
  useState(-1);
const [installmentDownpaymentNo, setInstallmentDownpaymentNo] =
  useState(null);

  useEffect(() => {
    axios
      .get(
        baseUrl + "/customer/payment/list",
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        // console.log(res?.data?.data?.data);
        setClientpaymentlist(res?.data?.data?.data);
      });
  }, []);

  const [modalCustomerName, setModalCustomerName] = useState("");
  const [modalCustomerId, setModalCustomerId] = useState("");


  //printing functionality
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });



  // ---------------for add payment start------------------
  //Add payment with modal handle functionality
  const [addPaymentModalData, setAddPaymentModalData] = useState(null);
  const [additionalAmountList, setAdditionalAmountList] = useState([]);
  const [downPaymentList, setDownPaymentList] = useState([]);
  const [installmentList, setInstallmentList] = useState([]);

  const [showAdditionalAmount, setshowAdditionalAmount] = useState(false);
  const [showDownPayment, setShowDownPayment] = useState(false);
  const [showInstallment, setShowInstallment] = useState(false);

  const setAddModalData = (data) => {
    document.getElementById("addPaymentModal").checked = true;
    let customerName = "";
    let customerId = "";
    for (let i = 0; i < data?.flat_or_plot?.customers?.length; i++) {
      if (i == 0) {
        customerName = data?.flat_or_plot?.customers[i].name;
        customerId = data?.flat_or_plot?.customers[i].id;
      } else {
        customerName =
          customerName + "/" + data?.flat_or_plot?.customers[i].name;
        customerId = customerId + "/" + data?.flat_or_plot?.customers[i].id;
      }
    }
    setModalCustomerName(customerName);
    setModalCustomerId(customerId);
    setAddPaymentModalData(data);
  };

  const againstAddSet = (position, project_id) => {
    if (position == 4) {
      axios
        .get(baseUrl + `/customer/payment/details/${addPaymentModalData?.id}`, {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        })
        .then((res) => {
          const additionalAmountPaymentCusListDatas = res?.data?.detail?.filter(
            (item) => item?.price_information?.total_additional_amount > 0
          );
          const singleAdditionalDataByPriceinfoId =
            additionalAmountPaymentCusListDatas?.find(
              (element) =>
                element?.price_information?.id == addPaymentModalData?.id
            );
          const additionalFilterByAmountDatas =
            singleAdditionalDataByPriceinfoId?.price_information?.additional_amount?.filter(
              (item) => item?.amount > 0
            );
          setAdditionalAmountList(additionalFilterByAmountDatas);
          setshowAdditionalAmount(true);
          setShowDownPayment(false);
          setShowInstallment(false);
        });
    } else if (position == 5) {
      axios
        .get(
          baseUrl + `/customer/payment/downpayment/${project_id}`,

          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("crm-tdata")
              )}`,
              "content-type": "text/json",
            },
          }
        )
        .then((res) => {
          // console.log(res);
          setDownPaymentList(res?.data?.data?.down_payment);
          setshowAdditionalAmount(false);
          setShowDownPayment(true);
          setShowInstallment(false);
        });
    } else if (position == 6) {
      axios
        .get(
          baseUrl + `/customer/payment/installment/${project_id}`,

          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("crm-tdata")
              )}`,
              "content-type": "text/json",
            },
          }
        )
        .then((res) => {
          setInstallmentList(res?.data?.data?.installment);
          setshowAdditionalAmount(false);
          setShowDownPayment(false);
          setShowInstallment(true);
        });
    } else {
      setshowAdditionalAmount(false);
      setShowDownPayment(false);
      setShowInstallment(false);
    }
  };

  const addPayment = (event) => {
    event.preventDefault();
    const form = event.target;
    const complete_date = form.complete_date.value;
    const Paid_by = form.Paid_by.value;
    if (Paid_by == "") {
      toast.error("Paid by required");
      return;
    }
    const money_receipt_id = form.money_receipt_id.value;
    if (money_receipt_id == "") {
      toast.error("Money receipt no is required!");
      return;
    }
    const payment_against_id = form.payment_against_id.value;
    if (payment_against_id == "") {
      toast.error("Payment against id is required!");
      return;
    }

    const additional_amount_id = form?.additional_amount_id?.value;
    if (additional_amount_id == "") {
      toast.error("Additional Amount Option is required!");
      return;
    }

    const amount = form.amount.value;
    if (amount == "") {
      toast.error("Amount field is required!");
      return;
    }
    if (amount < 0) {
      toast.error("Amount field cannot negative!");
      return;
    }
    let down_payment_id = "";
    let installment_id = "";
    if (payment_against_id == 5) {
      down_payment_id = form.down_payment_id.value.split(",")[0];
    } else if (payment_against_id == 6) {
      installment_id = form.installment_id.value.split(",")[0];
    }

    let payment_against_name = "";
    if (installmentDownpaymentNo > 0) {
      payment_against_name =
        paymentAgainstList[payment_against_id - 1]?.name +
        `_${installmentDownpaymentNo}`;
    } else {
      payment_against_name = paymentAgainstList[payment_against_id - 1]?.name;
    }
    let p_a_n = "";
    for (let i = 0; i < payment_against_name.split("_").length; i++) {
      if (i == 1 && payment_against_name.split("_").length == 3) {
        continue;
      }
      p_a_n = p_a_n + payment_against_name.split("_")[i] + " ";
    }
    const data = {
      flat_or_plot_id: addPaymentModalData?.flat_or_plot_id,
      complete_date,
      paid_by: Paid_by,
      money_receipt_id,
      payment_against_id,
      additional_amount_id,
      amount,
      installment_id,
      down_payment_id,
      payment_against_name: p_a_n,
    };
    // console.log(data);
    axios
      .post(baseUrl + `/customer/payment/store`, data, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success("Add payment successfullt!");
          setAddPaymentModalData(null);
          setDownPaymentList([]);
          setInstallmentList([]);
          setShowDownPayment(false);
          setShowInstallment(false);
          form.reset();
          document.getElementById("addPaymentModal").checked = false;
        } else {
          if (Array.isArray(res?.data?.message)) {
            res?.data?.message?.forEach((m) => toast.error(m));
          } else {
            toast.error(res?.data?.message);
          }
        }
      });
  };
 // ---------------for add payment end------------------


  // ---------------for edit-update Start------------------
  //Edit-Update payment with modal handle functionality
  const [editPaymentModalData, setEditPaymentModalData] = useState(null);
  const [additionalAmountEditList, setAdditionalAmountEditList] = useState([]);
  const [downPaymentEditList, setDownPaymentEditList] = useState([]);
  const [installmentEditList, setInstallmentEditList] = useState([]);

  const [showEditAdditionalAmount, setShowEditAdditionalAmount] =
    useState(false);
  const [showEditDownPayment, setShowEditDownPayment] = useState(false);
  const [showEditInstallment, setShowEditInstallment] = useState(false);

  const [editAmount, setEditAmount] = useState(null);

  const setEditModalData = (data) => {
    let customerName = "";
    let customerId = "";
    for (let i = 0; i < data?.flat_or_plot?.customers?.length; i++) {
      if (i == 0) {
        customerName = data?.flat_or_plot?.customers[i].name;
        customerId = data?.flat_or_plot?.customers[i].id;
      } else {
        customerName =
          customerName + "/" + data?.flat_or_plot?.customers[i].name;
        customerId = customerId + "/" + data?.flat_or_plot?.customers[i].id;
      }
    }
    setModalCustomerName(customerName);
    setModalCustomerId(customerId);
    document.getElementById("updatePaymentModal").checked = true;
    setEditPaymentModalData(data);
  };

  const againstEditSet = (position, project_id) => {
    // console.log(position);
    if (position == -1) {
      // document.getElementById("edit-amount-input-field").disabled = true;
      setShowEditDownPayment(false);
      setShowEditInstallment(false);
    } else if (position == 4) {
      // console.log("edit " + editPaymentModalData?.id);

      // document.getElementById("edit-amount-input-field").disabled = true;
      axios
        .get(
          baseUrl + `/customer/payment/details/${editPaymentModalData?.id}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("crm-tdata")
              )}`,
              "content-type": "text/json",
            },
          }
        )
        .then((res) => {
          const additionalAmountPaymentCusListDatas = res?.data?.detail?.filter(
            (item) => item?.price_information?.total_additional_amount > 0
          );
          const singleAdditionalDataByPriceinfoId =
            additionalAmountPaymentCusListDatas?.find(
              (element) =>
                element?.price_information?.id == editPaymentModalData?.id
            );
          const additionalFilterByAmountDatas =
            singleAdditionalDataByPriceinfoId?.price_information?.additional_amount?.filter(
              (item) => item?.amount > 0
            );
          // console.log(additionalFilterByAmountDatas);
          setAdditionalAmountEditList(additionalFilterByAmountDatas);
          setShowEditAdditionalAmount(true);
          setShowEditDownPayment(false);
          setShowEditInstallment(false);
        });
    } else if (position == 5) {
      // document.getElementById("edit-amount-input-field").disabled = true;
      axios
        .get(
          baseUrl + `/customer/payment/getUpgradeableDownpayment/${project_id}`,

          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("crm-tdata")
              )}`,
              "content-type": "text/json",
            },
          }
        )
        .then((res) => {
          setEditAmount(null);
          setDownPaymentEditList(res?.data?.data);
          setShowEditAdditionalAmount(false);
          setShowEditDownPayment(true);
          setShowEditInstallment(false);
        });
    } else if (position == 6) {
      // document.getElementById("edit-amount-input-field").disabled = true;
      axios
        .get(
          baseUrl + `/customer/payment/getUpgradeableInstallment/${project_id}`,

          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("crm-tdata")
              )}`,
              "content-type": "text/json",
            },
          }
        )
        .then((res) => {
          setEditAmount(null);
          setInstallmentEditList(res?.data?.data);
          setShowEditAdditionalAmount(false);
          setShowEditDownPayment(false);
          setShowEditInstallment(true);
        });
    } else {
      axios
        .post(
          baseUrl + `/customer/payment/getEditData`,
          {
            id: project_id,
            payment_against_id: position,
          },
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("crm-tdata")
              )}`,
              "content-type": "text/json",
            },
          }
        )
        .then((res) => {
          setEditAmount(res?.data?.data?.amount);
          // document.getElementById("edit-amount-input-field").disabled = false;
          setShowEditAdditionalAmount(false);
          setShowEditDownPayment(false);
          setShowEditInstallment(false);
        });
    }
  };

  const editPayment = (event) => {
    event.preventDefault();
    const form = event.target;
    const amount = form.amount.value;

    const payment_against_id = form.payment_against_id.value;
    if (payment_against_id == -1) {
      toast.error("Please select payment against field");
      return;
    }

    const additional_amount_id = form?.additional_amount_id?.value;
    // console.log(additional_amount_id);
    if (additional_amount_id == "") {
      toast.error("Additional Amount Option is required!");
      return;
    }
    let down_payment_id = "";
    let installment_id = "";
    if (payment_against_id == 5) {
      down_payment_id = form.down_payment_id.value;
    } else if (payment_against_id == 6) {
      installment_id = form.installment_id.value;
    }
    if (payment_against_id == 5) {
      if (down_payment_id == -1) {
        toast.error("Please select Downpayment field");
        return;
      }
    }
    if (payment_against_id == 6) {
      if (installment_id == -1) {
        toast.error("Please select installment field");
        return;
      }
    }
    if (amount < 0) {
      toast.error("Please enter valid amount");
      return;
    }

    const data = {
      flat_or_plot_id: editPaymentModalData?.flat_or_plot_id,
      payment_against_id,
      additional_amount_id,
      amount,
      installment_id,
      down_payment_id,
      payment_against_name: paymentAgainstList[payment_against_id - 1]?.name,
    };
    // console.log(data);

    axios
      .post(baseUrl + `/customer/payment/update`, data, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        // console.log(res);
        if (res?.data?.status == "success") {
          toast.success("Edit payment successfull!");
          setEditPaymentModalData(null);
          setDownPaymentEditList([]);
          setInstallmentEditList([]);
          setShowEditDownPayment(false);
          setShowEditInstallment(false);
          setEditAmount(null);
          form.reset();
          document.getElementById("updatePaymentModal").checked = false;
          // document.getElementById("edit-amount-input-field").disabled = true;
        } else {
          toast.error("Edit payment unsuccessfull!");
          toast.error(res?.data?.message);
        }
      });
  };

  //handle edit additional amount, downpayment and installment
  const handleAdditionalAmount = (id) => {
    const amount = additionalAmountEditList?.find(
      (item) => item.id == parseInt(id)
    );
    setEditAmount(amount?.paid);
  };
  const handleEditDownPaymentData = (id) => {
    if (id == -1) {
      setEditAmount(null);
      // document.getElementById("edit-amount-input-field").disabled = true;
    }
    for (let i = 0; i < downPaymentEditList?.length; i++) {
      if (downPaymentEditList[i].id == id) {
        setEditAmount(downPaymentEditList[i].paid);
        document.getElementById("edit-amount-input-field").disabled = false;
      }
    }
  };
  const handleEditInstallmentData = (id) => {
    if (id == -1) {
      setEditAmount(null);
      // document.getElementById("edit-amount-input-field").disabled = true;
    }
    for (let i = 0; i < installmentEditList?.length; i++) {
      if (installmentEditList[i].id == id) {
        setEditAmount(installmentEditList[i].paid);
        // document.getElementById("edit-amount-input-field").disabled = false;
      }
    }
  };
// ---------------for edit-update end------------------


  // ---------------search table data start------------------
  const handleSearch = (e) => {
    // console.log(e.target.value);
    // Declare variables
    let status = 0;
    let filterBy = e.target.value.toUpperCase();
    let table = document.getElementById("dataTable");
    let tr = table.querySelectorAll(".searchData");
    let i = 0;
    let j = 0;
    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      let tempTd = tr[i].getElementsByTagName("td");
      for (j = 0; j < tempTd.length; j++) {
        let td = tempTd[j];
        let txtValue;
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filterBy) > -1) {
            status = 1;
          }
          if (!status) {
            status = 0;
          }
        }
      }
      if (status) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  };
  // ------------------search table data end-----------------
  return (
    <div>
      <div>
        <Heading>Client Payment List</Heading>
        <div className="p-5 whiteBackground">
          <div className="flex flex-wrap justify-between my-5">
            <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
            <div className="flex flex-wrap justify-end my-2">
              <input
                onChange={handleSearch}
                id="searchInput"
                type="text"
                placeholder="Search..."
                className="input input-bordered input-sm w-full max-w-xs mb-2"
              />
            </div>
          </div>

          {clientpaymentlist == null ? (
            <Loader></Loader>
          ) : (
            <div className="overflow-x-auto max-h-[60vh]">
              <table
                id="dataTable"
                className="table table-zebra w-full"
                ref={componentRef}
              >
                <thead className="text-[#00CA08]">
                  <tr className="sticky top-0">
                    <th>
                      Customer's <br /> Country
                    </th>
                    <th>
                      Customer's <br /> Name
                    </th>
                    <th>
                      Customer's <br /> Id
                    </th>
                    <th>File No.</th>
                    <th>Booking Money</th>
                    <th>Car Parking</th>
                    <th>Utility Charge</th>
                    <th>
                      Additional <br /> Amount
                    </th>
                    <th>
                      Downpayment <br /> Amount
                    </th>
                    <th>
                      Installment <br /> Amount
                    </th>
                    <th className="hide-when-print">
                      Add/Edit <br /> Payment <br /> Method
                    </th>
                    <th className="hide-when-print">
                      Payment <br /> Statement <br /> Details <br /> View
                    </th>
                    <th className="hide-when-print">
                      Payment <br /> Money <br /> Receipt <br /> Folder
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {clientpaymentlist?.length > 0 ? (
                    clientpaymentlist?.map((clientpayment, index) => (
                      <tr
                        className="searchData"
                        key={
                          index + clientpayment?.id + Math.random().toFixed(6)
                        }
                      >
                        <td>
                          {clientpayment?.flat_or_plot?.customers?.map(
                            (customer) => {
                              return (
                                <p
                                  key={
                                    index +
                                    customer?.id +
                                    Math.random().toFixed(6)
                                  }
                                >
                                  {customer?.country ? customer?.country : "--"}
                                </p>
                              );
                            }
                          )}
                        </td>
                        <td>
                          {clientpayment?.flat_or_plot?.customers?.map(
                            (customer) => {
                              return (
                                <p
                                  key={
                                    index +
                                    customer?.id +
                                    Math.random().toFixed(6)
                                  }
                                >
                                  {customer?.name ? customer?.name : "--"}
                                </p>
                              );
                            }
                          )}
                        </td>
                        <td>
                          {clientpayment?.flat_or_plot?.customers?.map(
                            (customer) => {
                              return (
                                <p
                                  key={
                                    index +
                                    customer?.id +
                                    Math.random().toFixed(6)
                                  }
                                >
                                  {customer?.id ? customer?.id : "--"}
                                </p>
                              );
                            }
                          )}
                        </td>
                        <td>
                          {clientpayment?.flat_or_plot?.file_no
                            ? clientpayment?.flat_or_plot?.file_no
                            : "--"}
                        </td>
                        <td>
                          {clientpayment?.booking_money
                            ? `${clientpayment?.booking_money} Tk`
                            : "--"}
                        </td>
                        <td>
                          {clientpayment?.car_parking
                            ? `${clientpayment?.car_parking} Tk`
                            : "--"}
                        </td>
                        <td>
                          {clientpayment?.utility_charge
                            ? `${clientpayment?.utility_charge} Tk`
                            : "--"}{" "}
                        </td>
                        <td>
                          {clientpayment?.additional_amount
                            ? `${clientpayment?.additional_amount} Tk`
                            : "--"}
                        </td>
                        <td>
                          {clientpayment?.downpayment_amount
                            ? `${clientpayment?.downpayment_amount} Tk`
                            : "--"}
                        </td>
                        <td>
                          {clientpayment?.installment_amount
                            ? `${clientpayment?.installment_amount} Tk`
                            : "--"}
                        </td>
                        <td className="text-center flex gap-4 hide-when-print">
                          <label
                            htmlFor="updatePaymentModal"
                            className="cursor-pointer"
                            onClick={() => setEditModalData(clientpayment)}
                          >
                            <i className="fa-solid fa-pen-to-square m-2 text-xl"></i>
                          </label>

                          <label
                            htmlFor="addPaymentModal"
                            className="cursor-pointer"
                            onClick={() => setAddModalData(clientpayment)}
                          >
                            <i className="fa-solid fa-square-plus m-2 text-xl"></i>
                          </label>
                        </td>
                        <td className="text-center hide-when-print">
                          <Link
                            to={`viewclientpaymentdetails/${clientpayment?.id}`}
                          >
                            <i className="fa-solid fa-eye m-2 text-xl"></i>
                          </Link>
                        </td>
                        <td className="text-center hide-when-print">
                          <Link
                            to={`clientpaymentmoneyreceiptfolder/${clientpayment?.id}`}
                          >
                            <i className="fa-solid fa-folder text-xl"></i>
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>
                        <h2
                          className="text-center py-5 col-span-10 font-bold"
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          No Details Found
                        </h2>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {/* Add Payment Method Modal */}
      {
        <>
          <input
            type="checkbox"
            id="addPaymentModal"
            className="modal-toggle"
          />
          <div className="modal mt-5">
            <div className="modal-box max-w-2xl">
              {/* <h2 className="text-center ">Add payment</h2> */}
              <div className="flex flex-wrap justify-between">
                <div className="form-control  max-w-xs m-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      File No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={addPaymentModalData?.flat_or_plot?.file_no}
                    className="input input-bordered input-sm  max-w-xs"
                  />
                </div>
                <div className="form-control  max-w-xs m-2">
                  <label className="label">
                    <span className="label-text font-bold  text-[#717A82]">
                      Other File No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value="2709"
                    className="input input-bordered input-sm max-w-xs"
                  />
                </div>
                <div className="form-control max-w-xs m-2">
                  <label className="label">
                    <span className="label-text font-bold  text-[#717A82]">
                      Date
                    </span>
                  </label>
                  <input
                    disabled
                    type="date"
                    value={addPaymentModalData?.flat_or_plot?.booking_date}
                    className="input input-bordered input-sm max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-sm m-2">
                  <label className="label">
                    <span className="label-text font-bold  text-[#717A82]">
                      Customer name
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={modalCustomerName}
                    className="input input-bordered input-sm w-full max-w-sm"
                  />
                </div>
                <div className="form-control  max-w-xs m-2">
                  <label className="label">
                    <span className="label-text font-bold  text-[#717A82]">
                      Customer ID
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={modalCustomerId}
                    className="input input-bordered input-sm  max-w-xs"
                  />
                </div>
              </div>
              <div className="flex flex-wrap justify-between">
                <div className="form-control max-w-xs m-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      {addPaymentModalData?.flat_or_plot?.flat_number ||
                      addPaymentModalData?.flat_or_plot?.floor_no
                        ? "Building No."
                        : "Land No."}
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={addPaymentModalData?.flat_or_plot?.project_id}
                    className="input input-bordered input-sm max-w-xs"
                  />
                </div>
                <div className="form-control max-w-xs m-2">
                  {addPaymentModalData?.flat_or_plot?.flat_number !== null ? (
                    <>
                      <label className="label">
                        <span className="label-text font-bold text-[#717A82]">
                          Flat Number
                        </span>
                      </label>
                      <input
                        disabled
                        type="text"
                        value={addPaymentModalData?.flat_or_plot?.flat_number}
                        className="input input-bordered input-sm max-w-xs"
                      />
                    </>
                  ) : (
                    <>
                      <label className="label">
                        <span className="label-text font-bold text-[#717A82]">
                          Plot No
                        </span>
                      </label>
                      <input
                        disabled
                        type="text"
                        value={addPaymentModalData?.flat_or_plot?.plot_no}
                        className="input input-bordered input-sm max-w-xs"
                      />
                    </>
                  )}
                </div>
                {addPaymentModalData?.flat_or_plot?.floor_no !== null && (
                  <div className="form-control max-w-xs m-2">
                    <label className="label">
                      <span className="label-text font-bold text-[#717A82]">
                        Flat Floor Number
                      </span>
                    </label>
                    <input
                      disabled
                      type="text"
                      value={addPaymentModalData?.flat_or_plot?.floor_no}
                      className="input input-bordered input-sm max-w-xs"
                    />
                  </div>
                )}
              </div>
              <h1 className="text-xl font-bold  m-4 text-[#717A82]">
                Payment Option
              </h1>
              <form onSubmit={(e) => addPayment(e)}>
                <div className="flex flex-wrap justify-between">
                  <div className="form-control max-w-xs m-2">
                    <label className="label">
                      <span className="label-text font-bold text-[#717A82]">
                        Pay By
                      </span>
                    </label>
                    <select
                      className="select select-bordered select-sm"
                      name="Paid_by"
                    >
                      <option value="" selected>
                        Please Select
                      </option>
                      <option value="Cash">Cash</option>
                      <option value="Bank">Bank</option>
                      <option value="Cheque">Cheque</option>
                    </select>
                  </div>
                  <div className="form-control max-w-xs m-2">
                    <label className="label">
                      <span className="label-text font-bold text-[#717A82]">
                        Money Receipt No.
                      </span>
                    </label>
                    <input
                      name="money_receipt_id"
                      type="text"
                      placeholder="Type"
                      className="input input-bordered input-sm max-w-xs"
                    />
                  </div>
                  <div className="form-control max-w-xs m-2">
                    <label className="label">
                      <span className="label-text font-bold text-[#717A82]">
                        Against
                      </span>
                    </label>
                    <select
                      className="select select-bordered select-sm"
                      name="payment_against_id"
                      onBlur={(e) => {
                        againstAddSet(e.target.value, addPaymentModalData?.id);
                        setInstallmentDownpaymentNo(-1);
                      }}
                      onChange={(e) => {
                        againstAddSet(e.target.value, addPaymentModalData?.id);
                        setInstallmentDownpaymentNo(-1);
                      }}
                    >
                      <option value="" selected>
                        Please Select
                      </option>
                      <option value={1}>Booking Money</option>
                      <option value={2}>Car Parking</option>
                      <option value={3}>Utility Charge</option>
                      <option value={4}>Additional Amount</option>
                      <option value={5}>Downpayment</option>
                      <option value={6}>Installment</option>
                    </select>
                  </div>
                  {showDownPayment == true && (
                    <div className="form-control max-w-xs m-2">
                      <label className="label">
                        <span className="label-text font-bold text-[#717A82]">
                          Downpayment No
                        </span>
                      </label>
                      <select
                        className="select select-bordered select-sm"
                        name="down_payment_id"
                        onChange={(e) => {
                          setAddDownpaymentInstallment(
                            e.target.value.split(",")[0]
                          );
                          setInstallmentDownpaymentNo(
                            e.target.value.split(",")[1]
                          );
                        }}
                        onBlur={(e) => {
                          setAddDownpaymentInstallment(
                            e.target.value.split(",")[0]
                          );
                          setInstallmentDownpaymentNo(
                            e.target.value.split(",")[1]
                          );
                        }}
                      >
                        <option value={-1} selected>
                          Please Select
                        </option>
                        {downPaymentList?.length > 0 &&
                          downPaymentList?.map((list, index) => {
                            return (
                              <option value={[list?.id, index + 1]}>
                                Downpayment {index + 1}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  )}
                  {showInstallment == true && (
                    <div className="form-control max-w-xs m-2">
                      <label className="label">
                        <span className="label-text font-bold text-[#717A82]">
                          Installment No
                        </span>
                      </label>
                      <select
                        className="select select-bordered select-sm"
                        name="installment_id"
                        onChange={(e) => {
                          setAddDownpaymentInstallment(
                            e.target.value.split(",")[0]
                          );
                          setInstallmentDownpaymentNo(
                            e.target.value.split(",")[1]
                          );
                        }}
                        onBlur={(e) => {
                          setAddDownpaymentInstallment(
                            e.target.value.split(",")[0]
                          );
                          setInstallmentDownpaymentNo(
                            e.target.value.split(",")[1]
                          );
                        }}
                      >
                        <option value={-1} selected>
                          Please Select
                        </option>
                        {installmentList?.length > 0 &&
                          installmentList?.map((list, index) => {
                            return (
                              <option value={[list.id, index + 1]}>
                                Installment {index + 1}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  )}
                  {showAdditionalAmount == true && (
                    <div className="form-control max-w-xs m-2">
                      <label className="label">
                        <span className="label-text font-bold text-[#717A82]">
                          Additional Amount
                        </span>
                      </label>
                      <select
                        className="select select-bordered select-sm"
                        name="additional_amount_id"
                        // onChange={(e)=>{
                        //   console.log(e.target.value);
                        //   // setAdditionalAmountForId(e.target.value)
                        // }}
                        // onChange={(e) => {
                        //   setAddDownpaymentInstallment(
                        //     e.target.value.split(",")[0]
                        //   );
                        //   setInstallmentDownpaymentNo(
                        //     e.target.value.split(",")[1]
                        //   );
                        // }}
                        // onBlur={(e) => {
                        //   setAddDownpaymentInstallment(
                        //     e.target.value.split(",")[0]
                        //   );
                        //   setInstallmentDownpaymentNo(
                        //     e.target.value.split(",")[1]
                        //   );
                        // }}
                      >
                        <option value="" selected>
                          Please Select
                        </option>
                        {additionalAmountList?.map((opt) => (
                          <option key={opt.id} value={opt.id}>
                            {opt.amount_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="form-control max-w-xs m-2">
                    <label className="label">
                      <span className="label-text font-bold text-[#717A82]">
                        Amount Tk
                      </span>
                    </label>
                    <input
                      id="add-amount-input-field"
                      name="amount"
                      type="text"
                      placeholder="Enter amount"
                      className="input input-bordered input-sm max-w-xs"
                      // disabled={addDownpaymentInstallment != -1 ? false : true}
                    />
                  </div>

                  <div className="form-control max-w-xs m-2">
                    <label className="label">
                      <span className="label-text font-bold  text-[#717A82]">
                        Date
                      </span>
                    </label>
                    <input
                      name="complete_date"
                      type="date"
                      className="input input-bordered input-sm max-w-xs"
                    />
                  </div>
                </div>
                <div className="flex  justify-center mt-5">
                  <label
                    onClick={() => {
                      setAddPaymentModalData(null);
                      setDownPaymentList([]);
                      setInstallmentList([]);
                      setShowDownPayment(false);
                      setShowInstallment(false);
                    }}
                    htmlFor="addPaymentModal"
                    className="cursor-pointer uppercase  text-white text-[14px] font-semibold bg-[#E30B0B] rounded-md py-2.5 px-16 m-2 "
                  >
                    Cancel
                  </label>

                  <input
                    htmlFor="addPaymentModal"
                    type="submit"
                    className="cursor-pointer uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 m-2"
                    value="Submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </>
      }

      {/* edit payment Method modal */}
      {
        <>
          <input
            type="checkbox"
            id="updatePaymentModal"
            className="modal-toggle"
          />
          <div className="modal mt-5">
            <div className="modal-box max-w-2xl">
              {/* <h2 className="text-center ">Edit payment</h2> */}
              <div className="flex flex-wrap justify-between">
                <div className="form-control  max-w-xs m-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      File No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={editPaymentModalData?.flat_or_plot?.file_no}
                    className="input input-bordered input-sm  max-w-xs"
                  />
                </div>
                <div className="form-control  max-w-xs m-2">
                  <label className="label">
                    <span className="label-text font-bold  text-[#717A82]">
                      Other File No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value="2709"
                    className="input input-bordered input-sm max-w-xs"
                  />
                </div>
                <div className="form-control max-w-xs m-2">
                  <label className="label">
                    <span className="label-text font-bold  text-[#717A82]">
                      Date
                    </span>
                  </label>
                  <input
                    disabled
                    type="date"
                    value={editPaymentModalData?.flat_or_plot?.booking_date}
                    className="input input-bordered input-sm max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-sm m-2">
                  <label className="label">
                    <span className="label-text font-bold  text-[#717A82]">
                      Customer name
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={modalCustomerName}
                    className="input input-bordered input-sm w-full max-w-sm"
                  />
                </div>
                <div className="form-control  max-w-xs m-2">
                  <label className="label">
                    <span className="label-text font-bold  text-[#717A82]">
                      Customer ID
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={modalCustomerId}
                    className="input input-bordered input-sm  max-w-xs"
                  />
                </div>
              </div>
              <div className="flex flex-wrap justify-between">
                <div className="form-control max-w-xs m-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      {editPaymentModalData?.flat_or_plot?.flat_number ||
                      editPaymentModalData?.flat_or_plot?.floor_no
                        ? "Building No."
                        : "Land No."}
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={editPaymentModalData?.flat_or_plot?.project_id}
                    className="input input-bordered input-sm max-w-xs"
                  />
                </div>
                <div className="form-control max-w-xs m-2">
                  {editPaymentModalData?.flat_or_plot?.flat_number !== null ? (
                    <>
                      <label className="label">
                        <span className="label-text font-bold text-[#717A82]">
                          Flat Number
                        </span>
                      </label>
                      <input
                        disabled
                        type="text"
                        value={editPaymentModalData?.flat_or_plot?.flat_number}
                        className="input input-bordered input-sm max-w-xs"
                      />
                    </>
                  ) : (
                    <>
                      <label className="label">
                        <span className="label-text font-bold text-[#717A82]">
                          Plot No
                        </span>
                      </label>
                      <input
                        disabled
                        type="text"
                        value={editPaymentModalData?.flat_or_plot?.plot_no}
                        className="input input-bordered input-sm max-w-xs"
                      />
                    </>
                  )}
                </div>
                {editPaymentModalData?.flat_or_plot?.floor_no !== null && (
                  <div className="form-control max-w-xs m-2">
                    <label className="label">
                      <span className="label-text font-bold text-[#717A82]">
                        Flat Floor Number
                      </span>
                    </label>
                    <input
                      disabled
                      type="text"
                      value={editPaymentModalData?.flat_or_plot?.floor_no}
                      className="input input-bordered input-sm max-w-xs"
                    />
                  </div>
                )}
              </div>
              <h1 className="text-xl font-bold underline underline-offset-2 m-4 text-[#717A82]">
                Payment Option
              </h1>
              <form onSubmit={(e) => editPayment(e)}>
                <div className="flex flex-wrap justify-between">
                  <div className="form-control max-w-xs m-2">
                    <label className="label">
                      <span className="label-text font-bold text-[#717A82]">
                        Against
                      </span>
                    </label>
                    <select
                      required
                      className="select select-bordered select-sm"
                      name="payment_against_id"
                      onBlur={(e) =>
                        againstEditSet(e.target.value, editPaymentModalData?.id)
                      }
                      onChange={(e) =>
                        againstEditSet(e.target.value, editPaymentModalData?.id)
                      }
                    >
                      <option value={-1}>Please Select</option>
                      <option value={1}>Booking Money</option>
                      <option value={2}>Car Parking</option>
                      <option value={3}>Utility Charge</option>
                      <option value={4}>Additional Amount</option>
                      <option value={5}>Downpayment</option>
                      <option value={6}>Installment</option>
                    </select>
                  </div>
                  {showEditAdditionalAmount == true && (
                    <div className="form-control max-w-xs m-2">
                      <label className="label">
                        <span className="label-text font-bold text-[#717A82]">
                          Additional Amount
                        </span>
                      </label>
                      <select
                        className="select select-bordered select-sm"
                        name="additional_amount_id"
                        onChange={(e) => handleAdditionalAmount(e.target.value)}
                      >
                        <option value="" selected>
                          Please Select
                        </option>
                        {additionalAmountEditList?.map((opt) => (
                          <option key={opt.id} value={opt.id}>
                            {opt.amount_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {showEditDownPayment == true && (
                    <div className="form-control max-w-xs m-2">
                      <label className="label">
                        <span className="label-text font-bold text-[#717A82]">
                          Downpayment No
                        </span>
                      </label>
                      <select
                        required
                        className="select select-bordered select-sm"
                        name="down_payment_id"
                        onChange={(e) =>
                          handleEditDownPaymentData(e.target.value)
                        }
                        onBlur={(e) =>
                          handleEditDownPaymentData(e.target.value)
                        }
                      >
                        <option value={-1}>Please Select</option>
                        {downPaymentEditList?.length > 0 &&
                          downPaymentEditList?.map((list, index) => {
                            return (
                              <option value={list?.id}>
                                Downpayment {index + 1}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  )}
                  {showEditInstallment == true && (
                    <div className="form-control max-w-xs m-2">
                      <label className="label">
                        <span className="label-text font-bold text-[#717A82]">
                          Installment No
                        </span>
                      </label>
                      <select
                        required
                        className="select select-bordered select-sm"
                        name="installment_id"
                        onChange={(e) =>
                          handleEditInstallmentData(e.target.value)
                        }
                      >
                        <option selected value={-1}>
                          Please Select
                        </option>
                        {installmentEditList?.length > 0 &&
                          installmentEditList?.map((list, index) => {
                            return (
                              <option value={list.id}>
                                Installment {index + 1}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  )}
                  <div className="form-control max-w-xs m-2">
                    <label className="label">
                      <span className="label-text font-bold text-[#717A82]">
                        Amount Tk
                      </span>
                    </label>
                    {editAmount != null ? (
                      <input
                        // id="edit-amount-input-field"
                        required
                        name="amount"
                        type="text"
                        defaultValue={editAmount}
                        onChange={(e) => setEditAmount(e.target.value)}
                        className="input input-bordered input-sm max-w-xs"
                      />
                    ) : (
                      <input
                        // id="edit-amount-input-field"
                        disabled
                        name="amount"
                        type="text"
                        placeholder=""
                        onChange={(e) => setEditAmount(e.target.value)}
                        className="input input-bordered input-sm max-w-xs"
                      />
                    )}
                  </div>
                </div>

                <div className="flex  justify-center mt-5">
                  <label
                    onClick={() => {
                      setEditPaymentModalData(null);
                      setEditAmount(null);
                      setDownPaymentEditList([]);
                      setInstallmentEditList([]);
                      setShowEditDownPayment(false);
                      setShowEditInstallment(false);
                      // document.getElementById(
                      //   "edit-amount-input-field"
                      // ).disabled = true;
                    }}
                    htmlFor="updatePaymentModal"
                    className="cursor-pointer uppercase  text-white text-[14px] font-semibold bg-[#E30B0B] rounded-md py-2.5 px-16 m-2 "
                  >
                    Cancel
                  </label>

                  <input
                    type="submit"
                    className="cursor-pointer uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 m-2"
                    value="Update"
                  />
                </div>
              </form>
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default ClientPaymentListHome;
