import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Heading from "../../Shared/Heading";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import { useReactToPrint } from "react-to-print";
import shapla_city from "../../../assets/shapla-city.png";

const ViewAfterRegiCanceledFileInfo = () => {
  const location = useLocation();
  const viewAfterRegiCancelPaymentData = location.state.data;
  //   console.log(viewAfterRegiCancelPaymentData);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div>
      <Heading>
        <Link to={`/dashboard/afterregistrationcanceledcustomerlist`}>
          <i className="fa-solid fa-arrow-left"></i> After Registration Canceled
          file information
        </Link>
      </Heading>
      <div className="information_container">
        <div className="flex flex-wrap justify-start my-5">
          <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
          {/* <button className="btn btn-sm m-2">Legal Paper Folder</button> */}
          <button disabled className="btn btn-sm m-2 bg-slate-800">
            <span className="text-slate-800 text-xs normal-case ">
              {`Media Name: ${viewAfterRegiCancelPaymentData?.flat_or_plot?.sold_items?.media_name} | Media Phone Number: ${viewAfterRegiCancelPaymentData.flat_or_plot.sold_items.media_phone_number}`}
            </span>
          </button>
        </div>
      </div>
      <div
        className="gap-5 my-12 mx-5 max-w-[900px] print-section"
        ref={componentRef}
      >
        <>
          <div className="w-[210mm] h-[297mm] bg-white">
            <div className="flex justify-center">
              <img
                className="w-[30%] mt-20"
                src={shapla_city}
                alt=""
                srcSet=""
              />
            </div>
            <div className="m-6 font-bold flex justify-center pb-20">
              <h1 className="text-2xl font-semibold underline underline-offset-4 mt-4 mb-4 w-fit">
                Information
              </h1>
            </div>
            <div className="px-10 m-4">
              <div className="my-5">
                <p className="leading-[30px]">
                  <b className="text-[#555050]">Customer Name :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {viewAfterRegiCancelPaymentData?.flat_or_plot?.customers
                      ?.map((customer) => customer.name)
                      .join(", ")}
                  </span>
                </p>
                <p className="leading-[30px]">
                  <b className="text-[#555050]">Customer Id :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {viewAfterRegiCancelPaymentData?.flat_or_plot?.customers
                      ?.map((customer) => customer.id)
                      .join(", ")}
                  </span>
                </p>
                <p className="leading-[30px]">
                  <b className="text-[#555050]">Mobile No :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {viewAfterRegiCancelPaymentData?.flat_or_plot?.customers
                      ?.map((customer) => customer.phone_number)
                      .join(", ")}
                  </span>
                </p>
                <p className="leading-[30px]">
                  <b className="text-[#555050]">File No :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {viewAfterRegiCancelPaymentData?.flat_or_plot?.file_no}
                  </span>
                  <b className="text-[#555050]">Building/Land :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {
                      viewAfterRegiCancelPaymentData?.flat_or_plot?.project_info
                        ?.project_no
                    }
                  </span>
                  <b className="text-[#555050]">Flat/Plot Size :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {viewAfterRegiCancelPaymentData?.flat_or_plot?.size}
                  </span>
                </p>
                <p className="leading-[30px]">
                  <b className="text-[#555050]">New Address :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {viewAfterRegiCancelPaymentData?.new_address}
                  </span>
                </p>
              </div>
              <hr />
              <div className="pb-[100px] mt-5">
                <p className="leading-[30px]">
                  <b className="text-[#555050]">Canceled Application Date :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {
                      viewAfterRegiCancelPaymentData?.canceled_payment_start_date
                    }
                  </span>
                  <b className="text-[#555050]">
                    Total Amount of Canceled File :
                  </b>
                  <span className="underline underline-offset-4 mx-4">
                    {viewAfterRegiCancelPaymentData?.total_amount}
                  </span>
                </p>
                <p className="leading-[30px]">
                  <b className="text-[#555050]">
                    Canceled File Registration Date :
                  </b>
                  <span className="underline underline-offset-4 mx-4">
                    {viewAfterRegiCancelPaymentData?.canceled_file_reg_date}
                  </span>
                  <b className="text-[#555050]">
                    Canceled File Registration Deed No :
                  </b>
                  <span className="underline underline-offset-4 mx-4">
                    {viewAfterRegiCancelPaymentData?.canceled_file_reg_deed_no}
                  </span>
                  <b className="text-[#555050]">
                    Canceled File Registration Land Size :
                  </b>
                  <span className="underline underline-offset-4 mx-4">
                    {
                      viewAfterRegiCancelPaymentData?.canceled_file_reg_land_size
                    }
                  </span>
                  <b className="text-[#555050]">
                    Canceled Payment Start Date :
                  </b>
                  <span className="underline underline-offset-4 mx-4">
                    {
                      viewAfterRegiCancelPaymentData?.canceled_payment_start_date
                    }
                  </span>
                </p>
                <p className="leading-[30px]">
                  <b className="text-[#555050]">Authorized Person Name :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {viewAfterRegiCancelPaymentData?.authorized_person_name}
                  </span>
                  <b className="text-[#555050]">Authorized Mobile No :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {viewAfterRegiCancelPaymentData?.authorized_phone_number}
                  </span>
                  <b className="text-[#555050]">Description :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {viewAfterRegiCancelPaymentData?.description}
                  </span>
                </p>
              </div>
            </div>
            <div className="flex justify-around my-20 py-20">
              <div className="mt-10">
                <hr className="w-full bg-slate-400 h-0.5" />
                <h1>Customer's Signature</h1>
              </div>
              <div className="mt-10">
                <hr className="w-full bg-slate-400 h-0.5" />
                <h1>Official Signature</h1>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default ViewAfterRegiCanceledFileInfo;
