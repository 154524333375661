import React from "react";
import Heading from "../../Shared/Heading";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { useState } from "react";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

let allDatas = [];
const AdditionalAmountDueList = () => {
  const [additionalAmountDueListReports, setAdditionalAmountDueListReports] =
    useState([]);

  useEffect(() => {
    axios
      .get(baseUrl + `/reports/additionalAmountList`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        allDatas = res?.data?.data?.data;
        setAdditionalAmountDueListReports(res?.data?.data?.data);
      });
  }, []);

  //----------filter functionality start ---------------
  const handleFilterData = (e) => {
    console.log(e);
    if (e === "all") {
      setAdditionalAmountDueListReports(allDatas);
    } else if (e === "Total Additional Amount") {
      setAdditionalAmountDueListReports(
        allDatas?.filter(
          (list) => list?.price_information?.total_additional_amount > 0
        )
      );
    } else if (e === "Additional Taka for Land Price") {
      setAdditionalAmountDueListReports(
        allDatas?.filter(
          (list) =>
            list?.price_information?.additional_amount[0]?.amount -
              list?.price_information?.additional_amount[0]?.paid >
            0
        )
      );
    } else if (e === "Additional Taka for Flat Price") {
      setAdditionalAmountDueListReports(
        allDatas?.filter(
          (list) =>
            list?.price_information?.additional_amount[1]?.amount -
              list?.price_information?.additional_amount[1]?.paid >
            0
        )
      );
    } else if (e === "Additional Taka for Flat Size increase") {
      setAdditionalAmountDueListReports(
        allDatas?.filter(
          (list) =>
            list?.price_information?.additional_amount[2]?.amount -
              list?.price_information?.additional_amount[2]?.paid >
            0
        )
      );
    } else if (e === "Additional Taka for Flat Renovation") {
      setAdditionalAmountDueListReports(
        allDatas?.filter(
          (list) =>
            list?.price_information?.additional_amount[3]?.amount -
              list?.price_information?.additional_amount[3]?.paid >
            0
        )
      );
    } else if (e === "Additional Taka for Registration & Mutation Cost") {
      setAdditionalAmountDueListReports(
        allDatas?.filter(
          (list) =>
            list?.price_information?.additional_amount[4]?.amount -
              list?.price_information?.additional_amount[4]?.paid >
            0
        )
      );
    } else if (e === "Additional Taka for Flat Decoration") {
      setAdditionalAmountDueListReports(
        allDatas?.filter(
          (list) =>
            list?.price_information?.additional_amount[5]?.amount -
              list?.price_information?.additional_amount[5]?.paid >
            0
        )
      );
    } else if (e === "Total Additional Amount Payment Complete") {
      setAdditionalAmountDueListReports(
        allDatas.filter(
          (list) =>
            list?.price_information?.additional_amount.reduce(
              (pre, curr) => pre + (curr.amount === curr.paid ? curr.paid : 0),
              0
            ) > 0
        )
      );
    } else if (e === "Total Additional Due Amount") {
      setAdditionalAmountDueListReports(
        allDatas?.filter(
          (list, i) =>
            list?.price_information?.total_additional_amount -
              list?.price_information?.additional_amount.reduce(
                (pre, curr) =>
                  pre + (curr.amount === curr.paid ? curr.paid : 0),
                0
              ) >
            0
        )
      );
    }
  };
  //----------filter functionality End -----------------

  //----------Print a div or section functionality start-----------
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //----------Print a div or section functionality end-------------

    // ---------------search table data start------------------
    const handleSearch = (e) => {
      // console.log(e.target.value);
      // Declare variables
      let status = 0;
      let filterBy = e.target.value.toUpperCase();
      let table = document.getElementById("dataTable");
      let tr = table.querySelectorAll(".searchData");
      let i = 0;
      let j = 0;
      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        let tempTd = tr[i].getElementsByTagName("td");
        for (j = 0; j < tempTd.length; j++) {
          let td = tempTd[j];
          let txtValue;
          if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filterBy) > -1) {
              status = 1;
            }
            if (!status) {
              status = 0;
            }
          }
        }
        if (status) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    };
    // ------------------search table data end-----------------
  return (
    <div>
      <div>
        <Heading>Additional Amount Due List</Heading>
        <div className="p-5 whiteBackground">
          <div className="flex flex-wrap justify-between items-center my-5">
            <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
            <div className="my-2">
              <div className="form-control">
                <div className="flex flex-wrap items-center">
                  <h1 className="m-3 text-[#717A82] font-bold">
                    Search Additional Amount Due List
                  </h1>
                  <select
                    onChange={(e) => handleFilterData(e.target.value)}
                    className="select select-sm select-bordered  my-2 rounded-[5px] bg-[#ECECEC]"
                  >
                    <option value="all">All</option>
                    <option value={"Total Additional Amount"}>
                      Total Additional Amount
                    </option>
                    <option value={"Additional Taka for Land Price"}>
                      Additional Taka for Land Price
                    </option>
                    <option value={"Additional Taka for Flat Price"}>
                      Additional Taka for Flat Price
                    </option>
                    <option value={"Additional Taka for Flat Size increase"}>
                      Additional Taka for Flat Size increase
                    </option>
                    <option value={"Additional Taka for Flat Renovation"}>
                      Additional Taka for Flat Renovation
                    </option>
                    <option
                      value={"Additional Taka for Registration & Mutation Cost"}
                    >
                      Additional Taka for Registration & Mutation Cost
                    </option>
                    <option value={"Additional Taka for Flat Decoration"}>
                      Additional Taka for Flat Decoration
                    </option>
                    <option value={"Total Additional Amount Payment Complete"}>
                      Total Additional Amount Payment Complete
                    </option>
                    <option value={"Total Additional Due Amount"}>
                      Total Additional Due Amount
                    </option>
                  </select>
                  <input
                    onChange={handleSearch}
                    id="searchInput"
                    type="text"
                    placeholder="Search..."
                    className="input input-bordered input-sm max-w-xs ml-1"
                  />
                </div>
              </div>
            </div>
          </div>
          {additionalAmountDueListReports?.length > 0 ? (
            <div ref={componentRef} className="overflow-x-auto max-h-[60vh]">
              <table id="dataTable" className="table table-zebra w-full max-h-[60vh]">
                <thead className="text-[#00CA08]">
                  <tr className="sticky top-0">
                    <th>
                      Customer's <br /> Id
                    </th>
                    <th>
                      Customer's <br /> Name
                    </th>
                    <th>File No.</th>
                    <th>
                      Building/ <br /> Land <br /> No.
                    </th>
                    <th>
                      Flat/ <br /> Plot <br /> Size
                    </th>
                    <th>
                      Total <br />
                      Additional <br />
                      Amount
                    </th>
                    <th>
                      Additional <br />
                      Taka for <br />
                      Land Price <br />
                    </th>
                    <th>
                      Additional <br />
                      Taka for <br />
                      Flat Price
                    </th>
                    <th>
                      Additional <br />
                      Taka for <br />
                      Flat Size <br />
                      increase
                    </th>
                    <th>
                      Additional <br />
                      Taka for <br />
                      Flat <br />
                      Renovation
                    </th>
                    <th>
                      Additional <br />
                      Taka for <br />
                      Registration & <br />
                      Mutation Cost
                    </th>
                    <th>
                      Additional <br />
                      Taka for <br />
                      Flat <br />
                      Decoration
                    </th>
                    <th>
                      Total <br />
                      Additional <br />
                      Amount <br />
                      Payment <br /> Complete
                    </th>
                    <th>
                      Total <br />
                      Additional <br />
                      Due Amount <br />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {additionalAmountDueListReports?.map((list) => {
                    const additionalAmount =
                      list?.price_information?.additional_amount;
                    const completedAdditionalAmount = additionalAmount?.filter(
                      (item) => item.amount === item.paid
                    );
                    let totalAdditionalCompleteAmount = 0;
                    completedAdditionalAmount?.forEach((item) => {
                      totalAdditionalCompleteAmount += parseInt(item.paid);
                    });
                    let totalAdditionalPaidAmount = 0;
                    additionalAmount?.forEach((item) => {
                      totalAdditionalPaidAmount += parseInt(item.paid);
                    });
                    return (
                      <tr className="searchData" key={list.id}>
                        <td>
                          {list?.customers?.map((singleCustomer) => (
                            <h1 key={singleCustomer.id}>{singleCustomer.id}</h1>
                          ))}
                        </td>
                        <td>
                          {list.customers.map((singleCustomer) => (
                            <h1 key={singleCustomer.id}>
                              {singleCustomer.name}
                            </h1>
                          ))}
                        </td>
                        <td>{list.file_no}</td>
                        <td>{list?.project?.project_no}</td>
                        <td>{list.size}</td>
                        <td>
                          {list?.price_information?.total_additional_amount} TK
                        </td>
                        <td>
                          {list?.price_information?.additional_amount[0]
                            ?.amount -
                            list?.price_information?.additional_amount[0]
                              ?.paid >
                          0
                            ? `${
                                list?.price_information?.additional_amount[0]
                                  ?.amount -
                                list?.price_information?.additional_amount[0]
                                  ?.paid
                              }`
                            : 0}{" "}
                          Tk
                        </td>
                        <td>
                          {list?.price_information?.additional_amount[1]
                            ?.amount -
                            list?.price_information?.additional_amount[1]
                              ?.paid >
                          0
                            ? `${
                                list?.price_information?.additional_amount[1]
                                  ?.amount -
                                list?.price_information?.additional_amount[1]
                                  ?.paid
                              }`
                            : 0}{" "}
                          Tk
                        </td>
                        <td>
                          {list?.price_information?.additional_amount[2]
                            ?.amount -
                            list?.price_information?.additional_amount[2]
                              ?.paid >
                          0
                            ? `${
                                list?.price_information?.additional_amount[2]
                                  ?.amount -
                                list?.price_information?.additional_amount[2]
                                  ?.paid
                              } `
                            : 0}{" "}
                          Tk
                        </td>
                        <td>
                          {list?.price_information?.additional_amount[3]
                            ?.amount -
                            list?.price_information?.additional_amount[3]
                              ?.paid >
                          0
                            ? `${
                                list?.price_information?.additional_amount[3]
                                  ?.amount -
                                list?.price_information?.additional_amount[3]
                                  ?.paid
                              } `
                            : 0}{" "}
                          Tk
                        </td>
                        <td>
                          {list?.price_information?.additional_amount[4]
                            ?.amount -
                            list?.price_information?.additional_amount[4]
                              ?.paid >
                          0
                            ? `${
                                list?.price_information?.additional_amount[4]
                                  ?.amount -
                                list?.price_information?.additional_amount[4]
                                  ?.paid
                              }`
                            : 0}{" "}
                          Tk
                        </td>
                        <td>
                          {list?.price_information?.additional_amount[5]
                            ?.amount -
                            list?.price_information?.additional_amount[5]
                              ?.paid >
                          0
                            ? `${
                                list?.price_information?.additional_amount[5]
                                  ?.amount -
                                list?.price_information?.additional_amount[5]
                                  ?.paid
                              }`
                            : 0}{" "}
                          Tk
                        </td>
                        <td>{totalAdditionalCompleteAmount} TK</td>
                        <td>
                          {list?.price_information?.total_additional_amount -
                            totalAdditionalPaidAmount}{" "}
                          TK
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <h1 className="text-center font-bold my-5 py-5">No Data Found</h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdditionalAmountDueList;

/*------------Solve By Rashed Vai----------------
import React from "react";
import Heading from "../../Shared/Heading";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { useState } from "react";

let allDatas = [];
const AdditionalAmountDueList = () => {
  const [additionalAmountDueListReports, setAdditionalAmountDueListReports] =
    useState([]);

  useEffect(() => {
    axios
      .get(baseUrl + `/reports/additionalAmountList`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        //allDatas = res?.data?.data?.data;
        setAdditionalAmountDueListReports(res?.data?.data?.data);

        res?.data?.data?.data?.map((list) => {
          var payment_complete =
            list?.price_information?.additional_amount.reduce(
              (acc, item) => acc + (item.amount == item.paid ? item.paid : 0),
              0
            );
          allDatas.push({
            id: list?.id,
            customers: list?.customers,
            file_no: list?.file_no,
            project_no: list?.project?.project_no,
            size: list?.size,
            total_additional_amount:
              list?.price_information?.total_additional_amount,
            additional_amount_for_land_price:
              list?.price_information?.additional_amount[0]?.amount -
              list?.price_information?.additional_amount[0]?.paid,
            additional_amount_for_flat_price:
              list?.price_information?.additional_amount[1]?.amount -
              list?.price_information?.additional_amount[1]?.paid,
            additional_amount_for_flat_increase:
              list?.price_information?.additional_amount[2]?.amount -
              list?.price_information?.additional_amount[2]?.paid,
            additional_amount_for_flat_renovation:
              list?.price_information?.additional_amount[3]?.amount -
              list?.price_information?.additional_amount[3]?.paid,
            additional_amount_for_reg_mutation_cost:
              list?.price_information?.additional_amount[4]?.amount -
              list?.price_information?.additional_amount[4]?.paid,
            additional_amount_for_flat_decoration:
              list?.price_information?.additional_amount[5]?.amount -
              list?.price_information?.additional_amount[5]?.paid,
            additonal_payment_complete_amount: payment_complete,
            total_additional_due_amount:
              list?.price_information?.total_additional_amount -
              payment_complete,
          });
        });

        setAdditionalAmountDueListReports(allDatas);
      });
  }, []);

  console.log(allDatas);
  console.log(additionalAmountDueListReports);
  //----------filter functionality start ---------------
  const handleFilterData = (e) => {
    console.log(e);
    if (e === "all") {
      setAdditionalAmountDueListReports(allDatas);
    } else if (e === "Total Additional Amount") {
      setAdditionalAmountDueListReports(
        allDatas?.filter((list) => list?.total_additional_amount > 0)
      );
    } else if (e === "Additional Taka for Land Price") {
      setAdditionalAmountDueListReports(
        allDatas?.filter((list) => list?.additional_amount_for_land_price > 0)
      );
    } else if (e === "Additional Taka for Flat Price") {
      setAdditionalAmountDueListReports(
        allDatas?.filter((list) => list.additional_amount_for_flat_price > 0)
      );
    } else if (e === "Additional Taka for Flat Size increase") {
      setAdditionalAmountDueListReports(
        allDatas?.filter((list) => list.additional_amount_for_flat_increase > 0)
      );
    } else if (e === "Additional Taka for Flat Renovation") {
      setAdditionalAmountDueListReports(
        allDatas?.filter(
          (list) => list.additional_amount_for_flat_renovation > 0
        )
      );
    } else if (e === "Additional Taka for Registration & Mutation Cost") {
      setAdditionalAmountDueListReports(
        allDatas?.filter(
          (list) => list.additional_amount_for_reg_mutation_cost > 0
        )
      );
    } else if (e === "Additional Taka for Flat Decoration") {
      setAdditionalAmountDueListReports(
        allDatas?.filter(
          (list) => list.additional_amount_for_flat_decoration > 0
        )
      );
    } else if (e === "Total Additional Amount Payment Complete") {
      setAdditionalAmountDueListReports(
        allDatas?.filter((list) => list?.additonal_payment_complete_amount > 0)
      );
    } else if (e === "Total Additional Due Amount") {
      setAdditionalAmountDueListReports(
        allDatas?.filter((list) => list?.total_additional_due_amount > 0)
      );
    }
  };
  //----------filter functionality End ---------------
  return (
    <div>
      <div>
        <Heading>Additional Amount Due List</Heading>
        <div className="p-5 whiteBackground">
          <div className="flex flex-wrap justify-between items-center my-5">
            <CopyPrintPdfBtn></CopyPrintPdfBtn>
            <div className="my-2">
              <div className="form-control">
                <div className="flex flex-wrap items-center">
                  <h1 className="m-3 text-[#717A82] font-bold">
                    Search Additional Amount Due List
                  </h1>
                  <select
                    onChange={(e) => handleFilterData(e.target.value)}
                    className="select select-sm select-bordered  my-2 rounded-[5px] bg-[#ECECEC]"
                  >
                    <option value="all">All</option>
                    <option value={"Total Additional Amount"}>
                      Total Additional Amount
                    </option>
                    <option value={"Additional Taka for Land Price"}>
                      Additional Taka for Land Price
                    </option>
                    <option value={"Additional Taka for Flat Price"}>
                      Additional Taka for Flat Price
                    </option>
                    <option value={"Additional Taka for Flat Size increase"}>
                      Additional Taka for Flat Size increase
                    </option>
                    <option value={"Additional Taka for Flat Renovation"}>
                      Additional Taka for Flat Renovation
                    </option>
                    <option
                      value={"Additional Taka for Registration & Mutation Cost"}
                    >
                      Additional Taka for Registration & Mutation Cost
                    </option>
                    <option value={"Additional Taka for Flat Decoration"}>
                      Additional Taka for Flat Decoration
                    </option>
                    <option value={"Total Additional Amount Payment Complete"}>
                      Total Additional Amount Payment Complete
                    </option>
                    <option value={"Total Additional Due Amount"}>
                      Total Additional Due Amount
                    </option>
                  </select>
                  <input
                    type="text"
                    placeholder="Search..."
                    className="input input-bordered input-sm max-w-xs ml-1"
                  />
                </div>
              </div>
            </div>
          </div>
          {additionalAmountDueListReports?.length > 0 ? (
            <div className="overflow-x-auto max-h-[60vh]">
              <table className="table table-zebra w-full max-h-[60vh]">
                <thead className="text-[#00CA08]">
                  <tr className="sticky top-0">
                    <th>
                      Customer's <br /> Id
                    </th>
                    <th>
                      Customer's <br /> Name
                    </th>
                    <th>File No.</th>
                    <th>
                      Building/ <br /> Land <br /> No.
                    </th>
                    <th>
                      Flat/ <br /> Plot <br /> Size
                    </th>
                    <th>
                      Total <br />
                      Additional <br />
                      Amount
                    </th>
                    <th>
                      Additional <br />
                      Taka for <br />
                      Land Price <br />
                    </th>
                    <th>
                      Additional <br />
                      Taka for <br />
                      Flat Price
                    </th>
                    <th>
                      Additional <br />
                      Taka for <br />
                      Flat Size <br />
                      increase
                    </th>
                    <th>
                      Additional <br />
                      Taka for <br />
                      Flat <br />
                      Renovation
                    </th>
                    <th>
                      Additional <br />
                      Taka for <br />
                      Registration & <br />
                      Mutation Cost
                    </th>
                    <th>
                      Additional <br />
                      Taka for <br />
                      Flat <br />
                      Decoration
                    </th>
                    <th>
                      Total <br />
                      Additional <br />
                      Amount <br />
                      Payment <br /> Complete
                    </th>
                    <th>
                      Total <br />
                      Additional <br />
                      Due Amount <br />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {additionalAmountDueListReports?.map((list) => {
                    return (
                      <tr key={list.id}>
                        <td>
                          {list?.customers?.map((singleCustomer) => (
                            <h1 key={singleCustomer.id}>{singleCustomer.id}</h1>
                          ))}
                        </td>
                        <td>
                          {list.customers.map((singleCustomer) => (
                            <h1 key={singleCustomer.id}>
                              {singleCustomer.name}
                            </h1>
                          ))}
                        </td>
                        <td>{list.file_no}</td>
                        <td>{list?.project_no}</td>
                        <td>{list?.size}</td>
                        <td>{list?.total_additional_amount} TK</td>
                        <td>
                          {list?.additional_amount_for_land_price > 0
                            ? list?.additional_amount_for_land_price
                            : 0}{" "}
                          Tk
                        </td>
                        <td>
                          {list?.additional_amount_for_flat_price > 0
                            ? list?.additional_amount_for_flat_price
                            : 0}{" "}
                          Tk
                        </td>
                        <td>
                          {list?.additional_amount_for_flat_increase > 0
                            ? list?.additional_amount_for_flat_increase
                            : 0}{" "}
                          Tk
                        </td>
                        <td>
                          {list?.additional_amount_for_flat_renovation > 0
                            ? list?.additional_amount_for_flat_renovation
                            : 0}{" "}
                          Tk
                        </td>
                        <td>
                          {list?.additional_amount_for_reg_mutation_cost > 0
                            ? list?.additional_amount_for_reg_mutation_cost
                            : 0}{" "}
                          Tk
                        </td>
                        <td>
                          {list?.additional_amount_for_flat_decoration > 0
                            ? list?.additional_amount_for_flat_decoration
                            : 0}{" "}
                          Tk
                        </td>
                        <td>{list?.additonal_payment_complete_amount} TK</td>
                        <td>{list?.total_additional_due_amount} TK</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <h1 className="text-center font-bold my-5 py-5">No Data Found</h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdditionalAmountDueList;
------------Solve By Rashed Vai------------------*/
