import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../BaseUrl";
import AddButton from "../../Shared/AddButton";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import Heading from "../../Shared/Heading";
import Loader from "../../Shared/Loader";
import { useReactToPrint } from "react-to-print";

const BuildingAndFlatDetailHome = () => {
  const [buildingInfoList, setBuildingInfoList] = useState(null);

  useEffect(() => {
    axios
      .get(baseUrl + `/projects`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        setBuildingInfoList(res?.data?.data?.data);
      });
  }, []);

  //-----------print functionality start-----------
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //-----------print functionality end-----------

  // ---------------search table data start------------------
  const handleSearch = (e) => {
    // console.log(e.target.value);
    // Declare variables
    let status = 0;
    let filterBy = e.target.value.toUpperCase();
    let table = document.getElementById("dataTable");
    let tr = table.querySelectorAll(".searchData");
    let i = 0;
    let j = 0;
    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      let tempTd = tr[i].getElementsByTagName("td");
      for (j = 0; j < tempTd.length; j++) {
        let td = tempTd[j];
        let txtValue;
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filterBy) > -1) {
            status = 1;
          }
          if (!status) {
            status = 0;
          }
        }
      }
      if (status) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  };
  // ------------------search table data end-----------------
  return (
    <div>
      <Heading>Building And Land Details</Heading>
      <div className="p-5 whiteBackground">
        <div className="flex flex-wrap justify-between mb-5">
          <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
          <div className="my-2 flex">
            <div className="mx-1">
              <Link to="addbuildinginformation">
                <AddButton>Add Building Information</AddButton>
              </Link>
            </div>
            <div>
              <Link to="addlandinformation">
                <AddButton>Add Land Information</AddButton>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-end my-2">
          <input
            onChange={handleSearch}
            id="searchInput"
            type="text"
            placeholder="Type to Search..."
            className="input input-bordered input-sm w-full max-w-xs my-2"
          />
        </div>
        {buildingInfoList == null ? (
          <Loader></Loader>
        ) : (
          <>
            {buildingInfoList?.length > 0 ? (
              <div className="overflow-x-auto max-h-[60vh]">
                <table
                  id="dataTable"
                  className="table table-zebra w-full overflow-x-auto "
                  ref={componentRef}
                >
                  <thead className="text-[#00CA08]">
                    <tr className="sticky top-0">
                      <th>
                        Building/ <br /> Project <br />
                        Name
                      </th>
                      <th>
                        Building/ <br />
                        Land <br />
                        Location
                      </th>
                      <th>Road No.</th>
                      <th>
                        Building/ <br /> Land Face <br />
                        Direction
                      </th>
                      <th>
                        Building/ <br />
                        Land No.
                      </th>
                      <th>Land Size</th>
                      <th className="text-[blue]">
                        Total <br />
                        Number of <br />
                        Floor
                      </th>
                      <th className="text-[blue]">
                        Total <br /> Number of <br />
                        Flat/Plot
                      </th>
                      <th>
                        Work <br />
                        Start <br />
                        Date
                      </th>
                      <th>
                        Work <br /> Complete <br /> Date
                      </th>
                      <th>
                        Work Complete <br />
                        Extended <br /> Date
                      </th>
                      <th className="text-[blue]">
                        Number of <br />
                        Flat/Plot <br /> Sold
                      </th>
                      <th className="text-[blue]">
                        Number of <br />
                        Flat/Plot <br /> Unsold
                      </th>
                      <th className="hide-when-print">
                        Update <br />
                        Building/ <br /> Land <br />
                        Information
                      </th>
                      <th className="hide-when-print">
                        Add <br /> Flat/Plot
                      </th>
                      <th className="hide-when-print">
                        Action <br /> View
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {buildingInfoList !== null &&
                      buildingInfoList?.map((list, index) => (
                        <tr
                          className="searchData"
                          key={
                            list.project_id + index + Math.random().toFixed(3)
                          }
                        >
                          <td>
                            {list?.project_name ? list?.project_name : "--"}
                          </td>
                          <td>
                            {list?.project_location
                              ? list?.project_location
                              : "--"}
                          </td>
                          <td>
                            {list?.project_road_no
                              ? list?.project_road_no
                              : "--"}
                          </td>
                          <td>
                            {list?.project_face_direction
                              ? list?.project_face_direction
                              : "--"}
                          </td>
                          <td>{list?.project_no ? list?.project_no : `--`}</td>
                          <td>
                            {list?.project_land_size
                              ? list?.project_land_size
                              : "--"}
                          </td>
                          <td>
                            {list?.project_number_of_floor
                              ? list?.project_number_of_floor
                              : `--`}
                          </td>
                          <td className="text-center">
                            {list?.project_number_of_flat_or_plot
                              ? list?.project_number_of_flat_or_plot
                              : "--"}{" "}
                            <br />{" "}
                            <Link
                              to={
                                list.type == 1
                                  ? `viewflatsoldunsolddetails/${list?.project_id}`
                                  : `viewplotsoldunsolddetails/${list?.project_id}`
                              }
                            >
                              <button className="btn btn-sm hide-when-print">
                                <small>View Details</small>
                              </button>
                            </Link>
                          </td>
                          <td>
                            {list?.work_start_date
                              ? list?.work_start_date
                              : "--"}
                          </td>
                          <td>
                            {list?.work_end_date ? list?.work_end_date : "--"}
                          </td>
                          <td>
                            {list?.work_complete_date
                              ? list?.work_complete_date
                              : "--"}
                          </td>
                          <td>
                            {list?.sold_flat_or_plot_count
                              ? list?.sold_flat_or_plot_count
                              : "--"}
                          </td>
                          <td>
                            {list?.unsold_flat_or_plot_count
                              ? list?.unsold_flat_or_plot_count
                              : "--"}
                          </td>
                          <td className="text-center hide-when-print">
                            <Link
                              to={
                                list.type == 1
                                  ? `updatebuildinginformation/${list?.project_id}`
                                  : `updatelandinformation/${list.project_id}`
                              }
                            >
                              <i className="fa-solid fa-pen-to-square m-2 text-xl"></i>
                            </Link>
                          </td>
                          <td className="text-center hide-when-print">
                            <Link
                              to={
                                list.type == 1
                                  ? `addflatinformation/${list?.project_id}`
                                  : `addplotinformation/${list?.project_id}`
                              }
                            >
                              <i className="fa-solid fa-square-plus m-2 text-xl"></i>
                            </Link>
                          </td>
                          <td className="text-center hide-when-print">
                            <Link
                              to={
                                list.type == 1
                                  ? `buildingflatcustomerdetails/${list?.project_id}`
                                  : `buildingplotcustomerdetails/${list?.project_id}`
                              }
                            >
                              <i className="fa-solid fa-eye m-2 text-xl"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <h2 className="text-center py-5">No data found</h2>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default BuildingAndFlatDetailHome;
