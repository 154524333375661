import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { baseUrl } from "../../../BaseUrl";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import Heading from "../../Shared/Heading";
import Loader from "../../Shared/Loader";

const ViewClientPaymentDetails = () => {
  const [clientPaymentData, setClientPaymentData] = useState(null);
  const [additionalAmountPaymentCusList, setAdditionalAmountPaymentCusList] =
    useState(null);
  const { id } = useParams();

  // console.log("all", clientPaymentData);
  const singleAdditionalDataByPriceinfoId =
    additionalAmountPaymentCusList?.find(
      (element) => element?.price_information?.id == parseInt(id)
    );
  // console.log("single", singleAdditionalDataByPriceinfoId);
  const additionalFilterByAmountDatas =
    singleAdditionalDataByPriceinfoId?.price_information?.additional_amount?.filter(
      (item) => item?.amount > 0
    );
  // console.log("additional amout", additionalFilterByAmountDatas);

  useEffect(() => {
    axios
      .get(baseUrl + `/customer/payment/details/${id}`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        // console.log(res?.data);
        setClientPaymentData(res?.data);

        const additionalAmountPaymentCusListDatas = res?.data?.detail?.filter(
          (item) => item?.price_information?.total_additional_amount > 0
        );
        setAdditionalAmountPaymentCusList(additionalAmountPaymentCusListDatas);
      });
  }, [id]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <Heading>
        <Link to="/dashboard/clientpaymentlist">
          <i className="fa-solid fa-arrow-left"></i> Client Payment Details
        </Link>
      </Heading>

      <div className="information_container rounded-md">
        <div className="mt-8">
          <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
        </div>
        {clientPaymentData == null ? (
          <Loader></Loader>
        ) : (
          <div className="infomation_content mt-8 pt-8" ref={componentRef}>
            <div className="m-8">
              {clientPaymentData &&
                clientPaymentData?.statement &&
                clientPaymentData?.statement?.flat_or_plot?.customers?.map(
                  (customer, index) => {
                    return (
                      <div key={customer?.id} className="flex flex-wrap mb-3">
                        <div className="form-control w-full max-w-xs m-2">
                          <input
                            disabled
                            type="text"
                            value={`${index + 1}. Customer's Name : ${
                              customer?.name
                            }`}
                            className="input input-bordered font-bold text-lg input-md w-full max-w-xs"
                          />
                        </div>
                        <div className="form-control w-full max-w-xs m-2">
                          <input
                            disabled
                            type="text"
                            value={`Customer's ID : ${customer?.id}`}
                            className="input input-bordered font-bold text-lg input-md w-full max-w-xs"
                          />
                        </div>
                        <div className="form-control w-full max-w-sm m-2">
                          <input
                            disabled
                            type="text"
                            value={`File No : ${clientPaymentData?.statement?.flat_or_plot?.file_no}`}
                            className="input input-bordered font-bold text-lg input-md w-full max-w-sm"
                          />
                        </div>
                        {index == 0 && (
                          <div className="form-control w-full max-w-sm m-2">
                            <input
                              disabled
                              type="text"
                              value={
                                clientPaymentData?.statement?.flat_or_plot
                                  ?.status == 1
                                  ? `Building No : ${clientPaymentData?.statement?.flat_or_plot?.project_id}`
                                  : `Land No : ${clientPaymentData?.statement?.flat_or_plot?.project_id}`
                              }
                              className="input input-bordered font-bold text-lg input-md w-full max-w-sm"
                            />
                          </div>
                        )}
                      </div>
                    );
                  }
                )}
            </div>
            {/*  due content */}
            <div className="grid m-8 pb-8">
              <div className="grid lg:grid-cols-4 gap-1 mx-4">
                <div className="my-auto">
                  <label className="text-[#1b1d1f] text-lg font-bold">
                    Total Payment{" "}
                  </label>
                </div>
                <div className="form-control w-full max-w-xs m-1">
                  <label className="label h-[35px]">
                    <span className="label-text text-xs text-[#1d1d1f] font-bold">
                      Total Flat Price Amount
                    </span>
                  </label>
                  <input
                    type="text"
                    value={clientPaymentData?.statement?.total_money + ` Tk`}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-1">
                  <label className="label h-[35px]">
                    <span className="label-text text-xs text-[#80bc32] font-bold">
                      Total Payment Complete Amount
                    </span>
                  </label>
                  <input
                    type="text"
                    value={
                      parseInt(
                        clientPaymentData?.statement?.booking_money_paid
                      ) +
                      parseInt(clientPaymentData?.statement?.car_parking_paid) +
                      parseInt(
                        clientPaymentData?.statement?.utility_charge_paid
                      ) +
                      parseInt(
                        clientPaymentData?.statement
                          ?.total_downpayment_amount_paid
                      ) +
                      parseInt(
                        clientPaymentData?.statement
                          ?.total_installment_amount_paid
                      ) +
                      parseInt(
                        clientPaymentData?.statement
                          ?.total_additional_amount_paid
                      ) +
                      ` Tk`
                    }
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-1">
                  <label className="label h-[35px]">
                    <span className="label-text text-xs text-[#ed2222de] font-bold">
                      Total Payment Due Amount
                    </span>
                  </label>
                  <input
                    type="text"
                    value={
                      parseInt(clientPaymentData?.statement?.total_money) -
                      (parseInt(
                        clientPaymentData?.statement?.booking_money_paid
                      ) +
                        parseInt(
                          clientPaymentData?.statement?.car_parking_paid
                        ) +
                        parseInt(
                          clientPaymentData?.statement?.utility_charge_paid
                        ) +
                        parseInt(
                          clientPaymentData?.statement
                            ?.total_downpayment_amount_paid
                        ) +
                        parseInt(
                          clientPaymentData?.statement
                            ?.total_installment_amount_paid
                        ) +
                        parseInt(
                          clientPaymentData?.statement
                            ?.total_additional_amount_paid
                        )) +
                      ` Tk`
                    }
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-2 m-8 pb-8">
              <div className="mx-4">
                <div className="grid lg:grid-cols-4 gap-1">
                  <div className="my-auto">
                    <label className="text-[#717A82] text-xs font-bold">
                      Downpayment{" "}
                    </label>
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#b0a5a9] font-bold">
                        Downpayment Amount
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.statement?.total_downpayment_amount +
                        ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#9dc668] font-bold">
                        Payment Complete Amount
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.statement
                          ?.total_downpayment_amount_paid + ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#ed2222de] font-bold">
                        Payment Due Amount
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.statement?.downpayment_amount_due +
                        ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-4 gap-1">
                  <div className="my-auto">
                    <label className="text-[#717A82] text-xs font-bold">
                      Total Installment Payment{" "}
                    </label>
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#b0a5a9] font-bold">
                        Total Installment Amount
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.statement?.total_installment_amount +
                        ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#9dc668] font-bold">
                        Total Installment Complete Amount
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.statement
                          ?.total_installment_amount_paid + ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#ed2222de] font-bold">
                        Total Installment Due Amount
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.statement?.installment_amount_due +
                        ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-4 gap-1">
                  <div className="my-auto">
                    <label className="text-[#717A82] text-xs font-bold">
                      Bokking Amount{" "}
                    </label>
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#b0a5a9] font-bold">
                        Booking Money Amount
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.statement?.total_booking_money +
                        ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#9dc668] font-bold">
                        Payment Complete Amount
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.statement?.booking_money_paid + ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#ed2222de] font-bold">
                        Payment Due Amount
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.statement?.booking_money_due + ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                </div>
                {additionalFilterByAmountDatas?.map((item) => {
                  return (
                    <div key={item?.id} className="grid lg:grid-cols-4 gap-1">
                      <div className="my-auto">
                        <label className="text-[#717A82] text-xs font-bold">
                          {item.amount_name}
                        </label>
                      </div>
                      <div className="form-control w-full max-w-xs m-1">
                        <label className="label h-[35px]">
                          <span className="label-text text-xs text-[#b0a5a9] font-bold">
                            Total Amounts
                          </span>
                        </label>
                        <input
                          type="text"
                          value={item.amount + ` Tk`}
                          className="input input-bordered input-sm w-full max-w-xs"
                        />
                      </div>
                      <div className="form-control w-full max-w-xs m-1">
                        <label className="label h-[35px]">
                          <span className="label-text text-xs text-[#9dc668] font-bold">
                            Payment Complete Amount
                          </span>
                        </label>
                        <input
                          type="text"
                          value={item.paid + ` Tk`}
                          className="input input-bordered input-sm w-full max-w-xs"
                        />
                      </div>
                      <div className="form-control w-full max-w-xs m-1">
                        <label className="label h-[35px]">
                          <span className="label-text text-xs text-[#ed2222de] font-bold">
                            Payment Due Amount
                          </span>
                        </label>
                        <input
                          type="text"
                          value={item.amount - item.paid + ` Tk`}
                          className="input input-bordered input-sm w-full max-w-xs"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="mx-4">
                <div className="grid lg:grid-cols-4 gap-1">
                  <div className="my-auto">
                    <label className="text-[#717A82] text-xs font-bold">
                      As of Today Payment{" "}
                    </label>
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#b0a5a9] font-bold">
                        Need to Complete Amount
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.asOfTodayPayment?.needToComplete +
                        ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#9dc668] font-bold">
                        As of Today Payment Complete
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.asOfTodayPayment?.completed + ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#ed2222de] font-bold">
                        As of Today Payment Due
                      </span>
                    </label>
                    <input
                      type="text"
                      value={clientPaymentData?.asOfTodayPayment?.due + ` Tk`}
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-4 gap-1">
                  <div className="my-auto">
                    <label className="text-[#717A82] text-xs font-bold">
                      As of Today Downpayment{" "}
                    </label>
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#b0a5a9] font-bold">
                        Need to Complete Amount
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.asOfTodayDownpayment
                          ?.needToComplete + ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#9dc668] font-bold">
                        As of Today Payment Complete
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.asOfTodayDownpayment?.completed +
                        ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#ed2222de] font-bold">
                        As of Today Payment Due
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.asOfTodayDownpayment?.due + ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-4 gap-1">
                  <div className="my-auto">
                    <label className="text-[#717A82] text-xs font-bold">
                      As of Today Installment{" "}
                    </label>
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#b0a5a9] font-bold">
                        Need to Complete Amount
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.asOfTodayInstallmentpayment
                          ?.needToComplete + ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#9dc668] font-bold">
                        As of Today Payment Complete
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.asOfTodayInstallmentpayment
                          ?.completed + ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#ed2222de] font-bold">
                        As of Today Payment Due
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.asOfTodayInstallmentpayment?.due +
                        ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-4 gap-1">
                  <div className="my-auto">
                    <label className="text-[#717A82] text-xs font-bold">
                      Utility Charge{" "}
                    </label>
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#b0a5a9] font-bold">
                        Total Amount
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.statement?.total_utility_charge +
                        ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#9dc668] font-bold">
                        Payment Complete
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.statement?.utility_charge_paid +
                        ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#ed2222de] font-bold">
                        Payment Due
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.statement?.utility_charge_due + ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-4 gap-1">
                  <div className="my-auto">
                    <label className="text-[#717A82] text-xs font-bold">
                      Car Parking{" "}
                    </label>
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#b0a5a9] font-bold">
                        Total Amount
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.statement?.total_car_parking + ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#9dc668] font-bold">
                        Payment Complete
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.statement?.car_parking_paid + ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                  <div className="form-control w-full max-w-xs m-1">
                    <label className="label h-[35px]">
                      <span className="label-text text-xs text-[#ed2222de] font-bold">
                        Payment Due
                      </span>
                    </label>
                    <input
                      type="text"
                      value={
                        clientPaymentData?.statement?.car_parking_due + ` Tk`
                      }
                      className="input input-bordered input-sm w-full max-w-xs"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="m-8 pb-5">
              <div className="overflow-x-auto max-h-[60vh]">
                <table className="table table-zebra w-full payment-section-table">
                  <thead className="text-[#00CA08]">
                    <tr className="sticky top-0">
                      <th className="">Payment Schedule Date</th>
                      <th className="">Payment Against</th>
                      <th className="">Money Receipt Number / Cheque No</th>
                      <th className="">Paid By</th>
                      <th className="">Payment Complete Date</th>
                      <th className="">Payment Complete</th>
                      {/* <th>Payment Due</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {clientPaymentData?.statement?.payment_history &&
                    clientPaymentData?.statement?.payment_history?.length >
                      0 ? (
                      clientPaymentData?.statement?.payment_history?.map(
                        (payHistory, index) => (
                          <tr key={index}>
                            <td>{payHistory?.complete_date}</td>
                            <td>{payHistory?.payment_against}</td>
                            <td>{payHistory?.money_receipt_id}</td>
                            <td>{payHistory?.paid_by}</td>
                            <td>{payHistory?.complete_date}</td>
                            <td>{payHistory?.amount} Tk</td>
                            {/* <td>---</td> */}
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <h2
                            className="text-center py-5 col-span-10 font-bold"
                            style={{
                              fontSize: "20px",
                            }}
                          >
                            No Details Found
                          </h2>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewClientPaymentDetails;
