import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Heading from "../../Shared/Heading";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { toast } from "react-toastify";

const AddCancelApplication = () => {
  const [getAllFileInfo, setGetAllFileInfo] = useState([]);
  const [singleFileCustomersInfo, setSingleFileCustomersInfo] = useState({});
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(baseUrl + `/reports/cancelCustomerList/getAllFileInfo`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        console.log(res?.data?.data);
        setGetAllFileInfo(res?.data?.data);
      });
  }, []);

  const handleCustomerByFile = (e) => {
    e.preventDefault();
    const fileNo = parseInt(e.target.value);
    const singleFileInfo = getAllFileInfo.find((item) => item.id === fileNo);
    setSingleFileCustomersInfo(singleFileInfo);
  };

  const onSubmit = (data) => {
    const flat_or_plot_id = singleFileCustomersInfo?.id;
    const newData = {
      ...data,
      flat_or_plot_id,
    };
    axios
      .post(
        baseUrl + `/reports/cancelCustomerList/addCancelCustomer`,
        newData,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        if (res?.data?.status === "failed") {
          res?.data?.message.forEach((message) => {
            toast.error(message);
          });
        } else {
          toast.success(res?.data?.message);
          navigate("/dashboard/canceledcustomerlist");
        }
      });
  };
  return (
    <div className="m-5">
      <Heading>
        <Link to="/dashboard/canceledcustomerlist">
          <i className="fa-solid fa-arrow-left"></i> Canceled Application Form
        </Link>
      </Heading>
      <div className="m-5">
        <form onSubmit={handleSubmit(onSubmit)} className="w-3/5">
          <fieldset className="border-2 rounded-md border-[#1F487C]">
            <legend className="mx-4">
              <p className="bg-[#1F497D] text-[white] py-1 px-4 rounded-md">
                Canceled Application Form
              </p>
            </legend>
            <div className="grid lg:grid-cols-3 m-4">
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      File No.
                    </span>
                  </label>
                  <select
                    className="select select-bordered select-sm w-full max-w-lg"
                    onChange={handleCustomerByFile}
                  >
                    <option disabled selected>
                      Select File
                    </option>
                    {getAllFileInfo?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.file_no}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Building/Land No.
                    </span>
                  </label>
                  <input
                    required
                    value={singleFileCustomersInfo?.project?.project_no}
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Flat/Plot size
                    </span>
                  </label>
                  <input
                    required
                    value={singleFileCustomersInfo?.size}
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                     Customer's Name
                    </span>
                  </label>
                  <input
                    required
                    value={singleFileCustomersInfo?.customers?.map(
                      (customer) => customer.name
                    )}
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Customer ID
                    </span>
                  </label>
                  <input
                    required
                    value={singleFileCustomersInfo?.customers?.map(
                      (customer) => customer.id
                    )}
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Mobile Number
                    </span>
                  </label>
                  <input
                    required
                    value={singleFileCustomersInfo?.customers?.map(
                      (customer) => customer.phone_number
                    )}
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-wrap m-4">
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Canceled Application Date <span>*</span>
                    </span>
                  </label>
                  <input
                    {...register("canceled_application_date")}
                    name="canceled_application_date"
                    type="date"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Total Amount of Canceled File
                    </span>
                  </label>
                  <input
                    {...register("total_amount")}
                    name="total_amount"
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Orginal Amount of Canceled File
                    </span>
                  </label>
                  <input
                    {...register("original_amount")}
                    name="original_amount"
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Extra Amount of Canceled File
                    </span>
                  </label>
                  <input
                    {...register("extra_amount")}
                    name="extra_amount"
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Canceled Payment Start Date
                    </span>
                  </label>
                  <input
                    {...register("canceled_payment_start_date")}
                    name="canceled_payment_start_date"
                    type="date"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Authorized person name
                    </span>
                  </label>
                  <input
                    {...register("authorized_person_name")}
                    name="authorized_person_name"
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Authorized mobile no
                    </span>
                  </label>
                  <input
                    {...register("authorized_phone_number")}
                    name="authorized_phone_number"
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-2 m-4">
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Write new address here
                    </span>
                  </label>
                  <textarea
                    {...register("new_address")}
                    name="new_address"
                    className="textarea textarea-bordered"
                  ></textarea>
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Description
                    </span>
                  </label>
                  <textarea
                    {...register("description")}
                    name="description"
                    className="textarea textarea-bordered"
                  ></textarea>
                </div>
              </div>
            </div>
          </fieldset>

          <div className="flex justify-end">
            <button
              type="submit"
              className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 mb-10 m-2"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCancelApplication;
