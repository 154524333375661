import React from "react";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import Heading from "../../Shared/Heading";
import { Link, useLocation } from "react-router-dom";
// import Loader from "../../Shared/Loader";

const AfterRegiCanceledPaymentStatement = () => {
  const location = useLocation();
  const viewCancelPaymentData = location.state.data;
  // console.log(viewCancelPaymentData);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div>
      <Heading>
        <Link to="/dashboard/afterregistrationcanceledcustomerlist">
          <i className="fa-solid fa-arrow-left"></i>After Registration Canceled
          Payment Statement
        </Link>
      </Heading>
      <div className="information_container">
        <div className="flex flex-wrap justify-between my-2">
          <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
        </div>
        <div className="infomation_content">
          <div ref={componentRef} className="print-section">
            <div className="print-section p-8">
              <div className="form-control w-full  mx-2 text-slate-900">
                <p className="font-bold text-[20px]">
                  <span>File No</span> :{" "}
                  {viewCancelPaymentData?.flat_or_plot?.file_no}
                </p>
              </div>
              <div className="flex flex-wrap">
                <p className="text-slate-700 font-semibold m-2">
                  <span className="underline">Customer's name</span> :{" "}
                  {viewCancelPaymentData?.flat_or_plot?.customers
                    ?.map((item) => item.name)
                    .join(", ")}
                </p>
                <p className="text-slate-700 font-semibold m-2">
                  <span className="underline">Customer's Id</span> :{" "}
                  {viewCancelPaymentData?.flat_or_plot?.customers
                    ?.map((item) => item.id)
                    .join(", ")}
                </p>
                <p className="text-slate-700 font-semibold m-2">
                  <span className="underline">Mobile No</span> :{" "}
                  {viewCancelPaymentData?.flat_or_plot?.customers
                    ?.map((item) => item.phone_number)
                    .join(", ")}
                </p>
              </div>
              <div className="flex flex-wrap">
                <p className="text-slate-700 font-semibold m-2">
                  <span className="underline">Building/Land No</span> :{" "}
                  {
                    viewCancelPaymentData?.flat_or_plot?.project_info
                      ?.project_no
                  }
                </p>
                <p className="text-slate-700 font-semibold m-2">
                  <span className="underline">Flat/Plot Size</span> :{" "}
                  {viewCancelPaymentData?.flat_or_plot?.size}
                </p>
              </div>
              {/* <p className="text-slate-600 text-xl m-2">
                    <span className="underline">Payment Receiver Name</span> : {viewCancelPaymentData?.customer_payment?.map(item=>item.received_by).join(", ")}
                  </p>
                  <p className="text-slate-600 text-xl m-2">
                    <span className="underline">Transaction Completed Staff Name</span> : {viewCancelPaymentData?.customer_payment?.map(item=>item.staff_name).join(", ")}
                  </p> */}
            </div>
            {viewCancelPaymentData?.customer_payment?.length > 0 ? (
              <div className="overflow-x-auto mx-5 max-h-[60vh] table-container">
                <table className="table table-zebra w-[100%]">
                  <thead className="text-[#00CA08]">
                    <tr className="sticky top-0">
                      <th>Payment Date</th>
                      <th>Payment Details</th>
                      <th>Payment Invoice</th>
                      <th>To Pay</th>
                      <th>Payment Complete</th>
                      <th>Payment Due</th>
                    </tr>
                  </thead>
                  <tbody>
                    {viewCancelPaymentData?.customer_payment?.map((list) => {
                      return (
                        <tr key={list?.id}>
                          <td>{list?.payment_date}</td>
                          <td>{list?.payment_method}</td>
                          <td>{list?.invoice_no}</td>
                          <td>{viewCancelPaymentData.total_amount}</td>
                          <td>{list?.payment_amount}</td>
                          <td>
                            {
                              (viewCancelPaymentData.total_amount -=
                                list?.payment_amount)
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <h2 className="text-center py-5">No Data Found</h2>
            )}
          </div>
          <div className="m-5 py-4">
            {/* <PaginationSection></PaginationSection> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AfterRegiCanceledPaymentStatement;
