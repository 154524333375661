import axios from "axios";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../BaseUrl";
import AddButton from "../../Shared/AddButton";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import * as html2pdf from "html2pdf.js";
import { useReactToPrint } from "react-to-print";
import Loader from "../../Shared/Loader";

//global data
let defaultPlotFlatDatas;

const PlotFlatDetails = () => {
  const { register, handleSubmit, reset } = useForm();
  const [plotFlatData, setPlotFlatData] = useState(null);
  const [fileList, setFileList] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [customerName, setCustomerName] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [customeAddInfo, setCustomerAddInfo] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const [reFetch, setReFetch] = useState(false);

  useEffect(() => {
    axios
      .get(baseUrl + "/registration/plotOrFlat/manage", {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        var data = res?.data?.data?.plotOrFlatDetails;
        setPlotFlatData(data);
        defaultPlotFlatDatas = data;
      });
    axios
      .get(
        baseUrl + "/registration/plotOrFlat/addCustomerToPlotOrFlat",

        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setFileList(res?.data?.file_info);
      });
  }, [reFetch]);

  useEffect(() => {
    if (fileId != "null") {
      axios
        .get(
          baseUrl + `/registration/plotOrFlat/getCustomerInfo/${fileId}`,

          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("crm-tdata")
              )}`,
              "content-type": "text/json",
            },
          }
        )
        .then((res) => {
          setFileData(res?.data?.customerInfo[0]);
          let cusName = "";
          let cusId = "";
          for (
            let i = 0;
            i < res?.data?.customerInfo[0]?.customers?.length;
            i++
          ) {
            if (i == 0) {
              cusName = res?.data?.customerInfo[0]?.customers[i]?.name;
              cusId = res?.data?.customerInfo[0]?.customers[i]?.nid_number;
              // console.log(cusName);
              // console.log(cusId);
            } else {
              cusName =
                cusName +
                " / " +
                res?.data?.customerInfo[0]?.customers[i]?.name;
              cusId =
                cusId +
                " / " +
                res?.data?.customerInfo[0]?.customers[i]?.nid_number;
              // console.log(cusName);
              // console.log(cusId);
            }
          }
          setCustomerName(cusName);
          setCustomerId(cusId);
        });
    }
    setFileData(null);
  }, [fileId]);

  //----------add flat or plot functionality start-----------------
  const addFLatPlot = (data) => {
    axios
      .post(baseUrl + "/registration/plotOrFlat/store", data, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        if (res?.data?.status) {
          toast.success("Add successfully!");
          document.getElementById("addPlotModal").checked = false;
          reset();
          setReFetch(!reFetch);
        }
      })
      .catch((error) => {
        const errorMessage = Object.values(error?.response?.data?.errors);
        errorMessage.map((item) => toast.error(item[0]));
      });
  };
  //----------add flat or plot functionality end-----------------

  //----------update flat or plot functionality start-----------------
  const handleUpdate = (event) => {
    event.preventDefault();
    const form = event.target;
    const registration_date = form.registration_date.value;
    const sub_deed_no = form.sub_deed_no.value;
    const land_size = form.land_size.value;
    const mouza_name = form.mouza_name.value;
    const bs_daag_no = form.bs_daag_no.value;
    const bs_khatian_no = form.bs_khatian_no.value;
    const cs_daag_no = form.cs_daag_no.value;
    const cs_khatian_no = form.cs_khatian_no.value;
    const rs_daag_no = form.rs_daag_no.value;
    const rs_khatian_no = form.rs_khatian_no.value;
    const sa_daag_no = form.sa_daag_no.value;
    const sa_khatian_no = form.sa_khatian_no.value;
    const data = {
      registration_date,
      sub_deed_no,
      land_size,
      mouza_name,
      bs_daag_no,
      bs_khatian_no,
      cs_daag_no,
      cs_khatian_no,
      rs_daag_no,
      rs_khatian_no,
      sa_daag_no,
      sa_khatian_no,
    };
    axios
      .post(
        baseUrl + `/registration/plotOrFlat/update/${updateData?.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.status);
        }
        setReFetch(!reFetch);
        setUpdateData(null);
        document.getElementById("updatePlotModal").checked = false;
      })
      .catch((e) => {
        for (const variable in e?.response?.data.errors) {
          toast.error(e?.response?.data.errors[variable][0]);
        }
      });
  };
  //----------update flat or plot functionality end-----------------

  //----------add customer for flat or plot functionality start------------
  const handleAddCustomer = (fileID) => {
    if (fileID == undefined) {
      toast.error("Please fill all field");
      return;
    }
    axios
      .post(
        baseUrl + `/registration/plotOrFlat/assignCustomer/${fileID}`,
        {
          plot_or_flat_detailes_id: customeAddInfo?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        // console.log(res?.data?.status);
        if (res?.data?.status == "success") {
          toast.success("Add customer successfully!");
          setCustomerName("");
          setCustomerId("");
          setCustomerAddInfo(null);
          setFileData(null);
          document.getElementById("addCustomerPlotModal").checked = false;
        } else {
          toast.error("Add customer unsuccessfull!");
        }
      });
  };
  //----------add customer for flat or plot functionality end------------

  //--------------pdf download functionality start-------------
  const pdfDownload = () => {
    var element = document.getElementById("pdf-section");
    var opt = {
      margin: 0.2,
      filename: "myfile.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().from(element).set(opt).save();
    html2pdf(element, opt);
  };
  //--------------pdf download functionality end-------------

  //-----------print functionality start---------
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //-----------print functionality end-----------

  //------------- search by deed no functionality start-----------------
  const handleSearchByDeedNumber = (deedNumber) => {
    const deednumberData = plotFlatData?.filter(
      (item) => item.sub_deed_no === deedNumber
    );
    if (deednumberData.length) {
      setPlotFlatData(deednumberData);
    } else {
      setPlotFlatData(defaultPlotFlatDatas);
    }
  };
  //------------- search by deed no functionality end-----------------

  return (
    <div>
      <div className="flex flex-wrap justify-between items-center my-5">
        <CopyPrintPdfBtn
          handlePrint={handlePrint}
          handleDownloadPdf={pdfDownload}
        ></CopyPrintPdfBtn>
        <div className="my-2">
          <div className="form-control">
            <div className=" flex flex-wrap items-center">
              <div className="flex items-center gap-1">
                <p className="w-[100px] text-center text-[#717A82] font-bold">
                  Search
                </p>
                <input
                  onChange={(e) => {
                    handleSearchByDeedNumber(e.target.value);
                  }}
                  name="deed_number"
                  type="text"
                  placeholder="Deed No.."
                  className="input input-sm input-bordered w-[150px] mr-2 rounded-[5px] bg-[#ECECEC] text-[#494646] placeholder:text-slate-800"
                />
              </div>
              <div className="my-2 ms-2">
                <AddButton>
                  <label htmlFor="addPlotModal" className="cursor-pointer">
                    Add Plot/Flat Details
                  </label>
                </AddButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      {plotFlatData == null ? (
        <Loader></Loader>
      ) : (
        <div className="overflow-x-auto max-h-[60vh]">
          <table
            ref={componentRef}
            id="pdf-section"
            className="table table-zebra w-full"
          >
            <thead className="text-[#00CA08]">
              <tr className="sticky top-0">
                <th>
                  Plot/Flat <br />
                  Registration <br />
                  Date
                </th>
                <th>
                  Plot/Flat <br />
                  Registration <br />
                  Sub-deed No
                </th>
                <th>
                  Total Plot/Flat <br />
                  at Sub-Deed <br />
                  (decimal)
                </th>
                <th>
                  Plot/Flat <br />
                  Registration <br />
                  CS Daag No
                </th>
                <th>
                  Plot/Flat <br />
                  Registration <br />
                  SA Daag No
                </th>
                <th>
                  Plot/Flat <br />
                  Registration <br />
                  RS Daag No
                </th>
                <th>
                  Plot/Flat <br />
                  Registration <br />
                  BS Daag No
                </th>
                <th>
                  Plot/Flat <br />
                  Registration <br />
                  CS Khatian No
                </th>
                <th>
                  Plot/Flat <br />
                  Registration <br />
                  SA Khatian No
                </th>
                <th>
                  Plot/Flat <br />
                  Registration <br />
                  RS Khatian No
                </th>
                <th>
                  Plot/Flat <br />
                  Registration <br />
                  BS Khatian No
                </th>
                <th className="hide-when-print">
                  Edit <br />
                  Plot / Flat <br />
                  Details
                </th>
                <th className="hide-when-print">
                  Customer <br />
                  Add / View
                </th>
              </tr>
            </thead>
            <tbody className="text-sm text-[12px] font-thin">
              {plotFlatData && plotFlatData?.length > 0 ? (
                plotFlatData?.map((list, index) => (
                  <tr key={index + list?.id + Math.random().toFixed(5)}>
                    <td>{list?.registration_date}</td>
                    <td>{list?.sub_deed_no}</td>
                    <td>{list?.land_size}</td>
                    <td>{list?.cs_daag_no}</td>
                    <td>{list?.sa_daag_no}</td>
                    <td>{list?.rs_daag_no}</td>
                    <td>{list?.bs_daag_no}</td>
                    <td>{list?.cs_khatian_no}</td>
                    <td>{list?.sa_khatian_no}</td>
                    <td>{list?.rs_khatian_no}</td>
                    <td>{list?.bs_khatian_no}</td>
                    <td className="text-center hide-when-print">
                      <label
                        className="cursor-pointer"
                        onClick={() => {
                          setUpdateData(list);
                          document.getElementById(
                            "updatePlotModal"
                          ).checked = true;
                        }}
                      >
                        <i className="fa-solid fa-pen-to-square m-2 text-xl"></i>
                      </label>
                    </td>
                    <td className="text-center hide-when-print">
                      <label
                        className="cursor-pointer"
                        onClick={() => {
                          document.getElementById(
                            "addCustomerPlotModal"
                          ).checked = true;
                          const { id, land_size } = list;
                          setCustomerAddInfo({ id, land_size });
                        }}
                      >
                        <i className="fa-solid fa-square-plus m-2 text-xl"></i>
                      </label>
                      <Link
                        to={`/dashboard/registrationnumberdetails/viewplotcustomer/${list?.id}`}
                      >
                        <button>
                          <i className="fa-solid fa-eye m-2 text-xl"></i>
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>
                    <h2
                      className="text-center py-5 col-span-10 font-bold"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      No Plot or Flat Found
                    </h2>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {/*------------- Add Plot Modal-------------- */}
      <input type="checkbox" id="addPlotModal" className="modal-toggle" />
      <div className="modal mt-5">
        <form
          onSubmit={handleSubmit(addFLatPlot)}
          className="modal-box max-w-2xl"
        >
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text  font-bold text-[#717A82]">
                Plot/Flat Registration Date
              </span>
            </label>
            <input
              {...register("registration_date")}
              type="date"
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
          <div className="flex flex-wrap ">
            <div className="form-control max-w-sm m-2">
              <label className="label">
                <span className="label-text font-bold text-[#717A82]">
                  Plot/Flat Registration Sub-Deed No
                </span>
              </label>
              <input
                {...register("sub_deed_no")}
                type="text"
                className="input input-bordered input-sm max-w-sm"
              />
            </div>
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text font-bold text-[#717A82]">
                  Total Plot/Flat at Sub-Deed ( Decimal)
                </span>
              </label>
              <input
                type="text"
                {...register("land_size")}
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
            <div className="form-control w-full max-w-lg m-2">
              <label className="label">
                <span className="label-text font-bold text-[#717A82]">
                  Mouza Name
                </span>
              </label>
              <input
                type="text"
                {...register("mouza_name")}
                className="input input-bordered input-sm w-full max-w-lg"
              />
            </div>
          </div>
          <div>
            <div className="grid lg:grid-cols-4 gap-2">
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text">CS Daag No.</span>
                </label>
                <input
                  type="text"
                  {...register("cs_daag_no")}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text">SA Daag No.</span>
                </label>
                <input
                  type="text"
                  {...register("sa_daag_no")}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text">RS Daag No.</span>
                </label>
                <input
                  type="text"
                  {...register("rs_daag_no")}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text">BS Daag No.</span>
                </label>
                <input
                  type="text"
                  {...register("bs_daag_no")}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text">CS Khatian No.</span>
                </label>
                <input
                  type="text"
                  {...register("cs_khatian_no")}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text">SA Khatian No.</span>
                </label>
                <input
                  type="text"
                  {...register("sa_khatian_no")}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text">RS Khatian No.</span>
                </label>
                <input
                  type="text"
                  {...register("rs_khatian_no")}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text">BS Khatian No.</span>
                </label>
                <input
                  type="text"
                  {...register("bs_khatian_no")}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
            </div>
          </div>
          <div className="flex  justify-center mt-5">
            <label
              htmlFor="addPlotModal"
              className="cursor-pointer uppercase  text-white text-[14px] font-semibold bg-[#E30B0B] rounded-md py-2.5 px-16 m-2 "
            >
              Cancel
            </label>
            <button
              htmlFor="addPlotModal"
              type="submit"
              className="cursor-pointer uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 m-2"
            >
              Submit
            </button>
          </div>
        </form>
      </div>

      {/*------------- Update Plot Modal ----------- */}
      <input type="checkbox" id="updatePlotModal" className="modal-toggle" />
      {updateData !== null && (
        <div className="modal mt-5">
          <form onSubmit={handleUpdate} className="modal-box max-w-2xl">
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text  font-bold text-[#717A82]">
                  Plot/Flat Registration Date
                </span>
              </label>
              <input
                name="registration_date"
                type="date"
                defaultValue={updateData?.registration_date}
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
            <div className="flex flex-wrap ">
              <div className="form-control max-w-sm m-2">
                <label className="label">
                  <span className="label-text font-bold text-[#717A82]">
                    Plot/Flat Registration Sub-Deed No
                  </span>
                </label>
                <input
                  type="text"
                  name="sub_deed_no"
                  defaultValue={updateData?.sub_deed_no}
                  className="input input-bordered input-sm max-w-sm"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text font-bold text-[#717A82]">
                    Total Plot/Flat at Sub-Deed ( Decimal)
                  </span>
                </label>
                <input
                  type="text"
                  name="land_size"
                  defaultValue={updateData?.land_size}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-lg m-2">
                <label className="label">
                  <span className="label-text font-bold text-[#717A82]">
                    Mouza Name
                  </span>
                </label>
                <input
                  type="text"
                  name="mouza_name"
                  defaultValue={updateData?.mouza_name}
                  className="input input-bordered input-sm w-full max-w-lg"
                />
              </div>
            </div>
            <div>
              <div className="grid lg:grid-cols-4 gap-2">
                <div className="form-control w-full max-w-xs m-2">
                  <label className="label">
                    <span className="label-text">CS Daag No.</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={updateData?.cs_daag_no}
                    name="cs_daag_no"
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-2">
                  <label className="label">
                    <span className="label-text">SA Daag No.</span>
                  </label>
                  <input
                    type="text"
                    name="sa_daag_no"
                    defaultValue={updateData?.sa_daag_no}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-2">
                  <label className="label">
                    <span className="label-text">RS Daag No.</span>
                  </label>
                  <input
                    type="text"
                    name="rs_daag_no"
                    defaultValue={updateData?.rs_daag_no}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-2">
                  <label className="label">
                    <span className="label-text">BS Daag No.</span>
                  </label>
                  <input
                    type="text"
                    name="bs_daag_no"
                    defaultValue={updateData?.bs_daag_no}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-2">
                  <label className="label">
                    <span className="label-text">CS Khatian No.</span>
                  </label>
                  <input
                    type="text"
                    name="cs_khatian_no"
                    defaultValue={updateData?.cs_khatian_no}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-2">
                  <label className="label">
                    <span className="label-text">SA Khatian No.</span>
                  </label>
                  <input
                    type="text"
                    name="sa_khatian_no"
                    defaultValue={updateData?.sa_khatian_no}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-2">
                  <label className="label">
                    <span className="label-text">RS Khatian No.</span>
                  </label>
                  <input
                    type="text"
                    name="rs_khatian_no"
                    defaultValue={updateData?.rs_khatian_no}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-2">
                  <label className="label">
                    <span className="label-text">BS Khatian No.</span>
                  </label>
                  <input
                    type="text"
                    name="bs_khatian_no"
                    defaultValue={updateData?.bs_khatian_no}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
              </div>
            </div>
            <div className="flex  justify-center mt-5">
              <label
                // htmlFor="updatePlotModal"
                className="cursor-pointer uppercase  text-white text-[14px] font-semibold bg-[#E30B0B] rounded-md py-2.5 px-16 m-2 "
                onClick={() => {
                  setUpdateData(null);
                  document.getElementById("updatePlotModal").checked = false;
                }}
              >
                Cancel
              </label>
              <button
                htmlFor="updatePlotModal"
                type="submit"
                className="cursor-pointer uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 m-2"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      )}

      {/*--------------- Add Customer To This Plot Modal----------- */}
      <input
        type="checkbox"
        id="addCustomerPlotModal"
        className="modal-toggle"
      />
      <div className="modal mt-5">
        <div className="modal-box max-w-2xl">
          <h1 className="font-bold text-[#717A82] mx-2 my-5">
            Add Customer To This Plot/Flat
          </h1>
          <div className="flex flex-wrap ">
            <div className="form-control max-w-xs m-2">
              <label className="label">
                <span className="label-text font-bold text-[#717A82]">
                  File No.
                </span>
              </label>
              <select
                className="select select-bordered select-sm max-w-xs"
                onChange={(e) => setFileId(e.target.value)}
              >
                <option disabled selected value={"null"}>
                  Select File No
                </option>
                {fileList &&
                  fileList?.map((list, index) => {
                    return (
                      <option value={list?.id} key={index}>
                        {list?.file_no}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text font-bold text-[#717A82]">
                  Customer's Name
                </span>
              </label>
              <input
                disabled
                type="text"
                value={customerName}
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
          </div>
          <div className="flex flex-wrap ">
            <div className="form-control max-w-xs m-2">
              <label className="label">
                <span className="label-text font-bold text-[#717A82]">
                  Building/Land No.
                </span>
              </label>
              <input
                disabled
                type="text"
                value={fileData?.project?.project_no}
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
            <div className="form-control max-w-xs m-2">
              <label className="label">
                <span className="label-text font-bold text-[#717A82]">
                  Customer's ID
                </span>
              </label>
              <input
                disabled
                type="text"
                value={customerId}
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
            <div className="form-control max-w-xs m-2">
              <label className="label">
                <span className="label-text font-bold text-[#717A82]">
                  Plot/Flat Size(decimal)
                </span>
              </label>
              <input
                type="text"
                disabled
                value={customeAddInfo?.land_size}
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
          </div>
          <div className="flex  justify-center mt-5">
            <label
              // htmlFor="addCustomerPlotModal"
              onClick={() => {
                setCustomerName("");
                setCustomerId("");
                setCustomerAddInfo(null);
                setFileData(null);
                document.getElementById("addCustomerPlotModal").checked = false;
              }}
              className="cursor-pointer uppercase  text-white text-[14px] font-semibold bg-[#E30B0B] rounded-md py-2.5 px-16 m-2 "
            >
              Cancel
            </label>
            <label
              // htmlFor="addCustomerPlotModal"
              onClick={() => handleAddCustomer(fileData?.id)}
              type="submit"
              className="cursor-pointer uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 m-2"
            >
              Submit
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlotFlatDetails;
