import React from "react";
import Heading from "../../Shared/Heading";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const AddBuyBackDetails = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const viewCancelCustomerDataAfterRegi = location.state.data;
  // console.log(viewCancelCustomerDataAfterRegi);

  const onSubmit = (data) => {
    const after_reg_cancel_customer_id = viewCancelCustomerDataAfterRegi?.id;
    const flat_or_plot_id = viewCancelCustomerDataAfterRegi?.flat_or_plot?.id;
    const newData = {
      ...data,
      after_reg_cancel_customer_id,
      flat_or_plot_id,
    };
    axios
      .post(
        baseUrl + `/reports/afterRegCancelCustomerList/addBuyBackData`,
        newData,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        if (res?.data?.status === "failed") {
          // res?.data?.message.forEach((message) => {
          toast.error(res?.data?.message);
          // });
        } else {
          toast.success(res?.data?.message);
          navigate("/dashboard/afterregistrationcanceledcustomerlist");
        }
      });
  };
  return (
    <div className="m-5">
      <Heading>
        <Link to="/dashboard/afterregistrationcanceledcustomerlist">
          <i className="fa-solid fa-arrow-left"></i>Add Buy Back Details
        </Link>
      </Heading>
      <div className="m-5">
        <form onSubmit={handleSubmit(onSubmit)} className="w-3/5">
          <fieldset className="border-2 rounded-md border-[#1F487C]">
            <legend className="mx-4">
              <p className="bg-[#1F497D] text-[white] py-1 px-4 rounded-md">
                {" "}
                Add Buy Back Details From
              </p>
            </legend>

            <div className="grid lg:grid-cols-2 m-4">
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      File No.*
                    </span>
                  </label>
                  <input
                    value={
                      viewCancelCustomerDataAfterRegi?.flat_or_plot?.file_no
                    }
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Customer's Name*
                    </span>
                  </label>
                  <input
                    value={viewCancelCustomerDataAfterRegi?.flat_or_plot?.customers?.map(
                      (customerName) => customerName.name
                    )}
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-4 m-4">
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Customer ID
                    </span>
                  </label>
                  <input
                    value={viewCancelCustomerDataAfterRegi?.flat_or_plot?.customers?.map(
                      (customerName) => customerName.id
                    )}
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Mobile Number
                    </span>
                  </label>
                  <input
                    value={viewCancelCustomerDataAfterRegi?.flat_or_plot?.customers?.map(
                      (customerName) => customerName.phone_number
                    )}
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Building/Land No.
                    </span>
                  </label>
                  <input
                    value={
                      viewCancelCustomerDataAfterRegi?.flat_or_plot
                        ?.project_info?.project_no
                    }
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Flat/Plot size
                    </span>
                  </label>
                  <input
                    value={viewCancelCustomerDataAfterRegi?.flat_or_plot?.size}
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Authorized Person Name
                    </span>
                  </label>
                  <input
                    value={
                      viewCancelCustomerDataAfterRegi?.authorized_person_name
                    }
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Authorized Mobile No.
                    </span>
                  </label>
                  <input
                    value={
                      viewCancelCustomerDataAfterRegi?.authorized_phone_number
                    }
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-wrap m-4">
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Registration Buy Back Complete Date
                    </span>
                  </label>
                  <input
                    {...register("buy_back_date")}
                    name="buy_back_date"
                    type="date"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Registration Buy Back Deed No
                    </span>
                  </label>
                  <input
                    {...register("buy_back_deed_no")}
                    name="buy_back_deed_no"
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
            </div>
          </fieldset>

          <div className="flex justify-end">
            <button
              type="submit"
              className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 mb-10 m-2"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddBuyBackDetails;
