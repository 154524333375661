import axios from "axios";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { baseUrl } from "../../../BaseUrl";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import Heading from "../../Shared/Heading";
import Loader from "../../Shared/Loader";
import PaginationSection from "../../Shared/PaginationSection";

const ViewFlatSoldUnsoldDetailsHome = () => {
  const [flatList, setFlatList] = useState({});
  // console.log(flatList);

  const { id } = useParams();
  // console.log(id);

  useEffect(() => {
    axios
      .get(
        baseUrl + `/projects/viewDetails/${id}`,

        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        // console.log(res.data.data);
        setFlatList(res.data.data);
      });
  }, [id]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div>
      <Heading>
        <Link to="/dashboard/buildingandflatdetails">
          <i className="fa-solid fa-arrow-left"></i> Flat Sold/Unsold Details
        </Link>
      </Heading>
      <div className="information_container">
        <div className="infomation_content">
          <div className="flex flex-wrap justify-between m-8 pt-5">
            <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
            <div>
              <input
                type="text"
                value="Search..."
                className="input input-bordered input-sm w-full max-w-xs my-2"
              />
            </div>
          </div>
          <div ref={componentRef} className="print-section">
            <div className="m-8 print-section">
              <div className="flex flex-wrap">
                <div className="form-control mt-5 mx-1">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Building Name
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={flatList?.name}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control mt-5 mx-1">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Total Floor No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={flatList?.number_of_flat_or_plot}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control mt-5 mx-1">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Total Flat No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={flatList?.number_of_flat_or_plot}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
              </div>
              <div className="grid lg:grid-cols-4 gap-2">
                <div className="form-control w-full mt-5 mx-1">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Road No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={flatList?.road_no}
                    className="input input-bordered input-sm w-full "
                  />
                </div>
                <div className="form-control w-full mt-5 mx-1">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Building Number
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={flatList?.id}
                    className="input input-bordered input-sm w-full"
                  />
                </div>
                <div className="form-control w-full mt-5 mx-1">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Building Face Direction
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={flatList?.face_direction}
                    className="input input-bordered input-sm w-full"
                  />
                </div>
                <div className="form-control w-full mt-5 mx-1">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Building Location
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={flatList?.location}
                    className="input input-bordered input-sm w-full"
                  />
                </div>
              </div>
            </div>
            {flatList == null ? (
              <Loader></Loader>
            ) : (
              <>
                {flatList?.flat_or_plot?.length > 0 ? (
                  <div className="overflow-x-auto m-5  max-h-[60vh] table-container">
                    <table className="table table-zebra w-[100%] mt-8">
                      <thead className="text-[#00CA08]">
                        <tr className="sticky top-0">
                          <th>File Number</th>
                          <th>Flat Number</th>
                          <th>Flat Floor No.</th>
                          <th>Flat Face Direction</th>
                          <th>Flat Size</th>
                          <th>Sell Status</th>
                          <th className="hide-when-print">Edit Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {flatList?.flat_or_plot?.map((list) => (
                          <tr key={list?.id}>
                            <td className="font-bold">{list?.file_no}</td>
                            <td>{list?.flat_number}</td>
                            <td>{list?.floor_no}</td>
                            <td>{list?.face_direction}</td>
                            <td>{list?.size}</td>
                            <td>
                              {list?.status == "Unsold" ? (
                                <span className="text-[#00CA08] font-bold">
                                  Unsold
                                </span>
                              ) : (
                                <span className="text-[#FF0000] font-bold">
                                  Sold
                                </span>
                              )}
                            </td>
                            <td className="hide-when-print">
                              <Link to={`editflatdetails/${list?.id}`}>
                                <i className="fa-solid fa-pen-to-square m-2 text-xl text-white bg-blue-500 px-1 rounded-[5px]"></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <h2 className="text-center py-5">No Flat Found</h2>
                )}
              </>
            )}
          </div>
          <div className="m-5 py-4">
            {/* <PaginationSection></PaginationSection> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewFlatSoldUnsoldDetailsHome;
