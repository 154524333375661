import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { baseUrl } from "../../../BaseUrl";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import Heading from "../../Shared/Heading";
import PaginationSection from "../../Shared/PaginationSection";

const ViewStatus = () => {
  const [rowData, setRowData] = useState({});
  const { state } = useLocation();
  useEffect(() => {
    axios
      .get(
        baseUrl + `/registration/amount/due_details/${state?.id}`,

        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        // console.log(res?.data?.data);
        if (res?.status == 200) {
          setRowData(res?.data?.data);
        }
      });
  }, [state?.id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div>
      <Heading>
        <Link to="/dashboard/registrationamount">
          <i className="fa-solid fa-arrow-left"></i> Registry Due Amount Details
          View{" "}
        </Link>
      </Heading>
      <div className="information_container">
        <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
        <div className="infomation_content pt-8" ref={componentRef}>
          {rowData?.customers?.map((item, i) => (
            <div key={i} className="flex flex-wrap m-8 ">
              <div className="form-control w-full  mx-2 text-slate-900">
                <p className="font-bold text-[20px]">
                  <span>File No : </span>
                  <span className="font-bold text-lg">{rowData?.file_no}</span>
                </p>
              </div>
              <div className="form-control w-full  mx-2">
                <p className="text-slate-700 font-semibold mb-2">
                  <span className="underline">Customer name</span>
                  <span className="mx-1">:</span>
                  <span className="">{item?.name}</span>
                </p>
              </div>
              {/* <div className="form-control w-full max-w-xs m-2">
                <p className="text-slate-700 font-semibold mb-2">Customer Nid</p>
                <input
                  disabled
                  type="text"
                  value={item?.nid_number}
                  className="input input-bordered font-bold text-lg input-md w-full max-w-xs"
                />
              </div> */}
            </div>
          ))}

          <div className="grid lg:grid-cols-2 m-8 pb-8">
            <div className="mx-4">
              <div className="grid lg:grid-cols-4 gap-1">
                <div className="my-auto flex items-center">
                  <label className="text-[#191c1e] text-xs font-bold text-[19px]">
                    Total <br /> Registration <br /> Cost{" "}
                  </label>
                </div>
                <div className="form-control w-full max-w-xs m-1">
                  <label className="label">
                    <span className="label-text text-xs text-[#b0a5a9] font-bold">
                      Need to pay
                    </span>
                  </label>
                  <input
                    type="text"
                    value={rowData?.total_reg_amount}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-1">
                  <label className="label">
                    <span className="label-text text-xs text-[#00CA08] font-bold">
                      Payment Complete
                    </span>
                  </label>
                  <input
                    type="text"
                    value={rowData?.total_reg_payment}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-1">
                  <label className="label">
                    <span className="label-text text-xs text-[#ed2222de] font-bold">
                      Payment Due
                    </span>
                  </label>
                  <input
                    type="text"
                    value={rowData?.total_reg_dues}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
              </div>
              <div className="grid lg:grid-cols-4 gap-1">
                <div className="my-auto">
                  <label className="text-[#191c1e] text-xs font-bold text-[19px]">
                    Power of <br /> Attorney Cost{" "}
                  </label>
                </div>
                <div className="form-control w-full max-w-xs m-1">
                  <label className="label">
                    <span className="label-text text-xs text-[#b0a5a9] font-bold">
                      Need to pay
                    </span>
                  </label>
                  <input
                    type="text"
                    value={rowData?.power_of_attorney_cost_amount}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-1">
                  <label className="label">
                    <span className="label-text text-xs text-[#00CA08] font-bold">
                      Payment Complete
                    </span>
                  </label>
                  <input
                    type="text"
                    value={rowData?.power_of_attorney_cost_payment}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-1">
                  <label className="label">
                    <span className="label-text text-xs text-[#ed2222de] font-bold">
                      Payment Due
                    </span>
                  </label>
                  <input
                    type="text"
                    value={rowData?.power_of_attorney_cost_due}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
              </div>
            </div>
            <div className="mx-4">
              <div className="grid lg:grid-cols-4 gap-1">
                <div className="my-auto">
                  <label className="text-[#191c1e] text-xs font-bold text-[19px]">
                    Plot/Flat <br /> Registry Amount{" "}
                  </label>
                </div>
                <div className="form-control w-full max-w-xs m-1">
                  <label className="label">
                    <span className="label-text text-xs text-[#b0a5a9] font-bold">
                      Need to pay
                    </span>
                  </label>
                  <input
                    type="text"
                    value={rowData?.registry_amount}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-1">
                  <label className="label">
                    <span className="label-text text-xs text-[#00CA08] font-bold">
                      Payment Complete
                    </span>
                  </label>
                  <input
                    type="text"
                    value={rowData?.registry_payment}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-1">
                  <label className="label">
                    <span className="label-text text-xs text-[#ed2222de] font-bold">
                      Payment Due
                    </span>
                  </label>
                  <input
                    type="text"
                    value={rowData?.registry_due}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
              </div>
              <div className="grid lg:grid-cols-4 gap-1">
                <div className="my-auto">
                  <label className="text-[#191c1e] text-xs font-bold text-[19px]">
                    Mutation Cost{" "}
                  </label>
                </div>
                <div className="form-control w-full max-w-xs m-1">
                  <label className="label">
                    <span className="label-text text-xs text-[#b0a5a9] font-bold">
                      Need to pay
                    </span>
                  </label>
                  <input
                    type="text"
                    value={rowData?.mutation_cost_amount}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-1">
                  <label className="label">
                    <span className="label-text text-xs text-[#00CA08] font-bold">
                      Payment Complete
                    </span>
                  </label>
                  <input
                    type="text"
                    value={rowData?.mutation_cost_payment}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs m-1">
                  <label className="label">
                    <span className="label-text text-xs text-[#ed2222de] font-bold">
                      Payment Due
                    </span>
                  </label>
                  <input
                    type="text"
                    value={rowData?.mutation_cost_due}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto registration-amount-section-table m-5 max-h-[60vh]">
            <table className="table table-zebra w-full registration-amount-section-table">
              <thead className="text-[#00CA08]">
                <tr className="sticky top-0">
                  <th>Payment Schedule Date</th>
                  <th>Payment Against</th>
                  <th>Money Receipt No</th>
                  <th>Paid By</th>
                  <th>Payment Complete Date</th>
                  <th>Payment Complete</th>
                  <th>Payment Due</th>
                </tr>
              </thead>
              <tbody>
                {rowData?.payment_histories?.length > 0 ? (
                  rowData?.payment_histories?.map((paymentItem) => (
                    <tr>
                      <td>
                        {(+paymentItem?.payment_against == 1 &&
                          paymentItem?.registration_amount
                            ?.registry_amount_schedule_date) ||
                          (+paymentItem?.payment_against == 2 &&
                            paymentItem?.registration_amount
                              ?.mutation_cost_schedule_date) ||
                          (+paymentItem?.payment_against == 3 &&
                            paymentItem?.registration_amount
                              ?.power_of_attorney_cost_schedule_date)}
                      </td>
                      <td>
                        {(+paymentItem?.payment_against == 1 &&
                          "Registry Amount") ||
                          (+paymentItem?.payment_against == 2 &&
                            "Mutation cost amount") ||
                          (+paymentItem?.payment_against == 3 &&
                            "Power of attorney cost amount")}
                      </td>
                      <td>{paymentItem?.money_receipt_no}</td>
                      <td>{paymentItem?.pay_by}</td>
                      <td>{paymentItem?.payment_date}</td>
                      <td>{paymentItem?.payment_amount}</td>
                      <td>{paymentItem?.payment_due}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      <h2
                        className=" py-5 col-span-10 font-bold"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        No Details Found
                      </h2>
                    </td>
                  </tr>
                )}
                {/* <tr>
                  <td>15/10/2022</td>
                  <td>Downpayment 1</td>
                  <td>36VW85</td>
                  <td>Bkash</td>
                  <td>15/10/2022</td>
                  <td>100000 tk</td>
                  <td>00 tk</td>
                </tr>
                <tr>
                  <td>15/10/2022</td>
                  <td>Downpayment 2</td>
                  <td>36VW85</td>
                  <td>Nagad</td>
                  <td>--</td>
                  <td>--</td>
                  <td>100000 tk</td>
                </tr>
                <tr>
                  <td>15/10/2022</td>
                  <td>Installment 1</td>
                  <td>36VW85</td>
                  <td>Bank</td>
                  <td>15/10/2022</td>
                  <td>100000 tk</td>
                  <td>00 tk</td>
                </tr> */}
              </tbody>
            </table>
          </div>
          <div className="m-5 py-4">
            {/* <PaginationSection></PaginationSection> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewStatus;
