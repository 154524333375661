import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { baseUrl } from "../../../BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";

const EditPlotDetails = () => {
  const { id } = useParams();
  const { plotId } = useParams();
  const [plot, setPlot] = useState({});

  useEffect(() => {
    axios
      .get(
        baseUrl + `/flat_or_plot/detail/${plotId}`,
      
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setPlot(res.data.details[0]);
      });
  }, [plotId]);

  const navigate = useNavigate();
  const handleFlatUpdate = (e) => {
    e.preventDefault();

    const project_id = id;
    const file_no = id;
    const plot_no = e.target.plot_no.value;
    const face_direction = e.target.face_direction.value;
    const size = e.target.size.value;
    let status = e.target.status.value;
    if (status == "Unsold") {
      status = 0;
    } else if (status == "Sold") {
      status = 1;
    }
    if (plot_no == "") {
      toast.error("Plot number required!");
      return;
    }
    const data = {
      project_id,
      file_no,
      plot_no,
      face_direction,
      size,
      status,
    };
    axios
      .post(baseUrl + `/flat_or_plot/update/${plotId}`, data, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        // console.log(res);
        if (res?.data?.status == "success") {
          toast.success("Edit Plot successfully!");
          navigate(
            `/dashboard/buildingandflatdetails/viewplotsoldunsolddetails/${id}`
          );
        } else {
          res?.data?.message.forEach((m) => {
            toast.error(m);
          });
        }
      });
  };
  return (
    <div className="p-5 bg-white shadow-2xl w-[60%] rounded-lg mx-auto my-24">
      <h1 className="text-start text-2xl font-bold text-[#717A82] mx-2 mt-8 mb-4">
        Edit Plot Details
      </h1>
      <form onSubmit={handleFlatUpdate}>
        <div className="grid lg:grid-cols-3 gap-5">
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text">Plot Number</span>
            </label>
            <input
              defaultValue={plot?.plot_no}
              name="plot_no"
              type="text"
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text">Plot Face Direction</span>
            </label>
            <select
              name="face_direction"
              className="select select-bordered select-sm"
              defaultValue={plot?.flat_or_plot_face_direction}
            >
              <option disabled selected value={null}>
                {plot?.flat_or_plot_face_direction}
              </option>
              <option value="East">East</option>
              <option value="West">West</option>
              <option value="North">North</option>
              <option value="South">South</option>
              <option value="North-East">North-East</option>
              <option value="South-East">South-East</option>
              <option value="North-West">North-West</option>
              <option value="South-West">South-West</option>
            </select>
          </div>
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text">Plot Size</span>
            </label>
            {/* <select name="size" className="select select-bordered select-sm">
              <option disabled selected>
                {plot?.size}
              </option>
              <option>1091 sqft</option>
              <option>701 sqft</option>
              <option>390 sqft</option>
            </select> */}
            <input
              name="size"
              defaultValue={plot?.flat_or_plot_size}
              type="text"
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text">Sell Status</span>
            </label>
            <select
              name="status"
              type="number"
              className="select select-bordered select-sm"
              defaultValue={plot?.flat_or_plot_status}
            >
              <option disabled selected value={plot?.flat_or_plot_status}>
                {plot?.flat_or_plot_status == "Unsold" ? "Unsold" : "Sold"}
              </option>
              <option value={1}>Sold</option>
              <option value={0}>Unsold</option>
            </select>
          </div>
        </div>

        <div className="flex flex-wrap justify-start mt-8">
          <Link
            to={`/dashboard/buildingandflatdetails/viewplotsoldunsolddetails/${id}`}
          >
            <button
              type="submit"
              className="uppercase  text-white text-[14px] font-semibold bg-[#E30B0B] rounded-md py-2.5 px-16 m-2"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 mb-10 m-2"
          >
            Update Plot
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPlotDetails;
