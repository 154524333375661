import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { baseUrl } from "../../../BaseUrl";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import Heading from "../../Shared/Heading";
import * as html2pdf from "html2pdf.js";



const ViewFlatClientPrice = () => {
  const { clientId } = useParams();
  const [flatClientInfo, setFlatClientInfo] = useState([]);

  useEffect(() => {
    axios
      .get(
        baseUrl + `/customer/price/detail/${clientId}`,

        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setFlatClientInfo(res?.data?.detail);
      });
  }, [clientId]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const pdfDownload = () => {
    var element = document.getElementById("pdf-section");
    var opt = {
      margin: 0,
      filename: "myfile.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(element).set(opt).save();
    html2pdf(element, opt);
  };

  return (
    <div>
      <Heading>
        <Link to="/dashboard/clientpriceinfo">
          <i className="fa-solid fa-arrow-left"></i> View Flat Client Price
          Information
        </Link>
      </Heading>
      <div className="information_container">
        <div className="my-8">
          <CopyPrintPdfBtn
            componentRef={componentRef}
            handlePrint={handlePrint}
            handleDownloadPdf={pdfDownload}
          ></CopyPrintPdfBtn>
        </div>
        <div
          id={"pdf-section"}
          className="infomation_content print-section"
          ref={componentRef}
        >
          <h1 className="text-center text-2xl font-bold underline underline-offset-4 text-[#333547] pt-8">
            Client Price Information
          </h1>
          <div className="m-6">
            {flatClientInfo?.customers?.map((customer) => {
              return (
                <div className="mb-3">
                  <p className="mt-2">
                    <span className="underline underline-offset-2 mr-1 font-semibold">
                      Customer Name:
                    </span>
                    {customer?.name}
                  </p>
                  <h1>
                    <span className="underline underline-offset-2 mr-1 font-semibold">
                      Customer ID:
                    </span>
                    {customer?.id}
                  </h1>
                </div>
              );
            })}
            <h1 className="mt-5">
              <span className="underline underline-offset-2 mr-1 font-semibold">
                Media Name:
              </span>
              {flatClientInfo?.customers?.length > 0 &&
                flatClientInfo?.customers[0]?.sold_item?.media_name}
            </h1>
            <h1>
              <span className="underline underline-offset-2 mr-1 font-semibold">
                Media Phone Number:
              </span>

              {flatClientInfo?.customers?.length > 0 &&
                flatClientInfo?.customers[0]?.sold_item?.media_phone_number}
            </h1>
            <h1 className="mt-4">
              <span className="underline underline-offset-2 mr-1 font-semibold">
                File No:
              </span>
              {flatClientInfo?.file_no}
            </h1>
            {/* <h1>
              <span className="underline underline-offset-2 mr-1 font-semibold">
                Other File No:
              </span>
              NA
            </h1> */}
          </div>

          <h1 className="text-xl font-semibold underline underline-offset-2 m-6">
            Building Details
          </h1>
          <div className="grid lg:grid-cols-2 gap-2">
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Building Name :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={flatClientInfo?.project?.name}
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Building No :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={flatClientInfo?.project?.project_no}
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Building Location :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={flatClientInfo?.project?.location}
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Building Face Direction :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={flatClientInfo?.project?.face_direction}
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Road No :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={flatClientInfo?.project?.road_no}
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Flat Size :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={flatClientInfo?.size}
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Flat No :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={flatClientInfo?.flat_number}
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Flat Face Direction :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={flatClientInfo?.face_direction}
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Flat Flor No :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={flatClientInfo?.floor_no}
              />
            </div>
          </div>
          <h1 className="text-xl font-semibold underline underline-offset-4 m-6">
            Pricing Details (With start dates)
          </h1>
          <div className="grid lg:grid-cols-2">
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Total Price :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={
                  flatClientInfo?.price_information?.total_money
                    ? flatClientInfo?.price_information?.total_money + ` Tk`
                    : 0
                }
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Booking Money :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={
                  flatClientInfo?.price_information?.booking_money
                    ? flatClientInfo?.price_information?.booking_money + ` Tk`
                    : 0
                }
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Car Parking :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={
                  flatClientInfo?.price_information?.car_parking
                    ? flatClientInfo?.price_information?.car_parking + ` Tk`
                    : 0
                }
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Utility Charge :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={
                  flatClientInfo?.price_information?.utility_charge
                    ? flatClientInfo?.price_information?.utility_charge + ` Tk`
                    : 0
                }
              />
            </div>
           
          </div>
          <hr className="my-8 mx-4 h-1 bg-gray-200 dark:bg-gray-700"></hr>
          <div className="grid grid-cols-2 px-2 mx-4">
              <label>Total Additional Amount :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={
                  flatClientInfo?.price_information?.total_additional_amount
                    ? flatClientInfo?.price_information
                        ?.total_additional_amount + ` Tk`
                    : 0
                }
              />
            </div>
            {
              flatClientInfo?.price_information?.additional_amount?.map(list=>{
                return(<div key={list.id} className="grid grid-cols-2 px-2 mx-4">
              <label>{list?.amount_name} :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={list?.amount + ` Tk`}
              />
            </div>)
              })
            }
          <hr className="my-8 mx-4 h-1 bg-gray-200 dark:bg-gray-700"></hr>
          <div className="grid lg:grid-cols-2">
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Downpayment Amount :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={
                  flatClientInfo?.price_information?.total_downpayment_amount
                    ? flatClientInfo?.price_information
                        ?.total_downpayment_amount + ` Tk`
                    : 0
                }
              />
            </div>
            {flatClientInfo?.price_information?.down_payment.length > 0 &&
              flatClientInfo?.price_information?.down_payment?.map(
                (downPay, index) => {
                  return (
                    <div className="grid grid-cols-2 px-2 mx-4">
                      <label>Downpayment {index + 1} :</label>
                      <input
                        className="outline-none border-b-2 border-[#8C8C8C]"
                        type="text"
                        name=""
                        id=""
                        value={downPay.amount + ` Tk`}
                      />
                    </div>
                  );
                }
              )}
          </div>
          <hr className="my-8 mx-4 h-1 bg-gray-200 dark:bg-gray-700"></hr>
          <div className="grid lg:grid-cols-2">
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Installment Amount :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={
                  flatClientInfo?.price_information?.total_installment_amount
                    ? flatClientInfo?.price_information
                        ?.total_installment_amount + ` Tk`
                    : 0
                }
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>No Of Installment :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={
                  flatClientInfo?.price_information?.number_of_installment
                    ? flatClientInfo?.price_information?.number_of_installment
                    : 0
                }
              />
            </div>
            {flatClientInfo?.price_information?.installment &&
              flatClientInfo?.price_information?.installment?.length > 0 &&
              flatClientInfo?.price_information?.installment?.map(
                (installment, index) => {
                  return (
                    <div className="grid grid-cols-2 px-2 mx-4">
                      <label>Installment {index + 1} :</label>
                      <input
                        className="outline-none border-b-2 border-[#8C8C8C]"
                        type="text"
                        name=""
                        id=""
                        value={installment?.amount + ` TK`}
                      />
                    </div>
                  );
                }
              )}
          </div>

          <div className="flex justify-around my-10 py-10">
            <div className="mt-20">
              <hr className="w-full bg-slate-400 h-0.5" />
              <h1>Customer's Signature</h1>
            </div>
            <div className="mt-20">
              <hr className="w-full bg-slate-400 h-0.5" />
              <h1>Official Signature</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewFlatClientPrice;
