import React from "react";
import Heading from "../../Shared/Heading";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../../BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";

const AddLandInformation = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    axios
      .post(
        baseUrl + `/projects/land/store`,
        { ...data, type: 2 },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success(res?.data?.message);
          navigate("/dashboard/buildingandflatdetails");
        } else {
          if (Array.isArray(res?.data?.message)) {
            res?.data?.message?.map((data) => toast.error(data));
          } else {
            toast.error(res?.data?.message);
          }
        }
      });
  };
  return (
    <div>
      <Heading>
        <Link to="/dashboard/buildingandflatdetails">
          <i className="fa-solid fa-arrow-left"></i> Add Land Information
        </Link>
      </Heading>
      <div>
        <div className="p-5 bg-white shadow-2xl w-[64%] rounded-lg mx-auto my-8">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex">
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text text-[#717A82] text-md font-bold">
                    Land Name
                  </span>
                </label>
                <input
                  {...register("name")}
                  name="name"
                  type="text"
                  placeholder="name"
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
            </div>
            <div className="grid lg:grid-cols-4 gap-2">
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text text-[#717A82] text-md font-bold">
                    Road No.
                  </span>
                </label>
                <input
                  {...register("road_no")}
                  name="road_no"
                  type="text"
                  placeholder="road_no"
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text text-[#717A82] text-md font-bold">
                    Land No.
                  </span>
                </label>
                <input
                  {...register("project_no")}
                  name="project_no"
                  type="text"
                  placeholder="project_no"
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text text-[#717A82] text-md font-bold">
                    Land Face Direction
                  </span>
                </label>
                <select
                  {...register("face_direction")}
                  className="select select-bordered select-sm"
                >
                  <option selected value="">
                    Select
                  </option>
                  <option value="East">East</option>
                  <option value="West">West</option>
                  <option value="North">North</option>
                  <option value="South">South</option>
                  <option value="North-East">North-East</option>
                  <option value="South-East">South-East</option>
                  <option value="North-West">North-West</option>
                  <option value="South-West">South-West</option>
                </select>
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text text-[#717A82] text-md font-bold">
                    Land Location
                  </span>
                </label>
                <input
                  {...register("location")}
                  name="location"
                  type="text"
                  placeholder="location"
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text font-bold text-[#717A82]">
                    Land Size
                  </span>
                </label>
                <input
                  {...register("land_size")}
                  type="number"
                  placeholder="land_size"
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text font-bold text-[#717A82]">
                    Total No. of Plot
                  </span>
                </label>
                <input
                  {...register("number_of_flat_or_plot")}
                  type="text"
                  placeholder="total number of plot"
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text text-[#717A82] text-md font-bold">
                    Work Start Date
                  </span>
                </label>
                <input
                  {...register("work_start_date")}
                  type="date"
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text text-[#717A82] text-md font-bold">
                    Work Complete Date
                  </span>
                </label>
                <input
                  {...register("work_end_date")}
                  type="date"
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text text-[#717A82] text-md font-bold">
                    Work Complete Extended Date
                  </span>
                </label>
                <input
                  {...register("work_complete_date")}
                  type="date"
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
            </div>

            <div className="flex flex-wrap justify-start mt-8">
              <Link to="/dashboard/buildingandflatdetails">
                <button
                  type="submit"
                  className="uppercase  text-white text-[14px] font-semibold bg-[#E30B0B] rounded-md py-2.5 px-16 m-2"
                >
                  Cancel
                </button>
              </Link>

              <button
                type="submit"
                className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 mb-10 m-2"
              >
                Add Land
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddLandInformation;
