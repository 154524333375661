import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { baseUrl } from "../../../BaseUrl";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import Heading from "../../Shared/Heading";
import Loader from "../../Shared/Loader";

const ClientDueAmountHome = () => {
  const [clientAmount, setClientAmount] = useState(null);
  useEffect(() => {
    axios
      .get(
        baseUrl + "/customer/due/amount/list",
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setClientAmount(res?.data?.data?.due);
      });
  }, []);

  //--------print functionality start----------
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //---------print functionality end-----------

  // ---------------search table data start------------------
  const handleSearch = (e) => {
    // console.log(e.target.value);
    // Declare variables
    let status = 0;
    let filterBy = e.target.value.toUpperCase();
    let table = document.getElementById("dataTable");
    let tr = table.querySelectorAll(".searchData");
    let i = 0;
    let j = 0;
    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      let tempTd = tr[i].getElementsByTagName("td");
      for (j = 0; j < tempTd.length; j++) {
        let td = tempTd[j];
        let txtValue;
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filterBy) > -1) {
            status = 1;
          }
          if (!status) {
            status = 0;
          }
        }
      }
      if (status) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  };
  // ------------------search table data end-----------------

  return (
    <div>
      <Heading>Client Due Amount Details</Heading>
      <div className="p-5 whiteBackground">
        <div className="flex flex-wrap justify-between my-5">
          <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
          <input
            onChange={handleSearch}
            id="searchInput"
            type="text"
            placeholder="Search..."
            className="input input-bordered input-sm w-full max-w-xs mb-2"
          />
        </div>

        {clientAmount == null ? (
          <Loader></Loader>
        ) : (
          <div className="overflow-x-auto max-h-[60vh]">
            <table
              id="dataTable"
              className="table table-zebra w-full"
              ref={componentRef}
            >
              <thead className="text-[#00CA08]">
                <tr className="sticky top-0">
                  <th>Customer's Id</th>
                  <th>
                    Customer's <br /> Name
                  </th>
                  <th>File No.</th>
                  <th>
                    Total Flat/Plot <br /> Price <br /> Payment
                  </th>
                  <th className="text-[red]">
                    Total Payment <br /> Due Amount
                  </th>
                  <th className="text-[red]">
                    Additional <br /> Due Amount
                  </th>
                  <th className="text-[red]">
                    As of today <br /> Total Payment <br /> Due Amount
                  </th>
                  <th className="text-[red]">
                    As of today <br />
                    Downpayment
                    <br /> Due Amount
                  </th>
                  <th className="text-[red]">
                    As of today <br />
                    Installment
                    <br /> Due Amount
                  </th>
                  <th className="text-[red]">
                    Bokking Money <br />
                    Payment Due
                    <br /> Amount
                  </th>
                  <th className="text-[red]">
                    Utility Charge <br />
                    Payment Due
                    <br /> Amount
                  </th>
                  <th className="text-[red]">
                    Car Parking <br /> Payment Due
                    <br /> Amount
                  </th>
                  <th className="hide-when-print">Send Message</th>
                </tr>
              </thead>
              <tbody>
                {clientAmount?.length > 0 ? (
                  clientAmount?.map((single, index) => {
                    return (
                      <tr className="searchData" key={index}>
                        <td>
                          {single?.flat_or_plot?.customers?.map(
                            (customer, index) => (
                              <p key={index}>
                                {customer?.id ? customer?.id : "--"}
                              </p>
                            )
                          )}
                        </td>
                        <td>
                          {single?.flat_or_plot?.customers?.map(
                            (customer, index) => (
                              <p key={index}>
                                {customer?.name ? customer?.name : "--"}
                              </p>
                            )
                          )}
                        </td>
                        <td>
                          {single?.flat_or_plot?.file_no
                            ? single?.flat_or_plot?.file_no
                            : "--"}
                        </td>
                        <td>
                          {single?.total_money
                            ? `${single?.total_money} Tk`
                            : "--"}
                        </td>
                        <td>
                          {single?.total_due_payment
                            ? `${single?.total_due_payment} Tk`
                            : "--"}{" "}
                        </td>
                        <td>
                          {single?.additional_amount_due
                            ? `${single?.additional_amount_due} Tk`
                            : "--"}{" "}
                        </td>
                        <td>
                          {(single?.total_downPayment_money != null
                            ? single?.total_downPayment_money
                            : 0) -
                            (single?.paid_downPayment_money != null
                              ? single?.paid_downPayment_money
                              : 0) +
                            parseInt(
                              single?.total_installment_money
                                ? single?.total_installment_money
                                : 0
                            ) -
                            (single?.paid_installment_money != null
                              ? parseInt(single?.paid_installment_money)
                              : 0) +
                            (single?.booking_money_due_amount != null
                              ? parseInt(single?.booking_money_due_amount)
                              : 0) +
                            (single?.utility_charge_due_amount != null
                              ? parseInt(single?.utility_charge_due_amount)
                              : 0) +
                            (single?.additional_amount_due != null
                              ? parseInt(single?.additional_amount_due)
                              : 0) +
                            (single?.car_parking_due_amount != null
                              ? parseInt(single?.car_parking_due_amount)
                              : 0) +
                            `Tk`}
                        </td>
                        <td>
                          {single?.total_downPayment_money
                            ? `${
                                single?.total_downPayment_money != null
                                  ? single?.total_downPayment_money -
                                    single?.paid_downPayment_money
                                  : 0
                              } Tk`
                            : "--"}{" "}
                        </td>
                        <td>
                          {single?.total_installment_money
                            ? `${
                                parseInt(single?.total_installment_money) -
                                (single?.paid_installment_money != null
                                  ? parseInt(single?.paid_installment_money)
                                  : 0)
                              } Tk`
                            : "--"}
                        </td>
                        <td>
                          {single?.booking_money_due_amount
                            ? `${single?.booking_money_due_amount} Tk`
                            : "--"}{" "}
                        </td>
                        <td>
                          {single?.utility_charge_due_amount
                            ? `${single?.utility_charge_due_amount} Tk`
                            : "--"}{" "}
                        </td>
                        <td>
                          {single?.car_parking_due_amount
                            ? `${single?.car_parking_due_amount} Tk`
                            : "--"}
                        </td>
                        <td className="text-center hide-when-print">
                          <i className="fa-brands fa-facebook-messenger m-2 text-xl"></i>
                          <i className="fa-brands fa-whatsapp m-2 text-xl"></i>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td>
                      <h2
                        className="text-center py-5 col-span-10 font-bold"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        No Details Found
                      </h2>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientDueAmountHome;
