import React, { useEffect, useRef, useState } from "react";
import Heading from "../../Shared/Heading";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { useReactToPrint } from "react-to-print";

let allDatas = [];
const CanceledCustomerList = () => {
  const [canceledCustomerInfo, setCanceledCustomerInfo] = useState([]);
  useEffect(() => {
    axios
      .get(baseUrl + `/reports/cancelCustomerList/viewCanceledCustomerInfo`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        allDatas = res.data.data;
        setCanceledCustomerInfo(res.data.data);
      });
  }, []);

  //----------filter functionality start ---------------
  const handleFilterData = (e) => {
    console.log(e);
    if (e === "all") {
      setCanceledCustomerInfo(allDatas);
    } else if (e === "Canceled Application Date") {
      setCanceledCustomerInfo(
        allDatas?.filter((list) => list?.canceled_application_date)
      );
    } else if (e === "Total Amount of Canceled File") {
      setCanceledCustomerInfo(
        allDatas?.filter((list) => list?.total_amount > 0)
      );
    } else if (e === "Original Amount of Canceled File") {
      setCanceledCustomerInfo(
        allDatas?.filter((list) => list?.original_amount > 0)
      );
    } else if (e === "Extra Amount of Canceled File") {
      setCanceledCustomerInfo(
        allDatas?.filter((list) => list?.extra_amount > 0)
      );
    } else if (e === "Payment complete of Canceled File") {
      setCanceledCustomerInfo(
        allDatas?.filter((list) => list?.total_canceled_amount_paid > 0)
      );
    } else if (e === "Due payment of Canceled File") {
      setCanceledCustomerInfo(
        allDatas?.filter(
          (list) =>
            parseInt(list?.total_amount) -
              parseInt(list?.total_canceled_amount_paid) >
            0
        )
      );
    }
  };
  //----------filter functionality End ---------------

  //----------Print a div or section functionality start-----------
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //----------Print a div or section functionality end-------------

        // ---------------search table data start------------------
        const handleSearch = (e) => {
          // console.log(e.target.value);
          // Declare variables
          let status = 0;
          let filterBy = e.target.value.toUpperCase();
          let table = document.getElementById("dataTable");
          let tr = table.querySelectorAll(".searchData");
          let i = 0;
          let j = 0;
          // Loop through all table rows, and hide those who don't match the search query
          for (i = 0; i < tr.length; i++) {
            let tempTd = tr[i].getElementsByTagName("td");
            for (j = 0; j < tempTd.length; j++) {
              let td = tempTd[j];
              let txtValue;
              if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filterBy) > -1) {
                  status = 1;
                }
                if (!status) {
                  status = 0;
                }
              }
            }
            if (status) {
              tr[i].style.display = "";
            } else {
              tr[i].style.display = "none";
            }
          }
        };
        // ------------------search table data end-----------------
  return (
    <div>
      <div>
        <Heading>Canceled Customer List</Heading>
        <div className="p-5 whiteBackground">
          <div className="flex justify-start align-middle flex-wrap mb-4">
            <div className="w-[200px] h-[140px] bg-[#e8e0e0] rounded-md text-center m-1">
              <div className="bg-[gray] text-2xl font-bold py-2 text-white">
                {canceledCustomerInfo?.length}
              </div>
              <div className="p-2">
                <small>Total number of canceled customer</small>
              </div>
            </div>
            <div className="w-[200px] h-[140px] bg-[#e8e0e0] rounded-md text-center m-1">
              <div className="bg-[gray] text-2xl font-bold py-2 text-white">
                {canceledCustomerInfo.reduce((accumulator, item) => {
                  return accumulator + parseInt(item.total_amount);
                }, 0)}{" "}
                tk
              </div>
              <div className="p-2">
                <small>Total amount of canceled customer</small>{" "}
              </div>
            </div>
            <div className="w-[200px] h-[140px] bg-[#e8e0e0] rounded-md text-center m-1">
              <div className="bg-[gray] text-2xl font-bold py-2 text-white">
                {
                  canceledCustomerInfo.filter(
                    (item) =>
                      parseInt(item.total_amount) ===
                      parseInt(item.total_canceled_amount_paid)
                  ).length
                }
              </div>
              <div className="p-2">
                <small>
                  Total number of canceled customer payment complete
                </small>
              </div>
            </div>
            <div className="w-[200px] h-[140px] bg-[#e8e0e0] rounded-md text-center m-1">
              <div className="bg-[gray] text-2xl font-bold py-2 text-white">
                {canceledCustomerInfo
                  .filter(
                    (item) =>
                      parseInt(item.total_amount) ===
                      parseInt(item.total_canceled_amount_paid)
                  )
                  .reduce((accumulator, item) => {
                    return (
                      accumulator + parseInt(item.total_canceled_amount_paid)
                    );
                  }, 0)}{" "}
                tk
              </div>
              <div className="p-2">
                <small>
                  Total amount of canceled customer payment complete
                </small>
              </div>
            </div>
            <div className="w-[200px] h-[140px] bg-[#e8e0e0] rounded-md text-center m-1">
              <div className="bg-[gray] text-2xl font-bold py-2 text-white">
                {
                  canceledCustomerInfo.filter(
                    (item) =>
                      parseInt(item.total_amount) >
                        parseInt(item.total_canceled_amount_paid) &&
                      parseInt(item.total_canceled_amount_paid) > 0
                  ).length
                }
              </div>
              <div className="p-2">
                <small>
                  Total number of canceled customer payment continue
                </small>
              </div>
            </div>
            <div className="w-[200px] h-[140px] bg-[#e8e0e0] rounded-md text-center m-1">
              <div className="bg-[gray] text-2xl font-bold py-2 text-white">
                {canceledCustomerInfo
                  .filter(
                    (item) =>
                      parseInt(item.total_amount) >
                        parseInt(item.total_canceled_amount_paid) &&
                      parseInt(item.total_canceled_amount_paid) > 0
                  )
                  .reduce((accumulator, item) => {
                    return (
                      accumulator + parseInt(item.total_canceled_amount_paid)
                    );
                  }, 0)}{" "}
                tk
              </div>
              <div className="p-2">
                <small>Running canceled customer payment complete amount</small>
              </div>
            </div>
            <div className="w-[200px] h-[140px] bg-[#e8e0e0] rounded-md text-center m-1">
              <div className="bg-[gray] text-2xl font-bold py-2 text-white">
                {canceledCustomerInfo
                  .filter(
                    (item) =>
                      parseInt(item.total_amount) >
                        parseInt(item.total_canceled_amount_paid) &&
                      parseInt(item.total_canceled_amount_paid) > 0
                  )
                  .reduce((accumulator, item) => {
                    return accumulator + parseInt(item.total_amount);
                  }, 0) -
                  canceledCustomerInfo
                    .filter(
                      (item) =>
                        parseInt(item.total_amount) >
                          parseInt(item.total_canceled_amount_paid) &&
                        parseInt(item.total_canceled_amount_paid) > 0
                    )
                    .reduce((accumulator, item) => {
                      return (
                        accumulator + parseInt(item.total_canceled_amount_paid)
                      );
                    }, 0)}{" "}
                tk
              </div>
              <div className="p-2">
                <small>Running canceled customer due payment amount</small>
              </div>
            </div>
          </div>

          <div className="my-1 flex justify-end align-middle">
            <div className="mx-1">
              <div>
                <button className="uppercase text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-1.5 px-3">
                  <Link to="/dashboard/addcanceleapplication">
                    Canceled Application Form
                  </Link>
                </button>
              </div>
            </div>
            <div className="mx-1">
              <div>
                <button className="uppercase text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-1.5 px-3">
                  Payment Slip Folder
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-between items-center my-5">
            <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
            <div className="my-2">
              <div className="form-control">
                <div className="flex flex-wrap items-center">
                  <h1 className="m-3 text-[#717A82] font-bold">
                    Search by date
                  </h1>
                  <input
                    name="complete_date"
                    type="date"
                    className="input input-bordered input-sm max-w-xs"
                  />
                  <h1 className="m-3 text-[#717A82] font-bold">Search for</h1>
                  <select
                    onChange={(e) => handleFilterData(e.target.value)}
                    className="select select-sm select-bordered  my-2 rounded-[5px] bg-[#ECECEC]"
                  >
                    <option value={"all"}>All</option>
                    <option value={"Canceled Application Date"}>
                      Canceled Application Date
                    </option>
                    <option value={"Total Amount of Canceled File"}>
                      Total Amount of Canceled File
                    </option>
                    <option value={"Original Amount of Canceled File"}>
                      Original Amount of Canceled File
                    </option>
                    <option value={"Extra Amount of Canceled File"}>
                      Extra Amount of Canceled File
                    </option>
                    <option value={"Payment complete of Canceled File"}>
                      Payment complete of Canceled File
                    </option>
                    <option value={"Due payment of Canceled File"}>
                      Due payment of Canceled File
                    </option>
                    {/* <option value={"Payment Status"}>Payment Status</option> */}
                  </select>
                  <input
                  onChange={handleSearch}
                    id="searchInput"
                    type="text"
                    placeholder="Search..."
                    className="input input-bordered input-sm max-w-xs ml-1"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pb-10">
            {canceledCustomerInfo?.length > 0 ? (
              <div ref={componentRef} className="overflow-x-auto max-h-[60vh]">
                <table id="dataTable" className="table table-zebra w-full max-h-[60vh]">
                  <thead className="text-[#00CA08]">
                    <tr className="sticky top-0">
                      <th>
                        Customer's <br /> Id
                      </th>
                      <th>
                        Customer's <br /> Name
                      </th>
                      <th>File No.</th>
                      <th>
                        Building/ <br /> Land <br /> No.
                      </th>
                      <th>
                        Flat/ <br /> Plot <br /> Size
                      </th>
                      <th>
                        Canceled <br />
                        Application <br />
                        Date
                      </th>
                      <th>
                        Total <br /> Amount <br /> of
                        <br />
                        Canceled <br />
                        File
                      </th>
                      <th>
                        Original <br /> Amount <br />
                        of <br /> Canceled <br />
                        File
                      </th>
                      <th>
                        Extra <br /> Amount <br />
                        of <br /> Canceled <br />
                        File
                      </th>
                      <th>
                        Payment <br /> complete <br />
                        of <br /> Canceled <br />
                        File
                      </th>
                      <th>
                        Due <br /> payment <br />
                        of <br /> Canceled <br />
                        File
                      </th>
                      <th>
                        Payment <br />
                        Status
                      </th>
                      <th>Action</th>
                      <th>
                        Add <br /> Payment <br /> Slip
                      </th>
                      <th>
                        View <br /> Statement
                      </th>
                      <th>
                        View <br /> All
                        <br /> Payment <br />
                        Slip
                      </th>
                      <th>Documents</th>
                    </tr>
                  </thead>
                  <tbody>
                    {canceledCustomerInfo?.map((list) => {
                      return (
                        <tr className="searchData" key={list.id}>
                          <td>
                            {list?.flat_or_plot?.customers?.map(
                              (singleCustomer) => (
                                <h1 key={singleCustomer.id}>
                                  {singleCustomer.id}
                                </h1>
                              )
                            )}
                          </td>
                          <td>
                            {list?.flat_or_plot?.customers?.map(
                              (singleCustomer) => (
                                <h1 key={singleCustomer.id}>
                                  {singleCustomer.name}
                                </h1>
                              )
                            )}
                          </td>
                          <td>{list?.flat_or_plot?.file_no}</td>
                          <td>
                            {list?.flat_or_plot?.project_info?.project_no}
                          </td>
                          <td>{list?.flat_or_plot?.size}</td>
                          <td>{list?.canceled_application_date}</td>
                          <td>{list?.total_amount}</td>
                          <td>{list?.original_amount}</td>
                          <td>{list?.extra_amount}</td>
                          <td>{list?.total_canceled_amount_paid}</td>
                          <td>
                            {parseInt(list?.total_amount) -
                              parseInt(list?.total_canceled_amount_paid)}
                          </td>
                          <td>
                            <div
                              className={
                                (parseInt(list.total_canceled_amount_paid) ===
                                  0 &&
                                  "bg-[red] p-2 rounded-lg w-1/2 mx-auto") ||
                                (parseInt(list.total_amount) >
                                  parseInt(list.total_canceled_amount_paid) >
                                  0 &&
                                  "bg-[yellow] p-2 rounded-lg w-1/2 mx-auto") ||
                                (parseInt(list.total_amount) ===
                                  parseInt(list.total_canceled_amount_paid) &&
                                  "bg-[green] p-2 rounded-lg w-1/2 mx-auto")
                              }
                            ></div>
                          </td>
                          <td className="text-center hide-when-print">
                            <button>
                              <Link
                                to={`/dashboard/updatecanceleapplication`}
                                state={{ data: list }}
                              >
                                <i className="fa-solid fa-pen-to-square m-2 text-xl"></i>
                              </Link>
                            </button>
                            <button>
                              <Link
                                to={`/dashboard/viewcanceledfileinformation`}
                                state={{ data: list }}
                              >
                                <i className="fa-solid fa-eye m-2 text-xl"></i>
                              </Link>
                            </button>
                          </td>
                          <td className="text-center hide-when-print">
                            <button>
                              <Link
                                to={`/dashboard/addpaymentslip`}
                                state={{ data: list }}
                              >
                                <i className="fa-solid fa-square-plus m-2 text-xl"></i>
                              </Link>
                            </button>
                          </td>
                          <td className="text-center hide-when-print">
                            <button>
                              <Link
                                to={`/dashboard/canceledpaymentstatement`}
                                state={{ data: list }}
                              >
                                <i className="fa-solid fa-eye m-2 text-xl"></i>
                              </Link>
                            </button>
                          </td>
                          <td className="text-center hide-when-print">
                            <button>
                              <Link
                                to={`/dashboard/viewallpaymentslip`}
                                state={{ data: list }}
                              >
                                <i className="fa-solid fa-eye m-2 text-xl"></i>
                              </Link>
                            </button>
                          </td>
                          <td className="text-center hide-when-print">
                            <button>
                              <Link
                                to={`/dashboard/canceledcustomerdocuments`}
                                state={{ data: list }}
                              >
                                <i className="fa-solid fa-folder m-2 text-xl"></i>
                              </Link>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <h1 className="text-center my-10 font-bold">No Data Found</h1>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanceledCustomerList;
