import { Link, useNavigate } from "react-router-dom";
import Heading from "../../Shared/Heading";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import "./AddClient.css";
import { toast } from "react-toastify";

const AddClient = () => {
  const [totalCustomer, setTotalCustomer] = useState(1);
  const [clientImage, setClientImage] = useState(null);
  const [nomineeImage, setNomineeImage] = useState(null);
  const [customerForm, setCustomerForm] = useState([{ customer: "" }]);

  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [buildingId, setBuildingId] = useState("");
  const [landId, setLandId] = useState("");
  const [flatId, setFlatId] = useState("");
  const [plotId, setPlotId] = useState("");
  const [type, setType] = useState("");

  const [buildingList, setBuildingList] = useState([]);
  const [flatList, setFlatList] = useState([]);
  const [landList, setLandList] = useState([]);
  const [plotList, setPlotList] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    axios
      .post(
        baseUrl + `/customer/building_land/all`,
        { type: 1 },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setBuildingList(res?.data?.data);
      });
    axios
      .post(
        baseUrl + `/customer/building_land/all`,
        { type: 2 },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setLandList(res?.data?.data);
      });
  }, []);

  const handleBuildingChange = (e) => {
    e.preventDefault();
    const id = e.target.value;
    setBuildingId(e.target.value);
    setLandId("");
    setPlotId("");
    axios
      .get(
        baseUrl + `/customer/building/flat/${id}`,

        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setFlatList(res?.data?.data?.flat_or_plot);
      });
  };
  const handleLandChange = (e) => {
    e.preventDefault();
    const id = e.target.value;
    setLandId(e.target.value);
    setBuildingId("");
    setFlatId("");
    // setType(2);
    axios
      .get(
        baseUrl + `/customer/land/plot/${id}`,

        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        // console.log(res?.data?.data?.flat_or_plot);
        setPlotList(res?.data?.data?.flat_or_plot);
      });
  };

  const handleCustomerFormAdd = () => {
    setIndex(index + 1);
    // console.log(customerForm);
    setCustomerForm([...customerForm, { customer: "" }]);
  };

  const handleCustomerFormRemove = (index) => {
    // console.log(index);
    let list = [...customerForm];
    list = list.splice(0, index);
    setIndex(index - 1);
    setCustomerForm(list);
  };

  const onSubmit = (data) => {
    // console.log(data);

    setButtonDisabled(true);
    let flat_or_plot_id = "";
    let propertyType = "";
    if (plotId !== "") {
      setType(2);
      flat_or_plot_id = plotId;
      propertyType = 2;
    } else {
      setType(1);
      flat_or_plot_id = flatId;
      propertyType = 1;
    }
    const customer = data.customer.slice(0, customerForm.length);
    const newData = {
      ...data,
      flat_or_plot_registration_status: "Pending",
      customer,
      building_id: buildingId,
      land_id: landId,
      flat_or_plot_id: flat_or_plot_id,
      type: propertyType,
    };
    // console.log(newData);

    const formData = new FormData();
    Object.entries(newData).forEach(([key, value]) => {
      if (typeof value == "object") {
        for (let i = 0; i < value.length; i++) {
          // console.log(value[i],'this is customer object');
          let names = Object.keys(value[i]);
          names.forEach((name) => {
            if (value[i][name] instanceof FileList) {
              // console.log(value[i][name],'found a file');
              formData.append(
                `customer[${i}][${name}]`,
                value[i][name].item(0)
              );
            } else {
              formData.append(`customer[${i}][${name}]`, value[i][name]);
              // console.log(name,value[i][name],'this is a element of customer object');
            }
          });
        }
      } else {
        formData.append(key, value);
      }
    });
    // console.log(formData);
    axios
      .post(baseUrl + `/customer/information/store`, formData, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          // "content-type": "text/json",
        },
      })
      .then((res) => {
        // console.log(res?.data);
        if (res?.data?.status == "failed") {
          res?.data?.message.forEach((m) => {
            toast.error(m);
          });
        } else {
          toast.success(res?.data?.message);
          navigate("/dashboard/clientinfo");
        }
        setButtonDisabled(false);
      });
  };

  const buildingInfoBtnHandler = () => {
    document.getElementById("building_select").style.display = "block";
    document.getElementById("land_select").style.display = "none";
    document.getElementById("land_btn").style.background = "white";
    document.getElementById("land_btn").style.color = "#1F497D";
    document.getElementById("building_btn").style.background = "#1F497D";
    document.getElementById("building_btn").style.color = "white";
  };
  const landInfoBtnHandler = () => {
    document.getElementById("building_select").style.display = "none";
    document.getElementById("land_select").style.display = "block";
    document.getElementById("building_btn").style.background = "white";
    document.getElementById("building_btn").style.color = "#1F497D";
    document.getElementById("land_btn").style.background = "#1F497D";
    document.getElementById("land_btn").style.color = "white";
  };
  return (
    <>
      <Heading>
        <Link to="/dashboard/clientinfo">
          <i className="fa-solid fa-arrow-left"></i> Add Client
        </Link>
      </Heading>
      <div className="mx-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          {customerForm.map((singleCustomer, index) => (
            <div key={index} className="mt-5">
              <div className="grid lg:grid-cols-2 gap-10 mt-5">
                <div>
                  <fieldset className="border-2 rounded-md border-[#1F487C]">
                    <legend className="mx-4">
                      <p className="bg-[#1F497D] text-[white] py-1 px-4 rounded-md">
                        {" "}
                        Customer Information {index + 1}
                      </p>
                    </legend>

                    <div>
                      <div className="grid lg:grid-cols-2 ">
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Customer's Name <span>*</span>
                              </span>
                            </label>
                            <input
                              {...register(`customer[${index}].customer_name`)}
                              name={`customer[${index}].customer_name`}
                              type="text"
                              required
                              placeholder="Customer's Name"
                              className="input input-bordered input-sm w-full max-w-lg"
                            />
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Spouse Name
                              </span>
                            </label>
                            <input
                              {...register(
                                `customer[${index}].customer_spouse_name`
                              )}
                              required
                              name={`customer[${index}].customer_spouse_name`}
                              type="text"
                              placeholder="Spouse Name"
                              className="input input-bordered input-sm w-full max-w-lg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid lg:grid-cols-2 ">
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Father Name <span>*</span>
                              </span>
                            </label>
                            <input
                              required
                              {...register(
                                `customer[${index}].customer_father_name`
                              )}
                              name={`customer[${index}].customer_father_name`}
                              type="text"
                              placeholder="Father Name"
                              className="input input-bordered input-sm w-full max-w-lg"
                            />
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Mother Name <span>*</span>
                              </span>
                            </label>
                            <input
                              required
                              {...register(
                                `customer[${index}].customer_mother_name`
                              )}
                              name={`customer[${index}].customer_mother_name`}
                              type="text"
                              placeholder="Mother Name"
                              className="input input-bordered input-sm w-full max-w-lg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid lg:grid-cols-2 ">
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Email Address <span>*</span>
                              </span>
                            </label>
                            <input
                              required
                              {...register(`customer[${index}].customer_email`)}
                              name={`customer[${index}].customer_email`}
                              type="email"
                              placeholder="Email Address"
                              className="input input-bordered input-sm w-full max-w-lg"
                            />
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Phone Number <span>*</span>
                              </span>
                            </label>
                            <input
                              required
                              {...register(
                                `customer[${index}].customer_phone_number`
                              )}
                              name={`customer[${index}].customer_phone_number`}
                              type="text"
                              placeholder="Phone Number"
                              className="input input-bordered input-sm w-full max-w-lg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid lg:grid-cols-2 ">
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                National ID Number <span>*</span>
                              </span>
                            </label>
                            <input
                              required
                              {...register(
                                `customer[${index}].customer_nid_number`
                              )}
                              name={`customer[${index}].customer_nid_number`}
                              type="text"
                              placeholder="National ID Number"
                              className="input input-bordered input-sm w-full max-w-lg"
                            />
                          </div>
                        </div>
                        <div className="p-2">
                          <div>
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Date of Birth <span>*</span>
                                </span>
                              </label>
                              <input
                                required
                                {...register(
                                  `customer[${index}].customer_date_of_birth`
                                )}
                                name={`customer[${index}].customer_date_of_birth`}
                                type="date"
                                className="input input-bordered input-sm w-full max-w-lg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid lg:grid-cols-2 ">
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Other File No.
                              </span>
                            </label>
                            <input
                              required
                              {...register(
                                `customer[${index}].customer_other_file_no`
                              )}
                              name={`customer[${index}].customer_other_file_no`}
                              type="text"
                              placeholder="Other File No"
                              className="input input-bordered input-sm w-full max-w-lg"
                            />
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Profession *
                              </span>
                            </label>
                            <input
                              required
                              {...register(
                                `customer[${index}].customer_profession`
                              )}
                              name={`customer[${index}].customer_profession`}
                              type="text"
                              placeholder="Profession"
                              className="input input-bordered input-sm w-full max-w-lg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid lg:grid-cols-2 ">
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Designation (Optional)
                              </span>
                            </label>
                            <input
                              {...register(
                                `customer[${index}].customer_designation`
                              )}
                              name={`customer[${index}].customer_designation`}
                              type="text"
                              placeholder="Designation"
                              className="input input-bordered input-sm w-full max-w-lg"
                            />
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Country *
                              </span>
                            </label>
                            <select
                              defaultValue={null}
                              required
                              {...register(
                                `customer[${index}].customer_country`
                              )}
                              name={`customer[${index}].customer_country`}
                              className="select select-bordered select-sm w-full max-w-lg"
                            >
                              <option disabled selected value="">
                                Select Country
                              </option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="India">India</option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Nepal">Nepal</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="grid lg:grid-cols-2 ">
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Mailling Address
                              </span>
                            </label>
                            <textarea
                              required
                              {...register(
                                `customer[${index}].customer_mailing_address`
                              )}
                              name={`customer[${index}].customer_mailing_address`}
                              className="textarea textarea-bordered"
                              placeholder="Mailling Address"
                            ></textarea>
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Permanent Address <span>*</span>
                              </span>
                            </label>
                            <textarea
                              required
                              {...register(
                                `customer[${index}].customer_permanent_address`
                              )}
                              name={`customer[${index}].customer_permanent_address`}
                              className="textarea textarea-bordered"
                              placeholder="Permanent Address"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="grid lg:grid-cols-2 ">
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Office Address(Optional)
                              </span>
                            </label>
                            <textarea
                              {...register(
                                `customer[${index}].customer_office_address`
                              )}
                              name={`customer[${index}].customer_office_address`}
                              className="textarea textarea-bordered"
                              placeholder="Mailling Address"
                            ></textarea>
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            {/* <div className="m-3">
                              {clientImage && (
                                <img
                                  alt=""
                                  srcSet=""
                                  width={"120px"}
                                  height={"150px"}
                                  src={URL.createObjectURL(clientImage)}
                                />
                              )}
                            </div> */}
                            <div>
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Client Image (PP size 450 X 570){" "}
                                  <span>*</span>
                                </span>
                              </label>
                              <input
                                required
                                {...register(
                                  `customer[${index}].customer_image`
                                )}
                                name={`customer[${index}].customer_image`}
                                className="block w-full text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                onChange={(event) => {
                                  setClientImage(event.target.files[0]);
                                }}
                                type="file"
                                multiple="multiple"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div>
                  <fieldset className="border-2 rounded-md border-[#1F487C]">
                    <legend className="mx-4">
                      <p className="bg-[#1F497D] text-[white] py-1 px-4 rounded-md">
                        Nominee Information {index + 1}
                      </p>
                    </legend>

                    <div>
                      <div className="grid lg:grid-cols-2 ">
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Nominee Name <span>*</span>
                              </span>
                            </label>
                            <input
                              required
                              {...register(`customer[${index}].nominee_name`)}
                              name={`customer[${index}].nominee_name`}
                              type="text"
                              placeholder="Customer's Name"
                              className="input input-bordered input-sm w-full max-w-lg"
                            />
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Relation With Nominee <span>*</span>
                              </span>
                            </label>
                            <input
                              required
                              {...register(
                                `customer[${index}].relation_with_nominee`
                              )}
                              name={`customer[${index}].relation_with_nominee`}
                              type="text"
                              placeholder="Customer's Name"
                              className="input input-bordered input-sm w-full max-w-lg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid lg:grid-cols-2 ">
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Nominee Contact Number <span>*</span>
                              </span>
                            </label>
                            <input
                              required
                              {...register(
                                `customer[${index}].nominee_contact_number`
                              )}
                              name={`customer[${index}].nominee_contact_number`}
                              type="text"
                              placeholder="Nominee Contact Number"
                              className="input input-bordered input-sm w-full max-w-lg"
                            />
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Nominee Gets <span>*</span>
                              </span>
                            </label>
                            <input
                              required
                              {...register(`customer[${index}].nominee_gets`)}
                              name={`customer[${index}].nominee_gets`}
                              type="text"
                              placeholder="Nominee Gets"
                              className="input input-bordered input-sm w-full max-w-lg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid lg:grid-cols-2 ">
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            <label className="label">
                              <span className="label-text font-bold text-[#887d7d]">
                                Nominee Address
                              </span>
                            </label>
                            <textarea
                              required
                              {...register(
                                `customer[${index}].nominee_address`
                              )}
                              name={`customer[${index}].nominee_address`}
                              className="textarea textarea-bordered"
                              placeholder="Nominee Address"
                            ></textarea>
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="form-control w-full max-w-lg">
                            {/* <div className="m-3">
                              {nomineeImage && (
                                <img
                                  alt=""
                                  srcSet=""
                                  width={"120px"}
                                  height={"150px"}
                                  src={URL.createObjectURL(nomineeImage)}
                                />
                              )}
                            </div> */}
                            <div>
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Nominee Image (PP size 450 X 570){" "}
                                  <span>*</span>
                                </span>
                              </label>
                              <input
                                required
                                {...register(
                                  `customer[${index}].nominee_image`
                                )}
                                name={`customer[${index}].nominee_image`}
                                className="block w-full text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                onChange={(event) => {
                                  setNomineeImage(event.target.files[0]);
                                }}
                                type="file"
                                multiple="multiple"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          ))}

          {customerForm.length < 2 && (
            <div className="my-4">
              <h1
                onClick={handleCustomerFormAdd}
                className="uppercase w-full text-white text-center cursor-pointer text-[18px]  bg-[#16A085] rounded-md py-2.5 font-bold "
              >
                Add Another Customer
              </h1>
            </div>
          )}
          {customerForm.length >= 2 && (
            <div className="grid grid-cols-2 gap-10">
              <h1
                onClick={handleCustomerFormAdd}
                className="uppercase w-full text-white text-center cursor-pointer text-[18px]  bg-[#16A085] rounded-md py-2.5 my-5 font-bold"
              >
                Add Another Customer
              </h1>
              <h1
                onClick={() => handleCustomerFormRemove(index)}
                className="uppercase w-full text-white text-center cursor-pointer text-[18px] font-bold bg-[#329e1d] rounded-md py-2.5 my-5"
              >
                Delete Customer Field
              </h1>
            </div>
          )}

          <div className="grid lg:grid-cols-2 gap-10 mt-10">
            <div>
              <fieldset className="border-2 rounded-md border-[#1F487C]">
                <legend className="mx-4">
                  <p className="bg-[#1F497D] text-[white] py-1 px-4 rounded-md">
                    {" "}
                    Media Information
                  </p>
                </legend>

                <div className="grid lg:grid-cols-3 m-4">
                  <div className="p-2">
                    <div className="form-control w-full max-w-lg">
                      <label className="label">
                        <span className="label-text font-bold text-[#887d7d]">
                          Booking Date <span>*</span>
                        </span>
                      </label>
                      <input
                        required
                        {...register("booking_date")}
                        type="date"
                        name="booking_date"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="form-control w-full max-w-lg">
                      <label className="label">
                        <span className="label-text font-bold text-[#887d7d]">
                          Media Name
                        </span>
                      </label>
                      <input
                        required
                        {...register("media_name")}
                        type="text"
                        name="media_name"
                        placeholder="Media Name"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="form-control w-full max-w-lg">
                      <label className="label">
                        <span className="label-text font-bold text-[#887d7d]">
                          Media Phone Number
                        </span>
                      </label>
                      <input
                        required
                        {...register("media_phone_number")}
                        type="text"
                        name="media_phone_number"
                        placeholder="media_phone_number"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div>
              <fieldset className="border-2 rounded-md border-[#1F487C]">
                <legend className="mx-4">
                  <span
                    id="building_btn"
                    onClick={buildingInfoBtnHandler}
                    className="mr-1 btn  text-[16px]"
                  >
                    Building Informationss
                  </span>
                  <span
                    id="land_btn"
                    onClick={landInfoBtnHandler}
                    className="mr-1 btn p-3 font-bold hover:bg-[#1F487C] hover:text-white bg-white text-[#1F487C] border-[#1F487C]"
                  >
                    Land Information
                  </span>
                </legend>
                <div id="building_select">
                  <div className="grid lg:grid-cols-2 m-4">
                    <div className="p-2">
                      <div className="form-control w-full max-w-lg">
                        <label className="label">
                          <span className="label-text font-bold text-[#887d7d]">
                            Select Building
                          </span>
                        </label>
                        <select
                          defaultValue={null}
                          onChange={handleBuildingChange}
                          className="select select-bordered select-sm w-full max-w-lg"
                        >
                          <option disabled selected value="">
                            Select Building Name
                          </option>
                          {buildingList.map((list) => (
                            <option key={list.id} value={list.id}>
                              {list.building_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="form-control w-full max-w-lg">
                        <label className="label">
                          <span className="label-text font-bold text-[#887d7d]">
                            Select Flat Number
                          </span>
                        </label>
                        <select
                          defaultValue={null}
                          onBlur={(e) => {
                            e.preventDefault();
                            setFlatId(e.target.value);
                            setPlotId("");
                          }}
                          className="select select-bordered select-sm w-full max-w-lg"
                        >
                          <option selected value="">
                            Select Flat Number/reset
                          </option>
                          {flatList?.map((flist) => (
                            <option key={flist?.id} value={flist?.id}>
                              {flist?.flat_number}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="land_select">
                  <div className="grid lg:grid-cols-2 m-4">
                    <div className="p-2">
                      <div className="form-control w-full max-w-lg">
                        <label className="label">
                          <span className="label-text font-bold text-[#887d7d]">
                            Select Land Name
                          </span>
                        </label>
                        <select
                          defaultValue={null}
                          onChange={handleLandChange}
                          className="select select-bordered select-sm w-full max-w-lg"
                        >
                          <option disabled selected value="">
                            Select Land Name
                          </option>
                          {landList?.map((lList) => (
                            <option key={lList?.id} value={lList.id}>
                              {lList.land_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="form-control w-full max-w-lg">
                        <label className="label">
                          <span className="label-text font-bold text-[#887d7d]">
                            Select Plot Number
                          </span>
                        </label>
                        <select
                          defaultValue={null}
                          onBlur={(e) => {
                            e.preventDefault();
                            setPlotId(e.target.value);
                            setFlatId("");
                          }}
                          className="select select-bordered select-sm w-full max-w-lg"
                        >
                          <option selected value="">
                            Select Plot Number/reset
                          </option>
                          {plotList?.map((plist) => (
                            <option key={plist?.id} value={plist?.id}>
                              {plist?.plot_no}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>

          <div className="flex justify-end mx-16 my-8">
            {buttonDisabled == true ? (
              <label
                className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 mb-10 m-2 disabled"
                disabled={true}
              >
                Loading...
              </label>
            ) : (
              <button
                type="submit"
                className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 mb-10 m-2"
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default AddClient;
