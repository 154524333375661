import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth/AuthProvider";
import Loader from "../../Pages/Shared/Loader";
const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("crm-tdata"));
    if (token == undefined || token == null || token == "") {
      navigate("/login");
      setLoading(false);
    }
  }, []);
  return children;
};

export default PrivateRoute;
