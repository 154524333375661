import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { baseUrl } from "../../../BaseUrl";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import Heading from "../../Shared/Heading";
import Loader from "../../Shared/Loader";

const RegistrationAmountDetailsHome = () => {
  const [gridData, setGridData] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(baseUrl + `/registration/amount/view`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        setGridData(res?.data?.data);
      });
  }, []);

  //--------print functionality start----------
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //---------print functionality end-----------

  // ---------------search table data start------------------
  const handleSearch = (e) => {
    // console.log(e.target.value);
    // Declare variables
    let status = 0;
    let filterBy = e.target.value.toUpperCase();
    let table = document.getElementById("dataTable");
    let tr = table.querySelectorAll(".searchData");
    let i = 0;
    let j = 0;
    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      let tempTd = tr[i].getElementsByTagName("td");
      for (j = 0; j < tempTd.length; j++) {
        let td = tempTd[j];
        let txtValue;
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filterBy) > -1) {
            status = 1;
          }
          if (!status) {
            status = 0;
          }
        }
      }
      if (status) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  };
  // ------------------search table data end-----------------
  return (
    <div>
      <Heading>Registration Amount Payment & Due Details</Heading>
      <div className="p-5 whiteBackground">
        <div className="flex flex-wrap justify-between my-5">
          <CopyPrintPdfBtn
            handlePrint={handlePrint}
            componentRef={componentRef}
          ></CopyPrintPdfBtn>
          <input
            onChange={handleSearch}
            id="searchInput"
            type="text"
            placeholder="Search..."
            className="input input-bordered input-sm w-full max-w-xs mb-2"
          />
        </div>

        {gridData == null ? (
          <Loader></Loader>
        ) : (
          <div className="overflow-x-auto max-h-[60vh]">
            <table
              id="dataTable"
              ref={componentRef}
              className="table table-zebra w-full"
            >
              <thead className="text-[#00CA08]">
                <tr className="sticky top-0">
                  <th>Customer's Id</th>
                  <th>Customer's Name</th>
                  <th>File No.</th>
                  <th className="text-[black]">
                    Flat/Plot <br /> Registry <br /> Amount
                  </th>
                  <th>
                    Payment <br /> Complete <br /> Amount
                  </th>
                  <th className="text-[red]">
                    Payment <br /> Due <br /> Amount
                  </th>
                  <th className="text-[black]">
                    Mutation
                    <br /> Cost
                  </th>
                  <th>
                    Payment <br /> Complete <br /> Amount
                  </th>
                  <th className="text-[red]">
                    Payment <br /> Due <br /> Amount
                  </th>
                  <th className="text-[black]">
                    Power Of <br />
                    Attorney
                    <br /> Cost
                  </th>
                  <th>
                    Payment <br /> Complete <br /> Amount
                  </th>
                  <th className="text-[red]">
                    Payment <br /> Due <br /> Amount
                  </th>
                  <th className="hide-when-print">
                    Add/Edit <br /> Price <br /> Amount
                  </th>
                  <th className="hide-when-print">
                    Add/Edit <br /> Payment <br />
                    Received <br /> Amount
                  </th>
                  <th className="hide-when-print">
                    View <br /> Statement
                  </th>
                </tr>
              </thead>
              <tbody>
                {gridData?.length > 0 ? (
                  gridData?.map((item, i) => (
                    <tr
                      className="searchData"
                      key={i + Math.random().toFixed(5)}
                    >
                      <td>{item?.id}</td>
                      <td>
                        {item?.flat_or_plot?.customers?.map((child, i) => (
                          <div key={i + Math.random().toFixed(8)}>
                            <span>{child?.name} </span> <br />
                          </div>
                        ))}
                      </td>
                      <td>{item?.flat_or_plot?.file_no}</td>
                      <td>{item?.registry_amount}</td>
                      <td>{item?.registry_payment}</td>
                      <td>{item?.registry_amount - item?.registry_payment}</td>
                      <td>{item?.mutation_cost_amount}</td>
                      <td>{item?.mutation_cost_payment}</td>
                      <td>
                        {item?.mutation_cost_amount -
                          item?.mutation_cost_payment}
                      </td>
                      <td>{item?.power_of_attorney_cost_amount}</td>
                      <td>{item?.power_of_attorney_cost_payment}</td>
                      <td>
                        {item?.power_of_attorney_cost_amount -
                          item?.power_of_attorney_cost_payment}
                      </td>
                      <td className="text-center hide-when-print">
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            navigate("updatepriceamount", {
                              state: { id: item?.id },
                            });
                          }}
                          to="updatepriceamount"
                        >
                          <i className="fa-solid fa-pen-to-square m-2 text-xl"></i>
                        </span>
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            navigate("addpriceamount", {
                              state: { id: item?.id },
                            });
                          }}
                          to="addpriceamount"
                        >
                          <i className="fa-solid fa-square-plus m-2 text-xl"></i>
                        </span>
                      </td>
                      <td className="text-center hide-when-print">
                        <span
                          className="cursor-pointer"
                          onClick={() =>
                            navigate("addpaymentreceived", {
                              state: {
                                id: item?.id,
                              },
                            })
                          }
                        >
                          <i className="fa-solid fa-square-plus m-2 text-xl"></i>
                        </span>
                      </td>
                      <td className="text-center hide-when-print">
                        <span
                          className="cursor-pointer"
                          onClick={() =>
                            navigate("viewstatus", {
                              state: {
                                id: item?.id,
                              },
                            })
                          }
                          to="viewstatus"
                        >
                          <i className="fa-solid fa-eye m-2 text-xl"></i>
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      <h2
                        className="text-center py-5 col-span-10 font-bold"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        No Details Found
                      </h2>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegistrationAmountDetailsHome;
