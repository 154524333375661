import React, {  } from "react";
import { Outlet } from "react-router-dom";

const ClientInfo = () => {
  return (
    <>
        <Outlet></Outlet>
    </>
  );
};

export default ClientInfo;
