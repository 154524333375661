import React, { useRef } from "react";
import Heading from "../../Shared/Heading";
import { Link, useLocation } from "react-router-dom";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import { useReactToPrint } from "react-to-print";
import shapla_city from "../../../assets/shapla-city.png";

const SinglePaymentSlipAfterRegi = () => {
    const location = useLocation();
    const singlePaymentData = location.state.data;
    const viewCancelPaymentData = location.state.viewCancelPaymentData;
    // console.log(singlePaymentData);
    // console.log(viewCancelPaymentData);
  
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    return (
        <div>
      <Heading>
        <Link
          to={`/dashboard/viewafterregiallpaymentslip`}
          state={{
            data: viewCancelPaymentData,
          }}
        >
          <i className="fa-solid fa-arrow-left"></i> Payment Slip After Registration
        </Link>
      </Heading>
      <div className="information_container">
        <div className="flex flex-wrap justify-start my-5">
          <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
          {/* <button className="btn btn-sm m-2">Legal Paper Folder</button> */}
          <button disabled className="btn btn-sm m-2 bg-slate-800">
            <span className="text-slate-800 text-xs normal-case ">
              {`Media Name: ${viewCancelPaymentData?.flat_or_plot?.sold_items?.media_name} | Media Phone Number: ${viewCancelPaymentData.flat_or_plot.sold_items.media_phone_number}`}
            </span>
          </button>
        </div>
      </div>
      <div
        className="gap-5 my-12 mx-5 max-w-[900px] print-section"
        ref={componentRef}
      >
        <>
          <div className="w-[210mm] h-[297mm] bg-white">
            <div className="flex justify-center">
              <img className="w-[30%] mt-20" src={shapla_city} alt="" srcSet="" />
            </div>
            <div className="m-6 font-bold flex justify-center pb-20">
              <h1 className="text-2xl font-semibold underline underline-offset-4 mt-4 mb-4 w-fit">
              Payment Information
              </h1>
            </div>
            <div>
              <b className="px-10 m-4">
                Invoice Slip No : {singlePaymentData?.invoice_no}{" "}
              </b>
            </div>
            <div className="px-10 m-4">
              <div className="my-5">
                <p className="leading-[30px]">
                  <b className="text-[#555050]">Customer Name :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {viewCancelPaymentData?.flat_or_plot?.customers
                      ?.map((customer) => customer.name)
                      .join(", ")}
                  </span>
                </p>
                <p className="leading-[30px]">
                  <b className="text-[#555050]">Customer Id :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {viewCancelPaymentData?.flat_or_plot?.customers
                      ?.map((customer) => customer.id)
                      .join(", ")}
                  </span>
                </p>
                <p className="leading-[30px]">
                  <b className="text-[#555050]">Mobile No :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {viewCancelPaymentData?.flat_or_plot?.customers
                      ?.map((customer) => customer.phone_number)
                      .join(", ")}
                  </span>
                </p>
                <p className="leading-[30px]">
                  <b className="text-[#555050]">File No :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {viewCancelPaymentData?.flat_or_plot?.file_no}
                  </span>
                  <b className="text-[#555050]">Building/Land :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {
                      viewCancelPaymentData?.flat_or_plot?.project_info
                        ?.project_no
                    }
                  </span>
                  <b className="text-[#555050]">Flat/Plot Size :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {viewCancelPaymentData?.flat_or_plot?.size}
                  </span>
                </p>
              </div>
              <hr />
              <div className="pb-[100px] mt-5">
                <p className="leading-[30px]">
                  <b className="text-[#555050]">New Address :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {singlePaymentData?.new_address}
                  </span>
                </p>
                <p className="leading-[30px]">
                <b className="text-[#555050]">Payment Date :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {singlePaymentData?.payment_date}
                  </span>
                  <b className="text-[#555050]">Payment Amount :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {singlePaymentData?.payment_amount}
                  </span>
                </p>
                <p className="leading-[30px]">
                  <b className="text-[#555050]">Payment Amount in Words :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {singlePaymentData?.amount_in_words}
                  </span>
                </p>
                <p className="leading-[30px]">
                  <b className="text-[#555050]">Payment Method Details :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {singlePaymentData?.payment_method}
                  </span>
                  <b className="text-[#555050]">Payment Receiver Name :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {singlePaymentData?.received_by}
                  </span>
                </p>
                <p className="leading-[30px]">
                  <b className="text-[#555050]">Transactiopn Completed Staff Name :</b>
                  <span className="underline underline-offset-4 mx-4">
                    {singlePaymentData?.staff_name}
                  </span>
                </p>
              </div>
            </div>
            <div className="flex justify-around my-20 py-20">
              <div className="mt-10">
                <hr className="w-full bg-slate-400 h-0.5" />
                <h1>Customer's Signature</h1>
              </div>
              <div className="mt-10">
                <hr className="w-full bg-slate-400 h-0.5" />
                <h1>Official Signature</h1>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
    );
};

export default SinglePaymentSlipAfterRegi;