import React from "react";

const CopyPrintPdfBtn = ({ handlePrint, handleDownloadPdf }) => {
  return (
    <div>
      <div className="my-2">
        <button onClick={handlePrint} className="btn mr-2 btn-sm">
          Print
        </button>
        <button
          onClick={handleDownloadPdf}
          disabled={true}
          className="btn mr-2 btn-sm hidden"
        >
          PDF
        </button>
      </div>
    </div>
  );
};

export default CopyPrintPdfBtn;
