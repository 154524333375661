import { Link, useNavigate, useParams } from "react-router-dom";
import Heading from "../../Shared/Heading";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl, baseUrlImg } from "../../../BaseUrl";
import { toast } from "react-toastify";

const UpdateClientInfo = () => {
  const { flat_or_plot_id, project_type, project_id, Id } = useParams();
  const [predata, setPreData] = useState(null);
  const [updateButtonDisable, setUpdateButtonDisable] = useState(false);
  const [totalCustomer, setTotalCustomer] = useState(1);
  const [clientImage, setClientImage] = useState(null);
  const [nomineeImage, setNomineeImage] = useState(null);
  const [customerForm, setCustomerForm] = useState([]);
  const [newCustomerForm, setNewCustomerForm] = useState([]);
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [buildingId, setBuildingId] = useState("");
  const [landId, setLandId] = useState("");
  const [flatId, setFlatId] = useState("");
  const [plotId, setPlotId] = useState("");
  const [type, setType] = useState("");

  const [buildingList, setBuildingList] = useState([]);
  const [flatList, setFlatList] = useState([]);
  const [landList, setLandList] = useState([]);
  const [plotList, setPlotList] = useState([]);
  const [reFetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .post(
        baseUrl + `/customer/information/details`,
        {
          flat_or_plot_id,
          project_type,
          project_id,
        },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        if (res?.data?.data?.type == 1) {
          setFlatId(res?.data?.data?.flat_or_plot[0]?.id);
        } else {
          setPlotId(res?.data?.data?.flat_or_plot[0]?.id);
        }
        setPreData(res?.data?.data);
        let customerArray = [];
        for (
          let i = 0;
          i < res?.data?.data?.flat_or_plot[0]?.customers?.length;
          i++
        ) {
          const customer = res?.data?.data?.flat_or_plot[0]?.customers[i];
          customerArray.push({ customer: customer });
        }
        setCustomerForm(customerArray);
        setIndex(res?.data?.data?.flat_or_plot[0]?.customers?.length - 1);
        if (res?.data?.data?.flat_or_plot) {
          if (res?.data?.data?.flat_or_plot[0]?.flat_number) {
            buildingInfoBtnHandler();
          } else if (res?.data?.data?.flat_or_plot[0]?.plot_no) {
            landInfoBtnHandler();
          }
        }
      });
  }, [flat_or_plot_id, project_type, project_id, reFetch]);

  useEffect(() => {
    if (predata?.type == 1) {
      axios
        .get(
          baseUrl +
            `/customer/building/flat/${predata?.id}/${predata?.flat_or_plot[0]?.id}`,

          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("crm-tdata")
              )}`,
              "content-type": "text/json",
            },
          }
        )
        .then((res) => {
          setFlatList(res?.data?.data?.flat_or_plot);
        });
    } else if (predata?.type == 2) {
      axios
        .get(
          baseUrl +
            `/customer/land/plot/${predata?.id}/${predata?.flat_or_plot[0]?.id}`,

          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("crm-tdata")
              )}`,
              "content-type": "text/json",
            },
          }
        )
        .then((res) => {
          setPlotList(res?.data?.data?.flat_or_plot);
        });
    }
  }, [predata]);

  useEffect(() => {
    axios
      .post(
        baseUrl + `/customer/building_land/all`,
        { type: 1 },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setBuildingList(res?.data?.data);
      });
    axios
      .post(
        baseUrl + `/customer/building_land/all`,
        { type: 2 },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setLandList(res?.data?.data);
      });
  }, []);

  const handleBuildingChange = (e) => {
    e.preventDefault();
    const id = e.target.value;
    setBuildingId(e.target.value);
    setLandId("");
    setPlotId("");
    axios
      .get(
        baseUrl +
          `/customer/building/flat/${id}/${predata?.flat_or_plot[0]?.id}`,

        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setFlatList(res?.data?.data?.flat_or_plot);
      });
  };
  const handleLandChange = (e) => {
    e.preventDefault();
    const id = e.target.value;
    setLandId(e.target.value);
    setBuildingId("");
    setFlatId("");
    axios
      .get(
        baseUrl + `/customer/land/plot/${id}`,

        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setPlotList(res?.data?.data?.flat_or_plot);
      });
  };

  const handleCustomerFormAdd = () => {
    setIndex(customerForm.length + 1);
    setNewCustomerForm([
      ...newCustomerForm,
      {
        customer: {},
      },
    ]);
  };

  const handleCustomerFormRemove = (index) => {
    if (newCustomerForm?.length > 0) {
      let list = [...newCustomerForm];
      list = list.splice(0, newCustomerForm?.length - 1);
      setIndex(index - 1);
      setNewCustomerForm(list);
    } else {
      let list = [...customerForm];
      list = list.splice(0, customerForm?.length - 1);
      setIndex(index - 1);
      setCustomerForm(list);
    }
  };
  const onSubmit = (data) => {
    if (flatId == "" && plotId == "") {
      toast.error("flat Id or plot Id required");
      return;
    }
    if (data.media_name == "") {
      data.media_name =
        predata?.flat_or_plot[0]?.customers[0]?.sold_item?.media_name;
    }
    if (data.booking_date == "") {
      data.booking_date = predata?.flat_or_plot[0]?.booking_date;
    }
    if (data.media_phone_number == "") {
      data.media_phone_number =
        predata?.flat_or_plot[0]?.customers[0]?.sold_item?.media_phone_number;
    }
    let flat_or_plot_id = "";
    let propertyType = "";
    if (plotId !== "") {
      setType(2);
      flat_or_plot_id = plotId;
      propertyType = 2;
    } else {
      setType(1);
      flat_or_plot_id = flatId;
      propertyType = 1;
    }
    for (let i = 0; i < customerForm?.length; i++) {
      if (data?.customer[i]?.customer_image?.length == 0) {
        data.customer[i].customer_image = null;
      }
      if (data?.customer[i]?.nominee_image?.length == 0) {
        data.customer[i].nominee_image = null;
      }
      data.customer[i].id = predata?.flat_or_plot[0]?.customers[i]?.id;
    }
    let customer = data.customer.slice(0, customerForm?.length);
    let newCustomer = [];
    if (newCustomerForm?.length > 0) {
      newCustomer = data.newCustomer.slice(0, newCustomerForm?.length);
    }

    for (let i = 0; i < newCustomer?.length; i++) {
      newCustomer[i].id = null;
    }

    customer = [...customer, ...newCustomer];
    delete data?.newCustomer;
    const newData = {
      ...data,
      customer,
      new_building_id: buildingId,
      new_land_id: landId,
      flat_or_plot_id:
        flat_or_plot_id == "" ? predata?.flat_or_plot[0]?.id : flat_or_plot_id,
      old_building_id: predata?.type == 1 ? predata?.project_no : null,
      old_land_id: predata?.type == 2 ? predata?.project_no : null,
      old_flat_or_plot_id: predata?.flat_or_plot[0]?.id,
      type: propertyType,
    };

    const formData = new FormData();
    Object.entries(newData).forEach(([key, value]) => {
      if (typeof value == "object") {
        for (let i = 0; i < value?.length; i++) {
          let names = Object.keys(value[i]);
          names.forEach((name) => {
            if (value[i][name] instanceof FileList) {
              formData.append(
                `customer[${i}][${name}]`,
                value[i][name].item(0)
              );
            } else {
              formData.append(`customer[${i}][${name}]`, value[i][name]);
            }
          });
        }
      } else {
        formData.append(key, value);
      }
    });

    setUpdateButtonDisable(true);
    axios
      .post(baseUrl + `/customer/information/update`, formData, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          // "content-type": "text/json",
        },
      })
      .then((res) => {
        if (res?.data?.status == "failed") {
          if (Array?.isArray(res?.data?.message)) {
            res?.data?.message.forEach((m) => {
              toast.error(m);
            });
          } else {
            toast.error(res?.data?.message);
          }
          setUpdateButtonDisable(false);
        } else {
          toast.success(res?.data?.message);
          navigate("/dashboard/clientinfo");
        }
      });
  };
  const buildingInfoBtnHandler = () => {
    document.getElementById("building_select").style.display = "block";
    document.getElementById("land_select").style.display = "none";
    document.getElementById("land_btn").style.background = "white";
    document.getElementById("land_btn").style.color = "#1F497D";
    document.getElementById("building_btn").style.background = "#1F497D";
    document.getElementById("building_btn").style.color = "white";
  };
  const landInfoBtnHandler = () => {
    document.getElementById("building_select").style.display = "none";
    document.getElementById("land_select").style.display = "block";
    document.getElementById("building_btn").style.background = "white";
    document.getElementById("building_btn").style.color = "#1F497D";
    document.getElementById("land_btn").style.background = "#1F497D";
    document.getElementById("land_btn").style.color = "white";
  };
  return (
    <>
      <Heading>
        <Link to="/dashboard/clientinfo">
          <i className="fa-solid fa-arrow-left"></i> Update Client
        </Link>
      </Heading>
      <div className="mx-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          {customerForm &&
            customerForm?.map((singleCustomer, index) => (
              <div key={index + Math.random()} className="mt-5">
                <div className="grid lg:grid-cols-2 gap-10 mt-5">
                  <div>
                    <fieldset className="border-2 rounded-md border-[#1F487C]">
                      <legend className="mx-4">
                        <div className="bg-[#1F497D] text-[white] py-1 px-4 rounded-md">
                          {" "}
                          Customer Information {index +1}
                        </div>
                      </legend>
                      <div>
                        <div className="grid lg:grid-cols-2 ">
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Customer's Name <span>*</span>
                                </span>
                              </label>
                              <input
                                defaultValue={singleCustomer?.customer?.name}
                                {...register(
                                  `customer[${index}].customer_name`
                                )}
                                name={`customer[${index}].customer_name`}
                                // {...register(`customer_name`)}
                                // name={`customer_name`}
                                type="text"
                                required
                                placeholder="Customer's Name"
                                className="input input-bordered input-sm w-full max-w-lg"
                              />
                            </div>
                          </div>
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Spouse Name
                                </span>
                              </label>
                              <input
                                defaultValue={
                                  singleCustomer.customer?.profile?.spouse_name
                                }
                                {...register(
                                  `customer[${index}].customer_spouse_name`
                                )}
                                required
                                name={`customer[${index}].customer_spouse_name`}
                                // {...register(`spouse_name`)}
                                // name={`spouse_name`}
                                type="text"
                                placeholder="Spouse Name"
                                className="input input-bordered input-sm w-full max-w-lg"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid lg:grid-cols-2 ">
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Father Name <span>*</span>
                                </span>
                              </label>
                              <input
                                defaultValue={
                                  singleCustomer.customer?.profile?.father_name
                                }
                                required
                                {...register(
                                  `customer[${index}].customer_father_name`
                                )}
                                name={`customer[${index}].customer_father_name`}
                                // {...register(`father_name`)}
                                // name={`father_name`}
                                type="text"
                                placeholder="Father Name"
                                className="input input-bordered input-sm w-full max-w-lg"
                              />
                            </div>
                          </div>
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Mother Name <span>*</span>
                                </span>
                              </label>
                              <input
                                defaultValue={
                                  singleCustomer.customer?.profile?.mother_name
                                }
                                required
                                {...register(
                                  `customer[${index}].customer_mother_name`
                                )}
                                name={`customer[${index}].customer_mother_name`}
                                // {...register(`mother_name`)}
                                // name={`mother_name`}
                                type="text"
                                placeholder="Mother Name"
                                className="input input-bordered input-sm w-full max-w-lg"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid lg:grid-cols-2 ">
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Email Address <span>*</span>
                                </span>
                              </label>
                              <input
                                defaultValue={singleCustomer.customer?.email}
                                required
                                {...register(
                                  `customer[${index}].customer_email`
                                )}
                                name={`customer[${index}].customer_email`}
                                // {...register(`email_address`)}
                                // name={`email_address`}
                                type="email"
                                placeholder="Email Address"
                                className="input input-bordered input-sm w-full max-w-lg"
                              />
                            </div>
                          </div>
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Phone Number <span>*</span>
                                </span>
                              </label>
                              <input
                                defaultValue={
                                  singleCustomer.customer?.phone_number
                                }
                                required
                                {...register(
                                  `customer[${index}].customer_phone_number`
                                )}
                                name={`customer[${index}].customer_phone_number`}
                                // {...register(`phone_number`)}
                                // name={`phone_number`}
                                type="text"
                                placeholder="Phone Number"
                                className="input input-bordered input-sm w-full max-w-lg"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid lg:grid-cols-2 ">
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  National ID Number <span>*</span>
                                </span>
                              </label>
                              <input
                                defaultValue={
                                  singleCustomer.customer?.nid_number
                                }
                                required
                                {...register(
                                  `customer[${index}].customer_nid_number`
                                )}
                                name={`customer[${index}].customer_nid_number`}
                                // name={`nid_number`}
                                // {...register(`nid_number`)}
                                type="text"
                                placeholder="National ID Number"
                                className="input input-bordered input-sm w-full max-w-lg"
                              />
                            </div>
                          </div>
                          <div className="p-2">
                            <div>
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Date of Birth <span>*</span>
                                  </span>
                                </label>
                                <input
                                  defaultValue={
                                    singleCustomer.customer?.date_of_birth
                                  }
                                  required
                                  {...register(
                                    `customer[${index}].customer_date_of_birth`
                                  )}
                                  name={`customer[${index}].customer_date_of_birth`}
                                  // {...register(`date_of_birth`)}
                                  // name={`date_of_birth`}
                                  type="date"
                                  className="input input-bordered input-sm w-full max-w-lg"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="grid lg:grid-cols-2 ">
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Other File No.
                                </span>
                              </label>
                              <input
                                defaultValue={
                                  singleCustomer.customer?.document
                                    ?.other_file_no
                                }
                                required
                                {...register(
                                  `customer[${index}].customer_other_file_no`
                                )}
                                name={`customer[${index}].customer_other_file_no`}
                                // {...register(`other_file_no`)}
                                // name={`other_file_no`}
                                type="text"
                                placeholder="Other File No"
                                className="input input-bordered input-sm w-full max-w-lg"
                              />
                            </div>
                          </div>
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Profession *
                                </span>
                              </label>
                              <input
                                defaultValue={
                                  singleCustomer.customer?.profile?.profession
                                }
                                required
                                {...register(
                                  `customer[${index}].customer_profession`
                                )}
                                name={`customer[${index}].customer_profession`}
                                // {...register(`profession`)}
                                // name={`profession`}
                                type="text"
                                placeholder="Profession"
                                className="input input-bordered input-sm w-full max-w-lg"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid lg:grid-cols-2 ">
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Designation (Optional)
                                </span>
                              </label>
                              <input
                                defaultValue={
                                  singleCustomer.customer?.profile?.designation
                                }
                                {...register(
                                  `customer[${index}].customer_designation`
                                )}
                                name={`customer[${index}].customer_designation`}
                                // {...register(`designation`)}
                                // name={`designation`}
                                type="text"
                                placeholder="Designation"
                                className="input input-bordered input-sm w-full max-w-lg"
                              />
                            </div>
                          </div>
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Country *
                                </span>
                              </label>
                              <select
                                defaultValue={singleCustomer.customer?.country}
                                required
                                {...register(
                                  `customer[${index}].customer_country`
                                )}
                                name={`customer[${index}].customer_country`}
                                // {...register(`customer_country`)}
                                // name={`customer_country`}
                                className="select select-bordered select-sm w-full max-w-lg"
                              >
                                <option disabled selected value="">
                                  Select Country
                                </option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="India">India</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Nepal">Nepal</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="grid lg:grid-cols-2 ">
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Mailling Address
                                </span>
                              </label>
                              <textarea
                                defaultValue={
                                  singleCustomer.customer?.mailing_address
                                }
                                required
                                {...register(
                                  `customer[${index}].customer_mailing_address`
                                )}
                                name={`customer[${index}].customer_mailing_address`}
                                // {...register(`customer_mailing_address`)}
                                // name={`customer_mailing_address`}
                                className="textarea textarea-bordered"
                                placeholder="Mailling Address"
                              ></textarea>
                            </div>
                          </div>
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Permanent Address <span>*</span>
                                </span>
                              </label>
                              <textarea
                                defaultValue={
                                  singleCustomer.customer?.profile
                                    ?.permanent_address
                                }
                                required
                                {...register(
                                  `customer[${index}].customer_permanent_address`
                                )}
                                name={`customer[${index}].customer_permanent_address`}
                                // {...register(`customer_permanent_address`)}
                                // name={`customer_permanent_address`}
                                className="textarea textarea-bordered"
                                placeholder="Permanent Address"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="grid lg:grid-cols-2 ">
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Office Address(Optional)
                                </span>
                              </label>
                              <textarea
                                defaultValue={
                                  singleCustomer.customer?.profile
                                    ?.office_address
                                }
                                {...register(
                                  `customer[${index}].customer_office_address`
                                )}
                                name={`customer[${index}].customer_office_address`}
                                // {...register(`customer_office_address`)}
                                // name={`customer_office_address`}
                                className="textarea textarea-bordered"
                                placeholder="Mailling Address"
                              ></textarea>
                            </div>
                          </div>
                          <div className="p-2">
                            <div className="py-8">
                              <h5 className="text-blue-700">Previous image</h5>
                              <img
                                className="w-[200px] h-[200px]"
                                src={
                                  baseUrlImg +
                                  `/${singleCustomer.customer?.image}`
                                }
                                alt=""
                              />
                            </div>
                            <div className="form-control w-full max-w-lg">
                              {/* <div className="m-3">
                              {clientImage && (
                                <img
                                  alt=""
                                  srcSet=""
                                  width={"120px"}
                                  height={"150px"}
                                  src={URL.createObjectURL(clientImage)}
                                />
                              )}
                            </div> */}
                              <div>
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Client Image (PP size 450 X 570){" "}
                                    <span>*</span>
                                  </span>
                                </label>
                                <input
                                  {...register(
                                    `customer[${index}].customer_image`
                                  )}
                                  name={`customer[${index}].customer_image`}
                                  // {...register(`customer_image`)}
                                  // name={`customer_image`}
                                  className="block w-full text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                  // onChange={(event) => {
                                  //   setClientImage(event.target.files[0]);
                                  // }}
                                  type="file"
                                  multiple="multiple"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <fieldset className="border-2 rounded-md border-[#1F487C]">
                      <legend className="mx-4">
                        <div className="bg-[#1F497D] text-[white] py-1 px-4 rounded-md">
                          Nominee Information
                        </div>
                      </legend>

                      <div>
                        <div className="grid lg:grid-cols-2 ">
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Nominee Name <span>*</span>
                                </span>
                              </label>
                              <input
                                defaultValue={
                                  singleCustomer.customer?.nominee?.nominee_name
                                }
                                required
                                {...register(`customer[${index}].nominee_name`)}
                                name={`customer[${index}].nominee_name`}
                                // {...register(`nominee_name`)}
                                // name={`nominee_name`}
                                type="text"
                                placeholder="Customer's Name"
                                className="input input-bordered input-sm w-full max-w-lg"
                              />
                            </div>
                          </div>
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Relation With Nominee <span>*</span>
                                </span>
                              </label>
                              <input
                                defaultValue={
                                  singleCustomer.customer?.nominee
                                    ?.relation_with_nominee
                                }
                                required
                                {...register(
                                  `customer[${index}].relation_with_nominee`
                                )}
                                name={`customer[${index}].relation_with_nominee`}
                                // {...register(`relation_with_nominee`)}
                                // name={`relation_with_nominee`}
                                type="text"
                                placeholder="Nominee's Name"
                                className="input input-bordered input-sm w-full max-w-lg"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid lg:grid-cols-2 ">
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Nominee Contact Number <span>*</span>
                                </span>
                              </label>
                              <input
                                defaultValue={
                                  singleCustomer.customer?.nominee
                                    ?.nominee_contact_number
                                }
                                required
                                {...register(
                                  `customer[${index}].nominee_contact_number`
                                )}
                                name={`customer[${index}].nominee_contact_number`}
                                // {...register(`nominee_contact_number`)}
                                // name={`nominee_contact_number`}
                                type="text"
                                placeholder="Nominee Contact Number"
                                className="input input-bordered input-sm w-full max-w-lg"
                              />
                            </div>
                          </div>
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Nominee Gets <span>*</span>
                                </span>
                              </label>
                              <input
                                defaultValue={
                                  singleCustomer.customer?.nominee?.nominee_gets
                                }
                                required
                                {...register(`customer[${index}].nominee_gets`)}
                                name={`customer[${index}].nominee_gets`}
                                // {...register(`nominee_gets`)}
                                // name={`nominee_gets`}
                                type="text"
                                placeholder="Nominee Gets"
                                className="input input-bordered input-sm w-full max-w-lg"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid lg:grid-cols-2 ">
                          <div className="p-2">
                            <div className="form-control w-full max-w-lg">
                              <label className="label">
                                <span className="label-text font-bold text-[#887d7d]">
                                  Nominee Address
                                </span>
                              </label>
                              <textarea
                                defaultValue={
                                  singleCustomer.customer?.nominee
                                    ?.nominee_address
                                }
                                required
                                {...register(
                                  `customer[${index}].nominee_address`
                                )}
                                name={`customer[${index}].nominee_address`}
                                // {...register(`nominee_address`)}
                                // name={`nominee_address`}
                                className="textarea textarea-bordered"
                                placeholder="Nominee Address"
                              ></textarea>
                            </div>
                          </div>
                          <div className="p-2">
                            <div className="py-8">
                              <h5 className="text-blue-700">Previous image</h5>
                              <img
                                className="w-[200px] h-[200px]"
                                src={
                                  baseUrlImg +
                                  `/${singleCustomer.customer?.nominee?.nominee_image}`
                                }
                                alt=""
                              />
                            </div>
                            <div className="form-control w-full max-w-lg">
                              {/* <div className="m-3">
                              {nomineeImage && (
                                <img
                                  alt=""
                                  srcSet=""
                                  width={"120px"}
                                  height={"150px"}
                                  src={URL.createObjectURL(nomineeImage)}
                                />
                              )}
                            </div> */}
                              <div>
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Nominee Image (PP size 450 X 570){" "}
                                    <span>*</span>
                                  </span>
                                </label>
                                <input
                                  {...register(
                                    `customer[${index}].nominee_image`
                                  )}
                                  name={`customer[${index}].nominee_image`}
                                  // {...register(`nominee_image`)}
                                  // name={`nominee_image`}
                                  className="block w-full text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                  // onChange={(event) => {
                                  //   setNomineeImage(event.target.files[0]);
                                  // }}
                                  type="file"
                                  multiple="multiple"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            ))}
          {newCustomerForm &&
            newCustomerForm?.map((singleCustomer, index) => {
              return (
                <div key={index + Math.random()} className="mt-5">
                  <div className="grid lg:grid-cols-2 gap-10 mt-5">
                    <div>
                      <fieldset className="border-2 rounded-md border-[#1F487C]">
                        <legend className="mx-4">
                          <button className="bg-[#1F497D] text-[white] py-1 px-4 rounded-md">
                            {" "}
                            New Customer Information {index}
                          </button>
                        </legend>
                        <div>
                          <div className="grid lg:grid-cols-2 ">
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Customer's Name <span>*</span>
                                  </span>
                                </label>
                                <input
                                  {...register(
                                    `newCustomer[${index}].customer_name`
                                  )}
                                  name={`newCustomer[${index}].customer_name`}
                                  // {...register(`customer_name`)}
                                  // name={`customer_name`}
                                  type="text"
                                  required
                                  placeholder="Customer's Name"
                                  className="input input-bordered input-sm w-full max-w-lg"
                                />
                              </div>
                            </div>
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Spouse Name
                                  </span>
                                </label>
                                <input
                                  {...register(
                                    `newCustomer[${index}].customer_spouse_name`
                                  )}
                                  required
                                  name={`newCustomer[${index}].customer_spouse_name`}
                                  // {...register(`spouse_name`)}
                                  // name={`spouse_name`}
                                  type="text"
                                  placeholder="Spouse Name"
                                  className="input input-bordered input-sm w-full max-w-lg"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-2 ">
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Father Name <span>*</span>
                                  </span>
                                </label>
                                <input
                                  required
                                  {...register(
                                    `newCustomer[${index}].customer_father_name`
                                  )}
                                  name={`newCustomer[${index}].customer_father_name`}
                                  // {...register(`father_name`)}
                                  // name={`father_name`}
                                  type="text"
                                  placeholder="Father Name"
                                  className="input input-bordered input-sm w-full max-w-lg"
                                />
                              </div>
                            </div>
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Mother Name <span>*</span>
                                  </span>
                                </label>
                                <input
                                  required
                                  {...register(
                                    `newCustomer[${index}].customer_mother_name`
                                  )}
                                  name={`newCustomer[${index}].customer_mother_name`}
                                  // {...register(`mother_name`)}
                                  // name={`mother_name`}
                                  type="text"
                                  placeholder="Mother Name"
                                  className="input input-bordered input-sm w-full max-w-lg"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-2 ">
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Email Address <span>*</span>
                                  </span>
                                </label>
                                <input
                                  required
                                  {...register(
                                    `newCustomer[${index}].customer_email`
                                  )}
                                  name={`newCustomer[${index}].customer_email`}
                                  // {...register(`email_address`)}
                                  // name={`email_address`}
                                  type="email"
                                  placeholder="Email Address"
                                  className="input input-bordered input-sm w-full max-w-lg"
                                />
                              </div>
                            </div>
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Phone Number <span>*</span>
                                  </span>
                                </label>
                                <input
                                  required
                                  {...register(
                                    `newCustomer[${index}].customer_phone_number`
                                  )}
                                  name={`newCustomer[${index}].customer_phone_number`}
                                  // {...register(`phone_number`)}
                                  // name={`phone_number`}
                                  type="text"
                                  placeholder="Phone Number"
                                  className="input input-bordered input-sm w-full max-w-lg"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-2 ">
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    National ID Number <span>*</span>
                                  </span>
                                </label>
                                <input
                                  required
                                  {...register(
                                    `newCustomer[${index}].customer_nid_number`
                                  )}
                                  name={`newCustomer[${index}].customer_nid_number`}
                                  // name={`nid_number`}
                                  // {...register(`nid_number`)}
                                  type="text"
                                  placeholder="National ID Number"
                                  className="input input-bordered input-sm w-full max-w-lg"
                                />
                              </div>
                            </div>
                            <div className="p-2">
                              <div>
                                <div className="form-control w-full max-w-lg">
                                  <label className="label">
                                    <span className="label-text font-bold text-[#887d7d]">
                                      Date of Birth <span>*</span>
                                    </span>
                                  </label>
                                  <input
                                    required
                                    {...register(
                                      `newCustomer[${index}].customer_date_of_birth`
                                    )}
                                    name={`newCustomer[${index}].customer_date_of_birth`}
                                    // {...register(`date_of_birth`)}
                                    // name={`date_of_birth`}
                                    type="date"
                                    className="input input-bordered input-sm w-full max-w-lg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-2 ">
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Other File No.
                                  </span>
                                </label>
                                <input
                                  required
                                  {...register(
                                    `newCustomer[${index}].customer_other_file_no`
                                  )}
                                  name={`newCustomer[${index}].customer_other_file_no`}
                                  // {...register(`other_file_no`)}
                                  // name={`other_file_no`}
                                  type="text"
                                  placeholder="Other File No"
                                  className="input input-bordered input-sm w-full max-w-lg"
                                />
                              </div>
                            </div>
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Profession *
                                  </span>
                                </label>
                                <input
                                  required
                                  {...register(
                                    `newCustomer[${index}].customer_profession`
                                  )}
                                  name={`newCustomer[${index}].customer_profession`}
                                  // {...register(`profession`)}
                                  // name={`profession`}
                                  type="text"
                                  placeholder="Profession"
                                  className="input input-bordered input-sm w-full max-w-lg"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-2 ">
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Designation (Optional)
                                  </span>
                                </label>
                                <input
                                  required
                                  {...register(
                                    `newCustomer[${index}].customer_designation`
                                  )}
                                  name={`newCustomer[${index}].customer_designation`}
                                  // {...register(`designation`)}
                                  // name={`designation`}
                                  type="text"
                                  placeholder="Designation"
                                  className="input input-bordered input-sm w-full max-w-lg"
                                />
                              </div>
                            </div>
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Country *
                                  </span>
                                </label>
                                <select
                                  required
                                  {...register(
                                    `newCustomer[${index}].customer_country`
                                  )}
                                  name={`newCustomer[${index}].customer_country`}
                                  // {...register(`customer_country`)}
                                  // name={`customer_country`}
                                  className="select select-bordered select-sm w-full max-w-lg"
                                >
                                  <option disabled selected value="">
                                    Select Country
                                  </option>
                                  <option value="Bangladesh">Bangladesh</option>
                                  <option value="India">India</option>
                                  <option value="Pakistan">Pakistan</option>
                                  <option value="Nepal">Nepal</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-2 ">
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Mailling Address
                                  </span>
                                </label>
                                <textarea
                                  required
                                  {...register(
                                    `newCustomer[${index}].customer_mailing_address`
                                  )}
                                  name={`newCustomer[${index}].customer_mailing_address`}
                                  // {...register(`customer_mailing_address`)}
                                  // name={`customer_mailing_address`}
                                  className="textarea textarea-bordered"
                                  placeholder="Mailling Address"
                                ></textarea>
                              </div>
                            </div>
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Permanent Address <span>*</span>
                                  </span>
                                </label>
                                <textarea
                                  required
                                  {...register(
                                    `newCustomer[${index}].customer_permanent_address`
                                  )}
                                  name={`newCustomer[${index}].customer_permanent_address`}
                                  // {...register(`customer_permanent_address`)}
                                  // name={`customer_permanent_address`}
                                  className="textarea textarea-bordered"
                                  placeholder="Permanent Address"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-2 ">
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Office Address(Optional)
                                  </span>
                                </label>
                                <textarea
                                  required
                                  {...register(
                                    `newCustomer[${index}].customer_office_address`
                                  )}
                                  name={`newCustomer[${index}].customer_office_address`}
                                  // {...register(`customer_office_address`)}
                                  // name={`customer_office_address`}
                                  className="textarea textarea-bordered"
                                  placeholder="Mailling Address"
                                ></textarea>
                              </div>
                            </div>
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                {/* <div className="m-3">
                              {clientImage && (
                                <img
                                  alt=""
                                  srcSet=""
                                  width={"120px"}
                                  height={"150px"}
                                  src={URL.createObjectURL(clientImage)}
                                />
                              )}
                            </div> */}
                                <div>
                                  <label className="label">
                                    <span className="label-text font-bold text-[#887d7d]">
                                      Client Image (PP size 450 X 570){" "}
                                      <span>*</span>
                                    </span>
                                  </label>
                                  <input
                                    required
                                    {...register(
                                      `newCustomer[${index}].customer_image`
                                    )}
                                    name={`newCustomer[${index}].customer_image`}
                                    // {...register(`customer_image`)}
                                    // name={`customer_image`}
                                    className="block w-full text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                    // onChange={(event) => {
                                    //   setClientImage(event.target.files[0]);
                                    // }}
                                    type="file"
                                    multiple="multiple"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div>
                      <fieldset className="border-2 rounded-md border-[#1F487C]">
                        <legend className="mx-4">
                          <button className="bg-[#1F497D] text-[white] py-1 px-4 rounded-md">
                            Nominee Information
                          </button>
                        </legend>

                        <div>
                          <div className="grid lg:grid-cols-2 ">
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Nominee Name <span>*</span>
                                  </span>
                                </label>
                                <input
                                  required
                                  {...register(
                                    `newCustomer[${index}].nominee_name`
                                  )}
                                  name={`newCustomer[${index}].nominee_name`}
                                  // {...register(`nominee_name`)}
                                  // name={`nominee_name`}
                                  type="text"
                                  placeholder="Customer's Name"
                                  className="input input-bordered input-sm w-full max-w-lg"
                                />
                              </div>
                            </div>
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Relation With Nominee <span>*</span>
                                  </span>
                                </label>
                                <input
                                  required
                                  {...register(
                                    `newCustomer[${index}].relation_with_nominee`
                                  )}
                                  name={`newCustomer[${index}].relation_with_nominee`}
                                  // {...register(`relation_with_nominee`)}
                                  // name={`relation_with_nominee`}
                                  type="text"
                                  placeholder="Nominee's Name"
                                  className="input input-bordered input-sm w-full max-w-lg"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-2 ">
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Nominee Contact Number <span>*</span>
                                  </span>
                                </label>
                                <input
                                  required
                                  {...register(
                                    `newCustomer[${index}].nominee_contact_number`
                                  )}
                                  name={`newCustomer[${index}].nominee_contact_number`}
                                  // {...register(`nominee_contact_number`)}
                                  // name={`nominee_contact_number`}
                                  type="text"
                                  placeholder="Nominee Contact Number"
                                  className="input input-bordered input-sm w-full max-w-lg"
                                />
                              </div>
                            </div>
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Nominee Gets <span>*</span>
                                  </span>
                                </label>
                                <input
                                  required
                                  {...register(
                                    `newCustomer[${index}].nominee_gets`
                                  )}
                                  name={`newCustomer[${index}].nominee_gets`}
                                  // {...register(`nominee_gets`)}
                                  // name={`nominee_gets`}
                                  type="text"
                                  placeholder="Nominee Gets"
                                  className="input input-bordered input-sm w-full max-w-lg"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-2 ">
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                <label className="label">
                                  <span className="label-text font-bold text-[#887d7d]">
                                    Nominee Address
                                  </span>
                                </label>
                                <textarea
                                  required
                                  {...register(
                                    `newCustomer[${index}].nominee_address`
                                  )}
                                  name={`newCustomer[${index}].nominee_address`}
                                  // {...register(`nominee_address`)}
                                  // name={`nominee_address`}
                                  className="textarea textarea-bordered"
                                  placeholder="Nominee Address"
                                ></textarea>
                              </div>
                            </div>
                            <div className="p-2">
                              <div className="form-control w-full max-w-lg">
                                {/* <div className="m-3">
                              {nomineeImage && (
                                <img
                                  alt=""
                                  srcSet=""
                                  width={"120px"}
                                  height={"150px"}
                                  src={URL.createObjectURL(nomineeImage)}
                                />
                              )}
                            </div> */}
                                <div>
                                  <label className="label">
                                    <span className="label-text font-bold text-[#887d7d]">
                                      Nominee Image (PP size 450 X 570){" "}
                                      <span>*</span>
                                    </span>
                                  </label>
                                  <input
                                    {...register(
                                      `newCustomer[${index}].nominee_image`
                                    )}
                                    name={`newCustomer[${index}].nominee_image`}
                                    // {...register(`nominee_image`)}
                                    // name={`nominee_image`}
                                    className="block w-full text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                    // onChange={(event) => {
                                    //   setNomineeImage(event.target.files[0]);
                                    // }}
                                    type="file"
                                    multiple="multiple"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              );
            })}

          {customerForm.length >= 2 ||
          newCustomerForm.length >= 2 ||
          newCustomerForm.length + customerForm.length >= 2 ? (
            <div className="grid grid-cols-2 gap-10">
              <h1
                onClick={handleCustomerFormAdd}
                className="uppercase w-full text-white text-center cursor-pointer text-[18px]  bg-[#16A085] rounded-md py-2.5 my-5 font-bold"
              >
                Add Another Customer
              </h1>
              <h1
                onClick={() => handleCustomerFormRemove(index)}
                className="uppercase w-full text-white text-center cursor-pointer text-[18px] font-bold bg-[#329e1d] rounded-md py-2.5 my-5"
              >
                Delete Customer Field
              </h1>
            </div>
          ) : (
            <div className="my-4">
              <h1
                onClick={handleCustomerFormAdd}
                className="uppercase w-full text-white text-center cursor-pointer text-[18px]  bg-[#16A085] rounded-md py-2.5 font-bold "
              >
                Add Another Customer
              </h1>
            </div>
          )}

          <div className="grid lg:grid-cols-2 gap-10 mt-10">
            <div>
              <fieldset className="border-2 rounded-md border-[#1F487C]">
                <legend className="mx-4">
                  <button className="bg-[#1F497D] text-[white] py-1 px-4 rounded-md">
                    {" "}
                    Media Information
                  </button>
                </legend>

                <div className="grid lg:grid-cols-3 m-4">
                  <div className="p-2">
                    <div className="form-control w-full max-w-lg">
                      <label className="label">
                        <span className="label-text font-bold text-[#887d7d]">
                          Booking Date <span>*</span>
                        </span>
                      </label>
                      <input
                        defaultValue={predata?.flat_or_plot[0]?.booking_date}
                        required
                        {...register("booking_date")}
                        name="booking_date"
                        type="date"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="form-control w-full max-w-lg">
                      <label className="label">
                        <span className="label-text font-bold text-[#887d7d]">
                          Media Name
                        </span>
                      </label>
                      <input
                        defaultValue={
                          predata?.flat_or_plot[0]?.customers[0]?.sold_item
                            ?.media_name
                        }
                        required
                        {...register("media_name")}
                        type="text"
                        placeholder="Media Name"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="form-control w-full max-w-lg">
                      <label className="label">
                        <span className="label-text font-bold text-[#887d7d]">
                          Media Phone Number
                        </span>
                      </label>
                      <input
                        defaultValue={
                          predata?.flat_or_plot[0]?.customers[0]?.sold_item
                            ?.media_phone_number
                        }
                        required
                        {...register("media_phone_number")}
                        type="text"
                        name="media_phone_number"
                        placeholder="media_phone_number"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div>
              <fieldset className="border-2 rounded-md border-[#1F487C]">
                <legend className="mx-4">
                  <span
                    id="building_btn"
                    onClick={buildingInfoBtnHandler}
                    className="mr-1 btn  text-[16px] p-3 hover:text-white"
                  >
                    Building Information
                  </span>
                  <span
                    id="land_btn"
                    onClick={landInfoBtnHandler}
                    className="mr-1 btn p-3 font-bold hover:bg-[#1F487C] hover:text-white bg-white text-[#1F487C] border-[#1F487C]"
                  >
                    Land Information
                  </span>
                </legend>
                <div id="building_select">
                  <div className="grid lg:grid-cols-2 m-4">
                    <div className="p-2">
                      <div className="form-control w-full max-w-lg">
                        <label className="label">
                          <span className="label-text font-bold text-[#887d7d]">
                            Select Building
                          </span>
                        </label>
                        <select
                          defaultValue={
                            predata && predata?.type == 1 && predata?.id
                          }
                          onChange={handleBuildingChange}
                          className="select select-bordered select-sm w-full max-w-lg"
                        >
                          <option
                            disabled
                            selected={
                              predata && predata?.type == 2 ? true : false
                            }
                          >
                            Select Building Name
                          </option>
                          {buildingList.map((list) => {
                            return (
                              <option
                                key={list.id}
                                value={list.id}
                                selected={
                                  list?.id == predata?.id ? true : false
                                }
                              >
                                {list.building_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="form-control w-full max-w-lg">
                        <label className="label">
                          <span className="label-text font-bold text-[#887d7d]">
                            Select Flat Number
                          </span>
                        </label>
                        <select
                          onBlur={(e) => {
                            e.preventDefault();
                            // console.log(e.target.value);
                            setFlatId(e.target.value);
                            setPlotId("");
                          }}
                          defaultValue={predata?.flat_or_plot[0]?.id}
                          // {...register("flat_id")}
                          // name="flat_id"
                          className="select select-bordered select-sm w-full max-w-lg"
                        >
                          <option value="">Select Flat Number/reset</option>
                          {flatList?.map((flist) => (
                            <option
                              key={flist?.id}
                              value={flist?.id}
                              selected={
                                flist?.id == predata?.flat_or_plot[0]?.id
                                  ? true
                                  : false
                              }
                            >
                              {flist?.flat_number}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="land_select">
                  <div className="grid lg:grid-cols-2 m-4">
                    <div className="p-2">
                      <div className="form-control w-full max-w-lg">
                        <label className="label">
                          <span className="label-text font-bold text-[#887d7d]">
                            Select Land Name
                          </span>
                        </label>
                        <select
                          defaultValue={
                            predata && predata?.type == 2 && predata?.id
                          }
                          onChange={handleLandChange}
                          className="select select-bordered select-sm w-full max-w-lg"
                        >
                          <option
                            disabled
                            selected={
                              predata && predata?.type == 1 ? true : false
                            }
                          >
                            Select Land Name
                          </option>
                          {landList?.map((lList) => (
                            <option
                              key={lList?.id}
                              value={lList.id}
                              selected={lList?.id == predata?.id ? true : false}
                            >
                              {lList.land_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="form-control w-full max-w-lg">
                        <label className="label">
                          <span className="label-text font-bold text-[#887d7d]">
                            Select Plot Number
                          </span>
                        </label>
                        <select
                          onBlur={(e) => {
                            e.preventDefault();
                            setPlotId(e.target.value);
                            setFlatId("");
                          }}
                          defaultValue={predata?.flat_or_plot[0]?.id}
                          // {...register("plot_number")}
                          // name="plot_number"
                          className="select select-bordered select-sm w-full max-w-lg"
                        >
                          <option value="">Select Plot Number/reset</option>
                          {plotList?.map((plist) => (
                            <option
                              key={plist?.id}
                              value={plist?.id}
                              selected={
                                plist?.id == predata?.flat_or_plot[0]?.id
                                  ? true
                                  : false
                              }
                            >
                              {plist?.plot_no}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>

          <div className="flex justify-end mx-16 my-8">
            {updateButtonDisable == true ? (
              <label className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 mb-10 m-2">
                Loading...
              </label>
            ) : (
              <button
                type="submit"
                className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 mb-10 m-2"
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateClientInfo;
