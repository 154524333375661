import Heading from "../../Shared/Heading";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import AddButton from "../../Shared/AddButton";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../BaseUrl";
import axios from "axios";
import Loader from "../../Shared/Loader";
import { useReactToPrint } from "react-to-print";

const ClientInfoHome = () => {
  const [projects, setProjects] = useState(null);
  useEffect(() => {
    axios
      .get(baseUrl + `/customer/information/view`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        setProjects(res?.data?.data?.data);
      });
  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // ---------------search table data start------------------
  const handleSearch = (e) => {
    // console.log(e.target.value);
    // Declare variables
    let status = 0;
    let filterBy = e.target.value.toUpperCase();
    let table = document.getElementById("dataTable");
    let tr = table.querySelectorAll(".searchData");
    let i = 0;
    let j = 0;
    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      let tempTd = tr[i].getElementsByTagName("td");
      for (j = 0; j < tempTd.length; j++) {
        let td = tempTd[j];
        let txtValue;
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filterBy) > -1) {
            status = 1;
          }
          if (!status) {
            status = 0;
          }
        }
      }
      if (status) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  };
  // ------------------search table data end-----------------
  return (
    <div>
      <Heading>Client Information</Heading>
      <div className="p-5 whiteBackground">
        <div className="flex flex-wrap justify-between my-5">
          <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
          <div className="my-2">
            <Link to="addclient">
              <AddButton>
                Add Customer <i className="fa-solid fa-user-plus mx-2"></i>
              </AddButton>
            </Link>
          </div>
        </div>
        <div>
          <div className="flex flex-wrap justify-end my-2">
            <input
              onChange={handleSearch}
              id="searchInput"
              type="text"
              placeholder="Search..."
              className="input input-bordered input-sm w-full max-w-xs mb-2"
            />
          </div>
          {projects == null ? (
            <Loader></Loader>
          ) : (
            <div className="overflow-x-auto max-h-[60vh]">
              <table
                id="dataTable"
                className="table table-zebra w-full"
                ref={componentRef}
              >
                <thead className="text-[#00CA08]">
                  <tr className="sticky top-0">
                    <th>
                      Customer <br /> Booking Date
                    </th>
                    <th>
                      Customer's <br /> Country
                    </th>
                    <th>
                      Customer's <br /> Name
                    </th>
                    <th>
                      Customer's <br /> Id
                    </th>
                    <th>
                      Customer's <br /> Address
                    </th>
                    <th>
                      Customer's <br /> Mobile No
                    </th>
                    <th>
                      Building/ <br /> Land No.
                    </th>
                    <th>File No.</th>
                    <th>
                      Flat/Plot <br /> No.
                    </th>
                    <th>
                      Flat/Plot <br /> Size
                    </th>
                    <th className="hide-when-print">
                      Contact <br /> Customer
                    </th>
                    <th className="hide-when-print">
                      Details <br /> Update
                    </th>
                    <th className="hide-when-print">View</th>
                    <th className="hide-when-print">
                      Extra <br /> Documents
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {projects?.length > 0 ? (
                    projects?.map((list, index) => {
                      return (
                        <tr
                          className="searchData"
                          key={
                            list?.building_or_land_no +
                            index +
                            Math.random().toFixed(3)
                          }
                        >
                          <td>{list?.booking_date}</td>
                          <td>
                            {list?.customers?.map((cus, index) => (
                              <h1
                                key={index + Math.random().toFixed(3)}
                                className="mb-1"
                              >
                                {cus?.country}
                              </h1>
                            ))}
                          </td>
                          <td>
                            {list?.customers?.map((cus, index) => (
                              <h1
                                key={index + Math.random().toFixed(3)}
                                className="mb-1"
                              >
                                {cus?.name}
                              </h1>
                            ))}
                          </td>
                          <td>
                            {list?.customers?.map((cus, index) => (
                              <h1 key={index + Math.random().toFixed(3)}>
                                {cus?.id}
                              </h1>
                            ))}
                          </td>
                          <td>
                            {list?.customers?.map((cus, index) => (
                              <h1 key={index + Math.random().toFixed(3)}>
                                {cus?.mailing_address
                                  ? cus?.mailing_address
                                  : "---"}
                              </h1>
                            ))}
                          </td>
                          <td>
                            {list?.customers?.map((cus, index) => (
                              <h1
                                key={index + Math.random().toFixed(3)}
                                className="mb-1"
                              >
                                {cus?.phone_number}
                              </h1>
                            ))}
                          </td>
                          <td>{list?.project?.project_no}</td>
                          <td>{list?.file_no}</td>
                          <td>
                            {list?.plot_no && list?.plot_no}{" "}
                            {list?.flat_number && list?.flat_number}{" "}
                          </td>
                          <td>{list?.size ? list?.size : "--"}</td>
                          <td className="text-center hide-when-print">
                            <i className="fa-brands fa-facebook-messenger m-2 text-xl"></i>
                            <i className="fa-brands fa-whatsapp m-2 text-xl"></i>
                          </td>
                          <td className="text-center hide-when-print">
                            <Link
                              to={`updateclient/${list?.id}/${list?.project?.type}/${list?.project_id}`}
                            >
                              <i className="fa-solid fa-pen-to-square m-2 text-xl"></i>
                            </Link>
                          </td>
                          <td className="text-center hide-when-print">
                            <Link
                              to={`viewclient/${list?.id}/${list?.project?.type}/${list?.project_id}`}
                            >
                              <i className="fa-solid fa-eye m-2 text-xl"></i>
                            </Link>
                          </td>
                          <td className="text-center hide-when-print">
                            <Link to={`clientinfofolder/${list?.id}`}>
                              <i className="fa-solid fa-folder m-2 text-xl"></i>
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <h1 className="text-center font-bold my-5 py-5">
                      No Data Found
                    </h1>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientInfoHome;
