import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { baseUrl } from "../../../BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";

const EditFlatDetails = () => {
  const { id } = useParams();
  const { flatId } = useParams();
  const [flat, setFlat] = useState({});

  useEffect(() => {
    axios
      .get(
        baseUrl + `/flat_or_plot/detail/${flatId}`,
     
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setFlat(res.data.details[0]);
      });
  }, [flatId]);

  const navigate = useNavigate();
  const handleFlatUpdate = (e) => {
    e.preventDefault();

    const project_id = id;
    const file_no = id;
    const flat_number = e.target.flat_number.value;
    const floor_no = e.target.flat_floor_no.value;
    const face_direction = e.target.flat_or_plot_face_direction.value;
    const size = e.target.flat_or_plot_size.value;
    let status = e.target.flat_or_plot_status.value;
    if (status == "Unsold") {
      status = 0;
    } else if (status == "Sold") {
      status = 1;
    }

    const data = {
      project_id,
      file_no,
      flat_number,
      floor_no,
      face_direction,
      size,
      status,
    };

    if (flat_number == "") {
      toast.error("Flat number required!");
      return;
    }
    if (floor_no == "") {
      toast.error("Floor no required!");
      return;
    }

    axios
      .post(baseUrl + `/flat_or_plot/update/${flatId}`, data, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success("Edit Flat successfully!");
          navigate(
            `/dashboard/buildingandflatdetails/viewflatsoldunsolddetails/${id}`
          );
        } else {
          res?.data?.message.forEach((m) => {
            toast.error(m);
          });
        }
      });
  };

  return (
    <div className="p-5 bg-white shadow-2xl w-[60%] rounded-lg mx-auto my-24">
      <h1 className="text-start text-2xl font-bold text-[#717A82] mx-2 mt-8 mb-4">
        Edit Flat Details
      </h1>
      <form onSubmit={handleFlatUpdate}>
        <div className="grid lg:grid-cols-4 gap-5">
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text">Flat Number</span>
            </label>
            {/* <select name="number" className="select select-bordered select-sm">
              <option disabled selected>
                {flat?.number}
              </option>
              <option>G+6</option>
              <option>G+7</option>
              <option>G+8</option>
              <option>G+9</option>
              <option>G+10</option>
            </select> */}
            <input
              name="flat_number"
              defaultValue={flat?.flat_number}
              type="text"
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text">Flat Floor No.</span>
            </label>
            <input
              name="flat_floor_no"
              defaultValue={flat?.flat_floor_no}
              type="text"
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>

          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text">Flat Face Direction</span>
            </label>
            <select
              name="flat_or_plot_face_direction"
              className="select select-bordered select-sm"
            >
              <option disabled selected>
                {flat?.flat_or_plot_face_direction}
              </option>
              <option value="East">East</option>
              <option value="West">West</option>
              <option value="North">North</option>
              <option value="South">South</option>
              <option value="North-East">North-East</option>
              <option value="South-East">South-East</option>
              <option value="North-West">North-West</option>
              <option value="South-West">South-West</option>
            </select>
          </div>
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text">Flat Size</span>
            </label>
            {/* <select name="size" className="select select-bordered select-sm">
              <option disabled selected>
                {flat?.size}
              </option>
              <option>1091 sqft</option>
              <option>701 sqft</option>
              <option>390 sqft</option>
            </select> */}
            <input
              name="flat_or_plot_size"
              defaultValue={flat?.flat_or_plot_size}
              type="text"
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text">Sell Status</span>
            </label>
            <select
              name="flat_or_plot_status"
              type="number"
              className="select select-bordered select-sm"
            >
              <option disabled selected value={flat.flat_or_plot_status}>
                {flat.flat_or_plot_status == "Unsold" ? "Unsold" : "Sold"}
              </option>
              <option value={1}>Sold</option>
              <option value={0}>Unsold</option>
            </select>
          </div>
        </div>

        <div className="flex flex-wrap justify-start mt-8">
          <Link
            to={`/dashboard/buildingandflatdetails/viewflatsoldunsolddetails/${id}`}
          >
            <button
              type="submit"
              className="uppercase  text-white text-[14px] font-semibold bg-[#E30B0B] rounded-md py-2.5 px-16 m-2"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 mb-10 m-2"
          >
            Update Flat
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditFlatDetails;
