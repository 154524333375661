import React, { useState } from "react";
import Heading from "../../Shared/Heading";
import { Link, useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../../BaseUrl";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const UpdateBuildingInformation = () => {
  const [buildingInfo, setBuildingInfo] = useState({});
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(
        baseUrl + `/projects/building/data/${id}`,
      
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setBuildingInfo(res.data.data);
      });
  }, [id]);

  const navigate = useNavigate();
  const handleBuildingUpdate = (e) => {
    e.preventDefault();

    const name = e.target.name.value;
    const road_no = e.target.road_no.value;
    const project_no = e.target.project_no.value;
    const face_direction = e.target.face_direction.value;
    const location = e.target.location.value;
    const total_number_of_floor = e.target.total_number_of_floor.value;
    const number_of_flat_or_plot = e.target.number_of_flat_or_plot.value;
    const work_start_date = e.target.work_start_date.value;
    const work_end_date = e.target.work_end_date.value;
    const work_complete_date = e.target.work_complete_date.value;

    const data = {
      name,
      road_no,
      project_no,
      face_direction,
      location,
      total_number_of_floor,
      number_of_flat_or_plot,
      work_start_date,
      work_end_date,
      work_complete_date,
    };

    axios
      .post(baseUrl + `/projects/building/update/${id}`, data, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success(res?.data?.message);
          navigate("/dashboard/buildingandflatdetails");
        } else {
          res?.data?.message.forEach((m) => {
            toast.error(m);
          });
        }
      });
  };

  return (
    <div>
      <Heading>
        <Link to="/dashboard/buildingandflatdetails">
          <i className="fa-solid fa-arrow-left"></i> Update Building Information
        </Link>
      </Heading>
      <div>
        <div className="p-5 bg-white shadow-2xl w-[64%] rounded-lg mx-auto my-8">
          <form onSubmit={handleBuildingUpdate}>
            <div className="flex">
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text text-[#717A82] text-md font-bold">
                    Building Name
                  </span>
                </label>
                <input
                  name="name"
                  type="text"
                  defaultValue={buildingInfo?.name}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
            </div>
            <div className="grid lg:grid-cols-4 gap-2">
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text text-[#717A82] text-md font-bold">
                    Road No.
                  </span>
                </label>
                <input
                  name="road_no"
                  type="text"
                  defaultValue={buildingInfo?.road_no}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text text-[#717A82] text-md font-bold">
                    Building Number
                  </span>
                </label>
                <input
                  name="project_no"
                  type="text"
                  defaultValue={buildingInfo?.id}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text text-[#717A82] text-md font-bold">
                    Building Face Direction
                  </span>
                </label>
                <select
                  name="face_direction"
                  className="select select-bordered select-sm"
                >
                  <option disabled selected>
                    {buildingInfo?.face_direction}
                  </option>
                  <option>East</option>
                  <option>West</option>
                  <option>North</option>
                  <option>South</option>
                  <option>North-East</option>
                  <option>South-East</option>
                  <option>North-West</option>
                  <option>South-West</option>
                </select>
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text text-[#717A82] text-md font-bold">
                    Building Location
                  </span>
                </label>
                <select
                  name="location"
                  className="select select-bordered select-sm"
                >
                  <option disabled selected>
                    {buildingInfo?.location}
                  </option>
                  <option>Moghbajar</option>
                  <option>Badda</option>
                  <option>Gulsan</option>
                  <option>Bonani</option>
                  <option>Mirpur</option>
                </select>
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text font-bold text-[#717A82]">
                    Total No. of Floor
                  </span>
                </label>
                <input
                  name="total_number_of_floor"
                  type="number"
                  defaultValue={buildingInfo?.total_number_of_floor}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text font-bold text-[#717A82]">
                    Total No. of Flat
                  </span>
                </label>
                <input
                  name="number_of_flat_or_plot"
                  type="number"
                  defaultValue={buildingInfo?.number_of_flat_or_plot}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text text-[#717A82] text-md font-bold">
                    Work Start Date
                  </span>
                </label>
                <input
                  name="work_start_date"
                  type="date"
                  defaultValue={buildingInfo?.work_start_date}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text text-[#717A82] text-md font-bold">
                    Work Complete Date
                  </span>
                </label>
                <input
                  name="work_end_date"
                  type="date"
                  defaultValue={buildingInfo?.work_end_date}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
              <div className="form-control w-full max-w-xs m-2">
                <label className="label">
                  <span className="label-text text-[#717A82] text-md font-bold">
                    Work Complete Extended Date
                  </span>
                </label>
                <input
                  name="work_complete_date"
                  type="date"
                  defaultValue={buildingInfo?.work_complete_date}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
            </div>

            <div className="flex flex-wrap justify-start mt-8">
              <Link to="/dashboard/buildingandflatdetails">
                <button
                  type="submit"
                  className="uppercase  text-white text-[14px] font-semibold bg-[#E30B0B] rounded-md py-2.5 px-16 m-2"
                >
                  Cancel
                </button>
              </Link>

              <button
                type="submit"
                className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 mb-10 m-2"
              >
                Update Building
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateBuildingInformation;
