import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl, baseUrlImg } from "../../../BaseUrl";
import useDownloader from "react-use-downloader";
import Loader from "../../Shared/Loader";
import "@react-pdf-viewer/core/lib/styles/index.css";

const CanceledCustomerDocuments = () => {
  const location = useLocation();
  const viewCancelCustomerData = location.state.data;
  // console.log(viewCancelCustomerData);
  
  const [numPage, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const { download } = useDownloader();
  const [folderNames, setFoldernames] = useState([]);
  const [reFetch, setReFetch] = useState(false);
  const [showAddFolderModal, setShowAddFolderModal] = useState(false);
  const [showAddFileModal, setShowAddFileModal] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [selectFolderId, setSelectFolderId] = useState(null);
  const [fileList, setFileList] = useState(null);
  const [activeFolderId, setActiveFolderId] = useState(null);

  const [addFolderButtonDisable, setAddFolderButtonDisable] = useState(false);
  const [addFileButtonDisable, setAddFileButtonDisable] = useState(false);

  const [modalImage, setModalImage] = useState(null);

  useEffect(() => {
    axios
      .get(
        baseUrl + `/customer/information/folder/list/${viewCancelCustomerData?.flat_or_plot?.id}`,

        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setFoldernames(res?.data?.data);
      });
  }, [reFetch]);

  useEffect(() => {
    if (selectFolderId !== null) {
      axios
        .get(
          baseUrl +
            `/customer/information/folder/document/list/${selectFolderId}`,

          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("crm-tdata")
              )}`,
              "content-type": "text/json",
            },
          }
        )
        .then((res) => {
          // console.log(res?.data?.data);
          setFileList(res?.data?.data);
        });
    }
  }, [selectFolderId, reFetch]);

  const handleAddFolder = (e) => {
    e.preventDefault();
    const form = e.target;
    const folderName = form.folderName.value;
    setAddFolderButtonDisable(true);
    axios
      .post(
        baseUrl + `/customer/information/folder/create`,
        {
          flat_or_plot_id: viewCancelCustomerData?.flat_or_plot?.id,
          name: folderName,
        },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        // console.log(res);
        if (res?.data?.status == "success") {
          toast.success("Folder added successfully!");
          setReFetch(!reFetch);
          setShowAddFolderModal(false);
        } else {
          toast.error("Canot add folder!");
        }
        setAddFolderButtonDisable(false);
      });
  };

  const handleAddFile = (e) => {
    e.preventDefault();
    setAddFileButtonDisable(true);

    const form = e.target;
    const document = form.fileName.files[0];
    const data = new FormData();
    const folder_id = selectFolderId;
    data.append("document", document);
    data.append("folder_id", folder_id);
    // console.log(document, folder_id);

    axios
      .post(baseUrl + `/customer/information/folder/document/store`, data, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
        },
      })
      .then((res) => {
        // console.log(res);
        if (res?.data?.status == "success") {
          toast.success("Document added successfully!");
          setReFetch(!reFetch);
          setShowAddFileModal(false);
          setAddFileButtonDisable(false);
        } else {
          toast.error("Canot add document!");
          setAddFileButtonDisable(false);
        }
      })
      .catch((res) => {
        // console.log(res?.message);
        toast.error(res?.message);
        setAddFileButtonDisable(false);
      });
  };
  return (
    <div>
      <div className="flex justify-between items-center p-8">
        {showFiles == false ? (
          <>
            <Link
              to={`/dashboard/canceledcustomerlist`}
              className="text-[#333547] font-bold text-[24px] cursor-pointer"
            >
              <i id="test" className="fa-solid fa-arrow-left"></i> Legal Paper
              Folder
            </Link>
            <label
              className="bg-[#16A085] block mr-36 py-[10px] px-[25px] text-white font-bold rounded-[4px] cursor-pointer"
              onClick={() => setShowAddFolderModal(true)}
              htmlFor="my-modal-3"
            >
              Add New Folder
            </label>
          </>
        ) : (
          <>
            <p
              onClick={() => {
                setShowFiles(false);
                setSelectFolderId(null);
                setActiveFolderId(null);
              }}
              className="text-[#333547] font-bold text-[24px] cursor-pointer"
            >
              <i id="test" className="fa-solid fa-arrow-left"></i> Legal Paper
              Files
            </p>
            <label
              className="bg-[#16A085] block mr-36 py-[10px] px-[25px] text-white font-bold rounded-[4px] cursor-pointer"
              onClick={() => setShowAddFileModal(true)}
              htmlFor="my-modal-4"
            >
              Add Legal Paper
            </label>
          </>
        )}
      </div>
      <div className="grid gap-[25px] grid-cols-2 md:grid-cols-3 lg:grid-cols-6  p-8">
        {folderNames.map((folder, index) => (
          <div
            key={index}
            className="flex flex-col w-fit"
            onClick={() => {
              setSelectFolderId(folder?.id);
              setShowFiles(true);

              if (activeFolderId == folder?.id) {
                setActiveFolderId(null);
                setShowFiles(false);
                setSelectFolderId(null);
                setFileList(null);
              } else {
                setActiveFolderId(folder?.id);
                setFileList(null);
              }
            }}
          >
            <div
              className={
                activeFolderId == folder?.id
                  ? "w-24 h-24 text-base-100 bg-slate-800 shadow-md text-center rounded-[4px]"
                  : "w-24 h-24 bg-base-100 shadow-md text-center rounded-[4px]"
              }
            >
              <i
                className={
                  activeFolderId == folder?.id
                    ? "fa-solid fa-folder m-5 text-5xl"
                    : "fa-solid fa-folder m-5 text-5xl"
                }
              ></i>
            </div>
            <h1 className="text-sm mt-2 font-bold text-center">
              {folder?.name}
            </h1>
          </div>
        ))}
      </div>

      {selectFolderId !== null && (
        <div className="flex flex-wrap p-8 gap-[60px]">
          {fileList == null ? (
            <Loader></Loader>
          ) : (
            <>
              {fileList?.length == 0 ? (
                <h2 className="text-lg font-bold text-red-600">
                  No document found
                </h2>
              ) : (
                fileList?.map((file, index) => {
                  return (
                    <div
                      key={index + file?.id}
                      style={{
                        boxShadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.25)",
                      }}
                      className="flex flex-col justify-between w-[230px]"
                    >
                      {file?.file_path.slice(
                        file?.file_path.length - 3,
                        file?.file_path.length
                      ) == "pdf" ||
                      file?.file_path.slice(
                        file?.file_path.length - 4,
                        file?.file_path.length
                      ) == "xlsx" ? (
                        <>
                          <a
                            href={baseUrlImg + "/" + file?.file_path}
                            target="_blank"
                          >
                            {file?.file_path.slice(
                              file?.file_path.length - 3,
                              file?.file_path.length
                            ) == "pdf" ? (
                              <img
                                src={
                                  "https://downloadr2.apkmirror.com/wp-content/uploads/2020/01/5e1a772a817e4.png"
                                }
                                alt=""
                                className="w-100 p-[10px] pb-0 h-full mx-auto"
                              />
                            ) : (
                              <img
                                src={
                                  "https://i.pinimg.com/originals/13/88/5f/13885f590c6070c7f106b0f19a17ab9b.png"
                                }
                                alt=""
                                className="w-100 p-[10px] pb-0 h-full mx-auto"
                              />
                            )}
                          </a>
                        </>
                      ) : (
                        <img
                          src={baseUrlImg + "/" + file?.file_path}
                          alt=""
                          className="w-100 p-[10px] pb-0 h-full"
                          onClick={() =>
                            setModalImage(baseUrlImg + "/" + file?.file_path)
                          }
                        />
                      )}

                      <button className="text-[#333547] flex gap-[8px] justify-between items-center p-[10px] bg-[#ffffff] shadow-t-lg">
                        <span className="text-[15px] font-[500] text-left">
                          {file?.file_name}
                        </span>{" "}
                        <a
                          href={baseUrlImg + "/" + file?.file_path}
                          target="_blank"
                        >
                          <i
                            onClick={() =>
                              download(
                                baseUrlImg + "/" + file?.file_path,
                                file?.filename
                              )
                            }
                            className="fa-solid fa-download text-[#16A085] w-[20px] h-[20px]"
                          ></i>
                        </a>
                      </button>
                    </div>
                  );
                })
              )}
            </>
          )}
        </div>
      )}

      {/* end file list */}

      {/*  start add folder modal */}
      {showAddFolderModal == true && (
        <>
          <input type="checkbox" id="my-modal-3" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-3"
                className="btn btn-sm btn-circle absolute right-2 top-2"
                onClick={() => setShowAddFolderModal(false)}
              >
                ✕
              </label>
              <h3 className="text-lg text-[#717A82] text-[16px] font-[700]">
                Add New Folder
              </h3>
              <form className="mt-[20px] w-[85%]" onSubmit={handleAddFolder}>
                <input
                  type="text"
                  placeholder="Enter folder name"
                  className="input input-bordered w-full"
                  name="folderName"
                  required
                />
                {addFolderButtonDisable == true ? (
                  <label
                    className=" bg-[#16A085] rounded-[4px] py-[10px] px-[65px] mt-[20px] border-none text-white text-[17px] font-[500] normal-case float-right"
                    disabled={addFolderButtonDisable}
                  >
                    Loading...
                  </label>
                ) : (
                  <button
                    type="submit"
                    className=" bg-[#16A085] rounded-[4px] py-[10px] px-[65px] mt-[20px] border-none text-white text-[17px] font-[500] normal-case float-right"
                    disabled={addFolderButtonDisable}
                  >
                    Submit
                  </button>
                )}
              </form>
            </div>
          </div>
        </>
      )}
      {/*  end add folder modal */}

      {/*  start add file modal */}
      {showAddFileModal == true && (
        <>
          <input type="checkbox" id="my-modal-4" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-3"
                className="btn btn-sm btn-circle absolute right-2 top-2"
                onClick={() => setShowAddFileModal(false)}
              >
                ✕
              </label>
              <h3 className="text-lg text-[#717A82] text-[16px] font-[700]">
                Upload Legal Papers (Size 806px X 1344px)
              </h3>
              <form className="mt-[20px] w-[85%]" onSubmit={handleAddFile}>
                <input
                  type="file"
                  placeholder="Enter folder name"
                  className="input input-bordered w-full h-full ml-0 pl-[2px]"
                  name="fileName"
                  required
                />
                {addFileButtonDisable == true ? (
                  <label
                    className=" bg-[#16A085] rounded-[4px] py-[10px] px-[65px] mt-[20px] border-none text-white text-[17px] font-[500] normal-case float-right"
                    disabled={addFileButtonDisable}
                  >
                    Loading...
                  </label>
                ) : (
                  <button
                    type="submit"
                    className=" bg-[#16A085] rounded-[4px] py-[10px] px-[65px] mt-[20px] border-none text-white text-[17px] font-[500] normal-case float-right"
                    disabled={addFileButtonDisable}
                  >
                    Submit
                  </button>
                )}
              </form>
            </div>
          </div>
        </>
      )}
      {/*  end add file modal */}

      {/*  */}
      {modalImage && (
        <div
          id="modal-img"
          // onClick={() => setModalImage(null)}
          className="fixed top-0 left-0 bg-slate-500 bg-opacity-[0.8] w-[100%] h-[100%] flex items-center justify-center"
        >
          <div className="w-[1200px] h-auto bg-slate-800 text-white rounded-[8px]">
            <div>
              <div className="text-right mr-2 mt-2">
                <i
                  onClick={() => setModalImage(null)}
                  className="fa fa-times text-lg bg-red-600 p-2 py-0 rounded-[8px] cursor-pointer"
                  aria-hidden="true"
                ></i>
              </div>
              <div className="flex justify-center items-center pb-2">
                <div
                  className="p-4 flex justify-center items-center container relative w-fit"
                  id="img-body"
                >
                  <img
                    src={modalImage}
                    alt=""
                    className="img image max-h-[90vh] relative"
                  />
                  <div className="overlay bg-slate-600 bg-opacity-40">
                    <div className="icon">
                      <a href={modalImage} target="_blank">
                        <i
                          className="fa fa-link bg-green-700 p-5 rounded-full cursor-pointer"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CanceledCustomerDocuments;
