import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import avatar from "../../assets/avatar.png";
import "./Navbar.css";

const Navbar = () => {
  const navigate = useNavigate();
  const handleLogOut = () => {
    localStorage.removeItem("crm-tdata");
    if (
      JSON.parse(localStorage.getItem("crm-tdata")) == undefined ||
      JSON.parse(localStorage.getItem("crm-tdata")) == "" ||
      JSON.parse(localStorage.getItem("crm-tdata")) == null
    ) {
      toast.success("Logout successfully!");
      navigate("/login");
    }
  };
  return (
    <div className="navbar">
      <div className="flex-1">
        <h1 className="normal-case">Shapla City Limited</h1>
      </div>
      <div className="flex-none">
        <div className="dropdown dropdown-end">
          <label
            tabIndex="1"
            htmlFor="dashboard-sidebar"
            className="btn btn-ghost lg:hidden btn-circle drawer-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
          </label>
          <label tabIndex="0" className="btn btn-ghost btn-circle avatar">
            <div className=" mr-3 rounded-full border-[2px] border-slate-400 pt-1">
              <img src={avatar} alt="" />
            </div>
          </label>
          <ul
            tabIndex="0"
            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-[#333547] rounded-[8px] w-52"
          >
            <li>
              <a>Profile</a>
            </li>
            <li>
              <a>Settings</a>
            </li>
            {JSON.parse(localStorage.getItem("crm-tdata")) == null ||
            JSON.parse(localStorage.getItem("crm-tdata")) == "" ? (
              <li>
                <Link to="/">Login</Link>
              </li>
            ) : (
              <li onClick={handleLogOut}>
                <a className="text-red-400">Logout</a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
