import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import Dashboard from "./Pages/Home/Dashboard";
import ClientInfo from "./Pages/Home/ClientIformation/ClientInfo";
import DashboardHome from "./Pages/Home/DashboardHome";
import ClientPriceInfo from "./Pages/Home/ClientPriceInformation/ClientPriceInfo";
import ClientPaymentList from "./Pages/Home/ClientPaymentList/ClientPaymentList";
import ClientDueAmmount from "./Pages/Home/ClientDueAmountDetails/ClientDueAmmount";
import SearchPopertyStatus from "./Pages/Home/SearchByPropertyRegistrationStatus/SearchPopertyStatus";
import BuildingAndFlatDetails from "./Pages/Home/BuildingAndFlatDetails/BuildingAndFlatDetails";
import Login from "./Pages/Login/Login";
import AddClient from "./Pages/Home/ClientIformation/AddClient";
import ClientInfoHome from "./Pages/Home/ClientIformation/ClientInfoHome";
import ClientPaymentListHome from "./Pages/Home/ClientPaymentList/ClientPaymentListHome";
import UpdateClientInfo from "./Pages/Home/ClientIformation/UpdateClientInfo";
import ViewClient from "./Pages/Home/ClientIformation/ViewClient";
import ClientInfoFolder from "./Pages/Home/ClientIformation/ClientInfoFolder";
import NotFoundPage from "./Pages/Error/NotFoundPage";
import ClientPriceInfoHome from "./Pages/Home/ClientPriceInformation/ClientPriceInfoHome";
import AddFlatPrice from "./Pages/Home/ClientPriceInformation/AddFlatPrice";
import UpdateFlatPrice from "./Pages/Home/ClientPriceInformation/UpdateFlatPrice";
import ViewFlatClientPrice from "./Pages/Home/ClientPriceInformation/ViewFlatClientPrice";
import ViewClientPaymentDetails from "./Pages/Home/ClientPaymentList/ViewClientPaymentDetails";
import ClientPaymentMoneyReceiptFolder from "./Pages/Home/ClientPaymentList/ClientPaymentMoneyReceiptFolder";
import ClientDueAmountHome from "./Pages/Home/ClientDueAmountDetails/ClientDueAmountHome";
import RegistrationClientSearch from "./Pages/Home/RegistrationDetailsClientSearch/RegistrationClientSearch";
import BuildingAndFlatDetailHome from "./Pages/Home/BuildingAndFlatDetails/BuildingAndFlatDetailHome";
import AddBuildingInformation from "./Pages/Home/BuildingAndFlatDetails/AddBuildingInformation";
import AddFlatInformation from "./Pages/Home/BuildingAndFlatDetails/AddFlatInformation";
import UpdateBuildingInformation from "./Pages/Home/BuildingAndFlatDetails/UpdateBuildingInformation";
import ViewBuildingAndFlatDetails from "./Pages/Home/BuildingAndFlatDetails/ViewBuildingAndFlatDetails";
import ViewFlatSoldUnsoldDetails from "./Pages/Home/BuildingAndFlatDetails/ViewFlatSoldUnsoldDetails";
import EditFlatDetails from "./Pages/Home/BuildingAndFlatDetails/EditFlatDetails";
import ViewFlatSoldUnsoldDetailsHome from "./Pages/Home/BuildingAndFlatDetails/ViewFlatSoldUnsoldDetailsHome";
// import AddFile from "./Pages/Home/AddFile";
import AddCustomer from "./Pages/Home/ClientIformation/AddCustomer";
import AddPlotPrice from "./Pages/Home/ClientPriceInformation/AddPlotPrice";
import UpdatePlotPrice from "./Pages/Home/ClientPriceInformation/UpdatePlotPrice";
import RegistrationAmountDetails from "./Pages/Home/RegistrationAmountDetails/RegistrationAmountDetails";
import RegistrationAmountDetailsHome from "./Pages/Home/RegistrationAmountDetails/RegistrationAmountDetailsHome";
import AddPriceAmount from "./Pages/Home/RegistrationAmountDetails/AddPriceAmount";
import UpdatePriceAmount from "./Pages/Home/RegistrationAmountDetails/UpdatePriceAmount";
import ViewStatus from "./Pages/Home/RegistrationAmountDetails/ViewStatus";
import AddPaymentReceived from "./Pages/Home/RegistrationAmountDetails/AddPaymentReceived";
import UpdatePaymentReceived from "./Pages/Home/RegistrationAmountDetails/UpdatePaymentReceived";
import RegistrationNumberDetails from "./Pages/Home/RegistrationNumberDetails/RegistrationNumberDetails";
import PlotFlatDetails from "./Pages/Home/RegistrationNumberDetails/PlotFlatDetails";
import MutationDetails from "./Pages/Home/RegistrationNumberDetails/MutationDetails";
import PowerAttorneyDetails from "./Pages/Home/RegistrationNumberDetails/PowerAttorneyDetails";
import ViewPlotCustomer from "./Pages/Home/RegistrationNumberDetails/ViewPlotCustomer";
import ViewMutationCustomer from "./Pages/Home/RegistrationNumberDetails/ViewMutationCustomer";
import ViewPowerAttorneyCustomer from "./Pages/Home/RegistrationNumberDetails/ViewPowerAttorneyCustomer";
import PlotSearch from "./Pages/Home/RegistrationDetailsClientSearch/PlotSearch";
import MutationSearch from "./Pages/Home/RegistrationDetailsClientSearch/MutationSearch";
import PowerAttorneySearch from "./Pages/Home/RegistrationDetailsClientSearch/PowerAttorneySearch";
import AddLandInformation from "./Pages/Home/BuildingAndFlatDetails/AddLandInformation";
import UpdateLandInformations from "./Pages/Home/BuildingAndFlatDetails/UpdateLandInformations";
import AddPlotInformation from "./Pages/Home/BuildingAndFlatDetails/AddPlotInformation";
import ViewPlotSoldUnsoldDetailsHome from "./Pages/Home/BuildingAndFlatDetails/ViewPlotSoldUnsoldDetailsHome";
import EditPlotDetails from "./Pages/Home/BuildingAndFlatDetails/EditPlotDetails";
import ViewPlotSoldUnsoldDetails from "./Pages/Home/BuildingAndFlatDetails/ViewPlotSoldUnsoldDetails";
import ViewBuildingAndPlotDetails from "./Pages/Home/BuildingAndFlatDetails/ViewBuildingAndPlotDetails";
import ViewPlotClientPrice from "./Pages/Home/ClientPriceInformation/ViewPlotClientPrice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./routers/Private/PrivateRoute";
import { useEffect } from "react";
import AdditionalAmountPaymentCustomerList from "./Pages/Home/Reports/AdditionalAmountPaymentCustomerList";
import InstallmentDueList from "./Pages/Home/Reports/InstallmentDueList";
import DownpaymentDueList from "./Pages/Home/Reports/DownpaymentDueList";
import AdditionalAmountDueList from "./Pages/Home/Reports/AdditionalAmountDueList";
import UtilityChargeAndCarParkingPaymentDueList from "./Pages/Home/Reports/UtilityChargeAndCarParkingPaymentDueList";
import RegistrationAndMutationDueList from "./Pages/Home/Reports/RegistrationAndMutationDueList";
import RegistrationCompleteButPaymentDueList from "./Pages/Home/Reports/RegistrationCompleteButPaymentDueList";
import CanceledCustomerList from "./Pages/Home/Reports/CanceledCustomerList";
import AfterRegistrationCanceledCustomerList from "./Pages/Home/Reports/AfterRegistrationCanceledCustomerList";
import AddPaymentSlip from "./Pages/Home/Reports/AddPaymentSlip";
import AddCancelApplication from "./Pages/Home/Reports/AddCancelApplication";
import UpdateCancelApplication from "./Pages/Home/Reports/UpdateCancelApplication";
import CanceledPaymentStatement from "./Pages/Home/Reports/CanceledPaymentStatement";
import ViewAllPaymentSlip from "./Pages/Home/Reports/ViewAllPaymentSlip";
import SinglePaymentSlip from "./Pages/Home/Reports/SinglePaymentSlip";
import ViewCanceledFileInformation from "./Pages/Home/Reports/ViewCanceledFileInformation";
import AddAfterRegistrationCanceledApplication from "./Pages/Home/Reports/AddAfterRegistrationCanceledApplication";
import AddRegistrationPaymentSlip from "./Pages/Home/Reports/AddRegistrationPaymentSlip";
import AddBuyBackDetails from "./Pages/Home/Reports/AddBuyBackDetails";
import UpdateCanceledApplicationAfterRegi from "./Pages/Home/Reports/UpdateCanceledApplicationAfterRegi";
import ViewAfterRegiCanceledFileInfo from "./Pages/Home/Reports/ViewAfterRegiCanceledFileInfo";
import AfterRegiCanceledPaymentStatement from "./Pages/Home/Reports/AfterRegiCanceledPaymentStatement";
import ViewAfterRegiAllPaymentSlip from "./Pages/Home/Reports/ViewAfterRegiAllPaymentSlip";
import SinglePaymentSlipAfterRegi from "./Pages/Home/Reports/SinglePaymentSlipAfterRegi";
import UpdateBuyBackDetails from "./Pages/Home/Reports/UpdateBuyBackDetails";
import CanceledCustomerDocuments from "./Pages/Home/Reports/CanceledCustomerDocuments";
function App() {
  const navigate = useNavigate();
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("crm-tdata"));
    if (token === null || token === undefined || token === "") {
      navigate("/login");
    }
  }, [navigate]);
  return (
    <div className="max-w-[2220px] mx-auto">
      <Routes>
        <Route path="/" element={<Login></Login>}></Route>
        <Route path="/login" element={<Login></Login>}></Route>
        <Route path="dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>}>
          <Route index element={<DashboardHome></DashboardHome>}></Route>
          {/* <Route path="addfile" element={<AddFile></AddFile>}></Route> */}
          <Route path="addcustomer" element={<AddCustomer></AddCustomer>}></Route>
          <Route path="clientinfo" element={<ClientInfo></ClientInfo>}>
            <Route index element={<ClientInfoHome></ClientInfoHome>}></Route>
            <Route path="addclient" element={<AddClient></AddClient>}></Route>
            <Route path="updateclient/:flat_or_plot_id/:project_type/:project_id" element={<UpdateClientInfo></UpdateClientInfo>}></Route>
            <Route path="viewclient/:flat_or_plot_id/:project_type/:project_id" element={<ViewClient></ViewClient>}></Route>
            <Route path="clientinfofolder/:Id" element={<ClientInfoFolder></ClientInfoFolder>}></Route>
          </Route>
          <Route path="clientpriceinfo" element={<ClientPriceInfo></ClientPriceInfo>}>
            <Route index element={<ClientPriceInfoHome></ClientPriceInfoHome>}></Route>
            <Route path="addflatprice/:clientId" element={<AddFlatPrice></AddFlatPrice>}></Route>
            <Route path="updateflatprice/:clientId" element={<UpdateFlatPrice></UpdateFlatPrice>}></Route>
            <Route path="addplotprice/:clientId" element={<AddPlotPrice></AddPlotPrice>}></Route>
            <Route path="updateplotprice/:clientId" element={<UpdatePlotPrice></UpdatePlotPrice>}></Route>
            <Route path="viewflatclientprice/:clientId" element={<ViewFlatClientPrice></ViewFlatClientPrice>}></Route>
            <Route path="viewplotclientprice/:clientId" element={<ViewPlotClientPrice></ViewPlotClientPrice>}></Route>
          </Route>
          <Route path="clientpaymentlist" element={<ClientPaymentList></ClientPaymentList>}>
            <Route index element={<ClientPaymentListHome></ClientPaymentListHome>}></Route>
            <Route path="viewclientpaymentdetails/:id" element={<ViewClientPaymentDetails></ViewClientPaymentDetails>}></Route>
            <Route path="clientpaymentmoneyreceiptfolder/:Id" element={<ClientPaymentMoneyReceiptFolder></ClientPaymentMoneyReceiptFolder>}></Route>
          </Route>
          <Route path="clientdueamount" element={<ClientDueAmmount></ClientDueAmmount>}>
            <Route index element={<ClientDueAmountHome></ClientDueAmountHome>}></Route>
          </Route>
          <Route path="registrationamount" element={<RegistrationAmountDetails></RegistrationAmountDetails>}>
            <Route index element={<RegistrationAmountDetailsHome></RegistrationAmountDetailsHome>}></Route>
            <Route path="addpriceamount" element={<AddPriceAmount></AddPriceAmount>}></Route>
            <Route path="updatepriceamount" element={<UpdatePriceAmount></UpdatePriceAmount>}></Route>
            <Route path="addpaymentreceived" element={<AddPaymentReceived></AddPaymentReceived>}></Route>
            <Route path="updatepaymentreceived" element={<UpdatePaymentReceived></UpdatePaymentReceived>}></Route>
            <Route path="viewstatus" element={<ViewStatus></ViewStatus>}></Route>
          </Route>
          <Route path="searchpropertystatus" element={<SearchPopertyStatus></SearchPopertyStatus>}></Route>
          <Route path="registrationnumberdetails" element={<RegistrationNumberDetails></RegistrationNumberDetails>}>
            <Route index element={<PlotFlatDetails></PlotFlatDetails>}></Route>
            <Route path="mutation" element={<MutationDetails></MutationDetails>}></Route>
            <Route path="powerattorney" element={<PowerAttorneyDetails></PowerAttorneyDetails>}></Route>
          </Route>
          <Route path="registrationnumberdetails/viewplotcustomer/:id" element={<ViewPlotCustomer></ViewPlotCustomer>}></Route>
          <Route path="registrationnumberdetails/mutation/viewmutationcustomer/:id" element={<ViewMutationCustomer></ViewMutationCustomer>}></Route>
          <Route path="registrationnumberdetails/powerattorney/viewpowerattorneycustomer/:id" element={<ViewPowerAttorneyCustomer></ViewPowerAttorneyCustomer>}></Route>
          <Route path="registrationclientsearch" element={<RegistrationClientSearch></RegistrationClientSearch>}>
            <Route index element={<PlotSearch></PlotSearch>}></Route>
            <Route path="mutationsearch" element={<MutationSearch></MutationSearch>}></Route>
            <Route path="powerattorneysearch" element={<PowerAttorneySearch></PowerAttorneySearch>}></Route>
          </Route>
          <Route path="buildingandflatdetails" element={<BuildingAndFlatDetails></BuildingAndFlatDetails>}>
            <Route index element={<BuildingAndFlatDetailHome />}></Route>
            <Route path="addbuildinginformation" element={<AddBuildingInformation></AddBuildingInformation>}></Route>
            <Route path="addlandinformation" element={<AddLandInformation></AddLandInformation>}></Route>
            <Route path="updatebuildinginformation/:id" element={<UpdateBuildingInformation></UpdateBuildingInformation>}></Route>
            <Route path="updatelandinformation/:id" element={<UpdateLandInformations></UpdateLandInformations>}></Route>
            <Route path="addflatinformation/:id" element={<AddFlatInformation></AddFlatInformation>}></Route>
            <Route path="addplotinformation/:id" element={<AddPlotInformation></AddPlotInformation>}></Route>
            <Route path="buildingflatcustomerdetails/:id" element={<ViewBuildingAndFlatDetails></ViewBuildingAndFlatDetails>}></Route>
            <Route path="buildingplotcustomerdetails/:id" element={<ViewBuildingAndPlotDetails></ViewBuildingAndPlotDetails>}></Route>
            <Route path="viewflatsoldunsolddetails/:id" element={<ViewFlatSoldUnsoldDetails></ViewFlatSoldUnsoldDetails>}>
              <Route index element={<ViewFlatSoldUnsoldDetailsHome></ViewFlatSoldUnsoldDetailsHome>}></Route>
              <Route path="editflatdetails/:flatId" element={<EditFlatDetails></EditFlatDetails>} ></Route>
            </Route>
            <Route path="viewplotsoldunsolddetails/:id" element={<ViewPlotSoldUnsoldDetails></ViewPlotSoldUnsoldDetails>}>
              <Route index element={<ViewPlotSoldUnsoldDetailsHome></ViewPlotSoldUnsoldDetailsHome>}></Route>
              <Route path="editplotdetails/:plotId" element={<EditPlotDetails></EditPlotDetails>}></Route>
            </Route>
          </Route>
          {/* //reports routes start*/}
          <Route path="additionalamountpaymentcustomerlist" element={<AdditionalAmountPaymentCustomerList></AdditionalAmountPaymentCustomerList>}></Route>
          <Route path="installmentduelist" element={<InstallmentDueList></InstallmentDueList>}></Route>
          <Route path="downpaymentduelist" element={<DownpaymentDueList></DownpaymentDueList>}></Route>
          <Route path="additionalamountduelist" element={<AdditionalAmountDueList></AdditionalAmountDueList>}></Route>
          <Route path="utilitychargeandcarparkingpaymentduelist" element={<UtilityChargeAndCarParkingPaymentDueList></UtilityChargeAndCarParkingPaymentDueList>}></Route>
          <Route path="registrationandmutationduelist" element={<RegistrationAndMutationDueList></RegistrationAndMutationDueList>}></Route>
          <Route path="registrationcompletebutpaymentduelist" element={<RegistrationCompleteButPaymentDueList></RegistrationCompleteButPaymentDueList>}></Route>
          <Route path="canceledcustomerlist" element={<CanceledCustomerList></CanceledCustomerList>}></Route>
          <Route path="addcanceleapplication" element={<AddCancelApplication></AddCancelApplication>}></Route>
          <Route path="updatecanceleapplication" element={<UpdateCancelApplication></UpdateCancelApplication>}></Route>
          <Route path="viewcanceledfileinformation" element={<ViewCanceledFileInformation></ViewCanceledFileInformation>}></Route>
          <Route path="addpaymentslip" element={<AddPaymentSlip></AddPaymentSlip>}></Route>
          <Route path="canceledpaymentstatement" element={<CanceledPaymentStatement></CanceledPaymentStatement>}></Route>
          <Route path="viewallpaymentslip" element={<ViewAllPaymentSlip></ViewAllPaymentSlip>}></Route>
          <Route path="singlepaymentslip" element={<SinglePaymentSlip></SinglePaymentSlip>}></Route>
          <Route path="canceledcustomerdocuments" element={<CanceledCustomerDocuments></CanceledCustomerDocuments>}></Route>
          <Route path="afterregistrationcanceledcustomerlist" element={<AfterRegistrationCanceledCustomerList></AfterRegistrationCanceledCustomerList>}></Route>
          <Route path="addafterregistrationcanceledapplication" element={<AddAfterRegistrationCanceledApplication></AddAfterRegistrationCanceledApplication>}></Route>
          <Route path="updatecanceledapplicationafterregistration" element={<UpdateCanceledApplicationAfterRegi></UpdateCanceledApplicationAfterRegi>}></Route>
          <Route path="viewafterregicanceledfileinfo" element={<ViewAfterRegiCanceledFileInfo></ViewAfterRegiCanceledFileInfo>}></Route>
          <Route path="addregistrationpaymentslip" element={<AddRegistrationPaymentSlip></AddRegistrationPaymentSlip>}></Route>
          <Route path="addbuybackdetails" element={<AddBuyBackDetails></AddBuyBackDetails>}></Route>
          <Route path="updatebuybackdetails" element={<UpdateBuyBackDetails></UpdateBuyBackDetails>}></Route>
          <Route path="afterregicanceledpaymentstatement" element={<AfterRegiCanceledPaymentStatement></AfterRegiCanceledPaymentStatement>}></Route>
          <Route path="viewafterregiallpaymentslip" element={<ViewAfterRegiAllPaymentSlip></ViewAfterRegiAllPaymentSlip>}></Route>
          <Route path="singlepaymentslipafterregi" element={<SinglePaymentSlipAfterRegi></SinglePaymentSlipAfterRegi>}></Route>
          {/* //reports routes end*/}
        </Route>
        <Route path="*" element={<NotFoundPage></NotFoundPage>}></Route>
      </Routes>

      <ToastContainer />
    </div>
  );
}

export default App;
