import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../BaseUrl";
import Heading from "../../Shared/Heading";

const AddPlotInformation = () => {
  const [buildingInfo, setBuildingInfo] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        baseUrl + `/projects/land/data/${id}`,
        
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setBuildingInfo(res?.data?.data);
      });
  }, [id]);

  const { register, handleSubmit } = useForm();
  const onsubmit = (data) => {
    let newData = { ...data, project_id: id, type: 2 };

    axios
      .post(baseUrl + `/flat_or_plot/store`, newData, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success(res?.data?.message);
          navigate(
            `/dashboard/buildingandflatdetails/viewplotsoldunsolddetails/${id}`
          );
        } else {
          if (Array.isArray(res?.data?.message)) {
            res?.data?.message?.map((data) => toast.error(data));
          } else {
            toast.error(res?.data?.message);
          }
        }
      });
  };
  return (
    <div>
      <Heading>
        <Link to="/dashboard/buildingandflatdetails">
          <i className="fa-solid fa-arrow-left"></i> Add Plot Information
        </Link>
      </Heading>
      <div className="p-5 bg-white shadow-2xl w-[64%] rounded-lg mx-auto my-8">
        <div className="flex">
          <div className="form-control w-full max-w-sm m-2">
            <label className="label">
              <span className="label-text text-[#717A82] text-md font-bold">
                Project Name
              </span>
            </label>
            <input
              disabled
              type="text"
              value={buildingInfo?.name}
              className="input input-bordered input-sm w-full max-w-sm"
            />
          </div>
        </div>
        <div className="grid lg:grid-cols-4 gap-2">
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text text-[#717A82] text-md font-bold">
                Road No.
              </span>
            </label>
            <input
              disabled
              type="text"
              value={buildingInfo?.road_no}
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text text-[#717A82] text-md font-bold">
                Land No.
              </span>
            </label>
            <input
              disabled
              type="text"
              value={buildingInfo?.id}
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text text-[#717A82] text-md font-bold">
                Land Face Direction
              </span>
            </label>
            <input
              disabled
              type="text"
              value={buildingInfo?.face_direction}
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
          <div className="form-control w-full max-w-xs m-2">
            <label className="label">
              <span className="label-text text-[#717A82] text-md font-bold">
                Land Location
              </span>
            </label>
            <input
              disabled
              type="text"
              value={buildingInfo?.location}
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
        </div>

        <h1 className="text-start text-2xl font-bold underline underline-offset-4 text-[#717A82] mx-2 mt-8">
          Add Plot Details
        </h1>

        <form onSubmit={handleSubmit(onsubmit)}>
          <div className="grid lg:grid-cols-3 gap-2">
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text text-[#717A82] text-md font-bold">
                  File No.
                </span>
              </label>
              <input
                {...register("file_no")}
                type="text"
                name="file_no"
                placeholder="file_no"
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text text-[#717A82] text-md font-bold">
                  Plot No.
                </span>
              </label>
              <input
                {...register("plot_no")}
                type="text"
                name="plot_no"
                placeholder="plot_no"
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>

            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text text-[#717A82] text-md font-bold">
                  Plot Face Direction
                </span>
              </label>
              <select
                {...register("face_direction")}
                className="select select-bordered select-sm"
              >
                <option value={""}>Select</option>
                <option value={"East"}>East</option>
                <option value={"West"}>West</option>
                <option value={"North"}>North</option>
                <option value={"South"}>South</option>
                <option value={"North-East"}>North-East</option>
                <option value={"South-East"}>South-East</option>
                <option value={"North-West"}>North-West</option>
                <option value={"South-West"}>South-West</option>
              </select>
            </div>
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text text-[#717A82] text-md font-bold">
                  Plot Size
                </span>
              </label>
              <input
                {...register("size")}
                type="text"
                name="size"
                placeholder="size"
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
          </div>

          <div className="flex flex-wrap justify-start mt-8">
            <Link to="/dashboard/buildingandflatdetails">
              <button
                type="submit"
                className="uppercase  text-white text-[14px] font-semibold bg-[#E30B0B] rounded-md py-2.5 px-16 m-2"
              >
                Cancel
              </button>
            </Link>
            {/* <Link to="/dashboard/buildingandflatdetails"> */}
            <button
              type="submit"
              className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 mb-10 m-2"
            >
              Add Plot
            </button>
            {/* </Link> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPlotInformation;
