import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Heading from "../../Shared/Heading";

const RegistrationNumberDetails = () => {
  const [active, setActive] = useState("plot");
  const location = useLocation();
  useEffect(() => {
    if (location?.pathname.split("/")[3] == undefined) {
      setActive("plot");
    } else if (location?.pathname.split("/")[3] == "mutation") {
      setActive("mutation");
    } else if (location?.pathname.split("/")[3] == "powerattorney") {
      setActive("power");
    } else {
      ///
    }
  }, [location]);
  return (
    <div>
      <Heading>Registration Number Details</Heading>
      <div className="px-5 whiteBackground">
        <div className="flex flex-wrap flex-row my-5">
          <div className="basis-1/10 my-2">
            <Link to="/dashboard/registrationnumberdetails">
              <button
                onClick={() => setActive("plot")}
                className={`${
                  active == "plot"
                    ? "btn btn-sm mr-2"
                    : "btn btn-sm btn-outline mr-2"
                }`}
              >
                Plot/Flat Details
              </button>
            </Link>
          </div>
          <div className="basis-2/10 my-2">
            <Link to="/dashboard/registrationnumberdetails/mutation">
              <button
                onClick={() => setActive("mutation")}
                className={`${
                  active == "mutation"
                    ? "btn btn-sm mr-2"
                    : "btn btn-sm btn-outline mr-2"
                }`}
              >
                Mutation Details
              </button>
            </Link>
          </div>
          <div className="basis-2/10 my-2">
            <Link to="/dashboard/registrationnumberdetails/powerattorney">
              <button
                onClick={() => setActive("power")}
                className={`${
                  active == "power"
                    ? "btn btn-sm mr-2"
                    : "btn btn-sm btn-outline mr-2"
                }`}
              >
                Power of Attorney Details
              </button>
            </Link>
          </div>
        </div>
        <Outlet></Outlet>
      </div>
    </div>
  );
};

export default RegistrationNumberDetails;
