import { Link, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Heading from "../../Shared/Heading";
import userImg from "../../../assets/pp.png";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import axios from "axios";
import { baseUrl, baseUrlImg } from "../../../BaseUrl";
import { useReactToPrint } from "react-to-print";

const ViewClient = () => {
  const { flat_or_plot_id, project_type, Id, project_id } = useParams();
  const [data, setData] = useState(null);
  useEffect(() => {
    axios
      .post(
        baseUrl + `/customer/information/details`,
        {
          flat_or_plot_id,
          project_type,
          project_id,
        },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setData(res?.data?.data);
        // console.log(res?.data?.data);
      });
  }, [flat_or_plot_id, project_type, project_id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // console.log(data);
  return (
    <div>
      <Heading>
        <Link to="/dashboard/clientinfo">
          <i className="fa-solid fa-arrow-left"></i> View Customer Information
        </Link>
      </Heading>
      <div className="information_container">
        <div className="flex flex-wrap justify-start my-5">
          <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
          <p to={`/dashboard/clientinfo/clientinfofolder/${data?.id}`}>
            <button className="btn btn-sm m-2">Legal Paper Folder</button>
          </p>
          <button disabled className="btn btn-sm m-2 bg-slate-800">
            <span className="text-slate-800 text-xs normal-case ">
              {`Media Name: ${data?.flat_or_plot[0]?.customers[0]?.sold_item?.media_name} | Media Phone Number: ${data?.flat_or_plot[0]?.customers[0]?.sold_item?.media_phone_number}`}
            </span>
          </button>
        </div>
      </div>
      <div
        className="gap-5 my-12 mx-5 max-w-[900px] print-section"
        ref={componentRef}
      >
        {data &&
          data?.flat_or_plot[0]?.customers.map((perFlatOrPlot, index) => (
            <>
              <div className="infomation_content">
                {index == 0 && (
                  <>
                    <h1 className="text-center text-2xl font-bold underline underline-offset-4 text-[#333547] pt-12 ">
                      Information
                    </h1>
                    <div className="flex justify-between flex-wrap-reverse">
                      <div className="m-6">
                        <p className="text-sm">
                          <span className="underline underline-offset-2 font-semibold">
                            {data?.type == "1" ? "Building" : "Land"} Name:
                          </span>{" "}
                          {data?.name}
                        </p>
                        <p className="text-sm">
                          <span className="underline underline-offset-2 font-semibold">
                            {data?.type == "1" ? "Building" : "Land"} No:
                          </span>{" "}
                          {data?.project_no}
                        </p>
                        <p className="text-sm">
                          <span className="underline underline-offset-2 font-semibold">
                            {data?.type == "1" ? "Building" : "Land"} Location:
                          </span>{" "}
                          {data?.location}
                        </p>
                        <p className="text-sm">
                          <span className="underline underline-offset-2 font-semibold">
                            Road No:
                          </span>{" "}
                          {data?.road_no}
                        </p>
                        <p className="text-sm">
                          <span className="underline underline-offset-2 font-semibold">
                            {data?.type == "1" ? "Building" : "Land"} Face
                            Direction:
                          </span>{" "}
                          {data?.face_direction}
                        </p>
                        <p className="text-sm">
                          <span className="underline underline-offset-2 font-semibold">
                            Work Start Date:
                          </span>{" "}
                          {data?.work_start_date}
                        </p>
                        <p className="text-sm">
                          <span className="underline underline-offset-2 font-semibold">
                            Work Complete Date:
                          </span>{" "}
                          {data?.work_complete_date}
                        </p>
                      </div>
                      <div className="m-6 pt-5">
                        <p className="text-sm">
                          <span className="underline underline-offset-2 font-semibold">
                            File No:
                          </span>{" "}
                          {data && <>{data?.flat_or_plot[0]?.file_no}</>}
                        </p>
                        <p className="text-sm">
                          <span className="underline underline-offset-2 font-semibold">
                            {data?.type == "1" ? "Flat" : "Plot"} No:
                          </span>{" "}
                          {data && (
                            <>
                              {data?.flat_or_plot[0]?.plot_no}{" "}
                              {data?.flat_or_plot[0]?.flat_number}
                            </>
                          )}
                        </p>
                        <p className="text-sm">
                          <span className="underline underline-offset-2 font-semibold">
                            {data?.type == "1" ? "Flat" : "Plot"} Size:
                          </span>{" "}
                          {data && <>{data?.flat_or_plot[0]?.size} sqft</>}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                <div className="m-6 font-bold flex justify-between ">
                  <h1 className="text-xl font-semibold underline underline-offset-2 mt-8 mb-4 w-fit">
                    Customer Information
                  </h1>
                  <div className="m-6">
                    <img
                      className="h-28 w-24"
                      // src={baseUrlImg + `${clientInfo?.images?.path}`}
                      src={baseUrlImg + "/" + perFlatOrPlot?.image}
                      alt=""
                      srcSet=""
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-2">
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Customer's Name :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.name}
                    />
                  </div>
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Spouse Name :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.profile?.spouse_name}
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-2">
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Father Name :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.profile?.father_name}
                    />
                  </div>
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Mother Name :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.profile?.mother_name}
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-2">
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Date of Birth :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.date_of_birth}
                    />
                  </div>
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Phone Number :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.phone_number}
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-2">
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>National ID Number :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.nid_number}
                    />
                  </div>
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Booking Date :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value="Booking Date"
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-2">
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Email Address :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.email}
                    />
                  </div>
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Other file no :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.document?.other_file_no}
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-2">
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Country :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.country}
                    />
                  </div>
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Profession :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.profile?.profession}
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-2">
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Permanent Address :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.profile?.permanent_address}
                    />
                  </div>
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Designation (optional) :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.profile?.designation}
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-2">
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Mailing Address :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.mailing_address}
                    />
                  </div>
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Office Address :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.profile?.office_address}
                    />
                  </div>
                </div>

                <div className="flex justify-between flex-wrap-reverse">
                  <div className="m-6">
                    <h1 className="text-xl font-semibold underline underline-offset-2 mt-8 mb-4">
                      Nominee Information
                    </h1>
                  </div>
                  <div className="m-6">
                    <img
                      className="h-28 w-24"
                      // src={baseUrlImg + `${clientInfo?.images?.path}`}
                      src={
                        baseUrlImg + "/" + perFlatOrPlot?.nominee?.nominee_image
                      }
                      alt=""
                      srcSet=""
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-2">
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Nominee Name :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.nominee?.nominee_name}
                    />
                  </div>
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Nominee Gets :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.nominee?.nominee_gets}
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-2">
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Relationship with Nominee :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.nominee?.relation_with_nominee}
                    />
                  </div>

                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Nominee’s phone Number :</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.nominee?.nominee_contact_number}
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-2 mb-10">
                  <div className="grid grid-cols-2 px-2 mx-4">
                    <label>Nominee’s Address:</label>
                    <input
                      className="outline-none border-b-2 border-[#8C8C8C]"
                      type="text"
                      name=""
                      id=""
                      value={perFlatOrPlot?.nominee?.nominee_address}
                    />
                  </div>
                </div>
              </div>
            </>
          ))}
      </div>
    </div>
  );
};

export default ViewClient;
