import React from 'react';

const AddButton = ({children}) => {
    return (
        <div>
            <button className="uppercase text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-1.5 px-3">
              {children}
            </button>
        </div>
    );
};

export default AddButton;