import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { baseUrl } from "../../../BaseUrl";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import Heading from "../../Shared/Heading";
import Loader from "../../Shared/Loader";

const SearchPopertyStatus = () => {
  const [projectItemsList, setProjectItemList] = useState(null);
  const [reFetch, setReFetch] = useState(false);
  const [statusType, setStatusType] = useState("null");
  const [statusValue, setStatusValue] = useState("null");
  const [fileNo, setFileNo] = useState(null);
  const [searchButtonDisable, setSearchButtonDisable] = useState(false);

  useEffect(() => {
    let data = {
      file_no: fileNo,
    };
    if (statusType !== "null") {
      data.status_type = statusType;
    }
    if (statusValue !== "null") {
      data.status_value = statusValue;
    }
    if (statusType !== "null" || statusValue !== "null" || fileNo !== null) {
      axios
        .post(baseUrl + "/registration/status/search", data, {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        })
        .then((res) => {
          setProjectItemList(res?.data?.results);
          setSearchButtonDisable(false);
        });
    } else {
      axios
        .post(baseUrl + "/registration/status/list", data, {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        })
        .then((res) => {
          setProjectItemList(res?.data?.registration_statuses);
          setSearchButtonDisable(false);
        });
    }
  }, [reFetch, fileNo, statusType, statusValue]);

  const handleSearch = () => {
    setSearchButtonDisable(true);
    setReFetch(!reFetch);
  };

  const statusChange = (id, status_type, value) => {
    axios
      .post(
        baseUrl + `/registration/status/update/${id}/${status_type}/${value}`,
        { status: -1 },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success("Status Update successfully!");
          setReFetch(!reFetch);
        }
      });
  };

  //--------print functionality start----------
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //---------print functionality end-----------
  return (
    <div>
      <Heading>Search By Property Registration Status</Heading>
      <div className="p-5 whiteBackground">
        <div className="flex flex-wrap justify-between items-center my-5">
          <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
          <div className="my-2">
            <div className="form-control">
              <div className="input-group flex flex-wrap items-center">
                <h1 className="m-3 text-[#717A82] font-bold">
                  Search By File No
                </h1>
                <input
                  onChange={(e) => setFileNo(e.target.value)}
                  name="file_no_field"
                  style={{
                    borderRadius: "5px",
                  }}
                  type="text"
                  placeholder="Type file no"
                  className="input input-bordered w-[120px] rounded-[5px] bg-[#ECECEC] text-[#494646] placeholder:text-slate-800"
                />
                <select
                  className="select select-bordered mx-2 my-2 rounded-[5px] bg-[#ECECEC]"
                  onChange={(e) => setStatusValue(e.target.value)}
                >
                  <option value={"null"}>Select Status</option>
                  <option
                    className="bg-[#11862B] text-white font-bold"
                    value={1}
                  >
                    Complete
                  </option>
                  <option
                    className="bg-[#EA920F] text-white font-bold"
                    value={2}
                  >
                    Pending
                  </option>
                  <option
                    className="bg-[#EA0F0F] text-white font-bold"
                    value={3}
                  >
                    Not Processing
                  </option>
                  <option
                    className="bg-[#767676] text-white font-bold"
                    value={4}
                  >
                    Not Applicable
                  </option>
                </select>
                {/* {searchButtonDisable == true ? (
                  <label
                    style={{ borderRadius: "5px" }}
                    className="bg-[#57c9b3] text-white px-[32px] py-[13px] my-2 rounded-[5px] text-[14px]"
                  >
                    Loading...
                  </label>
                ) : (
                  <button
                    style={{ borderRadius: "5px" }}
                    className="bg-[#16A085] text-white px-[32px] py-[13px] my-2 rounded-[5px] text-[14px]"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                )} */}
              </div>
            </div>
          </div>
        </div>
        {projectItemsList == null ? (
          <Loader></Loader>
        ) : (
          <div className="overflow-x-auto max-h-[60vh]">
            <table
              className="table table-zebra w-full max-h-[60vh]"
              ref={componentRef}
            >
              <thead className="text-[#00CA08]">
                <tr className="sticky top-0">
                  <th>
                    Customer's <br /> Booking Date
                  </th>
                  <th>
                    Customer's <br /> Country
                  </th>
                  <th>Customer's Name</th>
                  <th>Customer's Id</th>
                  <th>
                    Customer's <br /> Address
                  </th>
                  <th>File No.</th>
                  <th>
                    Customer Plot/Flat <br /> Registration Status
                  </th>
                  <th>
                    Customer Mutation <br /> Cost Status
                  </th>
                  <th>
                    Customer Power of <br /> Attorney Cost Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {projectItemsList?.length > 0 ? (
                  projectItemsList?.map((project, index) => (
                    <tr key={index + project?.id}>
                      <td>{project?.flat_or_plot?.booking_date}</td>
                      <td>
                        {project?.flat_or_plot?.customers?.map(
                          (customer, index) => (
                            <p key={customer.id}>{customer?.country}</p>
                          )
                        )}
                      </td>

                      <td>
                        {project?.flat_or_plot?.customers?.map(
                          (customer, index) => (
                            <p key={customer.id} className={Math.random()}>
                              {customer?.name}
                            </p>
                          )
                        )}
                      </td>
                      <td>
                        {project?.flat_or_plot?.customers?.map(
                          (customer, index) => (
                            <p key={customer.id}>{customer?.id}</p>
                          )
                        )}
                      </td>
                      <td>
                        {project?.flat_or_plot?.customers?.map(
                          (customer, index) => (
                            <p key={customer.id}>{customer?.mailing_address}</p>
                          )
                        )}
                      </td>
                      <td>{project?.flat_or_plot?.file_no}</td>
                      <td>
                        <select
                          className={`select select-bordered select-sm my-2 text-[16px] rounded-[5px] border-[1px] border-[#767676] ${
                            project?.flat_or_plot_registration_status == 1 &&
                            "bg-[#11862B] text-white"
                          } ${
                            project?.flat_or_plot_registration_status == 2 &&
                            "bg-[#EA920F] text-white"
                          } 
                          ${
                            project?.flat_or_plot_registration_status == 3 &&
                            "bg-[#EA0F0F] text-white"
                          }
                          ${
                            project?.flat_or_plot_registration_status == 4 &&
                            "bg-[#767676] text-white"
                          }
                          ${
                            project?.flat_or_plot_registration_status == 0 &&
                            "text-[#767676] bg-white"
                          }
                       `}
                          defaultValue={
                            project?.flat_or_plot_registration_status
                          }
                          onChange={(e) =>
                            statusChange(
                              project?.id,
                              "flat_or_plot_registration_status",
                              e.target.value
                            )
                          }
                        >
                          <option
                            value={0}
                            className="bg-slate-200 text-slate-800"
                          >
                            Select Status
                          </option>
                          <option
                            className="bg-[#11862B] text-white font-bold "
                            value={1}
                            selected={
                              project?.flat_or_plot_registration_status == 1
                                ? true
                                : false
                            }
                          >
                            Complete
                          </option>
                          <option
                            className="bg-[#EA920F] text-white font-bold"
                            value={2}
                            selected={
                              project?.flat_or_plot_registration_status == 2
                                ? true
                                : false
                            }
                          >
                            Pending
                          </option>
                          <option
                            className="bg-[#EA0F0F] text-white font-bold"
                            value={3}
                            selected={
                              project?.flat_or_plot_registration_status == 3
                                ? true
                                : false
                            }
                          >
                            Not Processing
                          </option>
                          <option
                            className="bg-[#767676] text-white font-bold"
                            value={4}
                            selected={
                              project?.flat_or_plot_registration_status == 4
                                ? true
                                : false
                            }
                          >
                            Not Applicable
                          </option>
                        </select>
                      </td>
                      <td>
                        <select
                          className={`select select-bordered select-sm my-2 text-[16px] rounded-[5px] border-[1px] border-[#767676] 
                      ${
                        project?.mutation_cost_status == 1 &&
                        "bg-[#11862B] text-white"
                      } ${
                            project?.mutation_cost_status == 2 &&
                            "bg-[#EA920F] text-white"
                          } 
                      ${
                        project?.mutation_cost_status == 3 &&
                        "bg-[#EA0F0F] text-white"
                      }
                      ${
                        project?.mutation_cost_status == 4 &&
                        "bg-[#767676] text-white"
                      }
                      ${
                        project?.mutation_cost_status == 0 &&
                        "text-[#767676] bg-white"
                      }
                      `}
                          defaultValue={project?.mutation_cost_status}
                          onChange={(e) =>
                            statusChange(
                              project?.id,
                              "mutation_cost_status",
                              e.target.value
                            )
                          }
                        >
                          <option
                            value={0}
                            className="bg-slate-200 text-slate-800"
                          >
                            Select Status
                          </option>
                          <option
                            className="bg-[#11862B] text-white font-bold"
                            value={1}
                            selected={
                              project?.mutation_cost_status == 1 ? true : false
                            }
                          >
                            Complete
                          </option>
                          <option
                            className="bg-[#EA920F] text-white font-bold"
                            value={2}
                            selected={
                              project?.mutation_cost_status == 2 ? true : false
                            }
                          >
                            Pending
                          </option>
                          <option
                            className="bg-[#EA0F0F] text-white font-bold"
                            value={3}
                            selected={
                              project?.mutation_cost_status == 3 ? true : false
                            }
                          >
                            Not Processing
                          </option>
                          <option
                            className="bg-[#767676] text-white font-bold"
                            value={4}
                            selected={
                              project?.mutation_cost_status == 4 ? true : false
                            }
                          >
                            Not Applicable
                          </option>
                        </select>
                      </td>
                      <td>
                        <select
                          className={`select select-bordered select-sm my-2 text-[16px] rounded-[5px] border-[1px] border-[#767676] 
                      ${
                        project?.power_of_attorney_cost_status == 1 &&
                        "bg-[#11862B] text-white"
                      } ${
                            project?.power_of_attorney_cost_status == 2 &&
                            "bg-[#EA920F] text-white"
                          } 
                      ${
                        project?.power_of_attorney_cost_status == 3 &&
                        "bg-[#EA0F0F] text-white"
                      }
                      ${
                        project?.power_of_attorney_cost_status == 4 &&
                        "bg-[#767676] text-white"
                      }
                      ${
                        project?.power_of_attorney_cost_status == 0 &&
                        "text-[#767676] bg-white"
                      }
                      `}
                          defaultValue={project?.power_of_attorney_cost_status}
                          onChange={(e) =>
                            statusChange(
                              project?.id,
                              "power_of_attorney_cost_status",
                              e.target.value
                            )
                          }
                        >
                          <option
                            value={0}
                            className="bg-slate-200 text-slate-800"
                          >
                            Select Status
                          </option>
                          <option
                            className="bg-[#11862B] text-white font-bold"
                            value={1}
                            selected={
                              project?.power_of_attorney_cost_status == 1
                                ? true
                                : false
                            }
                          >
                            Complete
                          </option>
                          <option
                            className="bg-[#EA920F] text-white font-bold"
                            value={2}
                            selected={
                              project?.power_of_attorney_cost_status == 2
                                ? true
                                : false
                            }
                          >
                            Pending
                          </option>
                          <option
                            className="bg-[#EA0F0F] text-white font-bold"
                            value={3}
                            selected={
                              project?.power_of_attorney_cost_status == 3
                                ? true
                                : false
                            }
                          >
                            Not Processing
                          </option>
                          <option
                            className="bg-[#767676] text-white font-bold"
                            value={4}
                            selected={
                              project?.power_of_attorney_cost_status == 4
                                ? true
                                : false
                            }
                          >
                            Not Applicable
                          </option>
                        </select>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td style={{ columnSpan: "all" }}>
                      <h2
                        className="text-center py-5 col-span-10 font-bold"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        No Details Found
                      </h2>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchPopertyStatus;
