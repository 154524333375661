import React, { useEffect, useState } from "react";
import Heading from "../../Shared/Heading";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

let allDatas = [];
const DownpaymentDueList = () => {
  const [downpaymentDueListReports, setDownpaymentDueListReports] = useState(
    []
  );

  useEffect(() => {
    axios
      .get(baseUrl + `/reports/downpaymentDueList`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        allDatas = res?.data?.data;
        setDownpaymentDueListReports(res?.data?.data);
      });
  }, []);

  //get maximum downpayment number for table head and for select options
  let arrayLength = [];
  downpaymentDueListReports?.map((list) => {
    return arrayLength.push(
      list?.price_information?.downpayment?.length > 0
        ? list.price_information?.downpayment?.length
        : 0
    );
  });
  const maxLengthDownpayment = Math.max(...arrayLength);
  const maxDownpaymentDueListReports = downpaymentDueListReports?.find(
    (list) =>
      list?.price_information?.downpayment?.length === maxLengthDownpayment
  );

  //----------filter functionality start ---------------
  const handleFilterData = (e) => {
    const findDownpaymentNo = parseInt(e.split(" ")[1]);
    if (e === "all") {
      setDownpaymentDueListReports(allDatas);
    } else if (e === "Total_Downpayment_Amount") {
      setDownpaymentDueListReports(
        allDatas?.filter(
          (list) => list?.price_information?.total_downpayment_amount > 0
        )
      );
    } else if (e === `Downpayment ${findDownpaymentNo}`) {
      setDownpaymentDueListReports(
        allDatas?.filter(
          (list, i) =>
            list?.price_information?.downpayment[findDownpaymentNo - 1]
              ?.amount -
              list?.price_information?.downpayment[findDownpaymentNo - 1]
                ?.paid >
            0
        )
      );
    } else if (e === "Total_Downpayment_Complete_Amount") {
      setDownpaymentDueListReports(
        allDatas?.filter(
          (list) => list?.price_information?.total_downpayment_amount_paid > 0
        )
      );
    } else if (e === "Total_Downpayment_Due_Amount") {
      setDownpaymentDueListReports(
        allDatas?.filter(
          (list) =>
            list?.price_information?.total_downpayment_amount -
              list?.price_information?.total_downpayment_amount_paid >
              0 ||
            list?.price_information?.total_downpayment_amount -
              list?.price_information?.total_downpayment_amount_paid <
              0
        )
      );
    }
  };
  //----------filter functionality End ---------------

  //----------Print a div or section functionality start-----------
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //----------Print a div or section functionality end-------------

    // ---------------search table data start------------------
    const handleSearch = (e) => {
      // console.log(e.target.value);
      // Declare variables
      let status = 0;
      let filterBy = e.target.value.toUpperCase();
      let table = document.getElementById("dataTable");
      let tr = table.querySelectorAll(".searchData");
      let i = 0;
      let j = 0;
      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        let tempTd = tr[i].getElementsByTagName("td");
        for (j = 0; j < tempTd.length; j++) {
          let td = tempTd[j];
          let txtValue;
          if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filterBy) > -1) {
              status = 1;
            }
            if (!status) {
              status = 0;
            }
          }
        }
        if (status) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    };
    // ------------------search table data end-----------------
  return (
    <div>
      <Heading>Downpayment Due List</Heading>
      <div className="p-5 whiteBackground">
        <div className="flex flex-wrap justify-between items-center my-5">
          <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
          <div className="my-2">
            <div className="form-control">
              <div className="flex flex-wrap items-center">
                <h1 className="m-3 text-[#717A82] font-bold">
                  Search Downpayment Due List
                </h1>
                <select
                  onChange={(e) => handleFilterData(e.target.value)}
                  className="select select-sm select-bordered  my-2 rounded-[5px] bg-[#ECECEC]"
                >
                  <option value={"all"}>All</option>
                  <option value={"Total_Downpayment_Amount"}>
                    Total Downpayment Amount
                  </option>
                  {maxDownpaymentDueListReports?.price_information?.downpayment?.map(
                    (list) => (
                      <option key={list.id} value={list?.downpayment_no}>
                        {list?.downpayment_no} Amount
                      </option>
                    )
                  )}
                  <option value={"Total_Downpayment_Complete_Amount"}>
                    Total Downpayment Complete Amount
                  </option>
                  <option value={"Total_Downpayment_Due_Amount"}>
                    Total Downpayment Due Amount
                  </option>
                </select>
                <input
                onChange={handleSearch}
                    id="searchInput"
                  type="text"
                  placeholder="Search..."
                  className="input input-bordered input-sm max-w-xs ml-1"
                />
              </div>
            </div>
          </div>
        </div>
        {downpaymentDueListReports?.length > 0 ? (
          <div ref={componentRef} className="overflow-x-auto max-h-[60vh]">
            <table id="dataTable" className="table table-zebra w-full max-h-[60vh]">
              <thead className="text-[#00CA08]">
                <tr className="sticky top-0">
                  <th>Customer's Id</th>
                  <th>Customer's Name</th>
                  <th>File No.</th>
                  <th>
                    Building/Land <br /> No.
                  </th>
                  <th>
                    Flat/Plot <br /> Size
                  </th>
                  <th>
                    Total <br />
                    Downpayment <br />
                    Amount
                  </th>
                  {maxDownpaymentDueListReports?.price_information?.downpayment?.map(
                    (list) => {
                      return (
                        <th>
                          {list?.downpayment_no} <br />
                          Amount
                        </th>
                      );
                    }
                  )}
                  <th>
                    Total Downpayment <br />
                    Complete <br />
                    Amount
                  </th>
                  <th>
                    Total <br />
                    Downpayment <br />
                    Due Amount <br />
                  </th>
                </tr>
              </thead>
              <tbody>
                {downpaymentDueListReports.map((list, i) => {
                  return (
                    <tr className="searchData" key={list.id}>
                      <td>
                        {list?.customers?.map((singleCustomer) => (
                          <h1 key={singleCustomer.id}>{singleCustomer.id}</h1>
                        ))}
                      </td>
                      <td>
                        {list.customers.map((singleCustomer) => (
                          <h1 key={singleCustomer.id}>{singleCustomer.name}</h1>
                        ))}
                      </td>
                      <td>{list.file_no}</td>
                      <td>{list?.project?.project_no}</td>
                      <td>{list.size}</td>
                      <td>
                        {list?.price_information?.total_downpayment_amount
                          ? list?.price_information?.total_downpayment_amount
                          : 0}{" "}
                        TK
                      </td>
                      {maxDownpaymentDueListReports?.price_information?.downpayment?.map(
                        (item, i) => {
                          return (
                            <td>
                              {list?.price_information?.downpayment[i]?.amount
                                ? list?.price_information?.downpayment[i]
                                    ?.amount -
                                  list?.price_information?.downpayment[i]?.paid
                                : 0}{" "}
                              TK
                            </td>
                          );
                        }
                      )}
                      <td>
                        {list?.price_information?.total_downpayment_amount_paid}{" "}
                        TK
                      </td>
                      <td>
                        {list?.price_information?.total_downpayment_amount -
                          list?.price_information
                            ?.total_downpayment_amount_paid}{" "}
                        TK
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <h1 className="text-center font-bold my-5 py-5">No Data Found</h1>
        )}
      </div>
    </div>
  );
};

export default DownpaymentDueList;
