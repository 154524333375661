import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../BaseUrl";
import Heading from "../../Shared/Heading";

const AddFlatPrice = () => {
  let { clientId } = useParams();
  const [additionalPaymentLength, setAdditionalPaymentLength] = useState([
    {
      fieldTitle: "Additional Amount for Land Price",
      type: "land_price",
    },
    {
      fieldTitle: "Additional Amount for Flat Price",
      type: "flat_price",
    },
    {
      fieldTitle: "Additional Amount for Flat Size increase",
      type: "flat_size_increase",
    },
    {
      fieldTitle: "Additional Amount for Flat Renovation",
      type: "flat_renovation",
    },
    {
      fieldTitle: "Additional Amount for Registration & Mutation cost",
      type: "reg_mutation_cost",
    },
    {
      fieldTitle: "Additional Amount for Flat Decoration",
      type: "flat_decoration",
    },
  ]);
  const [flatData, setFlatData] = useState([]);
  const [customerNameId, setCustomerNameId] = useState("");
  const navigate = useNavigate();
  // console.log(flatData,"flast data")
  useEffect(() => {
    axios
      .get(
        baseUrl + `/customer/price/flatorplot/${clientId}`,

        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setFlatData(res.data.data);
        let nameAndId = "";
        res.data.data.customers.map((customer) => {
          if (nameAndId == "") {
            nameAndId = `${customer?.name}(${customer.id})`;
          } else {
            nameAndId = nameAndId + " / " + `${customer?.name}(${customer.id})`;
          }
        });
        setCustomerNameId(nameAndId);
      });
  }, []);
  const [downPayment, setdownPayment] = useState([]);
  const [numberOfInstallments, setNumberOfInstallments] = useState([]);
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    const addData = {
      ...data,
      project_type: flatData?.project?.type,
      flat_or_plot_id: flatData?.id,
      downpayment: data?.downpayment ? data?.downpayment : [],
      installment: data?.installment
        ? data?.installment.slice(0, data?.number_of_installment)
        : [],
    };

    // push new object for downpayment array
    addData.downpayment.forEach((element, index) => {
      addData.downpayment[index]["downpayment_no"] = `Downpayment ${index + 1}`;
    });

    // additional payment
    let additionalPayment = [];
    for (let i = 0; i < additionalPaymentLength?.length; i++) {
      let payment = additionalPaymentLength[i];
      if (!additionalPaymentLength[i]?.id) {
        payment.id = null;
      }
      //  amount
      if (!addData?.additionalPayment[i]?.amount) {
        payment.amount = 0;
      } else if (addData?.additionalPayment[i]?.amount == "") {
        payment.amount = additionalPaymentLength[i]?.amount;
      } else {
        payment.amount = addData?.additionalPayment[i]?.amount;
      }
      //  start date
      if (!addData?.additionalPayment[i]?.start_date) {
        payment.start_date = "";
      } else if (addData?.additionalPayment[i]?.start_date == "") {
        payment.start_date = additionalPayment[i]?.start_date;
      } else {
        payment.start_date = addData?.additionalPayment[i]?.start_date;
      }
      additionalPayment.push(payment);
    }
    addData.additional_amount = additionalPayment;

    axios
      .post(baseUrl + "/customer/price/store", addData, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        if (res?.data?.status == "failed") {
          if (Array?.isArray(res?.data?.message)) {
            res?.data?.message?.map((item) => toast.error(item));
          } else {
            toast.error(res?.data?.message);
          }
        } else {
          toast.success("Add price successfully!");
          navigate("/dashboard/clientpriceinfo");
        }
      });
  };

  const changeNumberOfInstallment = (number) => {
    let array = [];
    for (let i = 0; i < number; i++) {
      array.push(i);
    }
    setNumberOfInstallments(array);
  };
  return (
    <div>
      <Heading>
        <Link to="/dashboard/clientpriceinfo">
          <i className="fa-solid fa-arrow-left"></i> Add Flat Price
        </Link>
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="whiteBackground py-1">
          <div>
            <fieldset className="border-2 border-[#949494] m-5">
              <legend className="mx-4">
                <span className="bg-[#949494] text-[white] py-1.5 px-4 rounded-md">
                  {" "}
                  Previously Submitted Informations
                </span>
              </legend>
              <div className="grid lg:grid-cols-5 ">
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      File No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={flatData?.file_no}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Flat No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={flatData?.flat_number}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Flat Size
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={`${flatData?.size} sqf`}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Flat Floor No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={flatData?.floor_no}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Flat Face Direction
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={flatData?.face_direction}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Building Name
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={flatData?.project?.name}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Building No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value="12A"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Building Face Direction
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={flatData?.project?.face_direction}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Building Location
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={flatData?.project?.location}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Road No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={flatData?.project?.road_no}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Customer Name and Id
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={customerNameId}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
            </fieldset>
          </div>
          <div className="grid lg:grid-cols-3 gap-5 m-5">
            <div>
              <fieldset className="border-2 border-[#1F497D]">
                <legend className="mx-auto">
                  <span className="bg-[#1F497D] text-[white] py-1.5 px-4 rounded-md">
                    {" "}
                    Price Information
                  </span>
                </legend>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Total Money
                    </span>
                  </label>
                  <input
                    {...register(`total_money`)}
                    name={`total_money`}
                    type="text"
                    placeholder="Total Money"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Booking Money
                    </span>
                    <span className="label-text font-bold text-[#887d7d]">
                      Start Date
                    </span>
                  </label>
                  <div>
                    <label className="input-group">
                      <input
                        {...register(`booking_money`)}
                        name={`booking_money`}
                        type="text"
                        placeholder="Booking Money"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                      <input
                        {...register(`booking_money_date`)}
                        name={`booking_money_date`}
                        type="date"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                    </label>
                  </div>
                </div>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Car Parking
                    </span>
                    <span className="label-text font-bold text-[#887d7d]">
                      Start Date
                    </span>
                  </label>
                  <div>
                    <label className="input-group">
                      <input
                        {...register(`car_parking`)}
                        name={`car_parking`}
                        type="text"
                        placeholder="Car Parking"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                      <input
                        {...register(`car_parking_date`)}
                        name={`car_parking_date`}
                        type="date"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                    </label>
                  </div>
                </div>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Utility Charge
                    </span>
                    <span className="label-text font-bold text-[#887d7d]">
                      Start Date
                    </span>
                  </label>
                  <div>
                    <label className="input-group">
                      <input
                        {...register(`utility_charge`)}
                        name={`utility_charge`}
                        type="text"
                        placeholder="Utility Charge"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                      <input
                        {...register(`utility_charge_date`)}
                        name={`utility_charge_date`}
                        type="date"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                    </label>
                  </div>
                </div>
                {/* <div className="form-control w-full max-w-lg px-4 py-1.5 mb-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Additional Work Amount
                    </span>
                    <span className="label-text font-bold text-[#887d7d]">
                      Start Date
                    </span>
                  </label>
                  <div>
                    <label className="input-group">
                      <input
                        {...register("additional_work_amount")}
                        name="additional_work_amount"
                        type="text"
                        placeholder="Additional Work Amount"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                      <input
                        {...register("additional_work_amount_date")}
                        name="additional_work_amount_date"
                        type="date"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                    </label>
                  </div>
                </div> */}
              </fieldset>
            </div>
            <div>
              <fieldset className="border-2 border-[#1F497D]">
                <legend className="mx-auto">
                  <span className="bg-[#1F497D] text-[white] py-1.5 px-4 rounded-md">
                    {" "}
                    Downpayment Information
                  </span>
                </legend>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Total Downpayment Amount
                    </span>
                  </label>
                  <label className="input-group">
                    <input
                      {...register("total_downpayment_amount")}
                      type="text"
                      placeholder="Total Downpayment Amount"
                      className="input input-bordered input-sm w-full max-w-lg mr-2"
                    />
                    <span
                      className="ml-2 bg-[#16A085]"
                      onClick={() =>
                        setdownPayment([...downPayment, { downPayment: "" }])
                      }
                    >
                      <span
                        style={{
                          width: "fit-content",
                          padding: "0",
                          background: "#16A085",
                        }}
                      >
                        <i className="fa-solid fa-circle-plus text-white"></i>
                      </span>
                    </span>
                  </label>
                </div>
                {downPayment?.map((downPay, index) => {
                  return (
                    <div
                      key={index + Math.random().toFixed(6)}
                      className="form-control w-full max-w-lg px-4 py-1.5 mb-2"
                    >
                      <label className="label">
                        <span className="label-text font-bold text-[#887d7d]">
                          Downpayment {index + 1}
                        </span>
                        <span className="label-text font-bold text-[#887d7d]">
                          Start Date
                        </span>
                      </label>
                      <div>
                        <label className="input-group">
                          <input
                            {...register(`downpayment[${index}].amount`)}
                            type="text"
                            placeholder="Downpayment 1"
                            className="input input-bordered input-sm w-full max-w-lg"
                          />
                          <input
                            {...register(`downpayment[${index}].start_date`)}
                            type="date"
                            className="input input-bordered input-sm w-full max-w-lg"
                          />
                        </label>
                      </div>
                    </div>
                  );
                })}
              </fieldset>
            </div>

            {/* new field  */}
            <div>
              <fieldset className="border-2 border-[#1F497D]">
                <legend className="mx-auto">
                  <span className="bg-[#1F497D] text-[white] py-1.5 px-4 rounded-md">
                    {" "}
                    Additional Amount
                  </span>
                </legend>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Total Additional Amount
                    </span>
                  </label>
                  <label className="input-group">
                    <input
                      name="total_additional_amount"
                      {...register(`total_additional_amount`)}
                      defaultValue={
                        flatData?.price_information?.total_additional_amount
                      }
                      type="text"
                      placeholder="Total Additional Amount"
                      className="input input-bordered input-sm w-full max-w-lg mr-2"
                    />
                    {/* <span
                      className="ml-2 bg-[#16A085]"
                      onClick={() =>
                        setAdditionalPaymentLength([...additionalPaymentLength, {}])
                      }
                    >
                      <div>
                        <i className="fa-solid fa-circle-plus text-white"></i>
                      </div>
                    </span> */}
                  </label>
                </div>
                {additionalPaymentLength?.map((additionalPayment, index) => {
                  return (
                    <div className="form-control w-full max-w-lg px-4 py-1.5 mb-2">
                      <label className="label">
                        <span className="label-text font-bold text-[#887d7d]">
                          {additionalPayment.fieldTitle}
                        </span>
                        <span className="label-text font-bold text-[#887d7d]">
                          Start Date
                        </span>
                      </label>
                      <div>
                        <label className="input-group">
                          <input
                            {...register(`additionalPayment[${index}].amount`)}
                            name={`additionalPayment[${index}].amount`}
                            defaultValue={additionalPayment?.amount}
                            type="text"
                            placeholder={additionalPayment.fieldTitle}
                            className="input input-bordered input-sm w-full max-w-lg"
                          />
                          <input
                            {...register(
                              `additionalPayment[${index}].start_date`
                            )}
                            name={`additionalPayment[${index}].start_date`}
                            defaultValue={additionalPayment?.start_date}
                            type="date"
                            className="input input-bordered input-sm w-full max-w-lg"
                          />
                        </label>
                      </div>
                    </div>
                  );
                })}
              </fieldset>
            </div>
            <div>
              <fieldset className="border-2 border-[#1F497D]">
                <legend className="mx-auto">
                  <span className="bg-[#1F497D] text-[white] py-1.5 px-4 rounded-md">
                    {" "}
                    Installment Information
                  </span>
                </legend>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Total Installment Amount
                    </span>
                  </label>
                  <input
                    {...register("total_installment_amount")}
                    name="total_installment_amount"
                    type="text"
                    placeholder="Total Installment Amount"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Per Month Installment Amount
                    </span>
                  </label>
                  <input
                    {...register("per_month_installment_amount")}
                    name="per_month_installment_amount"
                    type="text"
                    placeholder="Per Month Installment Amount"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Number of Installments
                    </span>
                  </label>
                  <select
                    {...register("number_of_installment")}
                    className="select select-bordered select-sm"
                    onChange={(e) => changeNumberOfInstallment(e.target.value)}
                  >
                    <option disabled selected value={0}>
                      Number of Installments
                    </option>
                    <option value={0}>0</option>
                    <option value={6}>6</option>
                    <option value={12}>12</option>
                    <option value={18}>18</option>
                    <option value={24}>24</option>
                    <option value={30}>30</option>
                    <option value={36}>36</option>
                    <option value={42}>42</option>
                    <option value={48}>48</option>
                    <option value={54}>54</option>
                    <option value={60}>60</option>
                  </select>
                </div>
                {numberOfInstallments.map((installment, index) => {
                  return (
                    <div
                      key={index + Math.random().toFixed(5)}
                      className="form-control w-full max-w-lg px-4 py-1.5 mb-2"
                    >
                      <label className="label">
                        <span className="label-text font-bold text-[#887d7d]">
                          Installment {index + 1}
                        </span>
                        <span className="label-text font-bold text-[#887d7d]">
                          Date
                        </span>
                      </label>
                      <div>
                        <label className="input-group">
                          <input
                            {...register(`installment[${index}].amount`)}
                            type="text"
                            placeholder={`Installment ${index + 1}`}
                            className="input input-bordered input-sm w-full max-w-lg"
                          />
                          <input
                            {...register(`installment[${index}].start_date`)}
                            type="date"
                            className="input input-bordered input-sm w-full max-w-lg"
                          />
                        </label>
                      </div>
                    </div>
                  );
                })}
              </fieldset>
            </div>
          </div>
        </div>
        <div className="flex justify-start mx-16 mb-10">
          {/* <Link to="/dashboard/clientpriceinfo"> */}
          <button
            type="submit"
            className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-1.5 px-16"
          >
            Submit
          </button>
          {/* </Link> */}
        </div>
      </form>
    </div>
  );
};

export default AddFlatPrice;
