import React, { useEffect, useRef, useState } from "react";
import Heading from "../../Shared/Heading";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { useReactToPrint } from "react-to-print";

let allDatas = [];
const UtilityChargeAndCarParkingPaymentDueList = () => {
  const [
    utilityAndCarParkingDueListReports,
    setUtilityAndCarParkingDueListReports,
  ] = useState([]);

  useEffect(() => {
    axios
      .get(baseUrl + `/reports/utilityAndCarParkingDueList`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        allDatas = res?.data?.data;
        setUtilityAndCarParkingDueListReports(res.data.data);
      });
  }, []);

  //----------filter functionality start ---------------
  const handleFilterData = (e) => {
    console.log(e);
    if (e === "all") {
      setUtilityAndCarParkingDueListReports(allDatas);
    } else if (e === "Total_Utility_Charge_Amount") {
      setUtilityAndCarParkingDueListReports(
        allDatas?.filter((list) => list?.price_information?.utility_charge > 0)
      );
    } else if (e === "Utility_payment_complete_amount") {
      setUtilityAndCarParkingDueListReports(
        allDatas?.filter(
          (list) => list?.price_information?.total_utility_charge_paid > 0
        )
      );
    } else if (e === "Utility_charge_due_amount") {
      setUtilityAndCarParkingDueListReports(
        allDatas?.filter(
          (list) =>
            list?.price_information?.utility_charge -
              list?.price_information?.total_utility_charge_paid >
            0
        )
      );
    } else if (e === "Total_Car_Parking_Amount") {
      setUtilityAndCarParkingDueListReports(
        allDatas?.filter((list) => list?.price_information?.car_parking > 0)
      );
    } else if (e === "Car_parking_payment_complete_Amount") {
      setUtilityAndCarParkingDueListReports(
        allDatas?.filter(
          (list) => list?.price_information?.total_car_parking_paid > 0
        )
      );
    } else if (e === "Car_Parking_Due_amount") {
      setUtilityAndCarParkingDueListReports(
        allDatas?.filter(
          (list) =>
            list?.price_information?.car_parking -
              list?.price_information?.total_car_parking_paid >
            0
        )
      );
    }
  };
  //----------filter functionality End ---------------

  //----------Print a div or section functionality start-----------
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //----------Print a div or section functionality end-------------

  // ---------------search table data start------------------
  const handleSearch = (e) => {
    // console.log(e.target.value);
    // Declare variables
    let status = 0;
    let filterBy = e.target.value.toUpperCase();
    let table = document.getElementById("dataTable");
    let tr = table.querySelectorAll(".searchData");
    let i = 0;
    let j = 0;
    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      let tempTd = tr[i].getElementsByTagName("td");
      for (j = 0; j < tempTd.length; j++) {
        let td = tempTd[j];
        let txtValue;
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filterBy) > -1) {
            status = 1;
          }
          if (!status) {
            status = 0;
          }
        }
      }
      if (status) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  };
  // ------------------search table data end-----------------
  return (
    <div>
      <div>
        <Heading>Utility Charge & Car Parking payment due List</Heading>
        <div className="p-5 whiteBackground">
          <div className="flex flex-wrap justify-between items-center my-5">
            <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
            <div className="my-2">
              <div className="form-control">
                <div className="flex flex-wrap items-center">
                  <h1 className="m-3 text-[#717A82] font-bold">Search for</h1>
                  <select
                    onChange={(e) => handleFilterData(e.target.value)}
                    className="select select-sm select-bordered  my-2 rounded-[5px] bg-[#ECECEC]"
                  >
                    <option value={"all"}>All</option>
                    <option value={"Total_Utility_Charge_Amount"}>
                      Total Utility Charge Amount
                    </option>
                    <option value={"Utility_payment_complete_amount"}>
                      Utility payment complete amount
                    </option>
                    <option value={"Utility_charge_due_amount"}>
                      Utility charge due amount
                    </option>
                    <option value={"Total_Car_Parking_Amount"}>
                      Total Car Parking Amount
                    </option>
                    <option value={"Car_parking_payment_complete_Amount"}>
                      Car parking payment complete Amount
                    </option>
                    <option value={"Car_Parking_Due_amount"}>
                      Car Parking Due amount
                    </option>
                  </select>
                  <input
                    onChange={handleSearch}
                    id="searchInput"
                    type="text"
                    placeholder="Search..."
                    className="input input-bordered input-sm max-w-xs ml-1"
                  />
                </div>
              </div>
            </div>
          </div>
          {utilityAndCarParkingDueListReports?.length > 0 ? (
            <div ref={componentRef} className="overflow-x-auto max-h-[60vh]">
              <table
                id="dataTable"
                className="table table-zebra w-full max-h-[60vh]"
              >
                <thead className="text-[#00CA08]">
                  <tr className="sticky top-0">
                    <th>Customer's Id</th>
                    <th>Customer's Name</th>
                    <th>File No.</th>
                    <th>
                      Building/Land <br /> No.
                    </th>
                    <th>
                      Flat/Plot <br /> Size
                    </th>
                    <th>
                      Total Utility <br />
                      Charge Amount
                    </th>
                    <th>
                      Utility <br /> payment <br />
                      complete <br />
                      amount
                    </th>
                    <th>
                      Utility charge <br />
                      due amount
                    </th>
                    <th>
                      Total Car <br />
                      Parking <br />
                      Amount
                    </th>
                    <th>
                      Car parking <br /> payment <br />
                      complete <br />
                      Amount
                    </th>
                    <th>
                      Car Parking <br />
                      Due amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {utilityAndCarParkingDueListReports?.map((list) => {
                    return (
                      <tr className="searchData" key={list.id}>
                        <td>
                          {list?.customers?.map((singleCustomer) => (
                            <h1 key={singleCustomer.id}>{singleCustomer.id}</h1>
                          ))}
                        </td>
                        <td>
                          {list.customers.map((singleCustomer) => (
                            <h1 key={singleCustomer.id}>
                              {singleCustomer.name}
                            </h1>
                          ))}
                        </td>
                        <td>{list.file_no}</td>
                        <td>{list?.project?.project_no}</td>
                        <td>{list.size}</td>
                        <td>{list?.price_information?.utility_charge} TK</td>
                        <td>
                          {list?.price_information?.total_utility_charge_paid}{" "}
                          TK
                        </td>
                        <td>
                          {list?.price_information?.utility_charge -
                            list?.price_information
                              ?.total_utility_charge_paid}{" "}
                          TK
                        </td>
                        <td>{list?.price_information?.car_parking} TK</td>
                        <td>
                          {list?.price_information?.total_car_parking_paid} TK
                        </td>
                        <td>
                          {list?.price_information?.car_parking -
                            list?.price_information
                              ?.total_car_parking_paid}{" "}
                          TK
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <h1 className="text-center font-bold my-5 py-5">No Data Found</h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default UtilityChargeAndCarParkingPaymentDueList;
