import React from "react";
import "./Dashboard.css";
import Navbar from "../Shared/Navbar";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";


const Dashboard = () => {
  return (
    <div className="drawer drawer-mobile dashboard-bg">
      <input id="dashboard-sidebar" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content">
        <Navbar></Navbar>
        <Outlet></Outlet>
      </div>
      <div className="side_menu">
        <label htmlFor="dashboard-sidebar" className="drawer-overlay"></label>
        <Sidebar></Sidebar>      
      </div>
    </div>
  );
};

export default Dashboard;
