import DashboardCard from "./DashboardCard";
import frame1 from "../../assets/Frame1.png";
import frame2 from "../../assets/Frame2.png";
import Heading from "../Shared/Heading";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../BaseUrl";
import Loader from "../Shared/Loader";

import building from "../../assets/building.png";
import customer from "../../assets/customer.png";
import land from "../../assets/location-pin-land.png";

const DashboardHome = () => {
  const [dashboardCards, setDashboardCards] = useState(null);
  useEffect(() => {
    axios
      .get(baseUrl + "/admin/dashboard", {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        setDashboardCards([
          {
            _id: 1,
            title: "Number Of Custommers",
            number: res?.data?.data?.total_customer,
            landinfo: "",
            img: customer,
          },
          {
            _id: 3,
            title: "Total Sold Flat",
            number: res?.data?.data?.total_sold_flat,
            landinfo: "",
            img: building,
          },
          {
            _id: 4,
            title: "Total Sold Plot",
            number: res?.data?.data?.total_sold_plot,
            landinfo: "",
            img: land,
          },
          {
            _id: 5,
            title: "Total Unsold Flat",
            number: res?.data?.data?.total_unsold_flat,
            landinfo: "",
            img: building,
          },
          {
            _id: 5,
            title: "Total Unsold Plot",
            number: res?.data?.data?.total_unsold_plot,
            landinfo: "",
            img: land,
          },
        ]);
      });
  }, []);
  return (
    <div>
      <Heading>Dashboard</Heading>
      {dashboardCards == null ? (
        <Loader></Loader>
      ) : (
        <div className="grid sm:grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-5 my-5 p-2">
          {dashboardCards.map((dashboardItem, index) => (
            <DashboardCard
              key={dashboardItem._id + index}
              dashboardItem={dashboardItem}
            ></DashboardCard>
          ))}
        </div>
      )}
    </div>
  );
};

export default DashboardHome;
