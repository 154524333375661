import React from "react";
import Heading from "../../Shared/Heading";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { toast } from "react-toastify";

const AddPaymentSlip = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const viewCancelCustomerData = location.state.data;
  // console.log(viewCancelCustomerData);

  const onSubmit = (data) => {
    const canceled_customer_id = viewCancelCustomerData?.id;
    const flat_or_plot_id = viewCancelCustomerData?.flat_or_plot?.id;
    const newData = {
      ...data,
      canceled_customer_id,
      flat_or_plot_id,
    };
    // console.log(newData);
    axios
      .post(baseUrl + `/reports/cancelCustomerList/addPayment`, newData, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        // console.log(res);
        if (res?.data?.status === "failed") {
          // res?.data?.message.forEach((message) => {
          toast.error(res?.data?.message);
          // });
        } else {
          toast.success(res?.data?.message);
          navigate("/dashboard/canceledcustomerlist");
        }
      });
  };
  return (
    <div className="m-5">
      <Heading>
        <Link to="/dashboard/canceledcustomerlist">
          <i className="fa-solid fa-arrow-left"></i> Payment Slip
        </Link>
      </Heading>
      <div className="m-5">
        <form onSubmit={handleSubmit(onSubmit)} className="w-3/5">
          <fieldset className="border-2 rounded-md border-[#1F487C]">
            <legend className="mx-4">
              <p className="bg-[#1F497D] text-[white] py-1 px-4 rounded-md">
                {" "}
                Payment Slip
              </p>
            </legend>

            <div className="grid lg:grid-cols-2 m-4">
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      File No.*
                    </span>
                  </label>
                  <input
                    value={viewCancelCustomerData?.flat_or_plot?.file_no}
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Customer's Name*
                    </span>
                  </label>
                  <input
                    value={viewCancelCustomerData?.flat_or_plot?.customers?.map(
                      (customerName) => customerName.name
                    )}
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-4 m-4">
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Customer ID*
                    </span>
                  </label>
                  <input
                    value={viewCancelCustomerData?.flat_or_plot?.customers?.map(
                      (customerName) => customerName.id
                    )}
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Mobile Number*
                    </span>
                  </label>
                  <input
                    value={viewCancelCustomerData?.flat_or_plot?.customers?.map(
                      (customerName) => customerName.phone_number
                    )}
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Building/Land No.*
                    </span>
                  </label>
                  <input
                    value={
                      viewCancelCustomerData?.flat_or_plot?.project_info
                        ?.project_no
                    }
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Flat/Plot size*
                    </span>
                  </label>
                  <input
                    value={viewCancelCustomerData?.flat_or_plot?.size}
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-wrap m-4">
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Write new address here
                    </span>
                  </label>
                  <textarea
                    {...register("new_address")}
                    name="new_address"
                    className="textarea textarea-bordered"
                  ></textarea>
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Payment Date
                    </span>
                  </label>
                  <input
                    {...register("payment_date")}
                    name="payment_date"
                    type="date"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Payment Amount
                    </span>
                  </label>
                  <input
                    {...register("payment_amount")}
                    name="payment_amount"
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Payment Amount in Words
                    </span>
                  </label>
                  <input
                    {...register("amount_in_words")}
                    name="amount_in_words"
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap m-4">
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Payment Method Details
                    </span>
                  </label>
                  <select
                    {...register("payment_method")}
                    name="payment_method"
                    className="select select-bordered select-sm w-full max-w-lg"
                  >
                    <option disabled selected value="">
                      Select Payment Method
                    </option>
                    <option>Cash</option>
                    <option>BKash</option>
                    <option>Bank</option>
                    <option>Cheque</option>
                  </select>
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Enter Number
                    </span>
                  </label>
                  <input
                    {...register("invoice_no")}
                    name="invoice_no"
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Payment Receiver Name
                    </span>
                  </label>
                  <input
                    {...register("received_by")}
                    name="received_by"
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Transaction Completed Staff Name
                    </span>
                  </label>
                  <input
                    {...register("staff_name")}
                    name="staff_name"
                    type="text"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
            </div>
          </fieldset>

          <div className="flex justify-end">
            <button
              type="submit"
              className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 mb-10 m-2"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPaymentSlip;
