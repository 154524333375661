import React, { useState } from "react";
import Heading from "../../Shared/Heading";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

let allDatas = [];
const RegistrationAndMutationDueList = () => {
  const [registrationAndMutationDueList, setRegistrationAndMutationDueList] =
    useState([]);

  useEffect(() => {
    axios
      .get(baseUrl + `/reports/registrationAndMutationDueList`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        const registrationAndMutationDueCustomerList = res.data.data.filter(
          (item) => item.customers.length > 0
        );
        allDatas = registrationAndMutationDueCustomerList;
        setRegistrationAndMutationDueList(
          registrationAndMutationDueCustomerList
        );
      });
  }, []);

  //----------filter functionality start ---------------
  const handleFilterData = (e) => {
    console.log(e);
    if (e === "all") {
      setRegistrationAndMutationDueList(allDatas);
    } else if (e === "Total Registration & Mutation Cost Amount") {
      setRegistrationAndMutationDueList(
        allDatas?.filter(
          (list) =>
            parseInt(list?.registration_amount?.mutation_cost_amount) +
              parseInt(list?.registration_amount?.registry_amount) >
            0
        )
      );
    } else if (e === "Registration & Mutation Cost payment complete Amount") {
      setRegistrationAndMutationDueList(
        allDatas?.filter(
          (list) =>
            parseInt(list?.registration_amount?.mutation_cost_payment) +
              parseInt(list?.registration_amount?.registry_payment) >
            0
        )
      );
    } else if (e === "Registration & Mutation Cost Due amount") {
      setRegistrationAndMutationDueList(
        allDatas?.filter(
          (list) =>
            parseInt(list?.registration_amount?.mutation_cost_amount) +
              parseInt(list?.registration_amount?.registry_amount) -
              (parseInt(list?.registration_amount?.mutation_cost_payment) +
                parseInt(list?.registration_amount?.registry_payment)) !==
            0
        )
      );
    } else if (e === "Registration & Mutation Payment complete Date") {
      // setRegistrationAndMutationDueList(
      //   allDatas?.filter(
      //     (list) => list?.plot_or_flat_details?.registration_date
      //   )
      // );
    } else if (e === "Registration Deed complete Date") {
      setRegistrationAndMutationDueList(
        allDatas?.filter(
          (list) => list?.plot_or_flat_details?.registration_date
        )
      );
    } else if (e === "Registration Deed number") {
      setRegistrationAndMutationDueList(
        allDatas?.filter((list) => list?.plot_or_flat_details?.sub_deed_no)
      );
    } else if (e === "Mutation Submission Date") {
      setRegistrationAndMutationDueList(
        allDatas?.filter((list) => list?.mutation_details?.registration_date)
      );
    } else if (e === "Mutation Submission Number") {
      setRegistrationAndMutationDueList(
        allDatas?.filter((list) => list?.mutation_details?.misscase_no)
      );
    } else if (e === "Mutation Complete Date") {
      // setRegistrationAndMutationDueList(
      //   allDatas?.filter((list) => list?.mutation_details?.misscase_no)
      // );
    }
  };
  //----------filter functionality End ---------------

  //----------Print a div or section functionality start-----------
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //----------Print a div or section functionality end-------------

    // ---------------search table data start------------------
    const handleSearch = (e) => {
      // console.log(e.target.value);
      // Declare variables
      let status = 0;
      let filterBy = e.target.value.toUpperCase();
      let table = document.getElementById("dataTable");
      let tr = table.querySelectorAll(".searchData");
      let i = 0;
      let j = 0;
      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        let tempTd = tr[i].getElementsByTagName("td");
        for (j = 0; j < tempTd.length; j++) {
          let td = tempTd[j];
          let txtValue;
          if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filterBy) > -1) {
              status = 1;
            }
            if (!status) {
              status = 0;
            }
          }
        }
        if (status) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    };
    // ------------------search table data end-----------------
  return (
    <div>
      <div>
        <Heading>Registration & Mutation Due List</Heading>
        <div className="p-5 whiteBackground">
          <div className="flex flex-wrap justify-between items-center my-5">
            <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
            <div className="my-2">
              <div className="form-control">
                <div className="flex flex-wrap items-center">
                  <h1 className="m-3 text-[#717A82] font-bold">
                    Search by date
                  </h1>
                  <input
                    name="complete_date"
                    type="date"
                    className="input input-bordered input-sm max-w-xs"
                  />
                  <h1 className="m-3 text-[#717A82] font-bold">Search for</h1>
                  <select
                    onChange={(e) => handleFilterData(e.target.value)}
                    className="select select-sm select-bordered  my-2 rounded-[5px] bg-[#ECECEC]"
                  >
                    <option value={"all"}>All</option>
                    <option value={"Total Registration & Mutation Cost Amount"}>
                      Total Registration & Mutation Cost Amount
                    </option>
                    <option
                      value={
                        "Registration & Mutation Cost payment complete Amount"
                      }
                    >
                      Registration & Mutation Cost payment complete Amount
                    </option>
                    <option value={"Registration & Mutation Cost Due amount"}>
                      Registration & Mutation Cost Due amount
                    </option>
                    <option
                      value={"Registration & Mutation Payment complete Date"}
                    >
                      Registration & Mutation Payment complete Date
                    </option>
                    <option value={"Registration Deed complete Date"}>
                      Registration Deed complete Date
                    </option>
                    <option value={"Registration Deed number"}>
                      Registration Deed number
                    </option>
                    <option value={"Mutation Submission Date"}>
                      Mutation Submission Date
                    </option>
                    <option value={"Mutation Submission Number"}>
                      Mutation Submission Number
                    </option>
                    <option value={"Mutation Complete Date"}>
                      Mutation Complete Date
                    </option>
                  </select>
                  <input
                  onChange={handleSearch}
                    id="searchInput"
                    type="text"
                    placeholder="Search..."
                    className="input input-bordered input-sm max-w-xs ml-1"
                  />
                </div>
              </div>
            </div>
          </div>
          {registrationAndMutationDueList?.length > 0 ? (
            <div ref={componentRef} className="overflow-x-auto max-h-[60vh]">
              <table id="dataTable" className="table table-zebra w-full max-h-[60vh]">
                <thead className="text-[#00CA08]">
                  <tr className="sticky top-0">
                    <th>Customer's Id</th>
                    <th>Customer's Name</th>
                    <th>File No.</th>
                    <th>
                      Building/Land <br /> No.
                    </th>
                    <th>
                      Flat/Plot <br /> Size
                    </th>
                    <th>
                      Total Registration <br />
                      & Mutation Cost <br />
                      Amount
                    </th>
                    <th>
                      Registration <br />
                      & Mutation Cost <br />
                      payment complete <br />
                      Amount
                    </th>
                    <th>
                      Registration <br />
                      & Mutation Cost <br />
                      Due amount <br />
                    </th>
                    <th>
                      Registration <br />
                      & Mutation <br />
                      Payment complete <br />
                      Date
                    </th>
                    <th>
                      Registration Deed <br />
                      complete Date
                    </th>
                    <th>
                      Registration <br />
                      Deed <br />
                      number
                    </th>
                    <th>
                      Mutation <br />
                      Submission <br />
                      Date
                    </th>
                    <th>
                      Mutation <br />
                      Submission <br />
                      Number
                    </th>
                    <th>
                      Mutation <br />
                      Complete <br />
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {registrationAndMutationDueList?.map((list, index) => {
                    return (
                      <tr className="searchData" key={index}>
                        <td>{list.id}</td>
                        <td>
                          {list?.customers?.map((customer, index) => (
                            <p key={index}>
                              {customer?.name ? customer?.name : "--"}
                            </p>
                          ))}
                        </td>
                        <td>{list?.flat_or_plot_details?.file_no}</td>
                        <td>
                          {list?.project?.project_no
                            ? list?.project?.project_no
                            : "--"}
                        </td>
                        <td>{list?.flat_or_plot_details?.size}</td>
                        <td>
                          {parseInt(
                            list?.registration_amount?.mutation_cost_amount
                          ) +
                            parseInt(
                              list?.registration_amount?.registry_amount
                            )}
                        </td>
                        <td>
                          {parseInt(
                            list?.registration_amount?.mutation_cost_payment
                          ) +
                            parseInt(
                              list?.registration_amount?.registry_payment
                            )}
                        </td>
                        <td>
                          {parseInt(
                            list?.registration_amount?.mutation_cost_amount
                          ) +
                            parseInt(
                              list?.registration_amount?.registry_amount
                            ) -
                            (parseInt(
                              list?.registration_amount?.mutation_cost_payment
                            ) +
                              parseInt(
                                list?.registration_amount?.registry_payment
                              ))}
                        </td>
                        <td>dd-mm-yy</td>
                        <td>
                          {list?.plot_or_flat_details?.registration_date
                            ? list?.plot_or_flat_details?.registration_date
                            : "--"}
                        </td>
                        <td>
                          {list?.plot_or_flat_details?.sub_deed_no
                            ? list?.plot_or_flat_details?.sub_deed_no
                            : "--"}
                        </td>
                        <td>
                          {list?.mutation_details?.registration_date
                            ? list?.mutation_details?.registration_date
                            : "--"}
                        </td>
                        <td>
                          {list?.mutation_details?.misscase_no
                            ? list?.mutation_details?.misscase_no
                            : "--"}
                        </td>
                        <td>dd-mm-yy</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <h1 className="text-center font-bold my-5 py-5">No Data Found</h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegistrationAndMutationDueList;
