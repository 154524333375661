import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { baseUrl } from "../../../BaseUrl";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import Heading from "../../Shared/Heading";
import Loader from "../../Shared/Loader";
// import PaginationSection from "../../Shared/PaginationSection";

const ClientPriceInfoHome = () => {
  const [clientPriceList, setClientPriceList] = useState(null);
  console.log(clientPriceList);
  useEffect(() => {
    axios
      .get(
        baseUrl + `/customer/price/list`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setClientPriceList(res?.data?.data?.data);
      });
  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // ---------------search table data start------------------
  const handleSearch = (e) => {
    // console.log(e.target.value);
    // Declare variables
    let status = 0;
    let filterBy = e.target.value.toUpperCase();
    let table = document.getElementById("dataTable");
    let tr = table.querySelectorAll(".searchData");
    let i = 0;
    let j = 0;
    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      let tempTd = tr[i].getElementsByTagName("td");
      for (j = 0; j < tempTd.length; j++) {
        let td = tempTd[j];
        let txtValue;
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filterBy) > -1) {
            status = 1;
          }
          if (!status) {
            status = 0;
          }
        }
      }
      if (status) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  };
  // ------------------search table data end-----------------
  return (
    <div>
      <Heading>Client Price Information</Heading>
      <div className="whiteBackground">
        <div className="p-5">
          <div className="flex flex-wrap justify-between my-5">
            <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
            <div>
              <input
                onChange={handleSearch}
                id="searchInput"
                type="text"
                placeholder="Search..."
                className="input input-bordered input-sm w-full max-w-xs my-2"
              />
            </div>
          </div>
          {clientPriceList == null ? (
            <Loader></Loader>
          ) : (
            <div className="overflow-x-auto max-h-[60vh]">
              <table
                id="dataTable"
                className="table table-zebra w-full"
                ref={componentRef}
              >
                <thead className="text-[#00CA08]">
                  <tr className="sticky top-0">
                    <th>
                      Customer's <br /> Country
                    </th>
                    <th>
                      Customer's <br /> Name
                    </th>
                    <th>
                      Customer's <br /> Id
                    </th>
                    <th>
                      Building/ <br /> Land No
                    </th>
                    <th>File No.</th>
                    <th>
                      Flat/Plot <br />
                      No.
                    </th>
                    <th>
                      Flat/Plot <br />
                      Size
                    </th>
                    <th>
                      Flat/Plot <br />
                      Price
                    </th>
                    <th>
                      Utillity <br /> Charge
                    </th>
                    <th>
                      Car Parking <br /> Price
                    </th>
                    <th>
                      Total Additional <br /> Amount
                    </th>
                    <th>Total Amount</th>
                    <th>
                      Number Of <br /> Installments
                    </th>
                    <th className="hide-when-print">
                      Action <br /> Add/Edit
                    </th>
                    <th className="hide-when-print">View</th>
                  </tr>
                </thead>
                <tbody>
                  {clientPriceList?.length > 0 ? (
                    clientPriceList.map((list, index) => (
                      <tr className="searchData" key={index}>
                        <td>
                          {list.customers.map((singleCustomer) => (
                            <h1 key={singleCustomer.id}>
                              {singleCustomer.country}
                            </h1>
                          ))}
                        </td>
                        <td>
                          {list.customers.map((singleCustomer) => (
                            <h1 key={singleCustomer.id}>
                              {singleCustomer.name}
                            </h1>
                          ))}
                        </td>
                        <td>
                          {list.customers.map((singleCustomer) => (
                            <h1 key={singleCustomer.id}>{singleCustomer.id}</h1>
                          ))}
                        </td>
                        <td>{list.project?.project_no}</td>
                        <td>{list.file_no}</td>
                        {list?.plot_no ? (
                          <td>{list.plot_no}</td>
                        ) : (
                          <td>{list.flat_number}</td>
                        )}
                        <td>{list.size}</td>
                        <td>
                          {list?.price_information?.flat_or_plot_price !==
                          undefined ? (
                            `${list?.price_information?.flat_or_plot_price}Tk`
                          ) : (
                            <p className="">--</p>
                          )}
                        </td>
                        <td>
                          {list?.price_information?.utility_charge !=
                          undefined ? (
                            `${list?.price_information?.utility_charge} Tk`
                          ) : (
                            <p className="">--</p>
                          )}{" "}
                        </td>
                        <td>
                          {list?.price_information?.car_parking != undefined ? (
                            `${list?.price_information?.car_parking} Tk`
                          ) : (
                            <p className="">--</p>
                          )}{" "}
                        </td>
                        <td>
                          {list?.price_information?.total_additional_amount
                            ? `${list?.price_information?.total_additional_amount} TK`
                            : "--"}
                        </td>
                        <td>
                          {list?.price_information
                            ? `${
                                parseInt(
                                  list?.price_information?.flat_or_plot_price
                                ) +
                                parseInt(
                                  list?.price_information?.utility_charge
                                ) +
                                parseInt(list?.price_information?.car_parking) +
                                parseInt(
                                  list?.price_information
                                    ?.total_additional_amount
                                )
                              } TK`
                            : "--"}
                        </td>
                        <td>
                          {list?.price_information?.number_of_installment !=
                          undefined ? (
                            list?.price_information?.number_of_installment
                          ) : (
                            <p className="">--</p>
                          )}
                        </td>
                        <td className="text-center hide-when-print">
                          {list?.price_information?.id == undefined ? (
                            <Link
                              to={
                                list?.plot_no !== null
                                  ? `addplotprice/${list?.id}`
                                  : `addflatprice/${list?.id}`
                              }
                            >
                              <i className="fa-solid fa-square-plus m-2 text-xl"></i>
                            </Link>
                          ) : (
                            <Link
                              to={
                                list?.plot_no !== null
                                  ? `updateplotprice/${list.id}`
                                  : `updateflatprice/${list.id}`
                              }
                            >
                              <i className="fa-solid fa-pen-to-square m-2 text-xl"></i>
                            </Link>
                          )}
                        </td>
                        <td className="hide-when-print">
                          <Link
                            to={
                              list?.plot_no !== null
                                ? `viewplotclientprice/${list?.id}`
                                : `viewflatclientprice/${list?.id}`
                            }
                          >
                            <i className="fa-solid fa-eye m-2 text-xl"></i>
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <h1 className="text-center font-bold my-5 py-5">
                      No Data Found
                    </h1>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientPriceInfoHome;
