import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { baseUrl } from "../../../BaseUrl";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import Heading from "../../Shared/Heading";

const ViewPlotClientPrice = () => {
  const { clientId } = useParams();
  const [plotClientInfo, setPlotClientInfo] = useState([]);
  // console.log(plotClientInfo);
  useEffect(() => {
    axios
      .get(
        baseUrl + `/customer/price/detail/${clientId}`,

        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        // console.log(res?.data?.detail);
        setPlotClientInfo(res?.data?.detail);
      });
  }, []);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div>
      <Heading>
        <Link to="/dashboard/clientpriceinfo">
          <i className="fa-solid fa-arrow-left"></i> View Plot Client Price
          Information
        </Link>
      </Heading>
      <div className="information_container">
        <div className="my-8">
          <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
        </div>
        <div className="infomation_content print-section" ref={componentRef}>
          <h1 className="title text-center text-2xl font-bold underline underline-offset-4 text-[#333547] pt-8">
            Client Price Information
          </h1>
          <div className="m-6">
            {plotClientInfo?.customers?.map((customer, index) => {
              return (
                <div className="mb-3" key={index}>
                  <p className="mt-2">
                    <span className="underline underline-offset-2 mr-1 font-semibold">
                      Customer Name:
                    </span>
                    {customer?.name}
                  </p>
                  <h1>
                    <span className="underline underline-offset-2 mr-1 font-semibold">
                      Customer ID:
                    </span>
                    {customer?.id}
                  </h1>
                </div>
              );
            })}
            <h1 className="mt-5">
              <span className="underline underline-offset-2 mr-1 font-semibold">
                Media Name:
              </span>
              {plotClientInfo?.customers?.length > 0 &&
                plotClientInfo?.customers[0]?.sold_item?.media_name}
            </h1>
            <h1>
              <span className="underline underline-offset-2 mr-1 font-semibold">
                Media Phone Number:
              </span>

              {plotClientInfo?.customers?.length > 0 &&
                plotClientInfo?.customers[0]?.sold_item?.media_phone_number}
            </h1>
            <h1 className="mt-4">
              <span className="underline underline-offset-2 mr-1 font-semibold">
                File No:
              </span>
              {plotClientInfo?.file_no}
            </h1>
            {/* <h1>
              <span className="underline underline-offset-2 mr-1 font-semibold">
                Other File No:
              </span>
              NA
            </h1> */}
          </div>

          <h1 className="text-xl font-semibold underline underline-offset-2 m-6">
            Land Details
          </h1>
          <div className="grid lg:grid-cols-2 gap-2">
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Land Name :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={plotClientInfo?.project?.name}
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Land No :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={plotClientInfo?.project?.project_no}
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Land Location :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={plotClientInfo?.project?.location}
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Land Face Direction :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={plotClientInfo?.project?.face_direction}
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Road No :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={plotClientInfo?.project?.road_no}
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Plot Size :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={plotClientInfo?.project?.land_size}
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Plot No :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={plotClientInfo?.plot_no}
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Plot Face Direction :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={plotClientInfo?.face_direction}
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Plot Flor No :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={plotClientInfo?.floor_no}
              />
            </div>
          </div>
          <h1 className="text-xl font-semibold underline underline-offset-4 m-6">
            Pricing Details (With start dates)
          </h1>
          {plotClientInfo?.price_information == null ? (
            <h2 className="px-2 mx-4 text-slate-900 text-[23px] font-bold">
              No Price Information found
            </h2>
          ) : (
            <div className="grid lg:grid-cols-2">
              <div className="grid grid-cols-2 px-2 mx-4">
                <label>Total Price :</label>
                <input
                  className="outline-none border-b-2 border-[#8C8C8C]"
                  type="text"
                  name=""
                  id=""
                  value={plotClientInfo?.price_information?.total_money + ` Tk`}
                />
              </div>
              <div className="grid grid-cols-2 px-2 mx-4">
                <label>Booking Money :</label>
                <input
                  className="outline-none border-b-2 border-[#8C8C8C]"
                  type="text"
                  name=""
                  id=""
                  value={
                    plotClientInfo?.price_information?.booking_money + ` Tk`
                  }
                />
              </div>
              <div className="grid grid-cols-2 px-2 mx-4">
                <label>Car Parking :</label>
                <input
                  className="outline-none border-b-2 border-[#8C8C8C]"
                  type="text"
                  name=""
                  id=""
                  value={plotClientInfo?.price_information?.car_parking + ` Tk`}
                />
              </div>
              <div className="grid grid-cols-2 px-2 mx-4">
                <label>Utility Charge :</label>
                <input
                  className="outline-none border-b-2 border-[#8C8C8C]"
                  type="text"
                  name=""
                  id=""
                  value={
                    plotClientInfo?.price_information?.utility_charge + ` Tk`
                  }
                />
              </div>
            </div>
          )}
          <hr className="my-8 mx-4 h-1 bg-gray-200 dark:bg-gray-700"></hr>

          <div className="grid grid-cols-2 px-2 mx-4">
            <label>Total Additional Amount :</label>
            <input
              className="outline-none border-b-2 border-[#8C8C8C]"
              type="text"
              name=""
              id=""
              value={
                plotClientInfo?.price_information?.total_additional_amount +
                ` Tk`
              }
            />
          </div>
          {plotClientInfo?.price_information?.additional_amount?.map((list) => {
            return (
              <div key={list.id} className="grid grid-cols-2 px-2 mx-4">
                <label>{list?.amount_name} :</label>
                <input
                  className="outline-none border-b-2 border-[#8C8C8C]"
                  type="text"
                  name=""
                  id=""
                  value={list?.amount + ` Tk`}
                />
              </div>
            );
          })}
          <hr className="my-8 mx-4 h-1 bg-gray-200 dark:bg-gray-700"></hr>
          <div className="grid lg:grid-cols-2">
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Downpayment Amount :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={
                  plotClientInfo?.price_information?.total_downpayment_amount
                    ? `${plotClientInfo?.price_information?.total_downpayment_amount} Tk`
                    : `0 Tk`
                }
              />
            </div>
            {plotClientInfo?.price_information?.down_payment.length > 0 &&
              plotClientInfo?.price_information?.down_payment?.map(
                (downPay, index) => {
                  return (
                    <div className="grid grid-cols-2 px-2 mx-4" key={index}>
                      <label>Downpayment {index + 1} :</label>
                      <input
                        className="outline-none border-b-2 border-[#8C8C8C]"
                        type="text"
                        value={downPay.amount ? `${downPay.amount} Tk` : `0 Tk`}
                      />
                    </div>
                  );
                }
              )}
          </div>
          <hr className="my-8 mx-4 h-1 bg-gray-200 dark:bg-gray-700"></hr>
          <div className="grid lg:grid-cols-2">
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>Installment Amount :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={
                  plotClientInfo?.price_information?.total_installment_amount
                    ? `${plotClientInfo?.price_information?.total_installment_amount} Tk`
                    : `0 Tk`
                }
              />
            </div>
            <div className="grid grid-cols-2 px-2 mx-4">
              <label>No Of Installment :</label>
              <input
                className="outline-none border-b-2 border-[#8C8C8C]"
                type="text"
                name=""
                id=""
                value={
                  plotClientInfo?.price_information?.number_of_installment
                    ? plotClientInfo?.price_information?.number_of_installment
                    : 0
                }
              />
            </div>
            {plotClientInfo?.price_information?.installment &&
              plotClientInfo?.price_information?.installment?.length > 0 &&
              plotClientInfo?.price_information?.installment?.map(
                (installment, index) => {
                  return (
                    <div className="grid grid-cols-2 px-2 mx-4" key={index}>
                      <label>Installment {index + 1} :</label>
                      <input
                        className="outline-none border-b-2 border-[#8C8C8C]"
                        type="text"
                        name=""
                        id=""
                        value={installment?.amount + ` TK`}
                      />
                    </div>
                  );
                }
              )}
          </div>

          <div className="flex justify-around my-10 py-10">
            <div className="mt-20">
              <hr className="w-full bg-slate-400 h-0.5" />
              <h1>Customer’s Signature</h1>
            </div>
            <div className="mt-20">
              <hr className="w-full bg-slate-400 h-0.5" />
              <h1>Official Signature</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPlotClientPrice;
