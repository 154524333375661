import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../BaseUrl";
import Loader from "../Shared/Loader";

const Login = () => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [btnDisable, setBtnDisable] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("crm-tdata"));
    if (token == null || token == undefined || token == "") {
      setLoading(false);
    } else {
      return navigate("/dashboard");
    }
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    setBtnDisable(true);
    const form = e.target;
    const phone = form.phoneNumber.value;
    const password = form.password.value;
    // console.log(phone, password);

    const data = {
      phone,
      password,
    };
    if (phone == "") {
      toast.error("Phone number is required!");
      return;
    } else if (password == "") {
      toast.error("Password is required!");
      return;
    }
    if (phone !== "" && password !== "") {
      axios
        .post(baseUrl + "/login", data)
        .then((res) => {
          if (res?.data?.status !== "failed") {
            toast.success("Login successfully");
            const token = res?.data?.token;
            if (token) {
              const tokenStr = JSON.stringify(token);
              localStorage.setItem("crm-tdata", tokenStr);
              navigate("/dashboard");
            }
            setBtnDisable(false);
          }
        else{
          toast.warn(res?.data?.message);
          setBtnDisable(false);
        }}
        
        )
        .catch((res) => {
          for (const key in res?.response?.data?.errors) {
            toast.error(res?.response?.data?.errors[key][0]);
          }
          setBtnDisable(false);
        });
    }
  };
  return (
    <>
      {loading == true ? (
        <div className="h-[100vh] flex items-center justify-center bg-slate-900">
          <Loader></Loader>
        </div>
      ) : (
        <>
          <div
            className="hero min-h-screen"
            style={{
              // backgroundImage: `url("https://placeimg.com/1000/800/arch")`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="hero-overlay  bg-slate-900"></div>
            <div className="w-[97%] max-w-[420px] hero-content text-center text-neutral-content bg-slate-800 border-slate-400 border-2 rounded-[4px] py-[50px]">
              <form className="" onSubmit={handleLogin}>
                <h1 className="text-3xl text-left font-bold mb-5 max-w-xs mx-auto">
                  Login
                </h1>
                <input
                  name="phoneNumber"
                  type="text"
                  placeholder="Type phone number"
                  className="rounded-[4px] input input-bordered text-black w-full max-w-xs my-4"
                />
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  type="password"
                  placeholder="Type Password"
                  className="rounded-[4px] input input-bordered text-black w-full max-w-xs my-4"
                />
                <p className="text-left max-w-xs mx-auto text-red-400  mt-0">
                  {password != "" &&
                    password?.length < 8 &&
                    "Password at least 8 character"}
                </p>

                <div className=" text-left text-slate-300  max-w-xs mx-auto  flex items-center gap-2 my-4">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-success border-1 border-slate-200 h-[20px] w-[20px]"
                    id="remember-me"
                  />
                  <span className="text-left">Remember me</span>
                </div>

                {btnDisable == false ? (
                  <button
                    className="rounded-[4px] btn btn-primary px-14 border-0 mt-2 bg-green-600 w-full max-w-xs hover:bg-green-700"
                    type="submit"
                  >
                    Login
                  </button>
                ) : (
                  <label className="rounded-[4px] btn btn-primary px-14 border-0 mt-2 bg-green-600 w-full max-w-xs hover:bg-green-700">
                    Processing...
                  </label>
                )}
                <div className=" text-slate-400  max-w-xs mx-auto text-right">
                  <p className=" max-w-xs mx-auto mt-1">Forgot password?</p>
                </div>
              </form>
            </div>
          </div>
        </>
      )}{" "}
    </>
  );
};

export default Login;
