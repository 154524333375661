import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import Heading from "../../Shared/Heading";
import PaginationSection from "../../Shared/PaginationSection";
import Modal from "react-modal";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import Loader from "../../Shared/Loader";
import { useReactToPrint } from "react-to-print";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0 0 0 500vmax rgb(0 0 0 / 0.3)",
  },
};

const ViewPowerAttorneyCustomer = () => {
  const { id } = useParams();
  const [modalIsOpen, setIsOpen] = useState(false);

  const [poaData, setPoaData] = useState(null);

  useEffect(() => {
    axios
      .get(
        baseUrl + `/registration/powerOfAttorneyDetails/powerOfAttorney/${id}`,

        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        // console.log(res?.data?.powerOfAttorney);
        setPoaData(res?.data?.powerOfAttorney);
      });
  }, []);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div>
      <Heading>
        <Link to="/dashboard/registrationnumberdetails/powerattorney">
          <i className="fa-solid fa-arrow-left"></i> Registered Customer List
        </Link>
      </Heading>
      <div className="information_container">
        <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
        {poaData == null ? (
          <Loader></Loader>
        ) : (
          <div className="infomation_content print-section" ref={componentRef}>
            <h1 className="title text-center text-2xl font-bold underline underline-offset-4 text-[#333547] pt-8">
              Power of Atorney Details
            </h1>
            <div className="m-8 table-container">
              <h1 className="text-start text-md font-bold underline underline-offset-4 text-[#717A82] ">
                Details
              </h1>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-2">
                <div className="form-control w-full  mt-5">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Power of Attorney Date
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={poaData?.registration_date}
                    className="input input-bordered input-sm w-full "
                  />
                </div>
                <div className="form-control w-full  mt-5">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Power of Attorney Sub-Deed No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={poaData?.sub_deed_no}
                    className="input input-bordered input-sm w-full "
                  />
                </div>
                <div className="form-control w-full  mt-5">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Total Land Size(Decimal)
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={poaData?.land_size}
                    className="input input-bordered input-sm w-full "
                  />
                </div>
              </div>
              <div className="form-control w-full  mt-5">
                <label className="label">
                  <span className="label-text font-bold text-[#717A82]">
                    Mouza Name
                  </span>
                </label>
                <input
                  disabled
                  type="text"
                  value={poaData?.mouza_name}
                  className="input input-bordered input-sm w-full "
                />
              </div>
              <h1 className="text-start text-md font-bold underline underline-offset-4 text-[#717A82] mt-8">
                Dagg No.
              </h1>
              <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-2">
                <div className="form-control w-full  mt-5">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      CS Daag No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={poaData?.cs_daag_no}
                    className="input input-bordered input-sm w-full "
                  />
                </div>
                <div className="form-control w-full  mt-5">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      SA Dagg No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={poaData?.sa_daag_no}
                    className="input input-bordered input-sm w-full "
                  />
                </div>
                <div className="form-control w-full  mt-5">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      RS Dagg No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={poaData?.rs_daag_no}
                    className="input input-bordered input-sm w-full "
                  />
                </div>
                <div className="form-control w-full  mt-5">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      BS Dagg No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={poaData?.bs_daag_no}
                    className="input input-bordered input-sm w-full "
                  />
                </div>
              </div>
              <h1 className="text-start text-md font-bold underline underline-offset-4 text-[#717A82] mt-8">
                Khatian No.
              </h1>
              <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-2 ">
                <div className="form-control w-full  mt-5">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      CS Khatian No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={poaData?.cs_khatian_no}
                    className="input input-bordered input-sm w-full "
                  />
                </div>
                <div className="form-control w-full  mt-5">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      SA Khatian No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={poaData?.sa_khatian_no}
                    className="input input-bordered input-sm w-full "
                  />
                </div>
                <div className="form-control w-full  mt-5">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      RS Khatian No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={poaData?.rs_khatian_no}
                    className="input input-bordered input-sm w-full "
                  />
                </div>
                <div className="form-control w-full  mt-5">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      BS Khatian No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={poaData?.bs_khatian_no}
                    className="input input-bordered input-sm w-full "
                  />
                </div>
              </div>
            </div>
            <div className="overflow-x-auto m-5 pt-8  max-h-[60vh] table-container">
              <table className="table table-zebra w-[100%]">
                <thead className="text-[#00CA08]">
                  <tr className="sticky top-0">
                    <th>Customer's Name</th>
                    <th>Customer's Id</th>
                    <th>File No.</th>
                    <th>
                      Flat/Plot <br /> No.
                    </th>
                    <th>
                      Building No./ <br />
                      Project Name
                    </th>
                    <th>
                      Individual <br /> Land Size
                    </th>
                    <th className="hide-when-print">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {poaData?.power_of_attorney_registration
                    ?.flat_or_plot_for_power_of_attorney ? (
                    <tr>
                      <td>
                        {poaData?.power_of_attorney_registration?.flat_or_plot_for_power_of_attorney?.customers?.map(
                          (customer, index) => (
                            <p key={index}>{customer?.name}</p>
                          )
                        )}
                      </td>
                      <td>
                        {" "}
                        {poaData?.power_of_attorney_registration?.flat_or_plot_for_power_of_attorney?.customers?.map(
                          (customer, index) => (
                            <p key={index}>{customer?.nid_number}</p>
                          )
                        )}
                      </td>
                      <td>
                        {
                          poaData?.power_of_attorney_registration
                            ?.flat_or_plot_for_power_of_attorney?.file_no
                        }
                      </td>
                      <td>
                        {poaData?.power_of_attorney_registration
                          ?.flat_or_plot_for_power_of_attorney?.flat_number
                          ? poaData?.power_of_attorney_registration
                              ?.flat_or_plot_for_power_of_attorney?.flat_number
                          : poaData?.power_of_attorney_registration
                              ?.flat_or_plot_for_power_of_attorney?.plot_no}
                      </td>
                      <td>
                        {
                          poaData?.power_of_attorney_registration
                            ?.flat_or_plot_for_power_of_attorney?.project_id
                        }
                      </td>
                      <td>{poaData?.land_size}</td>
                      <td className="hide-when-print">
                        <button onClick={openModal}>
                          <i className="fa-solid fa-pen-to-square m-2 text-xl"></i>
                        </button>
                      </td>
                    </tr>
                  ) : (
                    <h2
                      className="text-center py-5 col-span-10 font-bold"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      No Power of Attorney Details Found
                    </h2>
                  )}
                </tbody>
              </table>
            </div>
            <div className="m-5 py-4">
              {/* <PaginationSection></PaginationSection> */}
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <form>
          <div className="form-control w-full max-w-xs m-5">
            <label className="label">
              <span className="label-text font-bold text-[#717A82]">
                Individual Land Size
              </span>
            </label>
            <input
              type="text"
              placeholder="32565"
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
          <div className="mt-5">
            <button
              onClick={closeModal}
              className="uppercase  text-white text-[14px] font-semibold bg-[#E30B0B] rounded-md py-2.5 px-16 m-2"
            >
              Cancel
            </button>

            <button
              onClick={closeModal}
              type="submit"
              className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 mb-10 m-2"
            >
              Update
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ViewPowerAttorneyCustomer;
