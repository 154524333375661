import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../BaseUrl";
import Heading from "../../Shared/Heading";

const AddPaymentReceived = () => {
  const [singleData, setSingleData] = useState({});
  const { state } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(
        baseUrl + `/registration/amount/due_details/${state?.id}`,

        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        if (res?.status == 200) {
          setSingleData(res?.data?.data);
        }
      });
  }, [state?.id]);
  const formikProps = useFormik({
    enableReinitialize: true,
    initialValues: {
      payment_against: "",
      payment_amount: "",
      payment_date: "",
      pay_by: "",
      money_receipt_no: "",
      id: state?.id,
    },
    onSubmit: (values) => {
      axios
        .post(baseUrl + `/registration/amount/add_payment`, values, {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        })
        .then((res) => {
          // console.log(res);
          if (res?.data?.status == "failed") {
            toast.error(res?.data?.message);
            return;
          }
          if (res?.status == 200) {
            toast.success("Payment receive amount added  successfully..");
            navigate("/dashboard/registrationamount");
          }
        })
        .catch((res) => {
          // console.log(res?.data);
          if (res?.response?.status == 422) {
            toast.error(res?.response?.data?.message);
          }
        });
    },
  });
  // console.log(formikProps.values,state,"state ");

  return (
    <div>
      <Heading>
        <Link to="/dashboard/registrationamount">
          <i className="fa-solid fa-arrow-left"></i> Add Payment Received Amount
        </Link>
      </Heading>
      <form onSubmit={formikProps.handleSubmit}>
        <div className="p-5 bg-white shadow-2xl lg:w-[43%] rounded-lg mx-auto my-8">
          <div className="flex flex-wrap ">
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text">File No.</span>
              </label>
              <input
                disabled
                type="text"
                value={singleData?.file_no}
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text">Date</span>
              </label>
              <input
                type="date"
                name="payment_date"
                onChange={formikProps.handleChange}
                value={formikProps.values.payment_date}
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text">Customer's Name</span>
              </label>
              <input
                disabled
                type="text"
                value={singleData?.customers?.map(
                  (item) =>
                    `${item?.name} ${
                      singleData?.customers?.length > 1 ? "/" : ""
                    }`
                )}
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text">Customer's Id</span>
              </label>
              <input
                disabled
                type="text"
                value={singleData?.customers?.map(
                  (item) =>
                    `${item?.nid_number}${
                      singleData?.customers?.length > 1 ? "/" : ""
                    }`
                )}
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
          </div>
          <div className="m-2">
            <h1 className="text-xl font-semibold text-[gray] underline te underline-offset-2 mt-8 mb-4">
              Payment Option
            </h1>
          </div>
          <div className="flex flex-wrap">
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text font-bold text-[#717A82]">
                  Pay By
                </span>
              </label>
              <select
                name="pay_by"
                onChange={formikProps.handleChange}
                className="select select-bordered select-sm"
              >
                <option disabled selected>
                  Please Select
                </option>
                <option value={"Bkash"}>Bkash</option>
                <option value={"Nagad"}>Nagad</option>
                <option value={"Rocket"}>Rocket</option>
                <option value={"Cash"}>Cash</option>
                <option value={"Bank Cheque"}>Bank Cheque</option>
              </select>
            </div>
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text font-bold text-[#717A82]">
                  Money Receipt No.
                </span>
              </label>
              <input
                name="money_receipt_no"
                value={formikProps.values.money_receipt_no}
                type="text"
                onChange={formikProps.handleChange}
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text font-bold text-[#717A82]">
                  Against
                </span>
              </label>
              <select
                name="payment_against"
                onChange={formikProps.handleChange}
                className="select select-bordered select-sm"
              >
                <option disabled selected>
                  Please Select
                </option>
                <option value={1}>Registry Amount</option>
                <option value={2}>Mutation Coust Amount</option>
                <option value={3}>Power Of Attorney Cost</option>
              </select>
            </div>
            <div className="form-control w-full max-w-xs m-2">
              <label className="label">
                <span className="label-text font-bold text-[#717A82]">
                  Amount Tk
                </span>
              </label>
              <input
                name="payment_amount"
                value={formikProps.values.payment_amount}
                type="text"
                onChange={formikProps.handleChange}
                className="input input-bordered input-sm w-full max-w-xs"
              />
            </div>
          </div>

          <div className="flex flex-wrap justify-start mt-8">
            <Link to="/dashboard/registrationamount">
              <button
                type="submit"
                className="uppercase  text-white text-[14px] font-semibold bg-[#E30B0B] rounded-md py-2.5 px-16 m-2"
              >
                Cancel
              </button>
            </Link>

            <button
              type="submit"
              className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-2.5 px-16 mb-10 m-2"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddPaymentReceived;
