import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from "../../../BaseUrl";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import Loader from "../../Shared/Loader";
import { useReactToPrint } from "react-to-print";

const PowerAttorneySearch = () => {
  const [gridData, setGridData] = useState(null);

  useEffect(() => {
    const data = {
      name: "powerOfAttorneyDetailsType",
    };
    axios
      .post(
        baseUrl + `/registration/powerOfAttorneyDetails/powerOfAttorneyView`,
        data,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
        setGridData(res?.data?.data?.powerOfAttorneyRegistrationDetails);
      });
  }, []);

  //-----------print functionality start---------
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //-----------print functionality end---------
  return (
    <div>
      <div className="flex flex-wrap justify-between my-5">
        <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
        <div>
          <div className="form-control my-2">
            <div className="input-group flex flex-wrap">
              <h1 className="mx-2 mt-1">Search By</h1>
              <div className="form-control">
                <div className="input-group w-full">
                  <select className="select select-bordered select-sm w-2/5">
                    {/* <option disabled selected>
                      Please Select
                    </option> */}
                    {/* <option>Customer Name</option>
                    <option>Customer ID</option> */}
                    <option>File No.</option>
                    {/* <option>Building No.</option>
                    <option>Land Size(decimal)</option>
                    <option>Land Registration Date</option>
                    <option>Land Registration Sub-deed Mo.</option>
                    <option>CS Daag No</option>
                    <option>SA Daag No</option>
                    <option>RS Daag No</option>
                    <option>BS Daag No</option>
                    <option>CS Khatian No</option>
                    <option>SA Khatian No</option>
                    <option>RS Khatian No</option>
                    <option>BS Khatian No</option> */}
                  </select>
                  <input
                    type="text"
                    placeholder="Input Here..."
                    className="input input-bordered input-sm w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {gridData == null ? (
        <Loader></Loader>
      ) : (
        <>
          <div className="overflow-x-auto max-h-[60vh]">
            <table className="table table-zebra w-full" ref={componentRef}>
              <thead className="text-[#00CA08]">
                <tr className="sticky top-0">
                  <th>Customer’s Name</th>
                  <th>File No.</th>
                  <th>
                    Building/ <br /> Land No.
                  </th>
                  <th>
                    Plot Size <br />
                    (Decimal <br />
                    Per Person)
                  </th>
                  <th>
                    Power of <br /> Attorney <br /> Date
                  </th>
                  <th>
                    Power of <br /> Attorney <br /> Sub-deed No
                  </th>
                  <th>
                    CS <br /> Daag <br /> No
                  </th>
                  <th>
                    SA <br /> Daag <br /> No
                  </th>
                  <th>
                    RS <br /> Daag <br /> No
                  </th>
                  <th>
                    BS <br /> Daag <br /> No
                  </th>
                  <th>
                    CS <br /> Khatian <br /> No
                  </th>
                  <th>
                    SA <br /> Khatian <br /> No
                  </th>
                  <th>
                    RS <br /> Khatian <br /> No
                  </th>
                  <th>
                    BS <br /> Khatian <br /> No
                  </th>
                </tr>
              </thead>
              <tbody>
                {gridData?.length > 0 ? (
                  gridData?.map((item, i) => (
                    <tr key={i}>
                      <td>
                        {item?.power_of_attorney_registration?.flat_or_plot_for_power_of_attorney?.customers?.map(
                          (dt, index) => <p key={index}>{dt?.name}</p>
                        ) || "--"}
                      </td>
                      <td>
                        {
                          item?.power_of_attorney_registration
                            ?.flat_or_plot_for_power_of_attorney?.file_no
                        }
                      </td>
                      <td>
                        {
                          item?.power_of_attorney_registration
                            ?.flat_or_plot_for_power_of_attorney?.project_id
                        }
                      </td>
                      <td>{item?.land_size}</td>
                      <td>{item?.registration_date}</td>
                      <td>{item?.sub_deed_no}</td>
                      <td>{item?.cs_daag_no}</td>
                      <td>{item?.sa_daag_no}</td>
                      <td>{item?.rs_daag_no}</td>
                      <td>{item?.bs_daag_no}</td>
                      <td>{item?.cs_khatian_no}</td>
                      <td>{item?.sa_khatian_no}</td>
                      <td>{item?.rs_khatian_no}</td>
                      <td>{item?.bs_khatian_no}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      <h2
                        className="text-center py-5 col-span-10 font-bold"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        No Power of Attorney Details Found
                      </h2>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default PowerAttorneySearch;
