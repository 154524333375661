import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../BaseUrl";
import Heading from "../../Shared/Heading";

const UpdateFlatPriceInfo = () => {
  const [preData, setPreData] = useState(null);
  const [downPaymentLength, setDownPaymentLength] = useState([]);
  const [additionalPaymentLength, setAdditionalPaymentLength] = useState([]);
  const [installmentLength, setInstallmentLength] = useState([]);
  const [reFetch, setReFetch] = useState(false);
  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      total_additional_amount:
        preData?.price_information?.total_additional_amount,
    },
  });
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [customerName, setCustomerName] = useState("");
  const [customerId, setCustomerId] = useState("");


  useEffect(() => {
    axios
      .get(
        baseUrl + `/customer/price/detail/${clientId}`,

        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        // console.log(res?.data?.detail);
        setPreData(res?.data?.detail);
        setValue(
          "total_additional_amount",
          res?.data?.detail?.price_information?.total_additional_amount || 0
        );
        setDownPaymentLength(
          res?.data?.detail?.price_information?.down_payment
        );
        const fieldName = [
          {
            fieldTitle: "Additional Amount for Land Price",
            type: "land_price",
          },
          {
            fieldTitle: "Additional Amount for Flat Price",
            type: "flat_price",
          },
          {
            fieldTitle: "Additional Amount for Flat Size increase",
            type: "flat_size_increase",
          },
          {
            fieldTitle: "Additional Amount for Flat Renovation",
            type: "flat_renovation",
          },
          {
            fieldTitle: "Additional Amount for Registration & Mutation cost",
            type: "reg_mutation_cost",
          },
          {
            fieldTitle: "Additional Amount for Flat Decoration",
            type: "flat_decoration",
          },
        ];

        const modifyAdditionalAmount =
          res?.data?.detail?.price_information?.additional_amount?.map(
            (v, i) => {
              return { ...v, fieldTitle: fieldName[i].fieldTitle };
            }
          );
        setAdditionalPaymentLength(modifyAdditionalAmount);
        setInstallmentLength(res?.data?.detail?.price_information?.installment);
        let name = "";
        let id = "";
        for (let i = 0; i < res?.data?.detail?.customers?.length; i++) {
          // console.log(res?.data?.detail?.customers[i]);
          if (i == 0) {
            name = res?.data?.detail?.customers[i]?.name;
            id = res?.data?.detail?.customers[i]?.id;
          } else {
            name = name + "/" + res?.data?.detail?.customers[i]?.name;
            id = id + "/" + res?.data?.detail?.customers[i]?.id;
          }
        }
        setCustomerName(name);
        setCustomerId(id);
      });
  }, [clientId, reFetch]);

  // console.log(preData);
  const onFormSubmit = (data) => {
    // console.log(data);
    let submitData = data;
    let newData = {};
    newData.flat_or_plot_id = preData?.id;
    newData.project_type = 1;

    if (submitData?.total_money == "") {
      newData.total_money = preData?.price_information?.total_money;
    } else {
      newData.total_money = submitData?.total_money;
    }
    if (submitData?.booking_money == "") {
      newData.booking_money = preData?.price_information?.booking_money;
    } else {
      newData.booking_money = submitData?.booking_money;
    }
    if (submitData?.car_parking == "") {
      newData.car_parking = preData?.price_information?.car_parking;
    } else {
      newData.car_parking = submitData?.car_parking;
    }
    if (submitData?.utility_charge == "") {
      newData.utility_charge = preData?.price_information?.utility_charge;
    } else {
      newData.utility_charge = submitData?.utility_charge;
    }
    if (submitData?.additional_work_amount == "") {
      newData.additional_work_amount =
        preData?.price_information?.additional_work_amount;
    } else {
      newData.additional_work_amount = submitData?.additional_work_amount;
    }
    if (submitData?.total_downpayment_amount == "") {
      newData.total_downpayment_amount =
        preData?.price_information?.total_downpayment_amount;
    } else {
      newData.total_downpayment_amount = submitData?.total_downpayment_amount;
    }
    if (submitData?.total_installment_amount == "") {
      newData.total_installment_amount =
        preData?.price_information?.total_installment_amount;
    } else {
      newData.total_installment_amount = submitData?.total_installment_amount;
    }
    if (submitData?.per_month_installment_amount == "") {
      newData.per_month_installment_amount =
        preData?.price_information?.per_month_installment_amount;
    } else {
      newData.per_month_installment_amount =
        submitData?.per_month_installment_amount;
    }
    //  date code
    if (submitData?.booking_money_date == "") {
      newData.booking_money_date =
        preData?.price_information?.booking_money_date;
    } else {
      newData.booking_money_date = submitData?.booking_money_date;
    }
    if (submitData?.booking_money_date == "") {
      newData.booking_money_date =
        preData?.price_information?.booking_money_date;
    } else {
      newData.booking_money_date = submitData?.booking_money_date;
    }
    if (submitData?.car_parking_date == "") {
      newData.car_parking_date = preData?.price_information?.car_parking_date;
    } else {
      newData.car_parking_date = submitData?.car_parking_date;
    }
    if (submitData?.utility_charge_date == "") {
      newData.utility_charge_date =
        preData?.price_information?.utility_charge_date;
    } else {
      newData.utility_charge_date = submitData?.utility_charge_date;
    }
    if (submitData?.additional_work_amount_date == "") {
      newData.additional_work_amount_date =
        preData?.price_information?.additional_work_amount_date;
    } else {
      newData.additional_work_amount_date =
        submitData?.additional_work_amount_date;
    }

    // downpayment
    let newDownPayment = [];
    for (let i = 0; i < downPaymentLength?.length; i++) {
      let payment = downPaymentLength[i];
      payment.downpayment_no = `Downpayment ${i + 1}`;
      if (!downPaymentLength[i]?.id) {
        payment.id = null;
      }
      //  amount
      if (!submitData?.downpayment[i]?.amount) {
        payment.amount = 0;
      } else if (submitData?.downpayment[i]?.amount == "") {
        payment.amount = downPaymentLength[i]?.amount;
      } else {
        payment.amount = submitData?.downpayment[i]?.amount;
      }
      //  start date
      if (!submitData?.downpayment[i]?.start_date) {
        payment.start_date = "";
      } else if (submitData?.downpayment[i]?.start_date == "") {
        payment.start_date = downPaymentLength[i]?.start_date;
      } else {
        payment.start_date = submitData?.downpayment[i]?.start_date;
      }
      newDownPayment.push(payment);
    }
    newData.downpayment = newDownPayment;
    // =================================================
    // additional payment
    let additionalPayment = [];
    for (let i = 0; i < additionalPaymentLength?.length; i++) {
      let payment = additionalPaymentLength[i];
      if (!additionalPaymentLength[i]?.id) {
        payment.id = null;
      }
      //  amount
      if (!submitData?.additionalPayment[i]?.amount) {
        payment.amount = 0;
      } else if (submitData?.additionalPayment[i]?.amount == "") {
        payment.amount = additionalPaymentLength[i]?.amount;
      } else {
        payment.amount = submitData?.additionalPayment[i]?.amount;
      }
      //  start date
      if (!submitData?.additionalPayment[i]?.start_date) {
        payment.start_date = "";
      } else if (submitData?.additionalPayment[i]?.start_date == "") {
        payment.start_date = additionalPayment[i]?.start_date;
      } else {
        payment.start_date = submitData?.additionalPayment[i]?.start_date;
      }
      additionalPayment.push(payment);
    }
    newData.additional_amount = additionalPayment;

    // installment
    let newInstallment = [];
    for (let i = 0; i < installmentLength?.length; i++) {
      let payment = installmentLength[i];
      if (!installmentLength[i]?.id) {
        payment.id = null;
      }
      //  amount
      if (!submitData?.installment[i]?.amount) {
        payment.amount = 0;
      } else if (submitData?.installment[i]?.amount == "") {
        payment.amount = installmentLength[i]?.amount;
      } else {
        payment.amount = submitData?.installment[i]?.amount;
      }
      //  start date
      if (!submitData?.installment[i]?.start_date) {
        payment.start_date = "";
      } else if (submitData?.installment[i]?.start_date == "") {
        payment.start_date = installmentLength[i]?.start_date;
      } else {
        payment.start_date = submitData?.installment[i]?.start_date;
      }
      newInstallment.push(payment);
    }
    newData.installment = newInstallment;
    newData.total_additional_amount = data.total_additional_amount;
    newData.number_of_installment = newData.installment.length;

    // console.log(newData);
    axios
      .post(baseUrl + "/customer/price/update", newData, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("crm-tdata")
          )}`,
          "content-type": "text/json",
        },
      })
      .then((res) => {
        if (res?.data?.status == "failed") {
          if (Array?.isArray(res?.data?.message)) {
            res?.data?.message?.map((item) => toast.error(item));
          } else {
            toast.error(res?.data?.message);
          }
        } else {
          toast.success("Update price successfully!");
          navigate("/dashboard/clientpriceinfo");
        }
        // if (res?.data?.message) {
        //   toast.success(res?.data?.message);
        //   reset();
        //   setReFetch(!reFetch);
        // }
      });
  };

  const changeInstallmentLength = (lengthValue) => {
    // console.log(lengthValue);
    let newArray = [...installmentLength];
    if (installmentLength?.length < lengthValue) {
      for (let i = 0; i < lengthValue - installmentLength?.length; i++) {
        newArray.push({});
      }
    } else {
      newArray = newArray.slice(0, lengthValue);
    }
    // console.log(newArray);
    setInstallmentLength(newArray);
  };

  return (
    <div>
      <Heading>
        <Link to="/dashboard/clientpriceinfo">
          <i className="fa-solid fa-arrow-left"></i> Update Flat Price{" "}
        </Link>
      </Heading>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="whiteBackground py-1">
          <div>
            <fieldset className="border-2 border-[#949494] m-5">
              <legend className="mx-4">
                <span className="bg-[#949494] text-[white] py-1.5 px-4 rounded-md">
                  {" "}
                  Previously Submitted Informations
                </span>
              </legend>
              <div className="grid lg:grid-cols-5 ">
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      File No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={preData?.file_no}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Flat No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={preData?.flat_number}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Flat Size
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={`${preData?.size} sqf`}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Flat Floor No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={preData?.floor_no}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Flat Face Direction
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={preData?.face_direction}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Building Name
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={preData?.project?.name}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Building No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={preData?.project?.project_no}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Building Face Direction
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={preData?.project?.face_direction}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Building Location
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={preData?.project?.location}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Road No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={preData?.project?.road_no}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Customer Name
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={customerName}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg p-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Customer ID
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={customerId}
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
              </div>
            </fieldset>
          </div>
          <div className="grid lg:grid-cols-3 gap-5 m-5">
            <div>
              <fieldset className="border-2 border-[#1F497D]">
                <legend className="mx-auto">
                  <span className="bg-[#1F497D] text-[white] py-1.5 px-4 rounded-md">
                    {" "}
                    Price Information
                  </span>
                </legend>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Total Money
                    </span>
                  </label>
                  <input
                    name="total_money"
                    {...register(`total_money`)}
                    defaultValue={preData?.price_information?.total_money}
                    type="text"
                    placeholder="Total Money"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Booking Money
                    </span>
                    <span className="label-text font-bold text-[#887d7d]">
                      Start Date
                    </span>
                  </label>
                  <div>
                    <label className="input-group">
                      <input
                        {...register(`booking_money`)}
                        name="booking_money"
                        defaultValue={preData?.price_information?.booking_money}
                        type="text"
                        placeholder="Booking Money"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                      <input
                        {...register(`booking_money_date`)}
                        name="booking_money_date"
                        defaultValue={
                          preData?.price_information?.booking_money_date
                        }
                        type="date"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                    </label>
                  </div>
                </div>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Car Parking
                    </span>
                    <span className="label-text font-bold text-[#887d7d]">
                      Start Date
                    </span>
                  </label>
                  <div>
                    <label className="input-group">
                      <input
                        name="car_parking"
                        {...register(`car_parking`)}
                        defaultValue={preData?.price_information?.car_parking}
                        type="text"
                        placeholder="Car Parking"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                      <input
                        name="car_parking_date"
                        {...register(`car_parking_date`)}
                        defaultValue={
                          preData?.price_information?.car_parking_date
                        }
                        type="date"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                    </label>
                  </div>
                </div>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Utility Charge
                    </span>
                    <span className="label-text font-bold text-[#887d7d]">
                      Start Date
                    </span>
                  </label>
                  <div>
                    <label className="input-group">
                      <input
                        name="utility_charge"
                        {...register(`utility_charge`)}
                        defaultValue={
                          preData?.price_information?.utility_charge
                        }
                        type="text"
                        placeholder="Utility Charge"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                      <input
                        name="utility_charge_date"
                        {...register(`utility_charge_date`)}
                        defaultValue={
                          preData?.price_information?.utility_charge_date
                        }
                        type="date"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                    </label>
                  </div>
                </div>
                {/* <div className="form-control w-full max-w-lg px-4 py-1.5 mb-2">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Additional Work Amount
                    </span>
                    <span className="label-text font-bold text-[#887d7d]">
                      Start Date
                    </span>
                  </label>
                  <div>
                    <label className="input-group">
                      <input
                        name="additional_work_amount"
                        {...register("additional_work_amount")}
                        defaultValue={
                          preData?.price_information?.additional_work_amount
                        }
                        type="text"
                        placeholder="Additional Work Amount"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                      <input
                        name="additional_work_amount_date"
                        {...register("additional_work_amount_date")}
                        defaultValue={
                          preData?.price_information
                            ?.additional_work_amount_date
                        }
                        type="date"
                        className="input input-bordered input-sm w-full max-w-lg"
                      />
                    </label>
                  </div>
                </div> */}
              </fieldset>
            </div>
            <div>
              <fieldset className="border-2 border-[#1F497D]">
                <legend className="mx-auto">
                  <span className="bg-[#1F497D] text-[white] py-1.5 px-4 rounded-md">
                    {" "}
                    Downpayment Information
                  </span>
                </legend>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Total Downpayment Amount
                    </span>
                  </label>
                  <label className="input-group">
                    <input
                      name="total_downpayment_amount"
                      {...register(`total_downpayment_amount`)}
                      defaultValue={
                        preData?.price_information?.total_downpayment_amount
                      }
                      type="text"
                      placeholder="Total Downpayment Amount"
                      className="input input-bordered input-sm w-full max-w-lg mr-2"
                    />
                    <span
                      className="ml-2 bg-[#16A085]"
                      onClick={() =>
                        setDownPaymentLength([...downPaymentLength, {}])
                      }
                    >
                      <div>
                        <i className="fa-solid fa-circle-plus text-white"></i>
                      </div>
                    </span>
                  </label>
                </div>
                {downPaymentLength?.map((downPayment, index) => {
                  return (
                    <div className="form-control w-full max-w-lg px-4 py-1.5 mb-2">
                      <label className="label">
                        <span className="label-text font-bold text-[#887d7d]">
                          Downpayment {index + 1}
                        </span>
                        <span className="label-text font-bold text-[#887d7d]">
                          Start Date
                        </span>
                      </label>
                      <div>
                        <label className="input-group">
                          <input
                            {...register(`downpayment[${index}].amount`)}
                            name={`downpayment[${index}].amount`}
                            defaultValue={downPayment?.amount}
                            type="text"
                            placeholder={`Downpayment ${index + 1}`}
                            className="input input-bordered input-sm w-full max-w-lg"
                          />
                          <input
                            {...register(`downpayment[${index}].start_date`)}
                            name={`downpayment[${index}].start_date`}
                            defaultValue={downPayment?.start_date}
                            type="date"
                            className="input input-bordered input-sm w-full max-w-lg"
                          />
                        </label>
                      </div>
                    </div>
                  );
                })}
              </fieldset>
            </div>

            {/* new field  */}
            <div>
              <fieldset className="border-2 border-[#1F497D]">
                <legend className="mx-auto">
                  <span className="bg-[#1F497D] text-[white] py-1.5 px-4 rounded-md">
                    {" "}
                    Additional Amount
                  </span>
                </legend>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Total Additional Amount
                    </span>
                  </label>
                  <label className="input-group">
                    <input
                      name="total_additional_amount"
                      {...register(`total_additional_amount`)}
                      defaultValue={
                        preData?.price_information?.total_additional_amount
                      }
                      type="text"
                      placeholder="Total Additional Amount"
                      className="input input-bordered input-sm w-full max-w-lg mr-2"
                    />
                    {/* <span
                      className="ml-2 bg-[#16A085]"
                      onClick={() =>
                        setAdditionalPaymentLength([...additionalPaymentLength, {}])
                      }
                    >
                      <div>
                        <i className="fa-solid fa-circle-plus text-white"></i>
                      </div>
                    </span> */}
                  </label>
                </div>
                {additionalPaymentLength?.map((additionalPayment, index) => {
                  return (
                    <div className="form-control w-full max-w-lg px-4 py-1.5 mb-2">
                      <label className="label">
                        <span className="label-text font-bold text-[#887d7d]">
                          {additionalPayment?.fieldTitle}
                        </span>
                        <span className="label-text font-bold text-[#887d7d]">
                          Start Date
                        </span>
                      </label>
                      <div>
                        <label className="input-group">
                          <input
                            {...register(`additionalPayment[${index}].amount`)}
                            name={`additionalPayment[${index}].amount`}
                            defaultValue={additionalPayment?.amount}
                            type="text"
                            placeholder={`additionalPayment ${index + 1}`}
                            className="input input-bordered input-sm w-full max-w-lg"
                          />
                          <input
                            {...register(
                              `additionalPayment[${index}].start_date`
                            )}
                            name={`additionalPayment[${index}].start_date`}
                            defaultValue={additionalPayment?.start_date}
                            type="date"
                            className="input input-bordered input-sm w-full max-w-lg"
                          />
                        </label>
                      </div>
                    </div>
                  );
                })}
              </fieldset>
            </div>
            <div>
              <fieldset className="border-2 border-[#1F497D]">
                <legend className="mx-auto">
                  <span className="bg-[#1F497D] text-[white] py-1.5 px-4 rounded-md">
                    {" "}
                    Installment Information
                  </span>
                </legend>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Total Installment Amount
                    </span>
                  </label>
                  <input
                    {...register("total_installment_amount")}
                    defaultValue={
                      preData?.price_information?.total_installment_amount
                    }
                    type="text"
                    placeholder="Total Installment Amount"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Per Month Installment Amount
                    </span>
                  </label>
                  <input
                    {...register("per_month_installment_amount")}
                    defaultValue={
                      preData?.price_information?.per_month_installment_amount
                    }
                    type="text"
                    placeholder="Per Month Installment Amount"
                    className="input input-bordered input-sm w-full max-w-lg"
                  />
                </div>
                <div className="form-control w-full max-w-lg px-4 py-1.5">
                  <label className="label">
                    <span className="label-text font-bold text-[#887d7d]">
                      Number of Installments
                    </span>
                  </label>
                  <select
                    {...register("number_of_installment")}
                    name="number_of_installment"
                    className="select select-bordered select-sm"
                    defaultValue="5555"
                    onChange={(e) => changeInstallmentLength(e.target.value)}
                  >
                    <option disabled>Number of Installments</option>
                    <option
                      value={0}
                      disabled={
                        preData?.price_information?.installment?.length > 0
                          ? true
                          : false
                      }
                      selected={
                        preData?.price_information?.installment?.length == 0
                          ? true
                          : false
                      }
                    >
                      0
                    </option>
                    <option
                      value={6}
                      disabled={
                        preData?.price_information?.installment?.length > 6
                          ? true
                          : false
                      }
                      selected={
                        preData?.price_information?.installment?.length == 6
                          ? true
                          : false
                      }
                    >
                      6
                    </option>
                    <option
                      value={12}
                      disabled={
                        preData?.price_information?.installment?.length > 12
                          ? true
                          : false
                      }
                      selected={
                        preData?.price_information?.installment?.length == 12
                          ? true
                          : false
                      }
                    >
                      12
                    </option>
                    <option
                      value={18}
                      disabled={
                        preData?.price_information?.installment?.length > 18
                          ? true
                          : false
                      }
                      selected={
                        preData?.price_information?.installment?.length == 18
                          ? true
                          : false
                      }
                    >
                      18
                    </option>
                    <option
                      value={24}
                      disabled={
                        preData?.price_information?.installment?.length > 24
                          ? true
                          : false
                      }
                      selected={
                        preData?.price_information?.installment?.length == 24
                          ? true
                          : false
                      }
                    >
                      24
                    </option>
                    <option
                      value={30}
                      disabled={
                        preData?.price_information?.installment?.length > 30
                          ? true
                          : false
                      }
                      selected={
                        preData?.price_information?.installment?.length == 30
                          ? true
                          : false
                      }
                    >
                      30
                    </option>
                    <option
                      value={36}
                      disabled={
                        preData?.price_information?.installment?.length > 36
                          ? true
                          : false
                      }
                      selected={
                        preData?.price_information?.installment?.length == 36
                          ? true
                          : false
                      }
                    >
                      36
                    </option>
                    <option
                      value={42}
                      disabled={
                        preData?.price_information?.installment?.length > 42
                          ? true
                          : false
                      }
                      selected={
                        preData?.price_information?.installment?.length == 42
                          ? true
                          : false
                      }
                    >
                      42
                    </option>
                    <option
                      value={48}
                      disabled={
                        preData?.price_information?.installment?.length > 48
                          ? true
                          : false
                      }
                      selected={
                        preData?.price_information?.installment?.length == 48
                          ? true
                          : false
                      }
                    >
                      48
                    </option>
                    <option
                      value={54}
                      disabled={
                        preData?.price_information?.installment?.length > 54
                          ? true
                          : false
                      }
                      selected={
                        preData?.price_information?.installment?.length == 54
                          ? true
                          : false
                      }
                    >
                      54
                    </option>
                    <option
                      value={60}
                      disabled={
                        preData?.price_information?.installment?.length > 60
                          ? true
                          : false
                      }
                      selected={
                        preData?.price_information?.installment?.length == 60
                          ? true
                          : false
                      }
                    >
                      60
                    </option>
                  </select>
                </div>
                {installmentLength?.map((installment, index) => {
                  return (
                    <div className="form-control w-full max-w-lg px-4 py-1.5">
                      <label className="label">
                        <span className="label-text font-bold text-[#887d7d]">
                          Installment {index + 1}
                        </span>
                        <span className="label-text font-bold text-[#887d7d]">
                          Date
                        </span>
                      </label>
                      <div>
                        <label className="input-group">
                          <input
                            {...register(`installment[${index}].amount`)}
                            defaultValue={installment?.amount}
                            type="text"
                            placeholder={`Installment ${index + 1}`}
                            className="input input-bordered input-sm w-full max-w-lg"
                          />
                          <input
                            {...register(`installment[${index}].start_date`)}
                            defaultValue={installment?.start_date}
                            type="date"
                            className="input input-bordered input-sm w-full max-w-lg"
                          />
                        </label>
                      </div>
                    </div>
                  );
                })}
              </fieldset>
            </div>

            {/* new field  */}
          </div>
        </div>
        <div className="flex justify-start mx-16 mb-10">
          {/* <Link to="/dashboard/clientpriceinfo"> */}
          <button
            type="submit"
            className="uppercase  text-white text-[14px] font-semibold bg-[#16A085] rounded-md py-1.5 px-16"
          >
            Update
          </button>
          {/* </Link> */}
        </div>
      </form>
    </div>
  );
};

export default UpdateFlatPriceInfo;
