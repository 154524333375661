import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Heading from "../../Shared/Heading";

const RegistrationClientSearch = () => {
  const [active, setActive] = useState("plot");
  const location = useLocation();
  useEffect(() => {
    if (location?.pathname.split("/")[3] == undefined) {
      setActive("plot");
    } else if (location?.pathname.split("/")[3] == "mutationsearch") {
      setActive("mutationsearch");
    } else if (location?.pathname.split("/")[3] == "powerattorneysearch") {
      setActive("powerattorneysearch");
    } else {
      ///
    }
  }, [location]);

  return (
    <div>
      {active == "plot" && (
        <Heading>Plot/Flat Registration Details Client Search</Heading>
      )}
      {active == "mutationsearch" && (
        <Heading>Mutation Registration Details Client Search</Heading>
      )}
      {active == "powerattorneysearch" && (
        <Heading>Power of Atorney Registration Details Client Search</Heading>
      )}
      <div className="px-5 whiteBackground">
        <div className="flex flex-wrap flex-row my-5">
          <div className="basis-1/10 my-2">
            <Link to="/dashboard/registrationclientsearch">
              <button
                onClick={() => setActive("plot")}
                className={`${
                  active == "plot"
                    ? "btn btn-sm mr-2 bg-slate-800 text-white"
                    : "btn btn-sm btn-outline mr-2"
                }`}
              >
                Plot/Flat Details
              </button>
            </Link>
          </div>
          <div className="basis-2/10 my-2">
            <Link to="/dashboard/registrationclientsearch/mutationsearch">
              <button
                onClick={() => setActive("mutationsearch")}
                className={`${
                  active == "mutationsearch"
                    ? "btn btn-sm mr-2 bg-slate-800 text-white"
                    : "btn btn-sm btn-outline mr-2"
                }`}
              >
                Mutation Details
              </button>
            </Link>
          </div>
          <div className="basis-2/10 my-2">
            <Link to="/dashboard/registrationclientsearch/powerattorneysearch">
              <button
                onClick={() => setActive("powerattorneysearch")}
                className={`${
                  active == "powerattorneysearch"
                    ? "btn btn-sm mr-2 bg-slate-800 text-white"
                    : "btn btn-sm btn-outline mr-2"
                }`}
              >
                Power of Attorney Details
              </button>
            </Link>
          </div>
        </div>

        <Outlet></Outlet>
      </div>
    </div>
  );
};

export default RegistrationClientSearch;
