import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { baseUrl } from "../../../BaseUrl";
import CopyPrintPdfBtn from "../../Shared/CopyPrintPdfBtn";
import Heading from "../../Shared/Heading";
import Loader from "../../Shared/Loader";
import PaginationSection from "../../Shared/PaginationSection";

const ViewBuildingAndPlotDetails = () => {
  const { id } = useParams();
  const [preData, setPreData] = useState([]);
  const [customer, setCustomer] = useState([]);

  useEffect(() => {
    axios
      .get(
        baseUrl + `/projects/customerDetails/${id}`,
    
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("crm-tdata")
            )}`,
            "content-type": "text/json",
          },
        }
      )
      .then((res) => {
        setPreData(res?.data?.details);
      });
  }, [id]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <Heading>
        <Link to="/dashboard/buildingandflatdetails">
          <i className="fa-solid fa-arrow-left"></i> Land & Plot Customer
          Details
        </Link>
      </Heading>
      <div className="information_container">
        <CopyPrintPdfBtn handlePrint={handlePrint}></CopyPrintPdfBtn>
        {preData == null ? (
          <Loader></Loader>
        ) : (
          <div className="infomation_content print-section" ref={componentRef}>
            <h1 className="title text-center text-2xl font-bold underline underline-offset-4 text-[#333547] pt-8">
              Land & Plot Customer Details
            </h1>
            <div className="m-8 px-8 print-section-body">
              <h1 className="text-start text-md font-bold underline underline-offset-4 text-[#717A82] ">
                Details
              </h1>
              <div className="grid lg:grid-cols-5 gap-2">
                <div className="form-control w-full max-w-xs mt-5 mx-1">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Land Name
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={preData?.name}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs mt-5 mx-1">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Road No.
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={preData?.road_no}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs mt-5 mx-1">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Land Number
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={preData?.project_no}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs mt-5 mx-1">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Land Face Direction
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={preData?.face_direction}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
                <div className="form-control w-full max-w-xs mt-5 mx-1">
                  <label className="label">
                    <span className="label-text font-bold text-[#717A82]">
                      Land Location
                    </span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={preData?.location}
                    className="input input-bordered input-sm w-full max-w-xs"
                  />
                </div>
              </div>
            </div>
            {preData?.flat_or_plot?.length > 0 ? (
              <div className="overflow-x-auto m-5 max-h-[60vh] table-container ">
                <table className="table table-zebra w-[100%]">
                  <thead className="text-[#00CA08]">
                    <tr className="sticky top-0">
                      <th>
                        Customer's <br /> Country
                      </th>
                      <th>
                        Customer's <br /> Name
                      </th>
                      <th>
                        Customer's <br /> Id
                      </th>
                      <th>File No.</th>
                      <th>
                        Plot <br /> Number
                      </th>
                      <th>
                        Plot <br /> Face Direction
                      </th>
                      <th>Plot Size</th>
                    </tr>
                  </thead>
                  <tbody>
                    {preData?.flat_or_plot?.map((list) => (
                      <tr key={list?.id}>
                        <td>
                          {list?.customers?.length == 0
                            ? "---"
                            : list?.customers?.map((customer, index) => {
                                return <p>{customer?.country}</p>;
                              })}
                        </td>
                        <td>
                          {list?.customers?.length == 0
                            ? "---"
                            : list?.customers?.map((customer, index) => {
                                return <p>{customer?.name}</p>;
                              })}
                        </td>
                        <td>
                          {list?.customers?.length == 0
                            ? "---"
                            : list?.customers?.map((customer, index) => {
                                return <p>{customer?.id}</p>;
                              })}
                        </td>
                        <td>{list?.file_no}</td>
                        <td>{list?.plot_no}</td>
                        <td>{list?.face_direction}</td>
                        <td>{list?.size}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <h2 className="text-center py-5">No Plot Found</h2>
            )}
            <div className="m-5 py-4">
              {/* <PaginationSection></PaginationSection> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewBuildingAndPlotDetails;
