import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/logo.png";
import "./Sidebar.css";

const Sidebar = () => {
  const location = useLocation();
  const activeRoute = location.pathname.split("/")[2];

  //submenu toggole handling
  const [isOpen, setIsOpen] = useState(false);

  const toggleSubmenuReports = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="menu">
      <ul className="overflow-y-auto w-64">
        <div className="flex">
          <label
            tabIndex="1"
            htmlFor="dashboard-sidebar"
            className="btn btn-ghost lg:hidden rounded-none h-[64px] drawer-button bg-white text-slate-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
          </label>
          <Link className="mb-2" to="/dashboard" element="">
            <img
              style={{ height: "64px" }}
              src={logo}
              alt=""
              className="w-64"
            />
          </Link>
        </div>

        <NavLink to="/dashboard">
          <li
            className={
              activeRoute == undefined ? "bg-white text-slate-800" : ""
            }
          >
            <div>
              <span>1. Dashboard</span>
            </div>
          </li>
        </NavLink>
        <NavLink to="/dashboard/clientinfo">
          <li
            className={
              activeRoute == "clientinfo" ? "bg-white text-slate-800" : ""
            }
          >
            <div>
              <span>2. Client Information</span>
            </div>
          </li>
        </NavLink>
        <NavLink to="/dashboard/clientpriceinfo">
          <li
            className={
              activeRoute == "clientpriceinfo" ? "bg-white text-slate-800" : ""
            }
          >
            <div>
              <span>3. Client Price Information</span>
            </div>
          </li>
        </NavLink>
        <NavLink to="clientpaymentlist">
          <li
            className={
              activeRoute == "clientpaymentlist"
                ? "bg-white text-slate-800"
                : ""
            }
          >
            <div>
              <span>4. Client Payment List</span>
            </div>
          </li>
        </NavLink>
        <NavLink to="/dashboard/clientdueamount">
          <li
            className={
              activeRoute == "clientdueamount" ? "bg-white text-slate-800" : ""
            }
          >
            <div>
              <span>5. Client Due Amount Details</span>
            </div>
          </li>
        </NavLink>
        <NavLink to="/dashboard/registrationamount">
          <li
            className={
              activeRoute == "registrationamount"
                ? "bg-white text-slate-800"
                : ""
            }
          >
            <div>
              <span>6. Registration Amount Details</span>
            </div>
          </li>
        </NavLink>
        <NavLink to="/dashboard/searchpropertystatus">
          <li
            className={
              activeRoute == "searchpropertystatus"
                ? "bg-white text-slate-800"
                : ""
            }
          >
            <div>
              <span>7. Search By Property Registration Status</span>
            </div>
          </li>
        </NavLink>
        <NavLink to="/dashboard/registrationnumberdetails">
          <li
            className={
              activeRoute == "registrationnumberdetails"
                ? "bg-white text-slate-800"
                : ""
            }
          >
            <div>
              <span>8. Registration Number Details</span>
            </div>
          </li>
        </NavLink>
        <NavLink to="/dashboard/registrationclientsearch">
          <li
            className={
              activeRoute == "registrationclientsearch"
                ? "bg-white text-slate-800"
                : ""
            }
          >
            <div>
              <span>9. Registration Details Client Search</span>
            </div>
          </li>
        </NavLink>
        <NavLink to="/dashboard/buildingandflatdetails">
          <li
            className={
              activeRoute == "buildingandflatdetails"
                ? "bg-white text-slate-800"
                : ""
            }
          >
            <div>
              <span>10. Add Building And Land Details</span>
            </div>
          </li>
        </NavLink>
        {/* <NavLink to="/dashboard/additionalamountpaymentcustomerlist"> */}
          <li
            className={isOpen ? "bg-white text-slate-800" : ""}
            onClick={toggleSubmenuReports}
          >
            <div className="flex justify-between">
              <div>11. Reports </div>
              <div>
                {isOpen ? (
                  <i className="fa-solid fa-arrow-down fs-6"></i>
                ) : (
                  <i className="fa-solid fa-arrow-right"></i>
                )}
              </div>
            </div>
          </li>
        {/* </NavLink> */}
        {isOpen && (
          <div className="submenu">
            <ul className="">
              <NavLink to="/dashboard/additionalamountpaymentcustomerlist">
                <li
                  className={
                    activeRoute == "additionalamountpaymentcustomerlist"
                      ? "bg-white text-slate-800"
                      : ""
                  }
                >
                  a. Additional amount payment customer list
                </li>
              </NavLink>
              <NavLink to="/dashboard/installmentduelist">
                <li
                  className={
                    activeRoute == "installmentduelist"
                      ? "bg-white text-slate-800"
                      : ""
                  }
                >
                  b. Installment Due List
                </li>
              </NavLink>
              <NavLink to="/dashboard/downpaymentduelist">
                <li
                  className={
                    activeRoute == "downpaymentduelist"
                      ? "bg-white text-slate-800"
                      : ""
                  }
                >
                  c. Downpayment Due List
                </li>
              </NavLink>
              <NavLink to="/dashboard/additionalamountduelist">
                <li
                  className={
                    activeRoute == "additionalamountduelist"
                      ? "bg-white text-slate-800"
                      : ""
                  }
                >
                  d. Additional Amount Due List
                </li>
              </NavLink>
              <NavLink to="/dashboard/utilitychargeandcarparkingpaymentduelist">
                <li
                  className={
                    activeRoute == "utilitychargeandcarparkingpaymentduelist"
                      ? "bg-white text-slate-800"
                      : ""
                  }
                >
                  e. Utility Charge due list & car parking payment due list
                </li>
              </NavLink>
              <NavLink to="/dashboard/registrationandmutationduelist">
                <li
                  className={
                    activeRoute == "registrationandmutationduelist"
                      ? "bg-white text-slate-800"
                      : ""
                  }
                >
                  f. Registration & Mutation due list
                </li>
              </NavLink>
              <NavLink to="/dashboard/registrationcompletebutpaymentduelist">
                <li
                  className={
                    activeRoute == "registrationcompletebutpaymentduelist"
                      ? "bg-white text-slate-800"
                      : ""
                  }
                >
                  g. Registration complete but payment due list
                </li>
              </NavLink>
              <NavLink to="/dashboard/canceledcustomerlist">
                <li
                  className={
                    activeRoute == "canceledcustomerlist"
                      ? "bg-white text-slate-800"
                      : ""
                  }
                >
                  h. Canceled customer list
                </li>
              </NavLink>
              <NavLink to="/dashboard/afterregistrationcanceledcustomerlist">
                <li
                  className={
                    activeRoute == "afterregistrationcanceledcustomerlist"
                      ? "bg-white text-slate-800"
                      : ""
                  }
                >
                  i. After Registration Canceled customer list
                </li>
              </NavLink>
            </ul>
          </div>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
