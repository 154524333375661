import React from "react";
// import Loader from "../../Shared/Loader";
import { Link, useLocation } from "react-router-dom";
import Heading from "../../Shared/Heading";

const ViewAfterRegiAllPaymentSlip = () => {
  const location = useLocation();
  const viewCancelPaymentData = location.state.data;
  return (
    <div>
      <Heading>
        <Link to="/dashboard/afterregistrationcanceledcustomerlist">
          <i className="fa-solid fa-arrow-left"></i> After Registration Canceled
          All Payment Slip
        </Link>
      </Heading>
      <div className="information_container">
        <div className="infomation_content">
          <div className="print-section">
            <div className="print-section p-5">
              <div className="form-control w-full  mx-2 text-slate-900">
                <p className="font-bold text-[20px]">
                  <span>File No</span> :{" "}
                  {viewCancelPaymentData?.flat_or_plot?.file_no}
                </p>
              </div>
              <div className="flex flex-wrap">
                <p className="text-slate-700 font-semibold m-2">
                  <span className="underline">Customer's name</span> :{" "}
                  {viewCancelPaymentData?.flat_or_plot?.customers
                    ?.map((item) => item.name)
                    .join(", ")}
                </p>
                <p className="text-slate-700 font-semibold m-2">
                  <span className="underline">Customer's Id</span> :{" "}
                  {viewCancelPaymentData?.flat_or_plot?.customers
                    ?.map((item) => item.id)
                    .join(", ")}
                </p>
                <p className="text-slate-700 font-semibold m-2">
                  <span className="underline">Mobile No</span> :{" "}
                  {viewCancelPaymentData?.flat_or_plot?.customers
                    ?.map((item) => item.phone_number)
                    .join(", ")}
                </p>
              </div>
              <div className="flex flex-wrap">
                <p className="text-slate-700 font-semibold m-2">
                  <span className="underline">Building/Land No</span> :{" "}
                  {
                    viewCancelPaymentData?.flat_or_plot?.project_info
                      ?.project_no
                  }
                </p>
                <p className="text-slate-700 font-semibold m-2">
                  <span className="underline">Flat/Plot Size</span> :{" "}
                  {viewCancelPaymentData?.flat_or_plot?.size}
                </p>
              </div>
            </div>
            <div>
              {viewCancelPaymentData?.customer_payment?.length > 0 ? (
                <div className="overflow-x-auto mx-5 max-h-[60vh] table-container">
                  <table className="table table-zebra w-[100%]">
                    <thead className="text-[#00CA08]">
                      <tr className="sticky top-0">
                        <th>Payment Date</th>
                        <th>New Address</th>
                        <th>Payment Details</th>
                        <th>Payment Invoice</th>
                        <th>Payment Amount</th>
                        <th>Received By</th>
                        <th>Staff Name</th>
                        <th>View Slip</th>
                      </tr>
                    </thead>
                    <tbody>
                      {viewCancelPaymentData?.customer_payment?.map((list) => (
                        <tr key={list?.id}>
                          <td>{list?.payment_date}</td>
                          <td>{list?.new_address}</td>
                          <td>{list?.payment_method}</td>
                          <td>{list?.invoice_no}</td>
                          <td>{list?.payment_amount}</td>
                          <td>{list?.received_by}</td>
                          <td>{list?.staff_name}</td>
                          <td className="hide-when-print">
                            <button>
                              <Link
                                to={`/dashboard/singlepaymentslipafterregi`}
                                state={{
                                  data: list,
                                  viewCancelPaymentData: viewCancelPaymentData,
                                }}
                              >
                                <i className="fa-solid fa-eye m-2 text-xl"></i>
                              </Link>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <h1 className="text-center font-bold my-5 py-5">No Data Found</h1>
              )}{" "}
            </div>
          </div>
          <div className="m-5 py-4">
            {/* <PaginationSection></PaginationSection> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAfterRegiAllPaymentSlip;
