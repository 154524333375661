import React from "react";
import "./DashboardCard.css";

const DashboardCard = ({ dashboardItem }) => {
  const { img, title, number, landinfo } = dashboardItem;
  return (
    <div className="card w-full rounded-[4px] shadow-sm hover:shadow-lg hover:shadow-[#ced1e4]  transition border-[2px] hover:border-[2px] hover:border-slate-400 ">
      <div className="card-img bg-[#ebebef]  border border-slate-400 flex justify-center items-center  rounded-full">
        <img src={img} alt="" />
      </div>
      <div className="card-content">
        <p className="font-semibold">{title}</p>
        <p>{landinfo}</p>
        <h1>{number}</h1>
      </div>
    </div>
  );
};

export default DashboardCard;
